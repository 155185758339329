import { Form, Input, Select, Switch, Space, Button } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const BanquetEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();
    // const [branchStoreData, setbranchStoreData] = useState<any>();
    /** 后端数据wmService */
    let wmService: any = "";

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'banquet',
    };
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            //查找品牌数据
            // httpGet(wmService, {
            //     apiId: "branchStore",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {

            //         setbranchStoreData(res.data)
            //     }
            // })


        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // const aa = (key: any, name: any, e: any) => {
    //     console.log("e", e);
    //     console.log("name", name);
    //     console.log("key", key);

    //   if(key===2){
    //     form.resetFields(["users",key,"last"])
    //   }

    //     // form.getFieldValue("users")[key].last="222"
    //     form.setFieldValue(["users",key,"last"],"2")

    //     let items = form.getFieldValue("users")
    //     console.log("item", items);
    // }
    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '宴会'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="宴名"
                        name="banquetName"
                        rules={[{ required: true, message: '请输入宴名!' }]}>
                        <Input />
                    </Form.Item>


                    {/* <Form.Item
                        label="品牌"
                        name="brandId"
                    >

                        <Select

                            showSearch
                            placeholder="品牌"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(brandData, "brandName")}
                        </Select>
                    </Form.Item> */}

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        < Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                    {/* <Form.List name="users">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'first']}
                                            rules={[{ required: true, message: 'Missing first name' }]}
                                        >
                                            <Select
                                                showSearch
                                                placeholder="选择分店"
                                                optionFilterProp="children"
                                                onChange={aa.bind(props, name, key)}
                                                filterOption={(input, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {getList.general(branchStoreData, 'branchStoreName')}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={"cc"}
                                            rules={[{ required: true, message: 'Missing last name' }]}
                                        >
                                            <Input  placeholder="Last Name" />
                                        </Form.Item>
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Add field
                                    </Button>
                                </Form.Item>
                            </>
                        )}
                    </Form.List> */}


                </Form>
            </AntDraggableModal>
        </>
    )
}

export default BanquetEdit;