import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';

const { TextArea } = Input;

export default function DeviceEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const [deviceTypeData, setDeviceTypeData] = useState<any>();

    const [branchStoreData, setBranchStoreData] = useState<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    if (data.type === "EDIT") {
                        let ipAddressRow = res.data.ipAddress.split('.');
                        for (var i = 0; i < ipAddressRow.length; i++) {
                            let str = "ipAddress_" + i
                            form.setFieldValue([str], ipAddressRow[i]);
                        }
                    }
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "deviceType",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setDeviceTypeData(res.data)
                }
            })

            fetchApi.GET(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setBranchStoreData(res.data)
                }
            })
        }
        fetchData()
    }, [])


    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'device'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '设备'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="门店"
                                name="branchStoreId"
                                rules={[{ required: true, message: '请选择门店!' }]}>
                                <Select
                                    showSearch
                                    placeholder="门店"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(branchStoreData, 'branchStoreName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="设备类型"
                                name="deviceTypeId"
                                rules={[{ required: true, message: '请选择设备类型!' }]}>
                                <Select
                                    showSearch
                                    placeholder="设备类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(deviceTypeData, 'deviceTypeName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="设备名称"
                                name="deviceName"
                                rules={[{ required: true, message: '请输入设备名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="mac地址"
                                name="macAddress">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="IP地址" >
                                <Form.Item
                                    name="ipAddress_0"
                                    rules={[{ required: true, message: '请输入IP地址!' }]}
                                    style={{ display: 'inline-block' }}
                                >
                                    <InputNumber min={0} max={255} />
                                </Form.Item>
                                &nbsp;
                                .
                                &nbsp;
                                <Form.Item
                                    name="ipAddress_1"
                                    rules={[{ required: true, message: '请输入IP地址!' }]}
                                    style={{ display: 'inline-block' }}
                                >
                                    <InputNumber min={0} max={255} />
                                </Form.Item>
                                &nbsp;
                                .
                                &nbsp;
                                <Form.Item
                                    name="ipAddress_2"
                                    rules={[{ required: true, message: '请输入IP地址!' }]}
                                    style={{ display: 'inline-block' }}
                                >
                                    <InputNumber min={0} max={255} />
                                </Form.Item>
                                &nbsp;
                                .
                                &nbsp;
                                <Form.Item
                                    name="ipAddress_3"
                                    rules={[{ required: true, message: '请输入IP地址!' }]}
                                    style={{ display: 'inline-block' }}
                                >
                                    <InputNumber min={0} max={255} />
                                </Form.Item>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="memo"
                            >
                                <TextArea
                                    placeholder="备注"
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}