import { Tooltip } from "antd";

/** 位置类型 */
export declare type positionType = 'prefix' | 'suffix';

/**
 * 显示的内容
 * @param str 字符串
 * @param showLength ? 显示的字符长度，默认8位 
 * @param pos 位置
 */
const showText = (text: string, showLength?: number, pos?: positionType) => {
    pos = pos ? pos : 'prefix';
    return (
        <Tooltip placement="topLeft" title={text}>{
            (typeof text !== "undefined" && text !== null) ?
                (pos === 'prefix' ?
                    (text.length >= showLength! ? text.substring(0, showLength) + '......' : text)
                    :
                    (text.length >= showLength! ? '......' + text.substring(text.length - showLength!, text.length) : text)
                )
                : ''
        }</Tooltip>
    )
}

/**
 * 表头id，显示头8位......
 * @param title ? 展示的名称，默认:id
 * @param dataIndex ? 数据名，默认:id
 * @param showLength ? 显示的字符长度，默认8位 
 * @param pos 位置
 * @returns 
 */
const getColumnsheadId = (title?: string, dataIndex?: string,
    showLength?: number, pos?: positionType) => {
    showLength = showLength ? showLength : 8;
    pos = pos ? pos : 'prefix';
    const columnsheadId = [
        {
            title: typeof title !== "undefined" ? title : 'id',
            dataIndex: typeof dataIndex !== "undefined" ? dataIndex : 'id',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (pos === 'prefix' ?
                        (text.length >= showLength! ? text.substring(0, showLength) + '......' : text)
                        :
                        (text.length >= showLength! ? '......' + text.substring(text.length - showLength!, text.length) : text)
                    )
                    : ''
            }</Tooltip>
        },]
    return columnsheadId;
};

/**
 * 表头id显示，头8位......
 */
const columnsheadId = [
    {
        title: 'id',
        dataIndex: 'id',
        width:50,
        render: (text: any) => <Tooltip placement="topLeft" title={text}>{
            (typeof text !== "undefined" && text !== null) ?
                (text.length >= 6 ? text.substring(0, 6) + '....' : text)
                : ''
        }</Tooltip>
    },];

/**
 * 表头id显示，尾8位......
 */
const columnsheadEndId = [
    {
        title: 'id',
        dataIndex: 'id',
        render: (text: any) => <Tooltip placement="topLeft" title={text}>{
            (typeof text !== "undefined" && text !== null) ?
                (text.length >= 8 ? '......' + text.substring(text.length - 8, text.length) : text)
                : ''
        }</Tooltip>
    },];

/**
 * 表头id显示，Uuid，13位......
 */
const columnsheadUuid = [
    {
        title: 'id',
        dataIndex: 'id',
        render: (text: any) => <Tooltip placement="topLeft" title={text}>{
            typeof text !== "undefined" ?
                (text.length >= 13 ? text.substring(0, 13) + '......' : text)
                : ''
        }</Tooltip>
    },];

export {
    getColumnsheadId,
    columnsheadId,
    columnsheadEndId,
    columnsheadUuid,
    showText
};