import { Form, Input, InputNumber, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { httpGet } from '../../utils/api/fetchApi';
import AntDraggableModal from '../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../system/WebEditUniversalNewEx'
import getList from '../universal/getList';
const { TextArea } = Input;


//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};





const Ze8OrderEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    /** 后端数据wmService */
    let wmService: any = "";
    const [branchStoreData, setbranchStoreData] = useState<any>();
    const [smsCustomTypeData, setSmsCustomTypeData] = useState<any>();
    const ref = useRef<any>();
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'ze8Order',
    };

    /**订单状态的数据 */
    const [orderStatusId, setOrderStatusId] = useState<any>([
        {orderStatusId: "交易关闭",id: "-1",},
        {orderStatusId: "等待付款",id: "0",},
        {orderStatusId: "交易成功",id: "1",},
        {orderStatusId: "已付款，待取货",id: "2",},
        {orderStatusId: "已退款",id: "-99",}
    ]);

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            httpGet(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {

                    setbranchStoreData(res.data)
                }
            })

            httpGet(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'smsCustomType',
                    apiExtend: 'showList'
                }).then(res => {

                    if (res.success) {
                        setSmsCustomTypeData(res.data);
                    }
                })
        };
        fetchData();
    }, []);


    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            // handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="下单客户"
                        name="unionId"
                        rules={[{ required: true, message: '请输入!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="分店"
                        name="branchStoreId"
                        rules={[{ required: true, message: '请输入!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="订单状态"
                        name="orderStatusId">
                         <Select
                            showSearch
                            placeholder="订单状态"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(orderStatusId, 'orderStatusId')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="支付单号"
                        name="orderPayId"
                        rules={[{ required: true, message: '请输入!' }]}>
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        label="价格"
                        name="price"
                        rules={[{ required: true, message: '请输入!' }]}>
                        <InputNumber min={0} addonBefore="¥" addonAfter="元"/>
                    </Form.Item>

                    <Form.Item
                        label="备注"
                        name="memo">
                        <TextArea rows={6} />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        <Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default Ze8OrderEdit;