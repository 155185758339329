import React from 'react'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import ClassificationWarehouseEdit from './ClassificationWarehouseEdit';

export default function ClassificationWarehouse(props:any) {

    
    //表头
    const columnsHead = [
        {
            title: '大类',
            dataIndex: 'warehouseCategories',
            render:(text:any)=>(
                text?text.warehouseCategoriesName:""
            )
        },
        {
            title: '分类',
            dataIndex: 'classificationWarehouseName',
            sorter: true,
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

        let extendProps: WUExtendPropsType = {
            ...props,
            titleName: '分类',
            apiId: 'classificationWarehouse',
            showStates: true,
            columns,
            pagination:true,
            EditComponent: ClassificationWarehouseEdit,
        };
   return(
    <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>

   )
}
