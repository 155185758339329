import { useEffect, useRef, useState } from 'react';
import DevelopApiEdit from './DevelopApiEdit';
import WebUniversal, { WUExtendPropsType } from '../system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';
import { Button, Space, Switch } from 'antd';
import DevelopApiTest from './DevelopApiTest';

/**
 * 组件 DevelopProjects 
 * @param props 
 * @returns 
 */
const DevelopProjects = (props: any) => {
    const apiId = 'developApi';

    //刷新变量
    const [refresh, setRefresh] = useState(0);
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>();
    /** 刷新数据 */
    let initData: any;
    const [modalTestData, setModalTestData] = useState({});
    const [modalTestopen, setModalTestopen] = useState(false);

    //打开test
    const openModalTest = (id: any) => {
        let sendData = {
            type: 'EDIT',
            id
        }
        setModalTestData(sendData);
        setModalTestopen(true);
    }

    //子组件回调函数，关闭modalTest
    const onCloseModal = (state: boolean) => {
        setModalTestopen(false)
    }

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        // const fetchData = async () => {
        // };
        // fetchData();
        if (refresh !== 0) {
            initData();
        }
    }, [refresh]); //初始化数据

    /** 表头内容 */
    const columnsHead = [
        {
            title: '项目',
            dataIndex: 'developProjects',
            sorter: true,
            render: (text: any) => (typeof text !== 'undefined' ?
                text.projectName : '')
        },
        {
            title: '功能',
            dataIndex: 'effect',
        },
        {
            title: 'apiId',
            dataIndex: 'apiId',
            sorter: true
        },
        {
            title: 'apiExtendId',
            dataIndex: 'apiExtendId',
            sorter: true
        },
        {
            title: '名称',
            dataIndex: 'apiName',
        },
        {
            title: '方法',
            dataIndex: 'method_Name',
        },
        {
            title: 'token',
            dataIndex: 'isToken',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '测试',
            dataIndex: 'test',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => openModalTest(record.id)}>测试</Button>
                </Space>
        },
    ];

    /**
     * 表头
     */
    const columns = columnsheadId
        .concat(columnsHead as []);

    /**
     * 扩展Props
     */
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '开发Api',
        apiId,
        columns,
        showStates: true,
        pagination:true,
        EditComponent: DevelopApiEdit
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
            {modalTestopen ? (<DevelopApiTest closeModal={onCloseModal} data={modalTestData} />) : null}
        </>
    )
}

export default DevelopProjects;