import { Form, Input, InputNumber, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import system from "../../../config/systematic";

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export default function MenuCondimentEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    /** 加料仓库数据 */
    const [condimentWarehouseData, setCondimentWarehouseData] = useState<any>();

    /** 单一加料仓库数据 */
    // const [singleCondimentWarehouseData, setSingleCondimentWarehouseData] = useState<any>();

    /** 后端数据wmService */
    let wmService = system.wmService;

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    getCondimentWarehouseData(res.data.condimentWarehouseId);
                }
            })
        }
        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "condimentWarehouse",
                apiExtend: 'showList',
            }).then(res => {
                if (res.success) {
                    setCondimentWarehouseData(res.data);
                }
            });
        }
        fetchData()
    }, [])

    const getCondimentWarehouseData = (condimentWarehouseId: string) => {

        fetchApi.GET(wmService, {
            apiId: 'condimentWarehouse',
            apiExtend: 'showSingle',
            apiVariable: condimentWarehouseId,
        }).then(res => {
            if (res.success) {
                // console.log(res.data);\
                // setSingleCondimentWarehouseData(res.data);

                form.setFieldsValue({ basePrice: res.data.price });
                const price = form.getFieldValue("price");
                const finnalPrice = res.data.price + price;
                form.setFieldsValue({ finnalPrice: finnalPrice });
            }
        });
    }

    const changePrice = (e: any) => {
        const basePrice = form.getFieldValue("basePrice");
        const finnalPrice = basePrice + e;
        form.setFieldsValue({ finnalPrice: finnalPrice });
    }

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuCondiment'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '菜式加料'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999, "price": 0 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyPress={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="菜式加料"
                        name="condimentWarehouseId">
                        <Select
                            disabled
                            showSearch
                            placeholder="菜式加料"
                            onSelect={getCondimentWarehouseData}
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(condimentWarehouseData, 'condimentWarehouseName')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="基础价"
                        name="basePrice">
                        <InputNumber disabled bordered={false} style={{ color: "green" }} />
                    </Form.Item>

                    <Form.Item
                        label="加价"
                        name="price"
                        rules={[{ required: true, message: '请输入加价!' }]}>
                        <InputNumber min={0} onChange={changePrice} />
                    </Form.Item>

                    <Form.Item
                        label="总价"
                        name="finnalPrice">
                        <InputNumber disabled bordered={false} style={{ color: "yellow", fontSize: 20 }} />
                    </Form.Item>


                    <Form.Item
                        label="是否客服专用"
                        name="isStaff"
                        valuePropName="checked"
                        initialValue={false}>
                        <Switch
                            // disabled={requiredState}
                            checkedChildren="是"
                            unCheckedChildren="否" />
                    </Form.Item>

                    <Form.Item
                        label="是否默认"
                        name="isDefault"
                        valuePropName="checked"
                        initialValue={false}>
                        <Switch
                            // disabled={requiredState}
                            checkedChildren="是"
                            unCheckedChildren="否" />
                    </Form.Item>

                    <Form.Item
                        label="排序"
                        name="sortBy">
                        <InputNumber min={1} />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        <Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>
                </Form>
            </AntDraggableModal>
        </>
    )
}