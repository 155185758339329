import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import { Card, List } from 'antd'
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import arrayHandle from '../../../utils/baseLib/arrayHandle';
import moment from 'moment';

export default function ViewMes(props: any) {
    const { data, closeModal} = props
     const [rows,setRows]=useState<Array<any>>([])

    const handleOk = (e: any) => {
        closeModal(true)
    }
    const handleCancel = (e: any) => {
        closeModal(true)

    }
    useEffect(()=>{
        const dataRows = data.sort((a:any, b:any) => moment(a.sysDate.createdAt).diff(moment(b.sysDate.createdAt)));
        setRows(dataRows)
    },[])
    return (
        <>
            <AntDraggableModal
                title={`查看短信`}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >

                <Card >
                    <List
                        itemLayout="horizontal"
                        dataSource={rows}
                        // position
                        renderItem={(message:any) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={"手机号码:"+message.phones+"--"+"日期:"+dayjs(message.sysDate.createdAt).format("YYYY-MM-DD HH:mm:ss")+"--"+"状态:"+(message.isSend?"成功":"失败")}
                                    description={message.content}
                                />
                            </List.Item>
                        )}
                    />
                </Card>
            </AntDraggableModal>
        
                
        </>
    )
}
