import { RouteObject } from 'react-router-dom';
import { getPageUrl } from './index';
//moonManagement
import Moon from '../components/moonManagement/Moon';
import MoonType from '../components/moonManagement/MoonType';
import MoonReport from '../components/moonManagement/MoonReport';
import DisCount from '../components/moonManagement/DisCount';


const routes_moonManagement = async (licenseList: Array<any>) => {
    const res: RouteObject[] = [
        //房间订位管理
        {
            path: await getPageUrl(licenseList, 'moon'),
            element: <Moon></Moon>
        },
        {
            path: await getPageUrl(licenseList, 'moonType'),
            element: <MoonType></MoonType>
        },
        {
            path: await getPageUrl(licenseList, 'moonReport'),
            element: <MoonReport></MoonReport>
        },
        {
            path: await getPageUrl(licenseList, 'disCount'),
            element: <DisCount></DisCount>
        },

    ]
    return res;
}



export default routes_moonManagement