import ServiceChargeEdit from './ServiceChargeEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'

import { columnsheadId } from '../../universal/columnshead';

//组件 
const ServiceCharge = (props: any) => {

    /** api服务id */
    const apiId = 'serviceCharge';

    //表头
    const columnsHead = [
      
        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any, record: any) => (text ? text.branchStoreName : "")
        },
        {
            title: '附加折扣',
            dataIndex: 'serviceCharge',
        },
      
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '服务费',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        showDefault:true,
        extendIdName:"branchStoreId",
        EditComponent: ServiceChargeEdit,
        mountDataOptions:{
            userMount:{
               isDept:true,
            }
       },
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default ServiceCharge;
