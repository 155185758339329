import { useEffect, useState } from "react";
import { columnsheadId } from "../../universal/columnshead";
import AdUploadFileEdit from "./AdUploadFileEdit";
import systemExpand from "../../../config/systemExpand";
import UploadFileListUniversal, { UFLUExtendPropsType } from "../../system/UploadFileListUniversalNewEx";

//组件 AdUploadFile
const AdUploadFile = (props: any) => {

    //extendProps
    const [extendProps, setExtendProps] = useState<UFLUExtendPropsType>();

    //表头
    const columnsHead = [
        {
            title: '所属广告',
            dataIndex: 'adName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.ad.adName}</div>
                )
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    const filesColumns = columnsheadId
        .concat(columnsHead as []);

    useEffect(() => {
        systemExpand.getConfig({
            id: 'config',
            nodeId: 'files'
        }).then(res => {
            const { adFilesGroupId } = res as any;
            setExtendProps({
                ...props,
                titleName: '广告图片',
                apiId: 'ad',
                filesGroupId: adFilesGroupId,
                fileType: "files",
                //+ File
                //上传 + UploadFile
                //删除 + DeleteFile
                filesColumns,
                showImage: true,
                showDownload: true,
                showStates: true,
                showDefault: true,
                extendIdName:"adId",
                EditComponent: AdUploadFileEdit,
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (extendProps) {
        return (
            <UploadFileListUniversal props={extendProps} />
        )
    }
}

export default AdUploadFile;