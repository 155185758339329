import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import DeviceEdit from './DeviceEdit';
import { Select } from 'antd';
import { useEffect, useRef, useState } from 'react';
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';

const Device = (props: any) => {

    const ref = useRef<any>();

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    /** 门店数据 */
    const [branchStoreData, setBranchStoreData] = useState<any>();

    const [branchStoreIdData, setBranchStoreIdData] = useState<any>();

    /** 设备类型数据 */
    const [deviceTypeData, setDeviceTypeData] = useState<any>();

    const [deviceTypeIdData, setDeviceTypeIdData] = useState<any>();

    /** api服务id */
    const apiId = 'device';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // wmService = ref.current.wmService;
        }

        //同步获取数据 async - await
        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "deviceType",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", deviceTypeName: "所有设备类型" }, ...res.data];
                    setDeviceTypeData(newData);
                }
            });

            fetchApi.GET(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", branchStoreName: "所有门店" }, ...res.data];
                    setBranchStoreData(newData);
                }
            });
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '设备类型',
            dataIndex: 'deviceTypeId',
            render: (text: any, record: any) => {
                return (
                    <div>{record.deviceType.deviceTypeName}</div>
                )
            },
            sorter: true,
        },
        {
            title: '设备名称',
            dataIndex: 'deviceName',
            sorter: true,
        },
        {
            title: 'mac地址',
            dataIndex: 'macAddress',
        },
        {
            title: 'ip地址',
            dataIndex: 'ipAddress',
            sorter: true,
        },
        {
            title: '备注',
            dataIndex: 'memo',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];

    // const initData = async () => {
    //     // const menuHeadId = data.menuHeadId;
    //     const currentData: initDataType = {
    //         apiId,
    //         // sendPageData: { menuHeadId: menuHeadId }
    //     }
    //     ref.current.initData(currentData);
    // }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '设备',
        apiId: 'device',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: DeviceEdit,
    };

    //查看不同门店
    const branchStoreChange = (e: any) => {
        setBranchStoreIdData(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: { branchStoreId: e, deviceTypeId: deviceTypeIdData }
        }
        ref.current.initData(currentData);
    }

    //查看不同设备类型
    const deviceTypeChange = (e: any) => {
        setDeviceTypeIdData(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: { branchStoreId: branchStoreIdData, deviceTypeId: e }
        }
        ref.current.initData(currentData);
    }

    return (
        <>
            <label>选择门店:  </label>
            <Select
                style={{ width: 150 }}
                showSearch
                defaultValue="所有门店"
                onChange={branchStoreChange}
                placeholder="选择门店"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.generalEx(branchStoreData, {
                    includeNull: false,
                    itemName: 'branchStoreName'
                })}
            </Select>
            <span>&nbsp;&nbsp;</span>
            <label>选择设备类型:  </label>
            <Select
                style={{ width: 150 }}
                showSearch
                defaultValue="所有设备类型"
                onChange={deviceTypeChange}
                placeholder="选择设备类型"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.generalEx(deviceTypeData, {
                    includeNull: false,
                    itemName: 'deviceTypeName'
                })}
            </Select>
            <span>&nbsp;&nbsp;</span>
            <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>
        </>
    )
}

export default Device