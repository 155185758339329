import { Col, DatePicker, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import objectHandle from '../../../utils/baseLib/objectHandle';

//布局
const layout = {
    // labelCol: { span: 4 },
    // wrapperCol: { span: 4 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const { sendPageData } = data;
    const { assetStatementId } = sendPageData;

    const ref = useRef<any>();
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    /** 后端数据wmService */
    let wmService: any = "";
    const [assetStatementData, setAssetStatementData] = useState<any>();
    const [assetStatusData, setAssetStatusData] = useState<any>();
    const [purchaseMethodData, setPurchaseMethodData] = useState<any>();
    const [branchStoreData, setBranchStoreData] = useState<any>();
    const [deptData, setDeptData] = useState<any>();
    const [employeesData, setEmployeesData] = useState<any>();
    const [storagePlaceData, setStoragePlaceData] = useState<any>();
    const [exitMethodData, setExitMethodData] = useState<any>();
    const [isAddDisabled, setIsAddDisabled] = useState(true);

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (res) {
                    if (res.success === true) {
                        const { custodianId } = res.data;
                        if (objectHandle.isEmpty(custodianId) !== true) {
                            fetchApi.GET(wmService, {
                                apiId: 'sys_employees',
                                apiExtend: 'showSingle',
                                apiVariable: custodianId
                            }).then(res => {
                                if (res.success) {
                                    setEmployeesData(res.data);
                                }
                            });
                        }
                    }
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: 'assetStatement',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAssetStatementData(res.data);
                }
            });
            fetchApi.GET(wmService, {
                apiId: 'assetStatus',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAssetStatusData(res.data);
                }
            });
            fetchApi.GET(wmService, {
                apiId: 'purchaseMethod',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setPurchaseMethodData(res.data);
                }
            });
            fetchApi.GET(wmService, {
                apiId: 'sys_branchStore',
                apiExtend: 'showList',
                apiData: {
                    isCurrentArea: true
                }
            }).then(res => {
                if (res.success) {
                    setBranchStoreData(res.data);
                }
            });
            fetchApi.GET(wmService, {
                apiId: 'sys_dept',
                apiExtend: 'showList',
                apiData: {
                    isCurrentArea: true
                }
            }).then(res => {
                if (res.success) {
                    setDeptData(res.data);
                }
            });
            fetchApi.GET(wmService, {
                apiId: 'sys_storagePlace',
                apiExtend: 'showList',
                apiData: {
                    sendPageData: {
                        type: 'asset'
                    }
                }
            }).then(res => {
                if (res.success) {
                    setStoragePlaceData(res.data);
                }
            });
            fetchApi.GET(wmService, {
                apiId: 'exitMethod',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setExitMethodData(res.data);
                }
            });
        }
        fetchData()
    }, [])

    const onAddClicked = (state: boolean) => {
        setIsAddDisabled(state);
    }

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'assetCard',
        dateColumnName: ['purchaseDate', 'exitDate']
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '资产卡'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            // handleOk(e)
                        }
                    }}>
                    <Row gutter={[8, 0]}>
                        <Col span={6}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="资产表"
                                name="assetStatementId"
                                initialValue={data.type === 'NEW' ? assetStatementId : ''}
                                rules={[{ required: true, message: '请选择资产表!' }]}>
                                <Select
                                    disabled={true}
                                    showSearch
                                    placeholder="资产表"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.search('assetStatement', setAssetStatementData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(assetStatementData, { itemName: 'aliasName' })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="资产状态"
                                name="assetStatusId"
                                rules={[{ required: true, message: '请选择资产状态!' }]}>
                                <Select
                                    showSearch
                                    placeholder="资产状态"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.search('assetStatement', setAssetStatementData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(assetStatusData, { itemName: 'assetStatusName' })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="序列号"
                                name="serialNumber"
                                tooltip="资产卡序列号，相同资产不能存在相同序列号"
                                rules={[{ required: isAddDisabled === true ? true : false, message: '请输入序列号，并且不能相同!' }]}>
                                <Input disabled={!isAddDisabled} />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="数量"
                                name="amount">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="购置方式"
                                name="purchaseMethodId"
                                rules={[{ required: true, message: '请选择购置方式!' }]}>
                                <Select
                                    showSearch
                                    placeholder="购置方式"
                                    optionFilterProp="children"
                                    // onSearch={(e) => getList.search('assetStatement', setAssetStatementData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(purchaseMethodData, { itemName: 'purchaseMethodName' })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="购置日期"
                                name="purchaseDate">
                                <DatePicker
                                    format="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="使用年限(月)"
                                name="serviceLife">
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="原值"
                                name="originalPrice">
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="净值"
                                name="netPrice">
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="分店"
                                name="branchStoreId">
                                <Select
                                    showSearch
                                    placeholder="分店"
                                    optionFilterProp="children"
                                    // onSearch={(e) => getList.search('assetStatement', setAssetStatementData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(branchStoreData, { itemName: 'branchStoreName', includeNull: true })}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={8}>
                            <Form.Item
                                label="部门"
                                name="deptId">
                                <Select
                                    showSearch
                                    placeholder="部门"
                                    optionFilterProp="children"
                                    // onSearch={(e) => getList.search('assetStatement', setAssetStatementData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(deptData, { itemName: 'deptName', includeNull: true })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="保管人"
                                tooltip="输入名称或id查找保管人"
                                name="custodianId">
                                <Select
                                    showSearch
                                    placeholder="保管人"
                                    optionFilterProp="children"
                                    onSearch={(e) => {
                                        getList.search('sys_employees', setEmployeesData, e);
                                    }}
                                    filterOption={(input, option: any) => { return true; }}>
                                    {getList.generalEx(employeesData, { includeNull: true })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="存放地点"
                                name="storagePlaceId">
                                <Select
                                    showSearch
                                    placeholder="存放地点"
                                    optionFilterProp="children"
                                    // onSearch={(e) => getList.search('assetStatement', setAssetStatementData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(storagePlaceData, { itemName: 'storagePlaceName', includeNull: true })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="退出方式"
                                name="exitMethodId">
                                <Select
                                    showSearch
                                    placeholder="退出方式"
                                    optionFilterProp="children"
                                    // onSearch={(e) => getList.search('assetStatement', setAssetStatementData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(exitMethodData, { itemName: 'exitMethodName', includeNull: true })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="退出日期"
                                name="exitDate">
                                <DatePicker
                                    format="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="描述"
                                name="describe">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="remarks">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="批量添加"
                                name="isMultipleSerialNumbers"
                                tooltip="按序列号批量添加，如存在重复序列号，则添加不成功"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否"
                                    disabled={data.type === 'NEW' ? false : true}
                                    onChange={(checked) => onAddClicked(!checked)}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="开始序号"
                                name="startSerialNumber"
                                rules={[{ required: isAddDisabled === true ? false : true, message: '请输入开始序列号!' }]}>
                                <InputNumber disabled={isAddDisabled} />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="结束序号"
                                name="endSerialNumber"
                                rules={[{ required: isAddDisabled === true ? false : true, message: '请输入结束序列号!' }]}>
                                <InputNumber disabled={isAddDisabled} />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;