import { BackTop, Col, DatePicker, Form, Input, InputNumber, Radio, Row, Select, Space, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification';

export default function AdEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    /** 选择跳转链接类型数据 */
    const [selectLinkTypeData, setSelectLinkTypeData] = useState<any>();

    /** 选择广告类型数据 */
    const [selectAdTypeData, setSelectAdTypeData] = useState<any>();

    /** 选择广告模式数据 */
    const [selectAdModeData, setSelectAdModeData] = useState<any>();

    /** 广告类型数据 */
    const [adTypeData, setAdTypeData] = useState<any>();

    /** 广告类型状态 */
    const [adTypeState, setAdTypeState] = useState<any>(true);

    /** 广告模式数据 */
    const [adModeData, setAdModeData] = useState<any>();

    /** 跳转链接类型数据 */
    const [linkTypeData, setLinkTypeData] = useState<any>();

    /** 是否共用数据 */
    const [publicData, setPublicData] = useState<any>(false);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });

            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {

                    setSelectAdTypeData(res.data.adTypeId);
                    setSelectAdModeData(res.data.adModeId);
                    if (res.data.linkTypeId) {
                        setSelectLinkTypeData(res.data.linkTypeId);
                    }

                    fetchApi.GET(wmService, {
                        apiId: 'generalData',
                        apiVariable: 'adType',
                        apiExtend: 'showList'
                    }).then(ress => {
                        if (ress.success) {
                            let newAdTypeData: any = [];
                            if (res.data.adModeId === 'notice') {
                                for (var j = 0; j < ress.data.length; j++) {
                                    newAdTypeData.push({
                                        value: ress.data[j].id,
                                        label: ress.data[j].name,
                                        disabled: ress.data[j].id === 'text' ? false : true
                                    })
                                }
                            }
                            else {
                                for (var j = 0; j < ress.data.length; j++) {
                                    newAdTypeData.push({
                                        value: ress.data[j].id,
                                        label: ress.data[j].name,
                                        disabled: ress.data[j].id === 'text' ? true : false
                                    })
                                }
                            }
                            setAdTypeData(newAdTypeData);
                        }
                    });
                }
            })
        }
        const fetchData = async () => {

            if (data.type === 'EDIT') {
                setPublicData(true);
                setAdTypeState(false);
            }

            if (data.type === 'NEW') {
                if (sessionStorage.getItem('isAdmin') === 'true') {
                    setPublicData(!sessionStorage.getItem('isAdmin'));
                }

                fetchApi.GET(wmService, {
                    apiId: 'generalData',
                    apiVariable: 'adType',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        let newAdTypeData: any = [];
                        for (var j = 0; j < res.data.length; j++) {
                            newAdTypeData.push({
                                value: res.data[j].id,
                                label: res.data[j].name,
                                disabled: false
                            })
                        }
                        setAdTypeData(newAdTypeData);
                    }
                });
            }

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'adMode',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAdModeData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'linkType',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setLinkTypeData(res.data);
                }
            });
        }
        fetchData()
    }, [])

    const getAdModeData = (adModeId: string) => {
        setSelectAdModeData(adModeId);
        setAdTypeState(false);
        form.setFieldsValue({ adTypeId: '' });
        setSelectAdTypeData('');

        if (adModeId === 'ad') {
            setSelectLinkTypeData('');
            form.setFieldsValue({ linkTypeId: '' });
            form.setFieldsValue({ appId: '' });
            form.setFieldsValue({ wxAddress: '' });
        }

        let newAdTypeData: Array<any> = [];
        if (adModeId === 'notice') {
            for (var j = 0; j < adTypeData.length; j++) {
                newAdTypeData.push({
                    value: adTypeData[j].value,
                    label: adTypeData[j].label,
                    disabled: adTypeData[j].value === 'text' ? false : true
                })
            }
        }
        else {
            for (var j = 0; j < adTypeData.length; j++) {
                newAdTypeData.push({
                    value: adTypeData[j].value,
                    label: adTypeData[j].label,
                    disabled: adTypeData[j].value === 'text' ? true : false
                })
            }
        }
        setAdTypeData(newAdTypeData);
    }

    const getAdTypeData = (adTypeId: string) => {
        setSelectAdTypeData(adTypeId);
        if (adTypeId !== 'text') {
            setSelectLinkTypeData('');
            form.setFieldsValue({ linkTypeId: '' });
            form.setFieldsValue({ appId: '' });
            form.setFieldsValue({ wxAddress: '' });
        }

        if (adTypeId === 'pic') {
            form.setFieldsValue({ movieId: '' });
        }
    }

    const getLinkTypeData = (linkTypeId: string) => {
        setSelectLinkTypeData(linkTypeId);
    }

    const changeDate = (e: any, key: any) => {
        if (key === 'startTime' && form.getFieldsValue()['endTime'] !== undefined) {
            if (dayjs(form.getFieldsValue()['endTime']).format("YYYY-MM-DD") < dayjs(e).format("YYYY-MM-DD")) {
                antMessage('error', '时间设置有误', '结束时间小于开始时间,请重新选择.');
                form.resetFields(['startTime']);
            }
        }

        if (key === 'endTime') {
            if (dayjs(form.getFieldsValue()['startTime']).format("YYYY-MM-DD") > dayjs(e).format("YYYY-MM-DD")) {
                antMessage('error', '时间设置有误', '开始时间大于结束时间,请重新选择.');
                form.resetFields(['endTime']);
            }
        }
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'ad',
        dateColumnName: ['startTime', 'endTime']
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '广告'}
                // title={(data.type === 'NEW' ? specialData === 99 ? '选择需要添加规格的类型' : newData ? '选择或填写菜式规格' : '新增菜式规格' : '编辑菜式规格')}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                >

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="门店Id"
                                name="branchStoreId"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="广告名称"
                                name="adName"
                                rules={[{ required: true, message: '请输入广告名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="选择广告模式"
                                name="adModeId"
                                rules={[{ required: true, message: '请选择广告模式!' }]}>
                                <Select
                                    showSearch
                                    placeholder="广告模式"
                                    optionFilterProp="children"
                                    onSelect={getAdModeData}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {getList.general(adModeData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="选择广告类型"
                                name="adTypeId"
                                rules={[{ required: true, message: '请选择广告类型!' }]}>
                                <Select
                                    showSearch
                                    disabled={adTypeState}
                                    placeholder="广告类型"
                                    optionFilterProp="children"
                                    onSelect={getAdTypeData}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                                    options={adTypeData}
                                >
                                    {/* {getList.general(adTypeData, 'name')} */}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="广告内容"
                                name="contents"
                                rules={[{ required: selectAdTypeData === 'notice' || selectAdTypeData === 'text' ? true : false, message: '请输入广告名称!' }]}>
                                <TextArea
                                    placeholder="广告内容"
                                    autoSize={{ minRows: 2, maxRows: 15 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={selectAdModeData === 'ad' && selectAdTypeData === 'tencentMovie' ? 24 : 0}>
                            <Form.Item
                                label="腾讯视频ID"
                                name="movieId"
                                rules={[{ required: selectAdTypeData === 'tencentMovie' ? true : false, message: '请输入腾讯视频ID!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={selectAdModeData === 'ad' ? 0 : 24}>
                            {/* <Col span={selectAdModeData === 'ad' || selectAdTypeData === 'pic' || selectAdTypeData === 'tencentMovie' ? 0 : 24}> */}
                            <Form.Item
                                label="选择跳转链接类型"
                                name="linkTypeId"
                            >
                                <Select
                                    showSearch
                                    placeholder="跳转链接类型"
                                    optionFilterProp="children"
                                    onSelect={getLinkTypeData}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {/* {getList.general(linkTypeData, 'name')} */}
                                    {getList.generalEx(linkTypeData, {
                                        itemName: "name",
                                        includeNull: true
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={selectLinkTypeData === 'externalWx' ? 24 : 0}>
                            <Form.Item
                                label="小程序AppId"
                                name="appId"
                                rules={[{ required: selectLinkTypeData === 'externalWx' ? true : false, message: '请输入小程序appId!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={selectLinkTypeData === 'externalWx' || selectLinkTypeData === 'internalLink' ? 24 : 0}>
                            <Form.Item
                                label="跳转到小程序路径"
                                name="wxAddress"
                                rules={[{ required: selectLinkTypeData === 'externalWx' || selectLinkTypeData === 'internalLink' ? true : false, message: '请输入小程序地址!' }]}>
                                <Input placeholder='例如:pages/index/index' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="开始时间"
                                name="startTime"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择时间',
                                    },
                                ]}
                            >
                                <DatePicker
                                    onChange={(e) => changeDate(e, 'startTime')}
                                    format="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="结束时间"
                                name="endTime"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择时间',
                                    },
                                ]}
                            >
                                <DatePicker
                                    onChange={(e) => changeDate(e, 'endTime')}
                                    format="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="是否共用"
                                name="isPublic"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch
                                    disabled={publicData}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="弹窗模式"
                                name="isPopup"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}