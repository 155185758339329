import { Button, Col, Form, Select, Space } from 'antd'
import { useEffect, useState } from 'react';
import getList from '../universal/getList';
import fetchApi from '../../utils/api/fetchApi';
import { MinusCircleOutlined, PlusOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import WebSetMealFormList from '../generalComponents/WebSetMealFormList';

//  套餐/菜单formList通用组件
export default function WebSetMealCategoryFormList(props: any) {

    const [setMealCategoryData, setSetMealCategoryData] = useState<any>();

    const { categoryForm, categoryType, categoryRef, setSetMealData } = props.props;

    const { categoryFormName, setMealData } = props;

    const [formList, setFormList] = useState<any>({ form: categoryForm, type: categoryType, ref: categoryRef, setSetMealData });

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (categoryRef.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = categoryRef.current.wmService;
        }
        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "setMealCategory",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setSetMealCategoryData(res.data);
                }
            });
        }
        fetchData()
    }, [])

    const customizeAdd = (add: Function) => {
        add();
        let newData: Array<any> = [];
        if (setMealData === undefined) {
            newData.push([]);
        }
        else {
            newData = setMealData;
            newData.push([]);
        }
        setSetMealData(newData);
    }

    const customizeMove = (move: Function, before: any, after: any) => {
        move(before, after);
        let newData: Array<any> = [];
        for (var i = 0; i < setMealData.length; i++) {
            if (i === before) {
                newData.push(setMealData[after]);
            }
            else{
                if (i === after) {
                    newData.push(setMealData[before]);
                }
                else{
                    newData.push(setMealData[i]);
                }
            }
        }
        setSetMealData(newData);
    }

    const customizeRemove = (remove: Function, num: number) => {
        remove(num);
        let newData: Array<any> = [];
        for (var i = 0; i < setMealData.length; i++) {
            if (i !== num) {
                newData.push(setMealData[i])
            }
        }
        setSetMealData(newData);
    }

    return (
        <>
            <Col span={24}>
                <Form.List name={categoryFormName}>
                    {(fields, { add, remove, move }) => {
                        return (
                            <>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Space
                                        key={key}
                                        style={{ display: 'flex', marginBottom: 8 }}
                                        align="baseline"
                                    >
                                        <Form.Item
                                            {...restField}
                                            label="菜式分组"
                                            name={[name, 'setMealCategoryId']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: '请选择菜式分组',
                                                },
                                            ]}
                                        >
                                            <Select
                                                //optionLabelProp="label"
                                                style={{ width: 1000 }}
                                                showSearch
                                                allowClear
                                                // onChange={(e) => handleChange(form, e, name, formName, type, tempDeleteData)}
                                                placeholder="菜式分组"
                                                optionFilterProp="children"
                                                // filterOption={(input, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                                                filterOption={(input, option: any) => {
                                                    // console.log("option",option);
                                                    // console.log("input",input);
                                                    return true
                                                }}
                                            >
                                                {getList.general(setMealCategoryData, "setMealCategoryName")}
                                            </Select>
                                            <WebSetMealFormList props={formList} formName={'menuData' + name} setMealData={setMealData}></WebSetMealFormList>
                                        </Form.Item>
                                        <CaretDownOutlined onClick={() => customizeMove(move, name, name + 1)} />
                                        <CaretUpOutlined onClick={() => customizeMove(move, name, name - 1)} />
                                        <MinusCircleOutlined onClick={() => customizeRemove(remove, name)} />
                                    </Space>
                                ))}
                                <Form.Item>
                                    <Button
                                        type="dashed"
                                        onClick={() => customizeAdd(add)}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                                        添加套餐/菜单分组
                                    </Button>
                                </Form.Item>
                            </>
                        )
                    }
                    }
                </Form.List>
            </Col>
        </>
    )
}