import systemConfig from '../../../config/systematic';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Switch, Table, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import dayjs from 'dayjs';
import antMessage from '../../../utils/extend/AntdNotification'
import DownloadExcel from '../../report/DownloadExcel';
import BranchStoreList from '../../generalComponents/BranchStoreList';
import {  QuestionCircleFilled } from '@ant-design/icons';
import { SocketContext } from '../../../App';
const { RangePicker } = DatePicker;

/**详情统计*/
export default function SalesDetail(props: any) {
  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const [form] = Form.useForm();

  const [sbData, setSbData] = useState<any>();


  const [rows, setRows] = useState<any>([]);
  const [shopTypeData, setShopTypeData] = useState<any>();

  const [shopTypeName, setShopTypeName] = useState<string>("所有");
  const [sbName, setSbName] = useState<string>("所有");
  const [userName, setUserName] = useState<string>("")
  const [caCoMonStatus, setCaCoMonStatus] = useState<Array<any>>();

  const [deptData, setDeptData] = useState<any>();

  const [caCoMonStatusName, setCaCoMonStatusName] = useState<string>("所有");
  const useContextData = useContext(SocketContext);
  const {  contextData } = useContextData;
  useEffect(() => {
    let branchStoreId = contextData?.currentBranchStoreId;
    const fetchData = async () => {
      form.setFieldsValue({
        "chooseTime": [dayjs(), dayjs()]
      })
      let dept = await fetchApi.GET(wmService, {
        apiId: "dept",
        apiExtend: 'getBrandStoreIdToDept',
        apiData: {
          branchStoreId
        }
      })
      if (dept.success) {

        setDeptData(dept.data)
      }

      if (branchStoreId) {
        let sb = await fetchApi.GET(wmService, {
          apiId: "sb",
          apiExtend: 'getBrandStoreIdToSb',
          apiData: {
            branchStoreId
          }
        })
        if (sb.success) {

          setSbData(sb.data)
        }

        // let shopType = await fetchApi.GET(wmService, {
        //   apiId: "shopType",
        //   apiExtend: 'showList',
        //   apiData: {
        //     branchStoreId,
        //     mountDataOptions:{
        //       userMount:{
        //          isDept:true,
        //       }
        //  },
        //   }
        // })
        // if (shopType.success) {

        //   setShopTypeData(shopType.data)
        // }

        fetchApi.GET(wmService,
          {
            apiId: 'generalData',
            apiVariable: 'caCoMonStatus',
            apiExtend: 'showList'
          }).then(res => {
            if (res.success) {

              setCaCoMonStatus(res.data);

            }
          })

      }

      // 
    }
    fetchData()
  }, [])
  /** 表头内容 */
  let columnsHead: any = [
    {
      title: "分类",
      dataIndex: "shopTypeName",
      width: 80
    },
    {
      title: "父项",
      dataIndex: "setMealName",
    },
    {
      title: "品名",
      dataIndex: "menuName",
    },
    {
      title: "规格",
      dataIndex: "menuSpecName",
      width: 80
    },
    {
      title: <div>数量<Tooltip title="菜品数量，不包括赠送，退菜，退货"><QuestionCircleFilled  /></Tooltip> </div>,
      dataIndex: "amount",
    },
    {
      title: <div>金额(折前)  <Tooltip title="所有菜品金额，不包括赠送，退菜，退货"><QuestionCircleFilled  /></Tooltip> </div>,
      dataIndex: "advancePrice",

    },
    {
      title: "折扣",
      dataIndex: "discountPrice",

    },
    {
      title:<div>金额(折后)  <Tooltip title="所有菜品金额，不包括赠送，退菜，退货"><QuestionCircleFilled  /></Tooltip> </div> ,
      dataIndex: "reducedPrice",

    },
    // {
    //   title:<div>金额(退菜)  <Tooltip title="仅退菜金额"><QuestionCircleFilled  /></Tooltip> </div>,
    //   dataIndex: "foodBackPrice",

    // },
    // {
    //   title:<div>数量(退菜)  <Tooltip title="仅退菜数量"><QuestionCircleFilled  /></Tooltip> </div>,
    //   dataIndex: "foodBackAmount",

    // },
    {
      title:<div>金额(退货)  <Tooltip title="仅退货金额,特指支付后，线上退款部分"><QuestionCircleFilled  /></Tooltip> </div>,
      dataIndex: "refundPrice",

    },
    {
      title:<div>数量(退货)  <Tooltip title="仅退货数量,特指支付后，线上退款部分"><QuestionCircleFilled  /></Tooltip> </div>,
      dataIndex: "refundAmount",

    },

    {
      title:<div>金额(赠送)  <Tooltip title="仅赠送金额"><QuestionCircleFilled  /></Tooltip> </div>,
      dataIndex: "giftPrice",

    },
    {
      title:<div>数量(赠送)  <Tooltip title="仅赠送数量"><QuestionCircleFilled  /></Tooltip> </div>,
      dataIndex: "giftAmount",

    },


  ]
  const onFinish = () => {
    let branchStoreId = contextData?.currentBranchStoreId;
    if (!branchStoreId) {
      antMessage("error", '错误', "必须选择分店");
      return false
    }
    form.validateFields()
      .then(async (values: any) => {

        fetchApi.GET(wmService, {
          apiId: "financeReport",
          apiExtend: 'salesDetail',
          apiData: {
            ...values,
            branchStoreId
          }
        }).then((res) => {
          
          if (res.success) {
            setUserName(res.data.userName)
            setRows(res.data.rows)

          }
        }).catch((err) => {
          antMessage("error", '错误', err);
        })



      })
      .catch((err) => {

        antMessage('warning', '无法通过数据校验', '请输入所需数据！');
      })





  }
  const changedShopType = (e: any, option: any) => {
    let shopTypeName: Array<any> = []
    for (let i = 0; i < option.length; i++) {
      shopTypeName.push(option[i].children)
    }


    setShopTypeName(shopTypeName.join(","))

  }

  const changedCaCoMonStatus = (e: any, option: any) => {
    let caCoMonStatusName: Array<any> = []
    for (let i = 0; i < option.length; i++) {
      caCoMonStatusName.push(option[i].children)
    }


    setCaCoMonStatusName(caCoMonStatusName.join(","))

  }

  const changeSb = (e: any, option: any) => {

    if (option?.children) setSbName(option.children)

  }
  return (
    <>
      <BranchStoreList setDeptData={setDeptData} setShopTypeData={setShopTypeData} setSbData={setSbData} form={form}></BranchStoreList>
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Row>

          <Col span={4}>
            <Form.Item
              label="市别"
              name="sbId"
    
            >
              <Select
                onChange={changeSb}
                allowClear
                placeholder="市别"
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(sbData, 'sbName')}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="分类"
              name="shopTypeIds"
            >
              <Select
                // orderTypeData
                allowClear
                onSearch={(e) => getList.search('shopType', setShopTypeData, e, { andWhereObj: { branchStoreId: localStorage.getItem("currentBranchStoreId") } })}
                mode="multiple"
                showSearch
                placeholder="请选择分类!"
                optionFilterProp="children"
                onChange={changedShopType}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(shopTypeData, 'shopTypeName')}
              </Select>
            </Form.Item>
          </Col>


          <Col span={8}>
            <Form.Item
              label="部门"
              name="deptIds"
            >
              <Select
                // orderTypeData
                allowClear
                mode="multiple"
                showSearch
                placeholder="请选择部门!"
                optionFilterProp="children"

                onSearch={(e) => getList.search('dept', setDeptData, e, {
                  andWhereObj: {
                    branchStoreId: localStorage.getItem("currentBranchStoreId")
                  }

                })}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(deptData, 'deptName')}
              </Select>
            </Form.Item>
          </Col>



          <Col span={4}>
            <Form.Item
              label="菜状态"
              name="caCoMonStatusId"
            >
              <Select
                onChange={changedCaCoMonStatus}
                // orderTypeData
                mode="multiple"
                showSearch
                placeholder="请选择菜状态!"
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(caCoMonStatus)}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label="品名"
              name="menuName"
            >
              <Input />
            </Form.Item>
          </Col>



          {/* <Col span={10}>
            <Form.Item
              label="品名"
              name="shopTypeIds"
            >
              <Select
                // orderTypeData
                onSearch={(e) => getList.search('shopType', setShopTypeData, e,undefined,{andWhereObj:{brandId:localStorage.getItem("currentrandId")}})}
                mode="multiple"
                showSearch
                placeholder="请选择分类!"
                optionFilterProp="children"
                onChange={changedShopType}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(shopTypeData, 'shopTypeName')}
              </Select>
            </Form.Item>
          </Col> */}


          <Col span={6}>
            <Form.Item
              label="日期"
              name="chooseTime"
            >
              <RangePicker
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>



          <Col span={3}>
            <Form.Item
              label="合并菜谱统计"
              name="isCombinedRecipeStatistics"
              valuePropName="checked"

              initialValue={true}>
              < Switch checked={true}
                checkedChildren="是"
                unCheckedChildren="否" />
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item
              label="合并分类统计"
              name="isShopTypeStatistics"
              valuePropName="checked"

              initialValue={false}>
              < Switch checked={false}
                checkedChildren="是"
                unCheckedChildren="否" />
            </Form.Item>
          </Col>





          <Col span={4}>
            <Form.Item >
              <Space>
                <Button type="primary" htmlType="submit">
                  统计
                </Button >
                <Button onClick={() => DownloadExcel(rows, "salesDetail", { shopTypeName, date: form.getFieldValue("chooseTime"), sbName, userName, caCoMonStatusName })}>下载EXCEL文件</Button>

                {/* purContrast */}
              </Space>
            </Form.Item>
          </Col>
        </Row>

      </Form>

      <div style={{ marginTop: "20px" }}>
        <Table
          scroll={{ x: "100%", y: 950 }}
          pagination={false}
          rowKey={(record: any, index: any) => {
            return index
          }}
          columns={columnsHead}
          dataSource={rows}

        >
        </Table>

      </div>
    </>
  )
}
