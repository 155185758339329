import React from 'react';
import PaymentMethodEdit from './PaymentMethodEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 支付方式 
const PaymentMethod = (props: any) => {

    /** api服务id */
    const apiId = 'paymentMethod';

    //表头
    const columnsHead = [
        {
            title: '支付方式',
            dataIndex: 'paymentMethodName',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '支付方式',
        apiId,
        columns,
        showStates: true,
        pagination:true,
        EditComponent: PaymentMethodEdit
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default PaymentMethod;
