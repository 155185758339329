import { Col, Form, Input, InputNumber, Radio, Row, Select, Space, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function BranchStoreMenuWarehouseSpecEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    /** 门店菜式仓库数据 */
    const [branchStoreMenuWarehouseData, setBranchStoreMenuWarehouseData] = useState<any>();

    /** 是否需要创建特殊规格新数据 */
    const [specialData, setSpecialData] = useState<any>(99);

    /** 是否特殊规格 */
    const [specialState, setSpecialState] = useState<any>(true);


    /** 规格名称是否可修改 */
    const [specNameState, setSpecNameState] = useState<any>(false);

    /** 是否需要创建新数据 */
    const [newData, setNewData] = useState<any>(true);

    /** 是否可以改价格 */
    const [priceData, setPriceData] = useState<any>(false);

    /** 菜式仓库规格 */
    const [menuWarehouseSpecData, setMenuWarehouseSpecData] = useState<any>([]);

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    if (res.data.isSpecial) {
                        setSpecialState(true);
                        setPriceData(true);
                    }

                }
            })
        }
        const fetchData = async () => {

            if (!data.sendPageData.menuWarehouseId) {
                setSpecialData(88);
                if (data.type === 'NEW') {
                    setSpecialState(false);
                }
            }

            if (data.type === 'EDIT') {
                setSpecialState(true);
                setSpecNameState(true);
            }

            form.setFieldsValue({ branchStoreMenuWarehouseId: data.sendPageData.branchStoreMenuWarehouseId });

            fetchApi.GET(wmService, {
                apiId: 'branchStoreMenuWarehouse',
                apiExtend: 'showMenuList',
                apiData: {
                    branchStoreId: localStorage.getItem("currentBranchStoreId")
                }
            }).then(res => {
                if (res.success) {
                    setBranchStoreMenuWarehouseData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'menuWarehouseSpec',
                apiExtend: 'checkSpec',
                branchStoreMenuWarehouseId: data.sendPageData.branchStoreMenuWarehouseId,
                menuWarehouseId: data.sendPageData.menuWarehouseId
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseSpecData(res.data);
                }
            });

        }
        fetchData()
    }, [])

    const onChangeRadio = (e: any) => {
        setNewData(!newData)
        let menuWarehouseSpecName: any;
        for (var i_data = 0, len_data = menuWarehouseSpecData.length; i_data < len_data; i_data++) {
            if (menuWarehouseSpecData[i_data].id === e.target.value) {
                menuWarehouseSpecName = menuWarehouseSpecData[i_data].menuWarehouseSpecName;
            }
        }
        if (e.target.value === "999") {
            setSpecialData(0);
            form.setFieldsValue({ branchStoreMenuWarehouseSpecName: "" });
        }
        else {
            form.setFieldsValue({ branchStoreMenuWarehouseSpecName: menuWarehouseSpecName });
        }
    };

    const switchChange = (e: any) => {
        if (e) {
            form.setFieldsValue({ price: 0 });
        }
        setPriceData(e);
    };

    const onChangeSpecial = (e: any) => {
        if (e.target.value === 0) {
            form.setFieldsValue({ price: 0 });
            setPriceData(true);
            form.setFieldsValue({ isSpecial: true });
            setSpecialState(true);
        }
        setSpecialData(e.target.value);
    };

    const handleTrim = (e: any) => {
        form.setFieldsValue({ branchStoreMenuWarehouseSpecName: e.target.value.trim() });
    };

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'branchStoreMenuWarehouseSpec'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? specialData === 99 ? '选择需要添加规格的类型' : newData ? '选择或填写菜式仓库规格' : '新增菜式仓库规格' : '编辑菜式仓库规格')}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={500}
            >
                <Form
                    form={form}
                    initialValues={{ "sortBy": 1 }}
                    name="Edit"
                    onFinish={handleOk}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={data.type === "NEW" && newData && specialData === 99 ? 24 : 0}>
                            <Row gutter={[8, 0]}>
                                <Col span={24}>
                                    <Form.Item
                                        name="specialId"
                                    >
                                        <Radio.Group onChange={onChangeSpecial}>
                                            <Space direction="vertical">
                                                <Radio
                                                    key={1}
                                                    value={0}
                                                >
                                                    添加特殊规格(如选粉、选汤、选规格、选甜度、选冷热...)
                                                </Radio>
                                                <Radio
                                                    key={0}
                                                    value={1}
                                                >
                                                    添加常规规格(如大份、小份、位、份...)<span style={{ color: "red" }}>【添加到仓库规格里】</span>
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                        <Col span={data.type === "NEW" && newData && specialData === 1 ? 24 : 0}>
                            <Row gutter={[8, 0]}>
                                <Col span={24}>
                                    <Form.Item
                                        name="menuWarehouseSpecId"
                                    >
                                        <Radio.Group onChange={onChangeRadio}>
                                            <Space direction="vertical">
                                                {
                                                    menuWarehouseSpecData.map((item: any, index: any) => {
                                                        return (
                                                            <>
                                                                <Radio
                                                                    key={item.id}
                                                                    disabled={item.state}
                                                                    value={item.id}
                                                                //onChange={(e) => onChangeRadio(e, item.id)}
                                                                >
                                                                    {item.menuWarehouseSpecName}
                                                                </Radio>
                                                            </>
                                                        )
                                                    })
                                                }
                                                <Radio
                                                    key={999}
                                                    value="999"
                                                >
                                                    列表没有想要的,我需要重新添加
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                        <Col span={data.type === "EDIT" ? 24 : (newData && (specialData === 99 || specialData === 1)) ? 0 : 24}>
                            <Row gutter={[8, 0]}>
                                <Col span={24}>
                                    <Form.Item
                                        label="id"
                                        name="id">
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="仓库规格id"
                                        name="menuWarehouseSpecId">
                                        <Input disabled={true} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label={data.sendPageData.foodType === 'setMeal' ? "套餐名称" : "菜式名称"}
                                        name="branchStoreMenuWarehouseId">
                                        <Select
                                            disabled
                                            showSearch
                                            placeholder={data.sendPageData.foodType === 'setMeal' ? "套餐名称" : "菜式名称"}
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(branchStoreMenuWarehouseData, 'foodName')}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label={data.sendPageData.foodType === 'setMeal' ? "规格 / 套餐类别" : "规格"}
                                        name="branchStoreMenuWarehouseSpecName"
                                        rules={[{ required: true, message: data.sendPageData.foodType === 'setMeal' ? "请输入规格 / 套餐类别" : '请输入规格!' }]}>
                                        <Input onChange={handleTrim} disabled={specNameState} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="基础价"
                                        name="price"
                                        rules={[{ required: true, message: '请输入基础价!' }]}>
                                        <InputNumber disabled={priceData} min={0} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="排序"
                                        name="sortBy">
                                        <InputNumber min={1} />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label={data.sendPageData.foodType === 'setMeal' ? "是否套餐类别" : "是否特殊属性"}
                                        name="isSpecial"
                                        valuePropName="checked">
                                        <Switch
                                            disabled={specialState}
                                            onChange={(e) => switchChange(e)}
                                            checkedChildren="是"
                                            unCheckedChildren="否" />
                                    </Form.Item>
                                </Col>

                                <Col span={24}>
                                    <Form.Item
                                        label="状态"
                                        name="isUse"
                                        valuePropName="checked"
                                        initialValue={true}>
                                        <Switch checked={true}
                                            checkedChildren="启用"
                                            unCheckedChildren="关闭" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}