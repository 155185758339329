import React, { useEffect, useRef, useState } from 'react';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import MenuPeriodDiscountEdit from './MenuPeriodDiscountEdit';
import { Button, Space, Switch } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';

const MenuPeriodDiscount = (props: any) => {

    const ref = useRef<any>();

    const { closeModal, data } = props;

    /** api服务id */
    const apiId = 'menuPeriodDiscount';

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const initData = async () => {
        // console.log(data.menuSpecId);
        const menuSpecId = data.menuSpecId;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                menuSpecId: menuSpecId
            }
        }
        ref.current.initData(currentData);
    }

    const handleDelete = (record: any) => {
        fetchApi.DELETE(wmService, {
            apiId,
            apiVariable: record.id,
            apiExtend: "remove"
        }).then(async res => {
            if (res.success) {
                antMessage('success', '删除成功', 'success');
                await initData();
                if (res.data.state) {
                    closeModal(true);
                }
            }
        })
    };

    //表头
    const columnsHead = [
        {
            title: '规格',
            dataIndex: 'menuSpec',
            render: (text: any, record: any) =>
                <div>{record.menuSpec.menuSpecName}</div>
        },
        {
            title: '周期',
            dataIndex: 'weekName',
        },
        {
            title: '开始时间',
            dataIndex: 'startTime',
            render: (val: any) =>
                <span>
                    {val.substring(0, 5)}
                </span>,
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            render: (val: any) =>
                <span>
                    {val.substring(0, 5)}
                </span>,
        },
        {
            title: '折扣类型',
            dataIndex: 'discountTypeName',
        },
        {
            title: '折扣',
            dataIndex: 'discount',
            render: (text: any, record: any) => {
                if (record.discountType === 'discount') {
                    return (
                        <div>{record.discount + " 折"}</div>
                    )
                }
                if (record.discountType === 'price') {
                    return (
                        <div>{"¥ " + record.discount}</div>
                    )
                }
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    //传关参数
    let sendData = {
        type: 'EDIT',
        menuPeriodDiscountId: '',
        path: 'MenuPeriodDiscount',
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '时段',
        apiId,
        columns,
        showStates: false,
        showQuery: false,
        pagination: true,
        // showDelete: false,
        showAdd: true,
        EditComponent: MenuPeriodDiscountEdit,
        sendPageData: {
            menuId: data.menuId,
            menuSpecId: data.menuSpecId,
            menuSpecName: data.menuSpecName,
            price: data.price
        },
        coverHandleDelete: handleDelete
    };

    return (
        <>
            <AntDraggableModal
                title={'时段'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}            
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={() => closeModal(true)}
                closable={false}
                maskClosable={false}
                width={1200}>
                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
            </AntDraggableModal>
        </>
    )
}

export default MenuPeriodDiscount