import { Form, Input, Switch, Select, Row, Col } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const LicenseEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const [datas, setDatas] = useState<any>();
    const [licenseTypeData, setLicenseTypeData] = useState<any>();
    const [licenseData, setLicenseData] = useState<any>();
    /** 后端数据wmService */
    let wmService: any = '';
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "license",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setLicenseData(res.data)
                }
            })

            fetchApi.GET(wmService,
                {
                    apiId: 'licenseGroup',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setDatas(res.data)
                    }
                });
            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'licenseType',
                    apiExtend: 'showList'
                }).then(res => {

                    if (res.success) {
                        setLicenseTypeData(res.data)
                    }
                });
        };
        fetchData();
    }, []);

    const { Option } = Select;
    const getLicenseGroupList = (datas: any) => {
        if (typeof datas !== "undefined") {
            return (datas.map((item: any, key: number) => {
                return <Option value={item.id} key={key}>{item.licenseGroupName}</Option>
            })
            )
        }
    };

    const getGeneralList = (datas: any) => {
        if (typeof datas !== "undefined") {
            return (datas.map((item: any, key: number) => {
                return <Option value={item.id} key={key}>{item.name}</Option>
            })
            )
        }
    };

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'license',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '权限'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            // handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="ID"
                                name="id"
                                tooltip='不输入id，则随机生成'>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="权限"
                                name="licenseName"
                                rules={[{ required: true, message: '请输入权限名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="类型"
                                name="licenseTypeId"
                                rules={[{ required: true, message: '请选择权限类型!' }]}>
                                <Select
                                    showSearch
                                    placeholder="权限类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getGeneralList(licenseTypeData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="权限组"
                                name="licenseGroupId"
                                rules={[{ required: false, message: '请选择权限组!' }]}>
                                <Select
                                    showSearch
                                    placeholder="选择权限组"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getLicenseGroupList(datas)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="页面路径"
                                name="pageUrl"
                                tooltip='相当于后台的apiId'
                                rules={[{ required: true, message: '请输入页面路径!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={18}>
                            <Form.Item
                                label="上级权限"
                                name="superiorLicenseId"
                                rules={[{ message: '请选择上级权限!' }]}>
                                <Select
                                    showSearch
                                    placeholder="选择上级权限"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(
                                        licenseData,
                                        {
                                            itemName: 'licenseName',
                                            includeNull: true
                                        })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="排序"
                                name="licenseSort">
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>

                        <Col span={18}>
                            <Form.Item
                                label="图标"
                                name="icon">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="memo">
                                <Input />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default LicenseEdit;