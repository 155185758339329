import { Button, Space, Image, Switch, Tooltip, Checkbox, Popover, Popconfirm } from 'antd';
import { useEffect, useState } from 'react';
import system from "../../../config/systematic";

/** 删除按钮对象类型 */
export interface deleteButtonConfigType {
    /** 是否使用复选框 */
    useCheckBox?: boolean,
    /** 复选框内容 */
    checkBoxContent?: String,
    /** 复选框是否被选中(默认不选中) */
    isChecked?: boolean,
    /** 删除按钮索引，默认'id' */
    index?: string,
};

/** behaviors数据类型 */
export interface behaviorsDataType {
    /** apiId */
    apiId: string,
    /** 主键名称 */
    primaryKeyName?: string,
    /**是否显示是否要交预定金 */
    showDeposit?: Boolean
    /** 是否显示图片 */
    showImage?: boolean,
    /**是否自动生成 */
    showAutomatic?: boolean,
    /** 是否显示文件上传按钮 */
    showUploadFile?: boolean,
    /** 是否显示下载按钮 */
    showDownload?: boolean,
    /** 是否显示默认选项 */
    showDefault?: boolean,
    /**是否显示发送选项 */
    showSend?: boolean,
    /** 是否显示状态选项 */
    showStates?: boolean,
    /**是否显示预定状态 */
    showReserve?: boolean,
    /** 是否显示操作区域 */
    showOperation?: boolean,
    /** 是否显示删除按钮 */
    showDelete?: boolean,
    /** 是否显示编辑按钮 */
    showEdit?: boolean,
    /** 方法：文件上传 */
    hanldUpload?: Function,
    /** 方法：删除 */
    handleDelete?: Function,
    /** 方法：编辑 */
    handleEdit?: Function,
    /** 方法：编辑权限对象 */
    handleEditAuthority?: Object,
    /** 方法：删除权限对象 */
    handleDelectAuthority?: object
    /** 方法：改变选项 */
    handleChangeOne?: Function,
    /**改变默认 */
    handleChangeDefault?: Function
    /** 表头 */
    behaviorsColumns?: Array<any>,
    /** 图片数据数组名称 */
    arrayFilesName?: string,
    /** 删除按钮配置 */
    deleteButtonConfig?: deleteButtonConfigType,

    isLevel?: boolean,
    levelKey?: any
};

/**
 * behaviors 表头操作区域
 * @param behaviorsData 
 * @returns 
 */
const WebBehaviors = (behaviorsData: behaviorsDataType) => {
    let {
        apiId,
        showImage,
        showUploadFile,
        showDownload,
        showDefault,
        showStates,
        showReserve,
        showOperation,
        showSend,
        showDelete,
        showEdit,
        hanldUpload,
        handleDelete,
        handleEdit,
        handleChangeDefault,
        handleChangeOne,
        behaviorsColumns,
        arrayFilesName,
        deleteButtonConfig,
        showDeposit,
        showAutomatic,
        handleEditAuthority,
        handleDelectAuthority,
        isLevel,
        levelKey
    } = behaviorsData;

    //删除按纽显示的popover
    const [dBtnPopoveropen, setDBtnPopoveropen] = useState<any>({});
    //删除按纽的checkBox是否checked
    const [dBtnChecked, setDBtnChecked] = useState(false);
    //删除按纽索引
    const [dBtnIndex, setdBtnIndex] = useState('id');

    if (typeof showOperation === "undefined") { showOperation = true };
    if (typeof showDelete === "undefined") { showDelete = true };
    if (typeof showEdit === "undefined") { showEdit = true };

    /** 主键名称 */
    const primaryKeyName = behaviorsData.primaryKeyName ? behaviorsData.primaryKeyName : 'id';


    useEffect(() => {

        //如果primaryKeyName不为空，配置删除键
        if (typeof primaryKeyName !== 'undefined') {
            setdBtnIndex(primaryKeyName);
        }
        //删除键配置
        if (typeof deleteButtonConfig === 'undefined') {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            deleteButtonConfig = {
                isChecked: false
            }
        }
        else {
            if (typeof deleteButtonConfig.isChecked !== 'undefined') {
                setDBtnChecked(deleteButtonConfig.isChecked);
            }
            if (typeof deleteButtonConfig.index !== 'undefined') {
                setdBtnIndex(deleteButtonConfig.index);
            }
        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据


    /**
     * 获取文件
     * @param record 
     * @returns 
     */
    const getFile = (record: any) => {
        /** 文件类型 */
        let fileType;
        /** 文件地址 */
        let fileUrl;
        /** 是否图片 */
        let isImage = false;
        //优先级高
        try {
            if (typeof record[arrayFilesName!] !== 'undefined') {
                fileType = record[arrayFilesName!][0].fileType;
                fileUrl = record[arrayFilesName!][0].destination + "/" +
                    record[arrayFilesName!][0].fileName;
            }
            else {
                if (typeof record[apiId + "Files"] !== "undefined") {
                    fileType = record[apiId + "Files"][0].fileType;
                    fileUrl = record[apiId + "Files"][0].destination + "/" +
                        record[apiId + "Files"][0].fileName;
                }
                else {
                    fileType = record.fileType;
                    fileUrl = record.destination + "/" +
                        record.fileName;
                }
            }
            if (typeof fileType !== 'undefined') {
                if (fileType.slice(0, 5) === 'image') {
                    isImage = true
                }
            }
        }
        catch { }
        return {
            /** 是否图片 */
            isImage,
            /** 文件地址 */
            fileUrl: system.filesServer.serverUrl + "/" + fileUrl
        }
    };

    /** 表头 */
    let operationColumns = [
        (showImage ? {
            title: '图片',
            dataIndex: 'id',
            render: (text: any, record: any) => (
                getFile(record).isImage ?
                    < Image
                        src={getFile(record).fileUrl}
                        width={60}
                        alt="图片" /> :
                    <span>{record.fileType}</span>
            )
        } : {}),
        (showUploadFile ? {
            title: '文件',
            dataIndex: 'operation',
            key: 'operation',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary"
                        onClick={() =>
                            typeof hanldUpload !== 'undefined' ?
                                hanldUpload(record.id) :
                                undefined
                        }>文件</Button>
                </Space>
        } : {}),
        (showDownload ? {
            title: '下载',
            dataIndex: 'fileName',
            render: (text: any, record: any) =>
                <a href={getFile(record).fileUrl} >
                    <Tooltip title={'请用鼠标右键点击下载（另存）！'}>下载</Tooltip></a>
        } : {}),
        (showDefault ? {
            title: '默认显示',
            dataIndex: 'isDefault',
            render: (text: any, record: any) => {
                return (
                    <Switch checkedChildren="是" unCheckedChildren="否"
                        checked={text} key={record.id}
                        onChange={(e) =>
                            typeof handleChangeDefault !== "undefined" ?
                                handleChangeDefault(record, apiId) :
                                undefined
                        }
                    />
                )
            }
        } : {}),
        // showAutomatic
        (showAutomatic ? {
            title: '自动生成',
            dataIndex: 'isAutomatic',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record[primaryKeyName]}
                    onChange={(e) =>
                        typeof handleChangeOne !== "undefined" ?
                            handleChangeOne(record[primaryKeyName], 'isAutomatic', e, apiId) :
                            undefined
                    }
                />
            )
        } : {}),
        // showSend
        (showSend ? {
            title: '发送状态',
            dataIndex: 'isSend',
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record[primaryKeyName]}
                    onChange={(e) =>
                        typeof handleChangeOne !== "undefined" ?
                            handleChangeOne(record[primaryKeyName], 'isSend', e, apiId) :
                            undefined
                    }
                />
            )
        } : {}),
        (showStates ? {
            title: '状态',
            dataIndex: 'isUse',
            width:75,
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record[primaryKeyName]}
                    onChange={(e) =>
                        typeof handleChangeOne !== "undefined" ?
                            handleChangeOne(record[primaryKeyName], 'isUse', e, apiId) :
                            undefined
                    }
                />
            )
        } : {}),
        (showReserve ? {
            title: '可预订',
            dataIndex: 'isReserve',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record[primaryKeyName]}
                    onChange={(e) =>
                        typeof handleChangeOne !== "undefined" ?
                            handleChangeOne(record[primaryKeyName], 'isReserve', e, apiId) :
                            undefined
                    }
                />
            )
        } : {}),
        (showDeposit ? {
            title: '需付定金',
            dataIndex: 'isDeposit',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record[primaryKeyName]}
                    onChange={(e) =>
                        typeof handleChangeOne !== "undefined" ?
                            handleChangeOne(record[primaryKeyName], 'isDeposit', e, apiId) :
                            undefined
                    }
                />
            )
        } : {}),
        (showOperation ? {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            width:110,
            render: (text: any, record: any) =>
                <Space size="small">
                    {showDelete ?
                        <>
                            <Popconfirm description={(
                                <>  {deleteButtonConfig?.useCheckBox ?
                                    <Checkbox
                                        checked={dBtnChecked}
                                        onClick={() => { setDBtnChecked(!dBtnChecked) }}>
                                        {deleteButtonConfig?.checkBoxContent ? deleteButtonConfig.checkBoxContent : '是否删除'}
                                    </Checkbox>
                                    : ''}
                                    <p />

                                </>
                            )}
                                title="是否删除？"
                                trigger="click"
                                disabled={isLevel ? record[levelKey].level === 1 ? false : handleDelectAuthority ? handleDelectA(record, handleDelectAuthority) : false : false}
                                onConfirm={() => {
                                    if (typeof handleDelete !== 'undefined') {
                                        handleDelete(record, dBtnChecked);
                                    }
                                }}
                            >
                                <Button disabled={isLevel ? record[levelKey].level === 1 ? false : handleDelectAuthority ? handleDelectA(record, handleDelectAuthority) : false : false} size={"small"} type="text" danger >删除</Button>
                            </Popconfirm >
                        </> : ''}
                    {showEdit ?
                        <Button disabled={isLevel ? record[levelKey].level === 1 ? false : handleEditAuthority ? handleEditA(record, handleEditAuthority) : false : false} size={"small"} type="text" onClick={() =>
                            typeof handleEdit !== "undefined" ?
                                handleEdit(record, handleEditAuthority) :
                                undefined
                        }>编辑</Button> : ''}

                </Space>

        } : {})
    ];
    /**
     * 合拼表头
     */
    let columns;

    //去除operationColumns 的空对象
    operationColumns = operationColumns.filter(valeus => Object.keys(valeus).length != 0);

    if (typeof behaviorsColumns !== "undefined") {

        columns = behaviorsColumns.concat(operationColumns);
    }
    return columns;
}
export default WebBehaviors;

//编辑权限扩展处理
export const handleEditA = (record: any, handleEditAuthority: any) => {
    let b = true;
    for (let key in handleEditAuthority) {
        if (record[key] === handleEditAuthority[key]) {
            b = false;
        }

    }

    return b
}

//删除权限扩展处理
export const handleDelectA = (record: any, handleDelectAuthority: any) => {
    let b = true;
    for (let key in handleDelectAuthority) {
        if (record[key] === handleDelectAuthority[key]) {
            b = false;
        }

    }

    return b
}

















