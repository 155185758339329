import { Col, Form, Input, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import objectHandle from '../../../utils/baseLib/objectHandle';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';

const { TextArea } = Input;
const BranchStoreEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const [areaData, setAreaData] = useState<any>();
    // 

    const [brandData, setBrandData] = useState<any>();
    const [orderPaymentModeData, setOrderPaymentModeData] = useState<any>();
    const [wxAccountWxPayData, setWxAccountWxPayData] = useState<any>();
    const [wxAccountWxMiniAppData, setWxAccountWxMiniAppData] = useState<any>();

    /** 后端数据wmService */
    let wmService: any = "";
    const ref = useRef<any>();
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'branchStore',
    };
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "generalData",
                apiExtend: 'bms',
                apiData: {
                    apiId: 'generalData',
                    apiExtend: 'wxAccountList',
                    apiData: {
                        slectType: 'wxPay'
                    }
                }
            }).then(res => {
                if (res.success) {
                    setWxAccountWxPayData(res.data);
                }
            })
            fetchApi.GET(wmService, {
                apiId: "generalData",
                apiExtend: 'bms',
                apiData: {
                    apiId: 'generalData',
                    apiExtend: 'wxAccountList',
                    apiData: {
                        slectType: 'wxMiniApp'
                    }
                }
            }).then(res => {
                if (res.success) {
                    setWxAccountWxMiniAppData(res.data);
                }
            })
            fetchApi.GET(wmService, {
                apiId: "area",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAreaData(res.data)
                }
            })

            fetchApi.GET(wmService, {
                apiId: "brand",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setBrandData(res.data)
                }
            })



            fetchApi.GET(wmService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: 'orderPaymentMode'
            }).then(res => {
                if (res.success) {

                    setOrderPaymentModeData(res.data)
                }
            })

        };
        fetchData();
    }, []);
    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '分店'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}>

                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') { handleOk(e) }
                    }}>
                    <Row>
                        <Col span={8} >
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item
                                label="名称"
                                name="branchStoreName"
                                rules={[{ required: true, message: '请输入分店名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item
                                label="区域"
                                name="areaId"
                                rules={[{ required: true, message: '请选择区域!' }]}>
                                <Select
                                    showSearch
                                    placeholder="选择区域"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(areaData, 'areaName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item
                                label="品牌"
                                name="brandId"
                                rules={[{ required: true, message: '请选择品牌!' }]}>
                                <Select
                                    showSearch
                                    placeholder="选择品牌"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(brandData, 'brandName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item
                                label="电话"
                                name="contactCall"
                                rules={[{ message: '请输入联系电话!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item
                                label="联系人"
                                name="contacts"
                                rules={[{ message: '请输入店铺名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="地址"
                                name="address">
                                <TextArea
                                    placeholder="店铺地址"
                                    autoSize={{ minRows: 1, maxRows: 6 }} />
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item
                                label="纬度"
                                name="latitude">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item
                                label="经度"
                                name="longitude" >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8} >
                            <Form.Item
                                label="邮编"
                                name="postcode">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="停车场"
                                name="parkingLot">
                                <TextArea
                                    placeholder="停车场"
                                    autoSize={{ minRows: 2, maxRows: 6 }} />
                            </Form.Item>
                        </Col>



                        <Col span={24}>
                            <Form.Item
                                label="简介"
                                name="intro">
                                <TextArea
                                    placeholder="简介"
                                    autoSize={{ minRows: 2, maxRows: 6 }} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="公告"
                                name="announcement">
                                <TextArea
                                    placeholder="公告"
                                    autoSize={{ minRows: 2, maxRows: 4 }} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="memos">
                                <TextArea
                                    placeholder="备注"
                                    autoSize={{ minRows: 1, maxRows: 6 }} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="提示"
                                name="tip">
                                <TextArea
                                    placeholder="提示"
                                    autoSize={{ minRows: 2, maxRows: 6 }} />
                            </Form.Item>
                        </Col>

                        <Col span={19}>
                            <Form.Item
                                label="营业内容"
                                name="isAlternateHoursMemo">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5} >
                            <Form.Item
                                label="替换营业时间"
                                name="isAlternateHours"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="微信支付id"
                                name="wxPayId"
                                rules={[{ required: true, message: '微信支付id!' }]}>
                                <Select
                                    showSearch
                                    placeholder="微信支付"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(wxAccountWxPayData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="阿里支付id"
                                name="aliPayId">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="微信小程序(外)"
                                name="wxMiniAppExternalId"
                                rules={[{ required: true, message: '微信小程序(外)!' }]}>
                                <Select
                                    showSearch
                                    placeholder="微信小程序(外)"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(wxAccountWxMiniAppData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="微信小程序(内)"
                                name="wxMiniAppInternalId"
                                rules={[{ required: true, message: '微信小程序(内)!' }]}>
                                <Select
                                    showSearch
                                    placeholder="微信小程序(内)"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(wxAccountWxMiniAppData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* 
                        <Col span={16} >
                            <Form.Item
                                label="仓库"
                                name="entrepotStr">
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择仓库"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(entrepotData, "entrepotName")}
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col span={4} >
                            <Form.Item
                                label="微信"
                                name="isWx"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4} >
                            <Form.Item
                                label="取餐码"
                                name="isPickupCode"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={4} >
                            <Form.Item
                                label="微信点餐"
                                name="isWxOrder"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4} >
                            <Form.Item
                                label="跳过预结"
                                name="isSkipPreknot"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4} >
                            <Form.Item
                                label="菜谱扫码"
                                name="isMenuHeadScanningCode"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>
                        <Col span={4} >
                            <Form.Item
                                label="多人下单"
                                name="isOrderTogether"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4} >
                            <Form.Item
                                label="预订房台"
                                name="isReserve"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4} >
                            <Form.Item
                                label="清购物车"
                                name="isClearShoppingCart"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        
                        <Col span={4} >
                            <Form.Item
                                label="购物车服务费"
                                name="isShoppingCartCharge"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>


                        <Col span={4} >
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>

                </Form>
            </AntDraggableModal>
        </>
    )
}

export default BranchStoreEdit;