import { Form, Input, Select } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';


export default function BuyerEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();
    const [buyerTypeData, setBuyerTypeData] = useState<any>()
    /** 后端数据Service */
    let wmService: any = "";
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'buyer'
    };
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            httpGet(wmService, {
                apiId: "buyerType",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setBuyerTypeData(res.data)
                }
            })


        }
        fetchData()
    }, [])

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '采购商'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="专属id"
                        name="selfId"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="采购商名称"
                        name="buyerName"
                        rules={[{ required: true, message: '请输入采购商名称!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="采购商电话"
                        name="buyerPhone"
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="采购商类型"
                        name="buyerTypeId"
                        rules={[{ required: true, message: '采购商类型!' }]}>
                        <Select
                            showSearch
                            placeholder="采购商类型"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(buyerTypeData, 'buyerTypeName')}
                        </Select>
                    </Form.Item>

                </Form>
            </AntDraggableModal>

        </>
    )
}
