import React, { useEffect, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import TempRoomList from '../../generalComponents/TempRoomList';
import { Button, Card, Input, List, Modal, Popconfirm, Space } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';
import dayjs from 'dayjs';

/**移台 */
export default function MoveRoom(props: any) {
    const { data, closeModal } = props;

    const { isCheckedId } = data;
    const [roomData, setRoomData] = useState<any>();
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [queryData, setQueryData] = useState('');
    const [orderListOpen, setOrderListOpen] = useState<boolean>(false);
    const [orderListData, setOrderListData] = useState<any>();
    useEffect(() => {

        initTempRoom({ timeTypeId: data.timeTypeId, queryString: queryData })
    }, [])
    const handleCancel = (e: any) => {
        closeModal(false)

    }
   
    const initTempRoom = async (apiData: any) => {
        const { timeTypeId, queryString } = apiData;
        let tempRoomData = await fetchApi.GET(wmService, {
            apiId: "tempRoom",
            apiExtend: 'query',
         
            apiData: {
                timeTypeId,
                roomStatus: data.roomStatus,
                queryString,
                mountDataOptions: {
                    userMount: {
                        isDept: true
                    }
                },
            }
        })
        if (tempRoomData.success) {
            setRoomData(tempRoomData.data.rows)
        }
    }
    const handleOrder=(id:string)=>{
        if(id===data.id){
            antMessage("error", "错误", "不能移入相同的订单")
            return false
        }
          fetchApi.POST(wmService, {
            apiId: "cateringOrder",
            apiExtend: 'removeVegetables',
            apiData:{
                newOrderId:id,
                ...orderListData
            },
        }).then((res) => {
            if (res.success) {
                antMessage("success", "成功", "移菜成功")
                closeModal(true)

            }

        }).catch((err) => {
            antMessage("error", "错误", err)
        })
    }
    const moveRoom = (item: any) => {
        const { room } = item;
        const { cateringOrders } = room;
        if (isCheckedId && isCheckedId.length > 0) {
            let apiData: any = {
                oldOrderId: data.id,
                roomId: item.roomId,
                tempRoomId: item.id,
                orTempRoomId: data.orTempRoomId,
                isCheckedId,
            }

            switch (cateringOrders.length) {
                case 0:
                    break;
                case 1:
                    apiData.newOrderId = cateringOrders[0].id;
                    if(apiData.newOrderId===apiData.oldOrderId){
                        antMessage("error", "失败", "无法移入相同订单")
                        return false
                    }
                    break;
                default:
                    break;
            }
            if (cateringOrders.length >= 2) {
                //执行弹窗选择
                setOrderListOpen(true)
                setOrderListData({rows:cateringOrders,...apiData})
            }
             else {
                fetchApi.POST(wmService, {
                    apiId: "cateringOrder",
                    apiExtend: 'removeVegetables',
                    apiData,
                }).then((res) => {
                    if (res.success) {
                        antMessage("success", "成功", "移菜成功")
                        closeModal(true)

                    }

                }).catch((err) => {
                    antMessage("error", "错误", err)
                })
            }

        } else {

            fetchApi.POST(wmService, {
                apiId: "cateringOrder",
                apiExtend: 'moveRoom',
                apiData: {
                    id: data.id,
                    roomId: item.roomId,
                    tempRoomId: item.id,
                    orTempRoomId: data.orTempRoomId,
                    // isCheckedId:.isCheckedId
                }
            }).then((res) => {
                if (res.success) {
                    antMessage("success", "成功", "移台成功")
                    closeModal(true)

                }

            }).catch((err) => {
                antMessage("error", "错误", err)
            })
        }


    }
    return (
        <>
            <AntDraggableModal
                title={
                    <div style={{ position: 'sticky', top: 0, zIndex: 1 }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div>{`${isCheckedId && isCheckedId.length > 0 ? "移菜" : "移台"}--原房号${data.roomNum}`}</div>
                            {isCheckedId && isCheckedId.length > 0 ? <div style={{ color: "red" }}>{`提示:当该桌是空闲时，则会生成新订单(该单是子单)，如果存在一个单时(任意市别)，则会加入这个订单上，如果存在多张单，则要选中加入到需要的订单上`}</div> : null}
                        </div>
                        <div>
                            <label>查询 :  </label>
                            <Space>
                                <Input
                                    style={{ width: 100 }}
                                   onKeyDown={(e: any) => {
                                        if (e.key === 'Enter')
                                            initTempRoom({ timeTypeId: data.timeTypeId, queryString: queryData })
                                    }}
                                    onChange={(e: any) => {

                                        setQueryData(e.target.value)
                                    }
                                    }
                                />
                            </Space></div>
                    </div>
                }

                open={true}
                maskClosable
                okText='确定'
                cancelText='取消'
                onCancel={handleCancel}
                closable={false}
                width={"85%"}
                footer={null}
            // confirmLoading={loading}

            >
                <div style={{ maxHeight: '700px', overflowY: 'auto', overflowX: "hidden", marginTop: "20px" }}>
                    <TempRoomList props={{
                        listObj: {
                            itemLayout: "horizontal",
                            grid: { gutter: 25, column: 5 },
                            dataSource: roomData,
                            renderItem: (item: any) => {
                                console.log("eee", item);

                                const { room } = item;
                                const { cateringOrders } = room;
                                return (
                                    <List.Item>
                                        <Card
                                            style={{ backgroundColor: item.color }}
                                            title={item.room.roomName}

                                            actions={[
                                                <Popconfirm title={`确定要将${isCheckedId && isCheckedId.length > 0 ? "菜" : data.roomNum}移动到${item.room.roomName}吗？`} onConfirm={() => moveRoom(item)} okText="确定" cancelText="取消">
                                                    <div >选择</div>
                                                </Popconfirm>,

                                            ]}

                                        >
                                            {item.roomStatus === 0 && cateringOrders.length > 0 ? <div style={{ color: "red", fontWeight: "bolder" }}>该房台在其他市别存在订单</div> : <div style={{ visibility: "hidden" }}>1</div>}
                                        </Card>
                                    </List.Item>
                                )
                            }

                        }
                    }}>

                    </TempRoomList>
                </div>


               
            </AntDraggableModal>
            {orderListOpen ? <Modal

                title={"请选择订单"}
                open={true}
                onCancel={ ()=>setOrderListOpen(false)}
                // onOk={handleOk}
                width={"50%"}
                footer={null}
            >
                <ul>
                    {orderListData?.rows.map((order: any) => (
                        <li key={order.id}>
                            <Space>
                                <span>{`${order.id}`}</span>
                                <span>{`${dayjs(order.orderDate).format("YYYY-MM-DD HH:mm:ss")}`}</span>
                                <Button disabled={order.id===data.id?true:false} onClick={() => handleOrder(order.id)}>
                                    选择
                                </Button>
                            </Space>


                        </li>
                    ))}
                </ul>
            </Modal>
                : null}
        </>
    )
}
