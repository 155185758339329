import { Col, Form, Input, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';

//布局
const layout = {
    // labelCol: { span: 4 },
    // wrapperCol: { span: 4 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const { sendPageData } = data;
    let type: any;
    if (sendPageData) {
        type = sendPageData.type;
    }
    const ref = useRef<any>();
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    /** 后端数据wmService */
    let wmService: any = "";
    const [areaData, setAreaData] = useState<any>();
    const [branchStoreData, setBranchStoreData] = useState<any>();

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;

            // ref.current.row.then(async (res: any) => {
            //     if (typeof res === 'undefined') { return }
            //     if (res.success === true) {
            //         // console.log(res.data);
            //     }
            // })
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: 'sys_branchStore',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setBranchStoreData(res.data);
                }
            });
            fetchApi.GET(wmService, {
                apiId: 'sys_area',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAreaData(res.data);
                }
            });
        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'sys_storagePlace',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '存放地点'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="区域"
                                name="areaId"
                                rules={[{ required: (typeof type === 'undefined') ? true : false, message: '区域!' }]}>
                                <Select
                                    placeholder="区域"
                                    optionFilterProp="children"
                                    disabled={(typeof type === 'undefined') ? false : true}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(areaData, { itemName: 'areaName' })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="分店"
                                name="branchStoreId">
                                <Select
                                    placeholder="分店"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(branchStoreData, { itemName: 'branchStoreName', includeNull: true })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={10}>
                            <Form.Item
                                label="存放地点"
                                name="storagePlaceName"
                                rules={[{ required: true, message: '请输入存放地点!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;