import { Form, Input, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import WebEditUniversal, { WEUExtendPropsType } from '../../components/system/WebEditUniversalNewEx'
import systemConfig from '../../config/systematic';
import fetchApi, { httpGet } from '../../utils/api/fetchApi';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import getList from '../universal/getList';
import antMessage from '../../utils/extend/AntdNotification'
//布局
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const MoonInsert = (props: any) => {
  const [form] = Form.useForm(); //form 
  const { data, closeModal } = props;


  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  /**产品数据 */
  const [moonTypeData, setMoonTypeData] = useState<any>()
  /**折扣数据 */
  const [disCountData, setDisCountData] = useState<any>()
  /**票据状态数据 */
  const [moonStatusData, setMoonStatusData] = useState<any>()
  /**分店数据 */
  const [branchStoreData, setbranchStoreData] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  useEffect(() => {
    let branchStoreId = sessionStorage.getItem("branchStoreId");
    form.setFieldsValue({ saleBranchStore: branchStoreId })

    form.setFieldsValue({ moonStatus: 1 })
    const fetchData = async () => {
      // httpGet(wmService, {
      //   apiId: "moonType",
      //   apiExtend: 'showList'
      // }).then(res => {

      //   setMoonTypeData(res.data)
      // })
      httpGet(wmService, {
        apiId: "moonType",
        apiExtend: 'branchStoreMoonType',
        apiVariable: branchStoreId,
      }).then(res => {
        if (res.success) {
          setMoonTypeData(res.data)
        }
      })
      httpGet(wmService, {
        apiId: "generalData",
        apiExtend: 'showList',
        apiVariable: "moonStatus",
      }).then(res => {
        if (res.success) {
          setMoonStatusData(res.data)
        }
      })
      httpGet(wmService, {
        apiId: "branchStore",
        apiExtend: 'showList'
      }).then(res => {
        setbranchStoreData(res.data)
      })
      httpGet(wmService, {
        apiId: "disCount",
        apiExtend: 'showList'
      }).then(res => {
        setDisCountData(res.data)
      })
    }
    fetchData()
  }, [])
  const handleOk = (e: any) => {
    form.validateFields()
      .then(async (values: any) => {
        setIsLoading(true)
        fetchApi.PUT(wmService, {
          apiId: "moon",
          apiExtend: "insertData",
          apiData: values
        }).then(res => {
          if (res.data === true) {
            setIsLoading(false)
            antMessage('success', '新增成功', 'success');
            closeModal(true)
          }
        }

        ).catch(err => {
          setIsLoading(false)
          antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
        })

      })

  };
  const handleCancel = (e: any) => {
    closeModal(false)

  };

  const getbranchStoreRoomTypeData = (branchStoreId: any) => {

    httpGet(wmService, {
      apiId: "moonType",
      apiExtend: 'branchStoreMoonType',
      apiVariable: branchStoreId,
    }).then(res => {

      if (res.success) {
        setMoonTypeData(res.data)
      }
    })


  }

  return (
    <>
      <AntDraggableModal
        title={"批量新增"}
        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        confirmLoading={isLoading}
      >
        <Form
          {...layout}
          form={form}
          name="Edit"

          onFinish={handleOk}
         onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}>

          <Form.Item
            label="折扣"
            name="disCountId"
            rules={[{ required: true, message: '请输入票据折扣!' }]}>
            <Select

              showSearch
              placeholder="折扣"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(disCountData, 'disCountName')}
            </Select>
          </Form.Item>
          <Form.Item
            label="售出分店"
            name="saleBranchStore"
            rules={[{ required: true, message: '请输入分店!' }]}>
            <Select
              showSearch
              disabled
              onSelect={getbranchStoreRoomTypeData}
              placeholder="售出分店"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(branchStoreData, 'branchStoreName')}
            </Select>
          </Form.Item>

          <Form.Item
            label="开始票号"
            name="statrTicketNo"
            rules={[{ required: true, message: '请输入票据开始票号!' }]}
          >
            <Input placeholder='请输入除票头后的6位数，列如输入:000001' />
          </Form.Item>

          <Form.Item
            label="结束票号"
            name="endTicketNo"
            rules={[{ required: true, message: '请输入票据结束票号!' }]}
          >
            <Input placeholder='请输入除票头后的6位数，列如输入:000001' />
          </Form.Item>

          <Form.Item
            label="票据类型"
            name="moonTypeId"
            rules={[{ required: true, message: '请输入票据类型!' }]}>
            <Select
              showSearch
              placeholder="票据类型"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(moonTypeData, 'moonType')}
            </Select>
          </Form.Item>

          <Form.Item
            label="票据状态"
            name="moonStatus"
            rules={[{ required: true, message: '请输入票据状态!' }]}>
            <Select
              disabled
              showSearch
              placeholder="票据状态"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(moonStatusData, 'name')}
            </Select>
          </Form.Item>

          <Form.Item
            label="是否需要7"
            name="isSeven"
            valuePropName="checked"
            initialValue={true}>
            < Switch checked={true}
              checkedChildren="是"
              unCheckedChildren="否" />
          </Form.Item>

          <Form.Item
            label="是否需要4"
            name="isFour"
            valuePropName="checked"
            initialValue={true}>
            < Switch checked={true}
              checkedChildren="是"
              unCheckedChildren="否" />
          </Form.Item>

        </Form>
      </AntDraggableModal>
    </>
  )
}

export default MoonInsert;