import { Form, Input, Select } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';


export default function BuyerEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();
    const [buyerTypeData,setBuyerTypeData]=useState<any>();
    const [areaData, setAreaData] = useState<any>()
    /** 后端数据Service */
    let wmService: any = "";
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'buyerType'
    };
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
        
            httpGet(wmService, {
                apiId: "area",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAreaData(res.data)
                }
            })


        }
        fetchData()
    }, [])

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '采购商类型'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>


                    <Form.Item
                        label="采购商类型"
                        name="buyerTypeName"
                        rules={[{ required: true, message: '请输入采购商类型!' }]}>
                        <Input />
                    </Form.Item>

                    
                    <Form.Item
                        label="区域"
                        name="areaId"
                        rules={[{ required: true, message: '请选择区域!' }]}>
                        <Select
                            showSearch
                            placeholder="请选择区域"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            options={getList.generalSelcetOption(areaData, 'areaName')}
                                >
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="排序"
                        name="buyerTSort">
                        <Input type={"number"} />
                    </Form.Item>


                </Form>
            </AntDraggableModal>

        </>
    )
}
