import { useEffect, useRef, useState } from 'react'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import ProductHeadEdit from './ProductHeadEdit';
import { Button } from 'antd';
import antMessage from '../../../utils/extend/AntdNotification'
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import DeptProduct from './DeptProduct';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';

export default function ProductHead(props: any) {

    const ref = useRef<any>();
    const [deptProductModal, setDeptProductModal] = useState<boolean>(false);
    const [deptProductData,setDeptProductData]=useState<any>();
    const { data, closeModal } = props;
    console.log("props",props);
    
    // const [form] = Form.useForm();
    const {deptId,deptName}=data

    // const [menuHeadData, setMenuHeadData] = useState<any>();

    /** api服务id */
    const apiId = 'productHead';
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

 

  

    //表头
    const columnsHead = [
    
        {
            title: '产品类型名称',
            dataIndex: 'productHeadName',
        },



        {
            title: '部门产品',
            // dataIndex: 'deptNameEx',
            render: (text: any, record: any) => {
                return <Button onClick={() => handleDeptProduct(record)} size={"small"} type="primary" >部门产品</Button>
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
        // {
        //     title: '微信查看',
        //     dataIndex: 'isWxCheck',
        //     render: (text: any, record: any) => (
        //         <Switch checkedChildren="是" unCheckedChildren="否"
        //             checked={text} key={record.id}
        //             onChange={(e) => handleChange(record.id, 'isWxCheck', e)}
        //         />
        //     )
        // }
    ];

  

    /**打开部门产品 */
    const handleDeptProduct = (record: any) => {
        setDeptProductData({
            productHeadId:record.id
        })
        // setDeptProductData()
        setDeptProductModal(true)
    }

    /**关闭部门产品 */
    const closeDeptProduct = (status: boolean) => {

        setDeptProductModal(false)
        if (status) {

        }
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品类型',
        apiId,
        showStates: true,
        showDefault: true,
        columns,
        pagination: true,
        sendPageData: { deptId },
        extendIdName: "deptId",
        EditComponent: ProductHeadEdit,
    };

    const handleOk = (e: any) => {
        closeModal(true);
    };

    return (
        <>
          <AntDraggableModal
                title={`${deptName}--产品类型`}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={800}>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </AntDraggableModal>
             
            {deptProductModal ? (<DeptProduct
                closeModal={closeDeptProduct}
                data={deptProductData}
            ></DeptProduct>) : null}
        </>
    )
}

