import React from 'react'
import SmsCustomEdit from './SmsCustomEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Tooltip } from 'antd';

export default function SmsCustom(props: any) {
    /** api服务id */
    const apiId = 'smsCustom';

    //表头
    const columnsHead = [
        {
            title: '定制名称',
            dataIndex: 'customName',
        },
        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any) => (text ? text.branchStoreName : "")
        },
        {
            title: '短信定制类',
            dataIndex: 'smsCustomTypeName',
        },
        {
            title: '发送内容',
            dataIndex: 'content',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 20 ? text.substring(0, 20) + '......' : text)
                    : ''
            }</Tooltip>
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '短信定制',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        showDefault: true,
        EditComponent: SmsCustomEdit,
        mountDataOptions:{
            userMount:{
               isDept:true,
            }
       },
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

