import React from 'react'
import SmsConfigEdit from './SmsConfigEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

export default function SmsConfig(props: any) {
  /** api服务id */
  const apiId = 'smsConfig';

  //表头
  const columnsHead = [
    {
      title: '配置名称',
      dataIndex: 'configName',
    },
    {
      title: '短信服务',
      dataIndex: 'smsService',
      render: (text: any) => (text ? text.serviceName : '')
    },
    {
      title: '分店',
      dataIndex: 'branchStore',
      render: (text: any) => (text ? text.branchStoreName : '')
    },
  ];

  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '短信配置',
    apiId,
    columns,
    showStates: true,
    pagination: true,
    EditComponent: SmsConfigEdit,
    mountDataOptions:{
      userMount:{
         isDept:true,
      }
 },
  };

  return (
    <WebUniversal props={extendProps} />
  )
}

