import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType, initDataType } from '../../system/WebUniversalNewEx';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { useRef, useState } from 'react';
import DeptProductEdit from './DeptProductEdit';
import ProductTransfer from '../../deptmanagement/ProductTransfer';
import { Button } from 'antd';

export default function DeptProduct(props: any) {
    const ref = useRef<any>();
    const { data, closeModal } = props;
    const {productHeadId,deptId}=data
    console.log("props",props);
    
    const [productTransferModel, setProductTransferModel] = useState<boolean>(false);
    const [estimateTransferData, setEstimateTransferData] = useState<any>();
    let apiId = "deptProduct"
    //表头
    const columnsHead = [
       
        {
            title: '产品',
            dataIndex: 'product',
            render: (text: any) => {
                return <div>{text.productName}</div>
            }

        },
        {
            title: '单位',
            dataIndex: 'product',
            render: (text: any) => {
                return <div>{text.productUnit.productUnitName}</div>
            }

        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },


    ];
    const handleOk = (e: any) => {
        closeModal(true);
    };
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '部门产品',
        apiId,
        showStates: true,
        sendPageData: { productHeadId },
        columns,
        pagination: true,
        EditComponent: DeptProductEdit,
    };
    // 沽清穿梭框处理
    const handleEstimateTransfer = () => {

        setEstimateTransferData({ productHeadId })
        setProductTransferModel(true);
    };
    //关闭穿梭框页面
    const onCloseEstimateTransfer = async (status: boolean) => {


        setProductTransferModel(false);
        if (status) {
            const currentData: initDataType = {
                apiId,

            }
            ref.current.initData(currentData)
        }
    }
    return (
        <>
            <AntDraggableModal
                title={'部门产品'}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={800}>
                <Button type="primary" onClick={handleEstimateTransfer}>添加部门产品</Button>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </AntDraggableModal>

            {productTransferModel
                ? (<ProductTransfer
                    closeModal={onCloseEstimateTransfer} data={estimateTransferData} />)
                : null}

        </>

    )
}
