import React from 'react'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import MenuWarehouseTypeEdit from './MenuWarehouseTypeEdit';

const MenuWarehouseType = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '大类',
            dataIndex: 'menuWarehouseCategory',
            render: (text: any) => (
                text ? text.menuWarehouseCategoryName : ""
            )
        },
        {
            title: '小类名称',
            dataIndex: 'menuWarehouseTypeName',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式仓库小类',
        apiId: 'menuWarehouseType',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: MenuWarehouseTypeEdit,
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}

export default MenuWarehouseType