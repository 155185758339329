import React, { useEffect, useRef, useState } from 'react';
import system from '../../../config/systematic';
import { columnsheadId } from '../../universal/columnshead';

import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalTree';
import DeptEdit from './DeptEdit';
import { Button } from 'antd';
import Employees from '../personnelManagement/Employees';
import PrintConfiguration from '../printManagement/PrintConfiguration';
import DeptProduct from '../../internal/warehouse/DeptProduct';
import ProductHead from '../../internal/warehouse/ProductHead';


const Dept = (props: any) => {
    /** 后端数据wmService */
    const wmService: any = system.wmService;
    const ref = useRef<any>();
    /** api服务id */
    const apiId = 'dept';
    const [modalEm, setModalEm] = useState(false);
    const [modalPrint, setModalPrint] = useState(false);


    const [productHeadModal, setProductHeadModal] = useState<boolean>(false);

    const [productHeadData,setProductHeadData]=useState<any>()

    // ProductHead
    const [deptId, setDeptId] = useState<string>();

    const [branchStoreId, setBranchStoreId] = useState<any>()

    const columnsHead = [

        {
            title: '专属id',
            dataIndex: 'selfId',
        },
        {
            title: '区域',
            dataIndex: 'area',
            sorter: true,
            render: (text: any) => (text ? text.areaName : "")
        },
        {
            title: '部门名称',
            dataIndex: 'deptName',
        },

        {
            title: '上级部门',
            dataIndex: 'superiorDept',
            render: (text: any) => (text ? text.deptName : "")
        },
        {
            title: '部门人员',
            dataIndex: 'deptNameEx',
            render: (text: any, record: any) => {
                return <Button onClick={() => handleDeptEm(record)} size={"small"} type="primary" >查看部门人员</Button>
            }
        },
        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any) => (text ? text.branchStoreName : "")
        },
        {
            title: '打印配置',
            // dataIndex: 'deptNameEx',
            render: (text: any, record: any) => {
                return <Button onClick={() => handlePrintConfiguration(record)} size={"small"} type="primary" >打印配置</Button>
            }
        },
     
        {
            title: '产品类型',
            // dataIndex: 'deptNameEx',
            render: (text: any, record: any) => {
                return <Button onClick={() => handleProductHead(record)} size={"small"} type="primary" >产品类型</Button>
            }
        },
    ]
    const columns = columnsheadId
        .concat(columnsHead as []);

    useEffect(() => {

    }, [])
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '部门',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        childrenColumnName: "subsidiaryDepts",
        indentSize: 15,
        EditComponent: DeptEdit
    };
    /**查看部门人员 */
    const handleDeptEm = (record: any) => {
        setDeptId(record.id)
        setModalEm(true)

    }
    /** 子组件回调函数，关闭员工窗口 */
    const onCloseModalEm = () => {
        setModalEm(false);
    };

    /**查看打印配置 */
    const handlePrintConfiguration = (record: any) => {
        setBranchStoreId(record.branchStoreId)
        setDeptId(record.id)
        setModalPrint(true)

    }

    /** 子组件回调函数，关闭打印配置窗口 */
    const closePrintConfiguration = () => {
        setModalPrint(false);
    };

   

    /**打开产品类型 */
    const handleProductHead = (record: any) => {
        console.log("record",record);
        
        setDeptId(record.id)
        setProductHeadData({
            deptId:record.id,
            deptName:record.deptName
        })
        setProductHeadModal(true)
    }

    /**关闭产品类型 */
    const closeProductHead= (status: boolean) => {

        setProductHeadModal(false)
        if (status) {

        }
    }


    /**关闭部门产品 */
    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref} />
            {modalEm ? (<Employees
                closeModal={onCloseModalEm}
                deptId={deptId}
                isModel={true}
            ></Employees>) : null}

            {modalPrint ? (<PrintConfiguration
                closeModal={closePrintConfiguration}
                deptId={deptId}
                isModel={true}
                branchStoreId={branchStoreId}
            ></PrintConfiguration>) : null}


            {/* {deptProductModal ? (<DeptProduct
                closeModal={closeDeptProduct}
                deptId={deptId}
            ></DeptProduct>) : null} */}

            
            {productHeadModal ? (<ProductHead
                closeModal={closeProductHead}
                data={productHeadData}
            ></ProductHead>) : null}

        </>
    )
};

export default Dept;
