// import { initDataType } from '../../system/WebUniversalNewEx';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { Button, Space, Spin, Switch, Table } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';

const MenuShopType = (props: any) => {

    const { data, closeModal } = props;
    const [menuShopTypeData, setMenuShopTypeData] = useState<any>();
    const [loadingData, setLoadingData] = useState<any>(false);

    const ref = useRef<any>();

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // initData = ref.current.initData;
        }
        const fetchData = async () => {
            getShowList();
        }
        fetchData()
    }, [])

    const getShowList = () => {
        fetchApi.GET(wmService, {
            apiId: "menuShopType",
            apiExtend: 'showShopTypeList',
            menuHeadId: data.menuHeadId,
            branchStoreId: localStorage.getItem("currentBranchStoreId")
        }).then(res => {
            if (res.success) {
                setMenuShopTypeData(res.data);
            }
        })
    }

    const handleChange = (record: any, key: boolean, rows: any) => {
        setLoadingData(true);

        let now_key: any;
        let after_key: any;

        for (var i = 0, len = rows.length; i < len; i++) {
            if (rows[i].id === record.id) {
                now_key = i;
                after_key = key ? (i - 1) < 0 ? 0 : i - 1 : (i + 1) === rows.length ? rows.length - 1 : i + 1;
            }
        }

        if (now_key !== after_key) {
            if (rows[now_key].state && rows[after_key].state) {
                fetchApi.PUT(wmService, {
                    apiId: "menuShopType",
                    apiExtend: 'changeShortCode',
                    menuHeadId: data.menuHeadId,
                    branchStoreId: localStorage.getItem("currentBranchStoreId"),
                    now_key: rows[now_key].shopType_shortCode,
                    after_key: rows[after_key].shopType_shortCode
                }).then(res => {
                    if (res.success) {
                        // const apiId = "menuShopType";
                        // const currentData: initDataType = {
                        //     apiId,
                        //     sendPageData: { menuHeadId: data.menuHeadId }
                        // }
                        // ref.current.initData(currentData);
                        getShowList();

                        // fetchApi.GET(wmService, {
                        //     apiId: "menuShopType",
                        //     apiExtend: 'showShopTypeList',
                        //     menuHeadId: data.menuHeadId
                        // }).then(ress => {
                        //     if (ress.success) {
                        //         console.log(ress.data);

                        //         // ref.current.initData(ress.data);
                        //         setMenuShopTypeData(ress.data);
                        //         // getShowList();
                        //     }
                        // })

                        setLoadingData(false);;
                    }
                })
            }
        }
    }

    //表头
    const columnsHead = [
        {
            title: '小分类',
            dataIndex: 'shopTypeName',
        },
        {
            title: '顺序',
            dataIndex: 'shopType_shortCode',
        },
        {
            title: '是否有数据',
            dataIndex: 'state',
            render: (text: any, record: any) => (
                <Switch checkedChildren="有" unCheckedChildren="无"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '调整顺序',
            dataIndex: 'sort',
            render: (text: any, record: any) => {
                if (record.state) {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" onClick={() => handleChange(record, true, menuShopTypeData)}>上</Button>
                            <Button size={"small"} type="primary" onClick={() => handleChange(record, false, menuShopTypeData)}>下</Button>
                        </Space>
                    )
                }
            }
        },
    ];

    /**关闭窗口并刷新数据 */
    // const onClose = (status: Boolean) => {
    //     closeModal(false)
    //     if (status === true) {
    //         getData(true);
    //     }
    // }

    const handleOk = (e: any) => {
        closeModal(true)
    };

    return (
        <>
            <AntDraggableModal
                title={'【 ' + data.menuHeadName + ' 】 调整小分类顺序'}
                open={true}
                okText='关闭'
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ disabled: loadingData }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={800}>

                {loadingData ? (
                    <Spin size="large" tip="Loading...">
                        <Table
                            pagination={false}
                            size="small"
                            rowKey={(record: any) => record.id}
                            columns={columnsHead} dataSource={menuShopTypeData}
                        >
                        </Table>
                    </Spin>
                ) :
                    (
                        <Table
                            pagination={false}
                            size="small"
                            rowKey={(record: any) => record.id}
                            columns={columnsHead} dataSource={menuShopTypeData}
                        >
                        </Table>
                    )}

            </AntDraggableModal >
        </>
    )
}

export default MenuShopType