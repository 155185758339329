import React from 'react'
import AreaEdit from './AreaEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

export default function Area(props:any) {
     /** api服务id */
     const apiId = 'area';

     //表头
     const columnsHead = [
         {
             title: '地区名称',
             dataIndex: 'areaName',
         },
     ];
 
     const columns = columnsheadId
         .concat(columnsHead as []);
 
     let extendProps: WUExtendPropsType = {
         ...props,
         titleName: '区域',
         apiId,
         columns,
         showStates: true,
         pagination:true,
         EditComponent: AreaEdit
     };
 
     return (
         <WebUniversal props={extendProps} />
     )
 }

