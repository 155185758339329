import { Form, Input, InputNumber, Switch } from 'antd';
import React, { useEffect, useRef } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export default function FeastTeamMenuWarehouseCategoryEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    // const [areaData, setAreaData] = useState<any>();

    /**区域的状态 */
    // const [areaIdDisable, setAreaIdDisable] = useState<any>(false);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            // fetchApi.GET(wmService, {
            //     apiId: "area",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {
            //         setAreaData(res.data)
            //     }
            // });

            // fetchApi.GET(wmService, {
            //     apiId: "feastTeamMenuWarehouse",
            //     apiExtend: 'getAreaId'
            // }).then(res => {
            //     if (res.success) {
            //         if (res.data.key !== 'manager') {
            //             setAreaIdDisable(true);
            //             form.setFieldsValue({ areaId: res.data.area.id });
            //         }
            //     }
            // });

        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'feastTeamMenuWarehouseCategory'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '福食菜式分类'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="福食菜式分类"
                        name="feastTeamMenuWarehouseCategoryName"
                        rules={[{ required: true, message: '请输入福食菜式分类!' }]}>
                        <Input />
                    </Form.Item>

                    {/* <Form.Item
                        label="区域"
                        name="areaId"
                        rules={[{ required: true, message: '请选择区域!' }]}>
                        <Select
                            showSearch
                            disabled={areaIdDisable}
                            placeholder="区域"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(areaData, 'areaName')}
                        </Select>
                    </Form.Item> */}

                    <Form.Item
                        label="排序"
                        name="sortBy">
                        <InputNumber min={1} />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        <Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>
                </Form>
            </AntDraggableModal>
        </>
    )
}