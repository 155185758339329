import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import moment from 'moment';

export default function Area(props: any) {
    /** api服务id */
    const apiId = 'caller';

    //表头
    const columnsHead = [
        {
            title: '号码',
            dataIndex: 'callerCustomer',
            render: (text: any) => (text ? text.id : "")
        },
        {
            title: '时间',
            dataIndex: 'sysDate',
            render: (text: any) => (text ? moment(text.createdAt).format('YYYY-MM-DD HH:mm:ss') : "")
        },
        {
            title: '客户',
            dataIndex: 'callerCustomer',
            render: (text: any) => (text ? text.customerName : "")
        },
        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any) => (text ? text.branchStoreName : "")
        },
        {
            title: '经手人',
            dataIndex: 'user',
            render: (text: any) => (text ? text.userName : "")
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '来电',
        apiId,
        columns,
        showStates: true,
        showEdit: false,
        pagination: true,
        showAdd: false,
        mountDataOptions:{
            userMount:{
               isDept:true,
            }
       },
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

