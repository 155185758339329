import React, { useEffect, useRef, useState } from 'react'
import { columnsheadId } from "../universal/columnshead";

import WebUniversalNewEx, { WUExtendPropsType } from '../system/WebUniversalNewEx';
import moment from 'moment';
import { Button } from 'antd';
import DownloadExcel from '../report/DownloadExcel';
import DownloadExcel1 from '../report/DownloadExcel1';
export default function DePurchaseApplication(props: any) {
    /** 可使用WebUniversal(父)组件函数 */
    const ref = useRef<any>();
    const apiId = "dePurchaseApplication"
    /** 刷新数据 */
    let initData: any;
    let wmService;
    const [refreshValue, setRefreshValue] = useState(0)
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // handleChangeOne = ref.current.handleChangeOne;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
            // 
        }
        if (refreshValue !== 0) {
            initData()
        }
    }, [])
    //表头
    const columnsHead = [
        {
            title: '部门',
            dataIndex: 'deptName',
        },


        {
            title: "下载",
            render: (text: any, record: any) => {

                return (
                    <Button onClick={() => DownloadExcel(record, apiId)}>下载EXCEL文件</Button>
                )
            }
        }

    ];

    const columns = columnsheadId
        .concat(columnsHead as []);
    /** 子表头内容 */
    const expandableColumnsHead = [
        {
            title: "分类名称",
            dataIndex: "classificationWarehouse",
            render: (text: any, record: any) => (text ? text.classificationWarehouseName : "")
        },
        {
            title: '时间',
            dataIndex: 'orderTime',
        }

    ]
    /** 子表头 */
    const expandableColumns = expandableColumnsHead;

    /**
     * 子子表头
     */
    const isExpandableColumns=[
        {
            title: "产品",
            dataIndex: "productName",
        },
        {
            title: "产品单位",
            dataIndex: "productUnitName",
        },
        {
            title: "备注",
            dataIndex: "memo",
        }
    ]
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '部门申购单',
        apiId,
        showStates: false,
        disabledAdd: true,
        showOperation: false,
        showPrint: true,
        showAdd: false,
        pagination: false,
        columns,
        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'products',

        //子子表格
        isIsExpandable:true,
        isExpandableColumns,
        isExpandableDataName:"bb"


    };
    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>

    )
}
