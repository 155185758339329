
import { useState, useEffect } from 'react';
import fetchApi from '../../utils/api/fetchApi';
import { Menu } from 'antd';
import system from '../../config/systematic';
import AntdIcon from './AntdIcon';
import { Link } from 'react-router-dom';
import antMessage from '../../utils/extend/AntdNotification';
import arrayHandle from '../../utils/baseLib/arrayHandle';

interface licenseGroup {
  id: Number,
  licenseGroupName: String,
  icon: React.ReactNode,
  licenses: license[],
};
interface MenuItem {
  key: string;
  id: string;
  label: React.ReactNode;
  path?: string;
  superiorLicenseId?: string,
  children?: MenuItem[];
  icon?: React.ReactNode,
  licenseSort?: number
}
interface license {
  id: Number,
  licenseName: String,
  icon: String,
  pageUrl: String,
  licenseSort: number,
};

interface httpData {
  success: boolean,
  data: licenseGroup[];
};

function transformData(data: any[]): MenuItem[] {
  const result: MenuItem[] = [];

  for (const item of data) {
    if (!item.licenses) {
      continue;
    }

    const menuItem: MenuItem = {
      key: item.id,
      id: item.id,
      label: item.licenseGroupName,
      icon: <AntdIcon iconName={item.icon} />,
      children: [],
    };

    for (const license of item.licenses) {
      if (license.superiorLicenseId) {
        continue;
      }

      const childItem: MenuItem = {
        id: license.id,
        key: license.id,
        label: license.licenseName,
        superiorLicenseId: license.superiorLicenseId,
        path: license.pageUrl,
        licenseSort: license.licenseSort,
        icon: <AntdIcon iconName={license.icon} />
      };

      if (!childItem.superiorLicenseId) {
        childItem.path = `/${childItem.path}`;
        childItem.label = license.licenseName
      }

      menuItem.children?.push(transformChildItem(childItem, item.licenses));


    }

    result.push(menuItem);
  }

  return reorder(result);
}

function transformChildItem(item: MenuItem, licenses: any[]): MenuItem {

  for (const license of licenses) {
    if (license.superiorLicenseId === item.id) {
      const childItem: MenuItem = {
        id: license.id,
        key: license.id,
        licenseSort: license.licenseSort,
        label: license.licenseName,
        path: license.pageUrl,
        icon: <AntdIcon iconName={license.icon} />
      };


      if (childItem.path) {
        childItem.path = `${item.path}/${childItem.path}`;
        childItem.label = childItem.label

      }
      item.children = item.children || [];



      item.children.push(transformChildItem(childItem, licenses));
    }
  }

  return item;
}


/**重新排序 */
function reorder(rows: MenuItem[]): MenuItem[] {

  return rows.map((item) => {

    if (item.children && item.children.length >= 0) {


      return {
        icon: item.icon,
        id: item.id,
        key: item.key,
        label: item.label,
        path: item.path,
        licenseSort: item.licenseSort,
        children: arrayHandle.sort(reorder(item.children), "licenseSort", "asc"),
      }
    } else {
      return {
        icon: item.icon,
        id: item.id,
        key: item.key,
        licenseSort: item.licenseSort,
        label: <Link to={item.path!}>{item.label}</Link>,
        path: item.path,
      }
    }
  })
}
const useSetMenu = (resAll: httpData) => {

  if (resAll === undefined) { return }
  let resData = resAll.data;

  //修改数据
  let newData = transformData(resData)


  let recordsComponent = (
    <Menu
      theme="dark"
      mode="inline"
      items={newData as MenuItem[]}
    >
    </Menu>)

  return recordsComponent;
};

const SiderMenu = (props: any) => {
  const [records, setRecords] = useState<httpData>();
  const { token } = props.props

  /** 后端数据wmService */
  const wmService: any = system.wmService;
  useEffect(() => {
    //web 方式
    const fetchData = async () => {
      fetchApi.GET(wmService,
        {
          apiId: 'licenseGroup',
          apiVariable: 'licenseManage',
          apiExtend: 'siderMenu'
        }, undefined, token).then(res => {
          
          // let date = sessionStorage.getItem("date") as any;
          if (res.data.length === 0) {
            antMessage('error', "错误", "无菜单数据/无菜单权限/token过期")
            setTimeout(() => {
              sessionStorage.clear();
              localStorage.removeItem("token")
              window.location.href = "/login"
            }, 1500)
          }

          // if (!date) {
          //   sessionStorage.setItem("date", moment().format("YYYY-MM-DD"))
          // }
          setRecords(res);
        }).catch(err => {
          if (err.message === "Invalid/Expired 无效/过期 token" || err.message === "No such user，用户不存在") {
            antMessage('error', "错误", err.message)
            setTimeout(() => {
              sessionStorage.clear();
              localStorage.removeItem("token")
              window.location.href = "/login"
            }, 2000)
          }
        })
    };
    fetchData();
  }, []);

  return (
    <div>
      {useSetMenu(records as httpData)}
    </div>
  )
}

export default SiderMenu;




