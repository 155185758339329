import { Button, Col, Form, Select, Space } from 'antd'
import { useEffect, useState } from 'react';
import getList from '../universal/getList';
import fetchApi from '../../utils/api/fetchApi';
import { MinusCircleOutlined, PlusOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

//  套餐/菜单formList通用组件
export default function WebSetMealFormList(props: any) {

  const { form, type, ref, setSetMealData } = props.props;

  const { formName, setMealData } = props;

  console.log(props);
  console.log(setMealData);
  

  const [menuData, setMenuData] = useState<any>();

  /** 后端数据wmService */
  let wmService: any = "";

  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wmService = ref.current.wmService;
    }
    const fetchData = async () => {

      fetchApi.GET(wmService, {
        apiId: "menu",
        apiExtend: 'showList'
      }).then(res => {
        if (res.success) {
          setMenuData(res.data);
        }
      });

    }

    fetchData()
  }, [])

  return (
    <>
      <Col span={24}>
        <Form.List name={formName}>
          {(fields, { add, remove, move }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      label="菜式"
                      name={[name, 'enuId']}
                      rules={[
                        {
                          required: true,
                          message: '请选择菜式',
                        },
                      ]}
                    >
                      <Select
                        //optionLabelProp="label"
                        style={{ width: 870 }}
                        showSearch
                        allowClear
                        // onChange={(e) => handleChange(form, e, name, formName, type, tempDeleteData)}
                        placeholder="菜式"
                        optionFilterProp="children"
                        // filterOption={(input, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                        filterOption={(input, option: any) => {
                          // console.log("option",option);
                          // console.log("input",input);
                          return true
                        }}
                      >
                        {getList.general(menuData, "foodName")}
                      </Select>
                    </Form.Item>
                    <CaretDownOutlined onClick={() => move(name, name + 1)} />
                    <CaretUpOutlined onClick={() => move(name, name - 1)} />
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    添加菜式
                  </Button>
                </Form.Item>
              </>
            )
          }
          }
        </Form.List>
      </Col>
    </>
  )
}