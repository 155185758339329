import AssetSubcategoryEdit from './AssetSubcategoryEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 
const WebComponents = (props: any) => {

    /** api服务id */
    const apiId = 'assetSubcategory';

    //表头
    const columnsHead = [
        {
            title: '类别',
            dataIndex: 'assetClass',
            render: (text: any) => (text ? text.className : "")
        },
        {
            title: '子类别名',
            dataIndex: 'subcategoryName'
        },
        {
            title: '排序',
            dataIndex: 'sortBy'
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '资产子类别',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: AssetSubcategoryEdit
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebComponents;
