import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';
import objectHandle from '../../../utils/baseLib/objectHandle';



const RoomEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    /** 后端数据wmService */
    let wmService: any = "";
    const ref = useRef<any>();
    const [floorData, setFloorData] = useState<any>();
    const [roomTypeData, setRoomTypeData] = useState<any>();
    // const [branchStoreData, setbranchStoreData] = useState<any>();
    // const [reserveStatusData, setReserveStatusData] = useState<any>();

    const [initialMenuHeadsId, setInitialMenuHeadsId] = useState<any>([]);
    const [menuHeadData, setMenuHeadData] = useState<any>();
    const [orderingMode, setOrderingMode] = useState<any>()
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'room',
    };
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') return
                if (res.success === true) {
                    const menuHeadsId = res.data.menuHeadsId;
                    let menuHeadRows;
                    if (objectHandle.isEmpty(menuHeadsId) === false) {
                        menuHeadRows = menuHeadsId.split('@');
                    }
                    setInitialMenuHeadsId(menuHeadRows);
                    form.setFieldsValue({
                        menuHeadsId: menuHeadRows
                    })
                }
            })
        }
        const fetchData = async () => {

            httpGet(wmService, {
                apiId: "branchStoreFloor",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                

                    setFloorData(res.data)
                }
            })

            httpGet(wmService, {
                apiId: "roomType",
                apiExtend: 'showList',
                apiData: {
                    extendObj:{
                        menuHeadsIdNum:-1,

                    }
                }
            }).then(res => {
                if (res.success) {

                    setRoomTypeData(res.data)
                }
            })

            // httpGet(wmService, {
            //     apiId: "branchStore",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {

            //         setbranchStoreData(res.data)
            //     }
            // })

            // httpGet(wmService, {
            //     apiId: "generalData",
            //     apiExtend: 'showList',
            //     apiVariable: "reserveStatus",
            // }).then(res => {
            //     if (res.success) {
            //         setReserveStatusData(res.data)
            //     }
            // })


            httpGet(wmService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: "orderingMode",
            }).then(res => {
                if (res.success) {
                    setOrderingMode(res.data)
                }
            })
            fetchApi.GET(wmService,
                {
                    apiId: 'menuHead',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {

                        setMenuHeadData(res.data);
                    }
                });
        };
        fetchData();
    }, []);
    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '房间'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={900}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row >

                        <Col span={6}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>


                            <Form.Item
                                label="房名"
                                name="roomName"
                                rules={[{ required: true, message: '请输入房间名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="房号"
                                name="roomNum"
                                rules={[{ required: true, message: '请输入房号!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="楼层"
                                name="floorId"
                                rules={[{ message: '请选择楼层!', required: true }]}>
                                <Select
                                    showSearch
                                    placeholder="选择楼层"
                                    optionFilterProp="children"
                                    // optionLabelProp='floor.floor'
                                    options={floorData}
                                    fieldNames={
                                        { value: "id",label:"floor" }
                                    }
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                                >

                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="菜谱"
                                name="menuHeadsId"
                                initialValue={initialMenuHeadsId} >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择菜谱"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(menuHeadData, 'menuHeadName', 'id', undefined, false)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="房间类型"
                                name="roomTypeId"
                                rules={[{ message: '请选择房间类型!', required: true }]}>
                                <Select
                                    showSearch
                                    placeholder="选择房间类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(roomTypeData, 'roomType')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="点餐模式"
                                name="orderingModeId"
                                rules={[{ message: '请选择点餐模式!', required: false }]}>
                                <Select
                                    showSearch
                                    placeholder="请选择点餐模式"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(orderingMode, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>


                        <Col span={6}>
                            <Form.Item
                                label="是否预定"
                                name="isReserve"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="排序"
                                name="roomSort">
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}
const aa = (item: any) => {
   

    return <div>{item.data.floor.floor}</div>
}
export default RoomEdit;