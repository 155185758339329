import React, { useRef } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import PaymentLlistEdit from './PaymentListEdit';
import { columnsheadId } from '../../universal/columnshead';

export default function PaymentLlist(props: any) {
  const { branchStoreId, closeModal } = props;
  const ref = useRef<any>();
  const handleOk = (e: any) => {
    closeModal(true);
  };
  //表头
  const columnsHead = [

    {
      title: '分店',
      dataIndex: 'branchStore',
      render: (text: any) => (text ? text.branchStoreName : "")
    },
    {
      title: '支付方式',
      dataIndex: 'paymentMethod',
      render: (text: any) => (text ? text.paymentMethodName : "")
    },
    {
      title: '排序',
      dataIndex: 'sortBy',
    },
    {
      title: '线上支付',
      dataIndex: 'isOnlinePayment',
      render:(text:any)=>(text?"是":"否")
    },
    {
      title: '支付二维码',
      dataIndex: 'isPayCode',
      render:(text:any)=>(text?"是":"否")
    },




  ];
  const columns = columnsheadId
    .concat(columnsHead as []);
  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '支付列表',
    apiId: 'paymentList',
    showStates: true,
    sendPageData: { branchStoreId },
    columns,
    pagination: true,
    EditComponent: PaymentLlistEdit,
    extendIdName:"branchStoreId",
    showDefault: true,
  };
  return (
    <div>
      <AntDraggableModal
        open={true}
        okText='确定'
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleOk}
        closable={false}
        maskClosable={false}
        width={800}>
        <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
      </AntDraggableModal>
    </div>
  )
}
