import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';

export default function CallWayEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    /** 阿里Voice数据 */
    const [aliVoiceData, setAliVoiceData] = useState<any>();

    /** 音频编码格式数据 */
    const [formatData, setFormatData] = useState<any>();

    /** 音频采样率数据 */
    const [sample_rateData, setSample_rateData] = useState<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;

            // ref.current.row.then(async (res: any) => {
            //     if (typeof res === 'undefined') { return }
            //     if (res.success === true) {
            //         // console.log(res.data);
            //     }
            // })
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: 'aliVoice',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAliVoiceData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'format',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setFormatData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'sample_rate',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setSample_rateData(res.data);
                }
            });
        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'callWay'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '叫号方式'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >
                <Form
                    form={form}
                    initialValues={{ "times": 1, "queuingSettingId": data.sendPageData.queuingSettingId, "volume": 50, "speech_rate": 0, "pitch_rate": 0, "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="排队设置Id"
                                name="queuingSettingId">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="阿里Voice"
                                name="aliVoiceId"
                                rules={[{ required: true, message: '请选择阿里Voice!' }]}>
                                <Select
                                    placeholder="阿里Voice"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {/* {getList.general(aliVoiceData, 'voiceName')} */}
                                    {getList.generalOption(aliVoiceData, {
                                        itemId: "id", noId: false, label: "voiceName", children: customizeData
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="选择音频编码格式"
                                name="format"
                                rules={[{ required: true, message: '请选择音频编码格式!' }]}
                            >
                                <Select
                                    placeholder="音频编码格式"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(formatData, {
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="选择音频采样率 (Hz)"
                                name="sample_rate"
                                rules={[{ required: true, message: '请选择音频采样率' }]}
                            >
                                <Select
                                    placeholder="音频采样率"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(sample_rateData, {
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="音量 (0 ~ 100)"
                                name="volume"
                            >
                                <InputNumber min={0} max={100} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="语速 (-500 ~ 500)"
                                name="speech_rate"
                            >
                                <InputNumber min={-500} max={500} placeholder='-500 ~ 500' />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="语调 (-500 ~ 500)"
                                name="pitch_rate"
                            >
                                <InputNumber min={-500} max={500} placeholder='-500 ~ 500' />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="叫号次数"
                                name="times"
                            >
                                <InputNumber min={0} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="排序"
                                name="sortBy"
                            >
                                <InputNumber min={0} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="访问个性化音色"
                                name="enable_ptts"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

/**自定义显示数据 */
export let customizeData = (item: any) => {
    return <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>{item.voiceName + " (" + item.languageTypeName + ")"}</div>
    </div>
}