import fetchApi from '../utils/api/fetchApi';
import systematic from './systematic';

const wmService = systematic.wmService;

/** 文件系统 */
const filesSystem = async () => { };

/** 获取默认服务器 */
filesSystem.getDefaultServer = async () => {
    let res;
    
    const obj = await fetchApi.GET(wmService,
        {
            apiId: 'filesServer',
            apiExtend: 'getDefaultServer'
        })
    
    if (obj.success === true) {
        
        res = obj.data;
    }
    return res;
};

export default filesSystem;