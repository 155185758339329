import React, { useEffect, useRef, useState } from 'react'
import WebEditUniversal, { WEUExtendPropsType } from '../../../system/WebEditUniversalNewEx'
import AntDraggableModal from '../../../../utils/extend/AntDraggableModal';
import { Form, Input, InputNumber, Select, Switch } from 'antd';
import fetchApi from '../../../../utils/api/fetchApi';
import getList from '../../../universal/getList';
import systemConfig from '../../../../config/systematic';

export default function MenuSpeSpecInventoryEdit(props: any) {
  const ref = useRef<any>();
  const [form] = Form.useForm(); //form 
  const { data } = props;


  /**菜品特殊规格数据 */
  const [menuSpeSpeData, setMenuSpeSpeData] = useState<any>();



  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const handleOk = (e: any) => {
    ref.current.handleOk();
  };
  const handleCancel = (e: any) => {
    ref.current.handleCancel();
  };


  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wmService = ref.current.wmService;
    }
    const fetchData = async () => {

      fetchApi.GET(wmService, {
        apiId: 'menuSpeSpecInventory',
        apiExtend: 'showList',
        apiData:{
          mountDataOptions:{
            userMount:{
               isDept:true,
            }
       },
        }
      }).then(res => {
          
        if (res.success) {
          setMenuSpeSpeData(res.data);
        }
      });
      // if (data.type !== "NEW") {
      //     ref.current.row.then((res: any) => {
      //         if (res.success) {
      //             form.setFieldsValue({menuHeadId:res.data.menu.menuHeadId})
      //             fetchApi.GET(wmService, {
      //                 apiId: "menu",
      //                 apiExtend: "getHeadToMenu",
      //                 apiData: {
      //                     menuHeadId: res.data.menu.menuHeadId
      //                 }
      //             }).then((res1: any) => {
      //                 setMenuData(res1.data)

      //             }).catch((e) => {
      //                 console.log(e);

      //             })
      //         }

      //     })


      // }
    }
    fetchData()
  }, [])
  // const changeMenu = (vaule: any) => {

  //     fetchApi.GET(wmService, {
  //         apiId: "menu",
  //         apiExtend: "getHeadToMenu",
  //         apiData: {
  //             menuHeadId: vaule
  //         }
  //     }).then((res: any) => {
  //         setMenuData(res.data)
  //         form.resetFields(["menuId"])

  //     }).catch((e) => {
  //         console.log(e);

  //     })
  // }
  let extendProps: WEUExtendPropsType = {
    ...props,
    form,
    apiId: 'menuSpeSpecInventory',
  };
  return (
    <>
      <WebEditUniversal ref={ref} props={extendProps} />
      <AntDraggableModal
        title={(data.type === 'NEW' ? '新增' : '编辑') + '菜品库存'}
        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
      >
        <Form
          form={form}
          name="Edit"
          onFinish={handleOk}
         onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}
        >
          <Form.Item
            label="id"
            name="id">
            <Input disabled={true} />
          </Form.Item>
          <Form.Item
            label="菜品特殊规格"
            name="branchStoreMenuWarehouseSpecSpecialId"
            rules={[{ required: true, message: '请选择菜品特殊规格' }]}
          >
            <Select
               showSearch
              onSearch={(e)=>getList.search("menuSpeSpecInventory",setMenuSpeSpeData,e)}
              placeholder="菜品特殊规格"
              optionFilterProp="children"
              filterOption={(input, option: any) => {
                return true
              }
              }
            >
              {getList.generalOption(menuSpeSpeData, {
                itemId: "id",noId: false,label: "branchStoreMenuWarehouseSpecSpecialName",children: customizeData,
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="quantity"
            label="库存"
            rules={[{ required: true, message: '请输入库存' }]}
          >
            <InputNumber />
          </Form.Item>

          <Form.Item
            label="状态"
            name="isUse"
            valuePropName="checked"
            initialValue={true}>
            <Switch checked={true}
              checkedChildren="启用"
              unCheckedChildren="关闭" />
          </Form.Item>

        </Form>
      </AntDraggableModal>
    </>
  )
}
/**自定义显示数据 */
export let customizeData = (item: any) => {
  return <div style={{ display: "flex", justifyContent: "space-between" }}>
    <div style={{ color: "yellow" }}>{item.foodName}</div>
    <div >{item.branchStoreMenuWarehouseSpecName}</div>
    <div >{item.branchStoreMenuWarehouseSpecSpecialName}</div>
  </div>
}