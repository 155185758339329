import { RouteObject } from 'react-router-dom';
import { getPageUrl } from './index';

//sms
import SmsConfig from '../components/external/sms/SmsConfig';
import SmsCustom from '../components/external/sms/SmsCustom';
import SmsManage from '../components/external/sms/SmsManage';
import SmsService from '../components/external/sms/SmsService';
//messageManage
import CallerCustomer from '../components/external/messageManage/CallerCustomer';
import Caller from '../components/external/messageManage/Caller';

const routes_sms = async (licenseList: Array<any>) => {
  const res: RouteObject[] = [
    //sms
    {
      path: await getPageUrl(licenseList, 'smsConfig'),
      element: <SmsConfig></SmsConfig>
    },
    {
      path: await getPageUrl(licenseList, 'smsCustom'),
      element: <SmsCustom></SmsCustom>
    },
    {
      path: await getPageUrl(licenseList, 'smsManage'),
      element: <SmsManage></SmsManage>
    },
    {
      path: await getPageUrl(licenseList, 'smsService'),
      element: <SmsService></SmsService>
    },
    //messageManage
    {
      path: await getPageUrl(licenseList, 'callerCustomer'),
      element: <CallerCustomer></CallerCustomer>
    },
    {
      path: await getPageUrl(licenseList, 'caller'),
      element: <Caller></Caller>
    },

  ]
  return res;
}


export default routes_sms