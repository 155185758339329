import { Form, Input, Switch, Select, Row, Col } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const [datas, setDatas] = useState<any>();
    const [licenseTypeData, setLicenseTypeData] = useState<any>();
    const [licenseData, setLicenseData] = useState<any>();
    /** 后端数据wmService */
    let wmService: any = '';
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "license",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setLicenseData(res.data)
                }
            })

            fetchApi.GET(wmService,
                {
                    apiId: 'licenseGroup',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setDatas(res.data)
                    }
                });
            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'licenseType',
                    apiExtend: 'showList'
                }).then(res => {

                    if (res.success) {
                        setLicenseTypeData(res.data)
                    }
                });
        };
        fetchData();
    }, []);

    const { Option } = Select;
    const getLicenseGroupList = (datas: any) => {
        if (typeof datas !== "undefined") {
            return (datas.map((item: any, key: number) => {
                return <Option value={item.id} key={key}>{item.licenseGroupName}</Option>
            })
            )
        }
    };

    const getGeneralList = (datas: any) => {
        if (typeof datas !== "undefined") {
            return (datas.map((item: any, key: number) => {
                return <Option value={item.id} key={key}>{item.name}</Option>
            })
            )
        }
    };

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'deviceManagement',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '设备'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="ID"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="名称"
                                name="deviceName">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={18}>
                            <Form.Item
                                label="设备码"
                                name="deviceCode">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="检查设备码"
                                name="isCheckDeviceCode"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={18}>
                            <Form.Item
                                label="IP"
                                name="ip">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="检查ip"
                                name="isCheckIp"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={18}>
                            <Form.Item
                                label="用户ids"
                                name="userIds">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="检查用户id"
                                name="isCheckUserId"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="memo">
                                <Input />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;