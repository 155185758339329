import { Card, Form, Input, List, Row, Select, Space, Tooltip } from 'antd'
import { useContext, useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi'
import systemConfig from '../../../config/systematic';
import TempRoomList from '../../generalComponents/TempRoomList';
import OrderList from './OrderList';
/** wsServer */
import CastOrderDrawe from './CastOrderDrawe';
import getList from '../../universal/getList';
import TimeTypeList from './TimeTypeList';

import { rtnType } from '../../../types/general';
import { SocketContext } from '../../../App';
import moment from 'moment';
import MenuTester from './MenuTester';
import arrayHandle from '../../../utils/baseLib/arrayHandle';

export default function Cast() {

    const useContextData = useContext(SocketContext);
    const { wsServer } = useContextData;


    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [tempRoomData, setTempRoomData] = useState<any>();
    const apiDataEx = useRef();

    // const [currentOrder, setCurrentOrder] = useState<any>();
    const [queryData, setQueryData] = useState<string>('');


    /**楼层Id */
    const [seleteFloorId, setSeleteFloorId] = useState<any>("998")
    /**
     * 房间类型Id
     */
    const [seleteRoomTypeId, setSeleteRoomTypeId] = useState<any>("999")
    /** 房间状态*/
    const [seleteRoomStatus, setSeleteRoomStatus] = useState<any>(-1)

    const [floorData, setFloorData] = useState<any>();
    const [roomTypeData, setRoomTypeData] = useState<any>();
    const [roomStatusData, setRoomStatusData] = useState<any>();

    const [castOrderDraweData, setCastOrderDraweData] = useState<any>();
    const [castOrderDraweOpen, setCastOrderDraweOpen] = useState<boolean>(false);


    const [orderListOpen, setOrderListOpen] = useState<boolean>(false);
    const [orderListData, setOrderListData] = useState<any>();


    const [menuTesterOpen, setMenuTesterOpen] = useState<boolean>(false);
    const [menuTesterData, setMenuTesterData] = useState<any>();

    // TimeTypeList
    const [timeTypeListOpen, setTimeTypeListOpen] = useState<boolean>(false);
    const [timeTypeListata, setTimeTypeListata] = useState<any>();


    const [seleteTimeTypeName, setSeleteTimeTypeName] = useState<any>("")

    const [seleteTimeTypeId, setSeleteTimeTypeId] = useState<any>("");

    const [seleteBookingDate, setSeleteBookingDate] = useState<any>("");

    const [diningMethodData, setDiningMethodData] = useState<any>();

    const [selectDiningMethod, setSelectDiningMethod] = useState<any>();

    useEffect(() => {
        if (wsServer.connected) {
            wsServer.addListener(["wm"], { wm: wsListener }
            )
        }
        const fetchData = async () => {
            await initTempRoom({})
            fetchApi.GET(wmService, {
                apiId: "branchStoreFloor",
                apiExtend: 'showList',
                apiData: {
                    mountDataOptions: {
                        userMount: {
                            isDept: true
                        }
                    }
                }
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "998", floor: "所有楼层" }, ...res.data]
                    setFloorData(newData)
                }
            })

            fetchApi.GET(wmService, {
                apiId: "roomType",
                apiExtend: 'showList',
                apiData: {
                    mountDataOptions: {
                        userMount: {
                            isDept: true
                        }
                    },

                    extendObj: {
                        menuHeadsIdNum: -1,

                    }
                }
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", roomType: "所有类型" }, ...res.data]
                    setRoomTypeData(newData)
                }
            })

            fetchApi.GET(wmService, {
                apiId: "diningMethod",
                apiExtend: 'showList',
                apiData: {
                    mountDataOptions: {
                        userMount: {
                            isDept: true
                        }
                    },


                }
            }).then(res => {
                if (res.success) {
                    setDiningMethodData(res.data)
                    let data = res.data.filter(((item: any) => item.isDefault))
                    if (data.length > 0 && data.length === 1) {
                        setSelectDiningMethod(data[0].id)
                    }



                }
            }).catch((err) => {

            })

            fetchApi.GET(wmService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: "roomStatus",
            }).then(res => {
                if (res.success) {
                    setRoomStatusData(res.data)
                }
            })
        }
        fetchData()
        return () => {
            wsServer.removeListener(["wm"])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    /**
        * ws监听
        * @param payload 接收到的数据
        * @param callback 回复服务器
        */
    const wsListener = async (payload: rtnType) => {

        if (payload.success === true) {
            const { data } = payload;
            const { apiId, apiExtend } = data;
            switch (apiId) {
                case 'wxCateringOrder':
                    switch (apiExtend) {
                        case "create":

                            const { queryString, timeTypeId, roomTypeId, roomStatus, floorId } = apiDataEx.current!;
                            await initTempRoom({ queryString, timeTypeId, roomTypeId, roomStatus, floorId })

                            break;
                    }
                    break;
                case "cateringOrder":
                    switch (apiExtend) {
                        case "settleAccounts":
                            const { queryString, timeTypeId, roomTypeId, roomStatus, floorId } = apiDataEx.current!;
                            await initTempRoom({ queryString, timeTypeId, roomTypeId, roomStatus, floorId })
                            break;
                    }
                    break;
                default:
                    break;
            }
        } else {
        }
    };

    const initTempRoom = async (apiData: any) => {
        const { timeTypeId, queryString, isHandleRoomOrder, roomTypeId, roomStatus, floorId, isEquality } = apiData;
        await fetchApi.GET(wmService, {
            apiId: "tempRoom",
            apiExtend: 'query',

            apiData: {
                timeTypeId: timeTypeId ? timeTypeId : seleteTimeTypeId,
                isOrderSb: true,
                queryString: queryString ? queryString : queryData,
                roomTypeId: roomTypeId ? roomTypeId : seleteRoomTypeId,
                roomStatus: roomStatus || Number(roomStatus) === 0 ? roomStatus : seleteRoomStatus,
                floorId: floorId ? floorId : seleteFloorId,
                isHandleRoomOrder,
                isEquality,
                mountDataOptions: {
                    userMount: {
                        isDept: true
                    }
                },
            }
        }).then((res) => {
            if (res.success) {

                let data = res.data;
                setSeleteTimeTypeId(data.findTime.id)
                setQueryData(data.queryString)
                setSeleteRoomStatus(data.roomStatus)
                setSeleteRoomTypeId(data.roomTypeId)
                setSeleteFloorId(data.floorId)
                setSeleteBookingDate(data.findTime.bookingDate)
                setSeleteTimeTypeName(data.findTime.typeName)
                setTempRoomData(data.rows)
                let obj: any = {
                    timeTypeId: data.findTime.id,
                    roomTypeId: data.roomTypeId,
                    roomStatus: data.roomStatus,
                    floorId: data.floorId,
                    queryString: data.queryString,
                }
                apiDataEx.current = obj;
                if (isHandleRoomOrder) {
                    if (data.rows.length === 1) {
                        handleRoomOrder(data.rows[0])
                    }
                }

            }
        }).catch((err) => {

        })

    }
    // const timeTypeChange = (id: string) => {
    //     setSeleteTimeTypeId(id)
    //     initTempRoom({ timeTypeId: id, queryString: queryData, floorId: seleteFloorId, roomStatus: seleteRoomStatus, roomTypeId: seleteRoomTypeId })


    // }

    const roomTypeChange = (e: any) => {

        setSeleteRoomTypeId(e)
        initTempRoom({
            roomTypeId: e,
            floorId: seleteFloorId,
            roomStatus: seleteRoomStatus,
            queryString: queryData,
            timeTypeId: seleteTimeTypeId,

        })


    }
    const reserveStatusChange = (e: any) => {

        setSeleteRoomStatus(e)
        initTempRoom({
            roomTypeId: seleteRoomTypeId,
            floorId: seleteFloorId,
            roomStatus: e,
            queryString: queryData,
            timeTypeId: seleteTimeTypeId,

        })


    }
    const floorChange = (e: any) => {
        setSeleteFloorId(e)
        initTempRoom({
            roomTypeId: seleteRoomTypeId,
            floorId: e,
            roomStatus: seleteRoomStatus,
            queryString: queryData,
            timeTypeId: seleteTimeTypeId,

        })
    }
    const diningMethodChage = (e: any) => {
        setSelectDiningMethod(e)

    }


    const handleRoomOrder = (item: any) => {
        const { roomId, id, room } = item;

        //开新单
        if (room.cateringOrders.length === 0) {

            handleCastOrderDrawe({ roomId, tempRoomId: id, type: "NEW", timeTypeId: item.timeTypeIdm, sbId: item.timeType.scheduledTime.sbId })
        } else {
            //查看订单
            if (!roomId || !item.timeType.scheduledTime.sbId || !id) return false;
            if (item.room.cateringOrders.length === 0) return false;

            fetchApi.GET(wmService, {
                apiId: "room",
                apiExtend: "queryOrderList",
                apiData: {
                    roomId,
                    timeTypeId: item.timeTypeId
                }
            }).then(async (res) => {

                if (res.data.length === 1) {

                    handleCastOrderDrawe({ ...res.data[0], tempRoomId: id, type: "EDIT" })
                    // setCurrentOrder(res.)
                    // setCurrentOrderId(res.data[0]!.id)
                    // await initData(res.data[0]!.id, false, true)
                } else if (res.data.length >= 2) {
                    //执行弹窗选择
                    setOrderListOpen(true)
                    setOrderListData({ rows: res.data, tempRoomId: id, timeTypeId: seleteTimeTypeId })
                } else {
                    // setRows([])
                    // setCurrentOrder(undefined)
                    // setRoomData([])
                    console.log("无订单");
                }
            })
        }



    }

    /**打开收银订单详情 */
    const handleCastOrderDrawe = (data: any) => {

        setCastOrderDraweOpen(true)
        setCastOrderDraweData({ ...data, timeTypeId: seleteTimeTypeId, type: data.type, isIo: true, diningMethodId: selectDiningMethod })
    }
    //关闭收银订单详情
    const onCloseCastOrderDraw = async (status: boolean) => {
        setCastOrderDraweOpen(false);
        if (status) {
            await initTempRoom({})


        }
    }

    //打开市别选择
    const handleTimeTypeList = () => {
        setTimeTypeListOpen(true)
        setTimeTypeListata({ bookingDate: seleteBookingDate ? seleteBookingDate : moment().format("YYYY-MM-DD") })
    }


    //关闭市别选择
    const onCloseTimeTypeList = async (status: boolean, apiData: any) => {
        setTimeTypeListOpen(false);
        if (status) {
            const { timeTypeId } = apiData;
            await initTempRoom({ timeTypeId })


        }
    }


    //关闭订单选择页面
    const onCloseOrderList = async (status: boolean) => {
        setOrderListOpen(false)
        if (status) {
            await initTempRoom({})
        }
    }

    //打开试机页面
    const openMenuTester = () => {
        setMenuTesterOpen(true)


    }

    //关闭试机
    const onCloseMenuTester = async (status: boolean) => {
        setMenuTesterOpen(false);
        if (status) {
            await initTempRoom({})


        }
    }

    return (
        <>
            <div style={{ marginBottom: 20, display: "flex", justifyContent: "space-between" }} >
                <div>
                    <label>房间类型 :  </label>
                    <Select
                        showSearch
                        onChange={roomTypeChange}
                        placeholder="房间类型"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {getList.general(roomTypeData, "roomType")}
                    </Select>
                    <span> - </span>
                    <label>房间状态 :  </label>
                    <Select
                        showSearch
                        onChange={reserveStatusChange}
                        placeholder="房间状态"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {getList.general(roomStatusData, "name")}
                    </Select>


                    <span> - </span>
                    <label>房间楼层 :  </label>
                    <Select
                        showSearch
                        onChange={floorChange}
                        placeholder="楼层"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {getList.general(floorData, "floor")}
                    </Select>

                    <span> - </span>
                    <label>就餐方式 :  </label>
                    <Select
                        showSearch

                        popupMatchSelectWidth={100}
                        value={selectDiningMethod}
                        onChange={diningMethodChage}
                        placeholder="就餐方式"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {getList.general(diningMethodData, "diningName")}
                    </Select>
                    <span> - </span>

                    <label>查询 :  </label>
                    <Space>
                        <Input
                            style={{ width: 100 }}
                           onKeyDown={(e: any) => {
                                if (e.key === 'Enter')
                                    initTempRoom({ timeTypeId: seleteTimeTypeId, queryString: queryData, floorId: seleteFloorId, roomStatus: seleteRoomStatus, roomTypeId: seleteRoomTypeId, isHandleRoomOrder: true, isEquality: true })
                            }}
                            onChange={(e: any) => {

                                setQueryData(e.target.value)
                            }
                            }
                        />
                    </Space>
                    <span> - </span>
                    <label onClick={() => handleTimeTypeList()} style={{ fontSize: 20 }}>当前市别 :<Tooltip title="点击可修改市别">
                        {seleteBookingDate ? seleteBookingDate + ' ' + seleteTimeTypeName : "请选择"}
                    </Tooltip> </label>
                </div>
                <div onClick={openMenuTester} style={{ cursor: "pointer" }}>
                    一键试机
                </div>
            </div>

            {/* <div style={{ maxHeight: '700px', overflowY: 'auto', overflowX: "hidden", marginTop: "20px" }}> */}
            <TempRoomList props={{
                listObj: {
                    itemLayout: "horizontal",
                    grid: { gutter: 15, column: 7 },
                    dataSource: tempRoomData,
                    renderItem: (item: any) => {

                        let cateringOrders = item.room.cateringOrders;

                        let length = cateringOrders.length;
                        let shouldMoney = 0;
                        let caOrderStatus = 999;
                        let diningMethodName;
                        let diningMethodId: any;
                        if (length === 1) {
                            let data = cateringOrders[0];
                            caOrderStatus = data.caOrderStatus;
                            shouldMoney = data.shouldMoney;
                            diningMethodId = data.diningMethodId;
                            if (diningMethodData) {
                                diningMethodData.forEach((item: any) => {

                                    if (item.id === diningMethodId) diningMethodName = item.diningName
                                })
                            }
                        }
                        return (
                            <List.Item onClick={() => handleRoomOrder(item)}>
                                <Card
                                    style={{ backgroundColor: item.color }}
                                    title={item.room.roomNum}
                                    actions={item.roomStatus === 0 && item.room.cateringOrders.length === 0 ? [
                                        <div>开台</div>
                                    ] : [<div>查看订单</div>]}
                                >
                                    <div style={{ height: "20px" }}>
                                        {length === 1 && caOrderStatus === 998 ?
                                            <div style={{ display: "flex", justifyContent: "space-between", fontSize: "15px", }}>
                                                <div>预价：{shouldMoney}</div>
                                            </div> : null
                                        }
                                        {length === 1 ?
                                            <div style={{ display: "flex", justifyContent: "space-between", fontSize: "15px", }}>
                                                <div>{diningMethodName}</div>
                                            </div> : null
                                        }
                                    </div>
                                </Card>
                            </List.Item>
                        )
                    }

                }
            }}>

            </TempRoomList>



            {/**市别详情 */}
            {
                timeTypeListOpen ? (<TimeTypeList data={timeTypeListata} closeModal={onCloseTimeTypeList}
                />)
                    : null
            }

            {/**收银订单详情 */}
            {
                castOrderDraweOpen ? (<CastOrderDrawe data={castOrderDraweData} closeModal={onCloseCastOrderDraw}
                />)
                    : null
            }

            {/**选择订单页面 */}
            {orderListOpen ? (<OrderList data={orderListData} closeModal={onCloseOrderList}
            />)
                : null}

            {/**打开试机页面 */}
            {menuTesterOpen ? <MenuTester data={menuTesterData} closeModal={onCloseMenuTester}></MenuTester> : null}

        </>

    )

}
