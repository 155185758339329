import { RouteObject } from 'react-router-dom';
import { getPageUrl } from './index';
import AssetStoragePlace from '../components/internal/asset/AssetStoragePlace';
import PurchaseMethod from '../components/internal/asset/PurchaseMethod';
import ExitMethod from '../components/internal/asset/ExitMethod';
import AssetClass from '../components/internal/asset/AssetClass';
import AssetSubcategory from '../components/internal/asset/AssetSubcategory';
import AssetStatement from '../components/internal/asset/AssetStatement';
import AssetStatus from '../components/internal/asset/AssetStatus';

/**
 * 获取路由地址
 * @param licenseList 
 * @returns 
 */
const routes_asset = async (licenseList: Array<any>) => {
    const res: RouteObject[] = [
        {
            path: await getPageUrl(licenseList, 'assetStoragePlace'),
            element: <AssetStoragePlace></AssetStoragePlace>
        },
        {
            path: await getPageUrl(licenseList, 'purchaseMethod'),
            element: <PurchaseMethod></PurchaseMethod>
        },
        {
            path: await getPageUrl(licenseList, 'exitMethod'),
            element: <ExitMethod></ExitMethod>
        },
        {
            path: await getPageUrl(licenseList, 'assetClass'),
            element: <AssetClass></AssetClass>
        },
        {
            path: await getPageUrl(licenseList, 'assetSubcategory'),
            element: <AssetSubcategory></AssetSubcategory>
        },
        {
            path: await getPageUrl(licenseList, 'assetStatement'),
            element: <AssetStatement></AssetStatement>
        },
        {
            path: await getPageUrl(licenseList, 'assetStatus'),
            element: <AssetStatus></AssetStatus>
        },


    ]
    return res;
};

export default routes_asset;