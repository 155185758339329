import React, { useEffect, useRef, useState } from 'react'
import WebEditUniversal, { WEUExtendPropsType } from '../../../system/WebEditUniversalNewEx'
import AntDraggableModal from '../../../../utils/extend/AntDraggableModal';
import { Form, Input, InputNumber, Select, Switch } from 'antd';
import fetchApi from '../../../../utils/api/fetchApi';
import getList from '../../../universal/getList';
import systemConfig from '../../../../config/systematic';

export default function ImCondimentInventoryEdit(props: any) {
    const ref = useRef<any>();
    const [form] = Form.useForm(); //form 
    const { data } = props;

 /**加料数据 */
 const [condimentWarehouseData, setCondimentWarehouseData] = useState<any>();


    /**定时模式数据 */
    const [timingMode, setTimingMode] = useState<any>();

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };


    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
          
            fetchApi.GET(wmService, {
                apiId: 'condimentWarehouse',
                apiExtend: 'showList',
                apiData:{
                    mountDataOptions:{
                        userMount:{
                           isDept:true,
                        }
                   },
                }
            }).then(res => {
                  
                if (res.success) {
                    setCondimentWarehouseData(res.data);
                }
            });
      


            fetchApi.GET(wmService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: 'timingMode'
            }).then(res => {

                if (res.success) {
                    setTimingMode(res.data);
                }
            }).catch((err) => {
                console.log("err", err);

            })
        }
        fetchData()
    }, [])
    // const changeMenu = (vaule: any) => {

    //     fetchApi.GET(wmService, {
    //         apiId: "menu",
    //         apiExtend: "getHeadToMenu",
    //         apiData: {
    //             menuHeadId: vaule
    //         }
    //     }).then((res: any) => {
    //         setMenuData(res.data)
    //         form.resetFields(["menuId"])

    //     }).catch((e) => {
    //         console.log(e);

    //     })
    // }
    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'imCondimentInventory',
    };
    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '加料库存'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label="加料"
                        name="condimentWarehouseId"
                        rules={[{ required: true, message: '请选择加料' }]}
                    >
                        <Select
                            showSearch
                            placeholder="加料"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(condimentWarehouseData,"condimentWarehouseName")
                            }
                        </Select>
                    </Form.Item> 
                
                    <Form.Item
                        name="quantity"
                        label="库存"
                        rules={[{ required: true, message: '请输入库存' }]}
                    >
                        <InputNumber />
                    </Form.Item>

                    <Form.Item
                        label="定时模式"
                        name="timingModeId"
                        rules={[{ required: false, message: '请选择定时模式' }]}
                    >
                        <Select
                            showSearch
                            placeholder="定时模式"
                            optionFilterProp="children"
                            filterOption={(input, option: any) => {
                                return true
                            }
                            }>
                            {getList.general(timingMode, "name")}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        <Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                </Form>
            </AntDraggableModal>
        </>
    )
}

/**自定义显示数据 */
export let customizeData = (item: any) => {
    
    
    return <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div >{item.foodName}</div>
        <div style={{ color: "yellow" }}>{item.branchStoreMenuWarehouseSpecName}</div>
    </div>
}