import React, { useEffect, useRef, useState } from 'react'
import { columnsheadId } from '../../universal/columnshead';
import  {  WUExtendPropsType } from '../../system/WebUniversalNewExEx';
import systemConfig from '../../../config/systematic';

import dayjs from 'dayjs';
import WebUniversalNewEx from '../../system/WebUniversalNewEx';
import CostPurchaseOrderEdit from './CostPurchaseOrderEdit';
import { DatePicker, Tooltip } from 'antd';
import setOrderDate from '../../../generalMethod/setOrderDate';
export default function CostPurchaseOrder(props: any) {
    /** api服务id */
    const apiId = 'costPurchaseOrder';
    const ref = useRef<any>();
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

 
   

   

    // const [index, setIndex] = useState<number>(0)
    //表头
    const columnsHead = [
        {
            title: "部门",
            dataIndex: "dept",
            render: (text: any) => (text ? text.deptName : ""),
            align: "center"
        },
        {
            title: '采购商',
            dataIndex: 'buyer',
            render: (text: any) => (text ? text.buyerName : ""),
            align: "center"

        },
        {
            title: "购买日期",
            dataIndex: "time",
            align: "center",

            render: (val: any, record: any) =>
                <span>
                    {dayjs(val).format('YYYY-MM-DD')}
                </span>,

        },
     

      
      
      
    ];

 

    /** 子表头内容 */
    const expandableColumnsHead: Array<any> = [
        {
            title: "品号",
            dataIndex: "product",
            render: (text: any) => (text ? text.selfId ? text.selfId : "" : "")
        },
        {
            title: "品名",
            dataIndex: "product",
            render: (text: any) => (text ? `${text.productName}${text.area ? `(${text.area?.areaName})` : ""}` : "")
        },
        {
            title: "单位",
            dataIndex: "product",
            render: (text: any) => (text ? text.productUnit.productUnitName : "")
        },
        {
            title: "规格",
            dataIndex: "productSpecifications"
        },
        {
            title: "数量",
            dataIndex: "bNum",
            render: (text: any) => (text ||text === Number(0)? text.toFixed(2) : undefined)
        },
        {
            title: "单价",
            dataIndex: "univalence",
            render: (text: any) => (text ||text === Number(0)? text.toFixed(2) : undefined)
        },
        {
            title: "总价格",
            dataIndex: "totalPrice",
            render: (text: any) => (text || text === Number(0)? Number(text).toFixed(2) : undefined)
        },
        {
            title: "备注",
            dataIndex: "memo"
        },
        // {
        //     title: "操作",
        //     render: (_text: any, record: any) => {
        //         // console.log("record", record);
        //         return <>
        //             {record.id === "合计:" ? null : <Button size="small" onClick={() => onOpenProduct(record, "purchaseProduct", "收货商品", "EDIT")}>编辑</Button>}

        //         </>
        //     }
        // },
    ]
    /** 子表头 */
    const expandableColumns = expandableColumnsHead;
    const columns = columnsheadId
        .concat(columnsHead as []);
    useEffect(() => {



        if (ref.current) {
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {


        }
        fetchData()

    
    }, [])





    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '订单',
        apiId: 'costPurchaseOrder',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: CostPurchaseOrderEdit,

        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'costPurchaseProducts',
    };

  

   
    return (
        <>
           <label>订单日期 :  </label>
            <Tooltip placement="topLeft" title={"当前仅修改新建订单时间使用"}>
                <DatePicker defaultValue={dayjs(sessionStorage.getItem("date"))} onChange={setOrderDate.date} />
            </Tooltip>
            <span>   </span>
            <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>

          

        </>

    )
}
