import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Space, Table } from 'antd';
import dayjs from 'dayjs';

import React, { useEffect, useState } from 'react'
import systemConfig from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification'
import 'dayjs/locale/zh-cn';
import locale from 'antd/es/date-picker/locale/zh_CN';
import DownloadExcel from '../../report/DownloadExcel';

export default function ProcessingRequisition(props: any) {

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [form] = Form.useForm()
    const [branchStoreData, setBranchStoreData] = useState<any>();



    const [queryCriteria, setQueryCriteria] = useState<any>()

    // 
    const [rows, setRows] = useState<any>([]);

    const initialValues: any = {
        chooseTime: [dayjs()],
        isBuyerDetail: true
    };

    const [columnsHead, setColumnsHead] = useState<Array<any>>([
        {
            title: '产品',
            dataIndex: 'productName',
            key:"productName",
            header:"产品",
            width:15

        },
        {
            title: '单位',
            dataIndex: 'productUnitName',
            key:"productUnitName",
            header:"单位",
            width:10

            

        },
        {
            title: '购买数',
            dataIndex: 'purchasesCounts',
            key:"purchasesCounts",
            header:"购买数",
            width:10


        },
        {
            title: '单价',
            dataIndex: 'unitPrice',
            key:"unitPrice",
            header:"单价",
            width:10

        },
        {
            title: '收货',
            dataIndex: 'receive',
            key:"receive",
            header:"收货",
            width:10
        },

    ])

    useEffect(() => {

        const fetchData = async () => {
            form.setFieldValue("chooseTime", dayjs())
            let branchStoreData = await fetchApi.GET(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList',
                apiData: {

                }
            })
            if (branchStoreData.success) {
                setBranchStoreData(branchStoreData.data)
            }
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    const onFinish = () => {
        form.validateFields()
            .then(async (values: any) => {
                fetchApi.GET(wmService, {
                    apiId: "processingOrder",
                    apiExtend: 'processingRequisition',
                    apiData: {
                        branchStoreIds: values.branchStoreIds,
                        // deptIds: handleData(values.deptIds, "deptId"),
                        // orderTypes: handleData(values.orderTypes, "orderType"),
                        time: dayjs(values.chooseTime).format("YYYY-MM-DD")
                    }
                }).then((res: any) => {  
                    let queryCriteria=res.data.queryCriteria;
                    setRows(res.data.rows)
                    setQueryCriteria({...queryCriteria,columnsHead})
                    // setUser(res.data.user)
                    antMessage('success', '搜索成功', 'success');

                }).catch((err: any) => {
                    console.log("err", err);

                    antMessage('error', '搜索失败', err.message + ' | status：' + err.errors.status);
                })
            })
            .catch((err) => {
                antMessage('warning', '无法通过数据校验', '请输入所需数据！');
            })
    }

    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
                initialValues={initialValues}
            >
                <Row>
                    <Col span={16}>
                        <Form.Item
                            label="分店"
                            name="branchStoreIds"
                        >
                            <Select

                                // orderTypeData
                                mode="multiple"
                                showSearch
                                placeholder="请选择分店!"
                                optionFilterProp="children"

                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(branchStoreData, 'branchStoreName')}
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col span={6}>
                        <Form.Item
                            label="日期"
                            name="chooseTime"

                        >
                            <DatePicker
                                locale={locale}
                                defaultValue={initialValues.chooseTime}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </Col>



                    <Col span={4}>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    统计
                                </Button >
                                 <Button onClick={() => DownloadExcel(rows, "processingRequisition",queryCriteria)} >
                                  下载EXCEL文件
                              </Button>

                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>



            {/* <Button onClick={() => DownloadExcel(rows, "moonReport",queryCriteria)}>下载EXCEL文件</Button> */}
            <div style={{ marginTop: "20px" }}>
                <Table
                    pagination={false}
                    rowKey={(record: any, index: any) => {
                        return index
                    }}
                    columns={columnsHead}
                    dataSource={rows}
                // summary={(pageData: any) => {
                //   let totalExport = 0;
                //   let totalExportCount = 0;

                //   let totalImport = 0;
                //   let totalImportCount = 0;

                //   let totalPurchase = 0;
                //   let totalPurchaseCount = 0;

                //   if (pageData.length > 0) {
                //     pageData.map((item: any) => {
                //       if (item.export) totalExport += item.export
                //       if (item.import) totalImport += item.import;
                //       if (item.purchase) totalPurchase += item.purchase;

                //       if (item.exportCount) totalExportCount += item.exportCount;
                //       if (item.importCount) totalImportCount += item.importCount;
                //       if (item.purchaseCount) totalPurchaseCount += item.purchaseCount;

                //     })
                //   }
                //   return (
                //     <Table.Summary  fixed>
                //       <Table.Summary.Row style={{"textAlign":"center"}}>
                //         <Table.Summary.Cell  index={0}>合计</Table.Summary.Cell>
                //         <Table.Summary.Cell  index={1}>{totalPurchase.toFixed(2)}</Table.Summary.Cell>
                //         <Table.Summary.Cell index={2}>{totalPurchaseCount}</Table.Summary.Cell>

                //         <Table.Summary.Cell index={3}>{totalImport.toFixed(2)}</Table.Summary.Cell>
                //         <Table.Summary.Cell index={4}>{totalImportCount}</Table.Summary.Cell>



                //         <Table.Summary.Cell index={5}>{totalExport.toFixed(2)}</Table.Summary.Cell>
                //         <Table.Summary.Cell index={6}>{totalExportCount}</Table.Summary.Cell>

                //       </Table.Summary.Row>
                //     </Table.Summary>
                //   )
                // }}
                >
                </Table>
            </div>
        </>
    )
}
// export let handleData = (rows: any, objKey: string) => {
//     let newArr: Array<any> = []

//     if (typeof rows === "undefined") return newArr

//     for (var i = 0; i < rows.length; i++) {
//         newArr.push({
//             [objKey]: rows[i]
//         })
//     }

//     return newArr
// }