import { useContext, useEffect, useState } from 'react';
import { Layout, Breadcrumb, Col, Row, Space, Avatar, Popconfirm, MenuProps } from 'antd';
import SiderMenu from './components/system/SiderMenu';
import {
    useNavigate,
    Outlet
} from "react-router-dom";
import { getQueryStringArgs } from './components/routerMag/RoutingTransit';
import moment from 'moment';
import fetchApi from './utils/api/fetchApi';
import systemConfig from './config/systematic';
import { getPageUrl } from './routers';
import systematic from './config/systematic';
import { wsConnect, wsDisconnect } from './App';
import { SocketContext } from './App';

const { Header, Content, Footer, Sider } = Layout;
/** 后端数据defaultService */
const defaultService = systemConfig.wmService;


const AppBm = (props: any) => {
    const [collapsed, setCollapsed] = useState(false);
    let [args, setArgs] = useState<any>(getQueryStringArgs())
    const navigate = useNavigate()
    const useContextData = useContext(SocketContext);

    const { wsServer, contextData } = useContextData;
        
    const exit = () => {
        wsDisconnect();
        sessionStorage.clear();
        localStorage.removeItem("token")
        navigate('/login')
    }
    useEffect(() => {
        let date = sessionStorage.getItem("date") as any;
        if (!date) {
            sessionStorage.setItem("date", moment().format("YYYY-MM-DD"))
        }
        setArgs(getQueryStringArgs())

        const fetchData = async () => {
            if (args.url) {
                await routingHop(args)
            }
        }
        // if(wsServer.connected){
        //     wsServer.addListener(["wm"], { wm: wsListener })
        // }
        fetchData()

    }, [])
  
    const routingHop = async (props: any) => {

        if (typeof props === "undefined") return true

        //url:跳转路由地址带/
        //data:额外参数 例如 data:{state:{xxxxxxx}}
        let { token, url, data, nameSpace, socketId } = props;
      

        
        if (!wsServer.connected) {
            wsConnect('tokenLogin', {
                token
            })
        }
        //字符串分割
        const urlId = url.split("/")[1]
        /** 根据路由id列表获取路由地址列表 */
        let licenseList: any;
        const routerUrlList = await fetchApi.GET(systematic.wmService, {
            apiId: 'license',
            apiExtend: 'routeList'
        })
        if (routerUrlList.success === true) {

            licenseList = routerUrlList.data;
        }
        let state;
        let subsystemId;
        if (data) {
            state = data.state;
            subsystemId = state.subsystemId
        }
        try {
            let configNodes = await fetchApi.GET(defaultService,
                {
                    apiId: 'configNodes',
                    apiExtend: 'getConfig',
                    apiData: {
                        id: 'config',
                        nodeId: "users",
                        isUser: true

                    }
                }, {}, token)
            if (configNodes.success) {

                sessionStorage.setItem("userName", configNodes.data.userName)
                sessionStorage.setItem("entrepotName", configNodes.data.entrepotName)
            }
        } catch (e) {
            console.log(e);

        }

        localStorage.setItem('nameSpace', nameSpace);
        localStorage.setItem('subsystemId', subsystemId);
        localStorage.setItem('num', "0");
        sessionStorage.setItem('token', token);
        localStorage.setItem('wdmSocketId', socketId);
        navigate(await getPageUrl(licenseList, urlId), data)

    }
    return (
        <Layout style={{ minHeight: '100vh' }}>

            <Sider collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
                <div className="logo" style={{ textAlign: 'center', lineHeight: "32px" }}> 菜单</div>
                <SiderMenu props={args} />
            </Sider>
            <Layout className="site-layout" style={{ color: "#fff" }}   >
                <Header className="site-layout-background" style={{ padding: 0, height: '8vh', verticalAlign: 'middle' }}>
                    <Row>
                        <Col span={6} >
                            <Breadcrumb>

                            </Breadcrumb>
                        </Col>
                        <Col span={18} >
                            <div className="header-right">
                                <Space>
                                    {/* <span>当前分店:{localStorage.getItem("currentBranchStoreName")} </span> */}
                                    <span>当前仓库:{sessionStorage.getItem("entrepotName")} </span>
                                    <span>当前用户:{sessionStorage.getItem("userName")} </span>
                                    <span>当前分店:{contextData?.currentBranchStoreName ? `${contextData?.currentBrandName?contextData?.currentBrandName:""}${contextData?.currentBranchStoreName}` : ""} </span>


                                    {/* <Avatar size={60} style={{ color: '#f56a00', backgroundColor: '#fde3cf' }}></Avatar> */}

                                    <Popconfirm title="确定要退出登录吗？" onConfirm={exit} okText="确定" cancelText="取消">
                                        <div className='exit' style={{ color: "#1d5edc" }} >登出</div>
                                    </Popconfirm>
                                </Space>
                            </div>
                        </Col>
                    </Row>
                </Header>
                <Content style={{ margin: '0 16px' }}>
                    <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                        <Outlet />
                    </div>
                </Content>
                <Footer style={{ textAlign: 'center' }}>©2024 CopyRight BrandName</Footer>
            </Layout>
        </Layout>
    )
};

export default AppBm;