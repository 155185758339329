import { Button, Tooltip } from 'antd';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import MenuLabelEdit from './MenuLabelEdit';

const MenuLabel = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '标签名称',
            dataIndex: 'menuLabelName',
        },
        {
            title: '字体颜色',
            dataIndex: 'fontColor',
            render: (text: any, record: any) => {
                return (
                    <Tooltip placement="topLeft" title={record.fontColor}>
                        <div><Button style={{ backgroundColor: record.fontColor }}></Button></div>
                    </Tooltip>

                )
            }
        },
        {
            title: '背景颜色',
            dataIndex: 'backgroundColor',
            render: (text: any, record: any) => {
                return (
                    <Tooltip placement="topLeft" title={record.backgroundColor}>
                        <div><Button style={{ backgroundColor: record.backgroundColor }}></Button></div>
                    </Tooltip>
                )
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式标签',
        apiId: 'menuLabel',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: MenuLabelEdit,
        sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}

export default MenuLabel