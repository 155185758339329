import React, { useEffect, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import { Form, Select } from 'antd'
import systemConfig from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification'


/**整单折扣 */
export default function SingleDiscount(props: any) {
    const { data, closeModal } = props;
    
    const [form] = Form.useForm();
    const [disCount, setDisCount] = useState<any>();
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    useEffect(() => {
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: 'disCount',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setDisCount(res.data);
                }
            });
        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const handleOk = (e: any) => {
        form.validateFields() //数据校验
            .then(async (values: any) => {
                fetchApi.POST(wmService, {
                    apiId: "cateringOrder",
                    apiExtend: "singleDiscount",
                    apiData: {
                        id:data.id,
                        isForce:data.isForce,
                        ...values
                    }
                }).then((res) => {
                     
                     // setLoading(false)
                     antMessage('success', '打折成功', 'success');
                     closeModal(false);
        
                }).catch((err) => {
                    closeModal(false);
                    antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
                })
            })
            .catch((err: any) => {
                antMessage('warning', '无法通过数据校验', err);
            });


    }
    return (
        <AntDraggableModal
            title={'折扣选择'}
            open={true}
            okText='确定'
            cancelText='取消'
            onCancel={() => closeModal(false)}
            closable={false}
            maskClosable={false}
            onOk={handleOk}
        >
            <Form
                form={form}
                name="Edit"
                onFinish={handleOk}
                onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                        handleOk(e)
                    }
                }}
            >
                <Form.Item
                    label="折扣"
                    name="disCountId"
                    rules={[{ required: true, message: '请填写折扣' }]}
                    >
                    <Select
                        placeholder="折扣选择"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {getList.general(disCount, 'disCountName',"id",false,false)}
                    </Select>
                </Form.Item>
            </Form>

        </AntDraggableModal>
    )
}
