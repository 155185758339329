import React, { useRef } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import DiningMethodEdit from './DiningMethodEdit';

export default function BranchStoreFloor(props: any) {
    const { branchStoreId, closeModal } = props;
    const ref = useRef<any>();
    const handleOk = (e: any) => {
        closeModal(true);
    };
    //表头
    const columnsHead = [

        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any) => (text ? text.branchStoreName : "")
        },
        {
            title: '就餐方式',
            dataIndex: 'dining',
            render: (text: any) => (text ? text.diningName : "")
        },
        {
            title: '房台',
            dataIndex: 'room',
            render: (text: any) => (text ? text.roomName : "")
        },
        {
            title: '付款模式',
            dataIndex: 'orderPaymentModeName',
        },
        {
            title: '打印订单类型',
            dataIndex: 'printOrderType_name',
        },
        // printOrderType
        {
            title: '排序',
            dataIndex: 'sortBy',
        },


        // orderPaymentModeName

    ];
    const columns = columnsheadId
        .concat(columnsHead as []);
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '分店就餐方式',
        apiId: 'diningMethod',
        showStates: true,
        sendPageData: { branchStoreId },
        columns,
        showDefault: true,
        extendIdName: "branchStoreId",
        pagination: true,
        EditComponent: DiningMethodEdit,
    };
    return (
        <div>
            <AntDraggableModal
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={800}>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </AntDraggableModal>
        </div>
    )
}
