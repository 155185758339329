import { useEffect, useRef, useState } from 'react';
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { Button, Popconfirm, Select, Space, Tooltip } from 'antd';
import BranchStoreMenuWarehouseEdit from '../menu/BranchStoreMenuWarehouseEdit';
import Synchronize from './Synchronize';
import WarehouseToMenu from '../menu/WarehouseToMenu';
import BranchStoreMenuWarehouseSpec from '../menu/BranchStoreMenuWarehouseSpec';
import CopyMenuWarehouse from './CopyMenuWarehouse';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import BranchStoreList from '../../generalComponents/BranchStoreList';
import getList from '../../universal/getList';
// import fetchApi from '../../../utils/api/fetchApi';
// import systemConfig from '../../../config/systematic';

//组件 BranchStoreMenuWarehouse 
const BranchStoreMenuWarehouse = (props: any) => {

    const ref = useRef<any>();

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    //同步窗口
    const [synchronizeVisible, setSynchronizeVisible] = useState(false);

    //同步数据
    const [synchronizeData, setSynchronizeData] = useState<any>();

    //转移菜式
    const [warehouseToMenuVisible, setWarehouseToMenuVisible] = useState(false);

    //选择转移菜式
    const [branchStoreMenuWarehouseData, setBranchStoreMenuWarehouseData] = useState<any>();

    //规格数据
    const [branchStoreMenuWarehouseSpecData, setBranchStoreMenuWarehouseSpecData] = useState<any>();

    //规格窗口
    const [branchStoreMenuWarehouseSpecVisible, setBranchStoreMenuWarehouseSpecVisible] = useState(false);

    //复制菜式窗口
    const [copyMenuWarehouseVisible, setCopyMenuWarehouseVisible] = useState(false);

    //复制菜式数据
    const [copyMenuWarehouseData, setCopyMenuWarehouseData] = useState<any>();

    /** 菜品类型数据 */
    const [foodTypeData, setFoodTypeData] = useState<any>();

    const [foodTypeIdData, setFoodTypeIdData] = useState<any>();

    /** 部门数据 */
    const [deptData, setDeptData] = useState<any>();

    const [deptIdData, setDeptIdData] = useState<any>();

    /** api服务id */
    const apiId = 'branchStoreMenuWarehouse';

    // /** 后端数据wmService */
    // let wmService: any = systemConfig.wmService;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // wmService = ref.current.wmService;
        }

        //同步获取数据 async - await
        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'foodType',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", name: "所有分类" }, ...res.data];
                    setFoodTypeData(newData);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'dept',
                apiExtend: 'showBranchDeptList',
                apiData: {
                    branchStoreId: localStorage.getItem("currentBranchStoreId")
                }
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", deptName: "所有部门" }, ...res.data];
                    setDeptData(newData);
                }
            });
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据


    //打开添加规格
    const handleBranchStoreMenuWarehouseSpec = (record: any) => {
        //console.log(record)

        sendData.id = record.id;
        sendData.foodName = record.foodName;
        sendData.branchStoreMenuWarehouseId = record.id;
        sendData.menuWarehouseId = record.menuWarehouseId;
        sendData.foodType = record.foodType;
        setBranchStoreMenuWarehouseSpecData(sendData);
        setBranchStoreMenuWarehouseSpecVisible(true);
    };

    // 打开同步/关联
    const handleSynchronize = (record: any, key: any) => {
        // console.log(record);

        sendData.branchStoreMenuWarehouseId = record.id;
        sendData.foodName = record.foodName;
        sendData.foodNameEn = record.foodNameEn;
        sendData.description = record.description;
        sendData.introduction = record.introduction;
        sendData.branchStoreMenuWarehouseSpecRow = record.branchStoreMenuWarehouseSpecs;
        sendData.branchStoreId = record.branchStoreId;
        sendData.brandId = record.branchStore.brandId;
        sendData.menuWarehouseId = record.menuWarehouseId;
        sendData.key = key;
        sendData.path = 'BranchStoreMenuWarehouse';
        setSynchronizeData(sendData);
        setSynchronizeVisible(true);
    };

    //关闭同步页面
    const onCloseSynchronize = async (status: boolean) => {
        setSynchronizeVisible(false);
        if (status) {
            await initData();
        }
    }

    //关闭门店菜式规格页面
    const onCloseBranchStoreMenuWarehouseSpec = async (status: boolean) => {
        setBranchStoreMenuWarehouseSpecVisible(false);
        if (status) {
            await initData();
        }
    }

    //关闭穿梭框页面
    const onCloseWarehouseToMenu = async (status: boolean) => {
        setWarehouseToMenuVisible(false);
        if (status) {
            await initData();
        }
    }

    /**
     * 复制菜式
     */
    const handleCopy = () => {
        sendData.path = 'BranchStoreMenuWarehouse';
        // sendData.branchStoreId = record.branchStoreId;
        setCopyMenuWarehouseData(sendData);
        setCopyMenuWarehouseVisible(true);
    };

    /**
     * 更新所有菜式的英文
     */
    // const updateAllEn = (record: any) => {
    //     // console.log(record);
    //     if (record.menuWarehouseId) {
    //         fetchApi.POST(wmService, {
    //             apiId: "branchStoreMenuWarehouse",
    //             apiExtend: 'updateMenuWarehouseEn',
    //             apiData: {
    //                 branchStoreMenuWarehouseId: record.id
    //             }
    //         }).then(res => {
    //             if (res.success) {
    //                 // console.log(res.data);
    //                 console.log("门店菜式：【 " + record.foodName + "】  英文更新为【 " + res.data.foodNameEn + " 】");
    //                 if (res.data.menus.length > 0) {
    //                     for (var menus = 0; menus < res.data.menus.length; menus++) {
    //                         console.log("---------【" + res.data.menus[menus].menuHead.menuHeadName + "】菜式：【 " + res.data.menus[menus].foodName + "】  英文更新为【 " + res.data.foodNameEn + " 】");
    //                     }
    //                 }
    //                 console.log("")
    //                 console.log("")
    //             }
    //         });
    //     }
    // };

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                // updateAllEn(record);
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) => {
                if (sessionStorage.getItem('isAdmin') === 'true') {
                    return (
                        <Tooltip placement="topLeft" title={record.menuWarehouse ? "【" + record.menuWarehouse.brandId + "】" + record.menuWarehouse.foodName : '暂时未关联仓库菜式'} color='green'>
                            <div>{text}</div>
                        </Tooltip>
                    )
                }
                else {
                    return (
                        <div>{text}</div>
                    )
                }
            }
        },
        {
            title: '类别',
            dataIndex: 'shopTypeName',
            render: (text: any, record: any) => {
                if (record.shopType) {
                    return (
                        <div>{record.shopType.shopTypeName}</div>
                    )
                }
            }
        },
        {
            title: '简码',
            dataIndex: 'selfId',
            sorter: true,
        },
        {
            title: '类型',
            dataIndex: 'foodTypeName',
        },
        {
            title: '出单部门',
            dataIndex: 'deptName',
            render: (text: any, record: any) => {
                if (record.dept) {
                    return (
                        <Tooltip placement="topLeft" title={record.isPrint ? '设置为【打印】 ' : '设置为【不打印】 '} color='red'>
                            <div>{record.dept.deptName + (record.specialDept ? " ----- " + record.specialDept.deptName + " (特殊)" : "")}</div>
                        </Tooltip>
                    )
                }
                else {
                    return (
                        <p>
                            <div style={{ color: 'red', fontSize: '15px' }}>设置出单部门后才能进行后续操作</div>
                        </p>
                    )
                }
            }
        },
        {
            title: '单位',
            dataIndex: 'productUnitName',
            render: (text: any, record: any) => {
                if (record.productUnit) {
                    return (
                        <div>{record.productUnit.productUnitName}</div>
                    )
                }
            }
        },
        {
            title: '价钱',
            dataIndex: 'price',
            render: (text: any, record: any) => {
                if (record.branchStoreMenuWarehouseSpecs.length > 0) {
                    return (
                        <div style={{ fontSize: 20, color: 'yellow' }}>
                            {record.branchStoreMenuWarehouseSpecs.length === 1 ? record.branchStoreMenuWarehouseSpecs[0].price : record.branchStoreMenuWarehouseSpecs[0].price + " - " + record.branchStoreMenuWarehouseSpecs[record.branchStoreMenuWarehouseSpecs.length - 1].price}
                        </div>
                    )
                }
            }
        },
        {
            title: '规格',
            dataIndex: '',
            key: 'branchStoreMenuWarehouseSpec',
            render: (text: any, record: any) => {
                if (record.dept) {
                    if (record.branchStoreMenuWarehouseSpecs.length > 0) {
                        return (
                            <Space size="small">
                                <Button size={"small"} type="primary" onClick={() => handleBranchStoreMenuWarehouseSpec(record)}>规格</Button>
                            </Space>
                        )
                    }
                    else {
                        return (
                            <Space size="small">
                                <Button size={"small"} type="primary" danger onClick={() => handleBranchStoreMenuWarehouseSpec(record)}>规格</Button>
                            </Space>
                        )
                    }
                }
            }
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => {
                // console.log(record);
                if (record.dept) {
                    if (record.menuWarehouseId) {
                        if (record.branchStoreMenuWarehouseSpecs.length > 0) {

                            if (sessionStorage.getItem('isAdmin') === 'true') {
                                return (
                                    <Space size="small">
                                        <Button size={"small"} type="primary" onClick={() => handleSynchronize(record, 'ToMenuWarehouse')}>同步到...</Button>
                                        <span>&nbsp;&nbsp;</span>
                                        <Button size={"small"} type="primary" onClick={() => cancelConnect(record)}>取消关联仓库</Button>
                                    </Space>
                                )
                            }
                            else {
                                return (
                                    <Space size="small">
                                        <Button size={"small"} type="primary" onClick={() => handleSynchronize(record, 'ToMenuWarehouse')}>同步到...</Button>
                                        <span>&nbsp;&nbsp;</span>
                                        {/* <Button size={"small"} type="primary" onClick={() => cancelConnect(record)}>取消关联仓库</Button> */}
                                    </Space>
                                )
                            }
                        }
                    }
                    else {
                        if (record.branchStoreMenuWarehouseSpecs.length > 0) {
                            if (sessionStorage.getItem('isAdmin') === 'true') {
                                return (
                                    <Space size="small">
                                        <Button size={"small"} type="primary" onClick={() => handleSynchronize(record, 'ToMenuWarehouse')}>同步到...</Button>
                                        <span>&nbsp;&nbsp;</span>
                                        <Button size={"small"} type="primary" onClick={() => handleSynchronize(record, 'FromMenuWarehouse')}>关联</Button>
                                    </Space>
                                )
                            }
                            else {
                                return (
                                    <Space size="small">
                                        <Button size={"small"} type="primary" onClick={() => handleSynchronize(record, 'ToMenuWarehouse')}>同步到...</Button>
                                    </Space>
                                )
                            }

                        }
                    }
                }
            }
        },
        // {
        //     title: '排序',
        //     dataIndex: 'sortBy',
        // },
    ];

    /** 子表头内容 */
    const expandableColumnsHead: Array<any> = [
        // {
        //     title: "id",
        //     dataIndex: "branchStoreMenuWarehouseSpecName",
        //     render: (text: any, record: any) => {
        //         console.log("record", record);
        //         return <>
        //             {record.id}
        //         </>
        //     }
        // },
        {
            title: "规格",
            dataIndex: "branchStoreMenuWarehouseSpecName",
            render: (text: any, record: any) => {
                // console.log("record", record);
                return <>
                    {record.branchStoreMenuWarehouseSpecName}
                </>
            }
        },
        {
            title: "基础价",
            dataIndex: "price",
            render: (text: any, record: any) => {
                // console.log("record", record);
                return <>
                    {record.price}
                </>
            }
        },
        // {
        //     title: "排序",
        //     dataIndex: "sortBy",
        //     render: (text: any, record: any) => {
        //         // console.log("record", record);
        //         return <>
        //             {record.sortBy}
        //         </>
        //     }
        // },
    ]

    /** 子表头 */
    const expandableColumns = expandableColumnsHead;
    const columns = columnsheadId
        .concat(columnsHead as []);

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
        branchStoreMenuWarehouseId: '',
        menuWarehouseId: '',
        branchStoreMenuWarehouseSpecRow: '',
        foodName: '',
        foodNameEn: '',
        description: '',
        introduction: '',
        branchStoreId: '',
        brandId: '',
        foodType: '',
        key: '',
        path: ''
    }

    // 打开从菜式仓选择到门店
    const handleSelectMenuWarehouse = () => {

        sendData.key = 'menuWarehouseToBranchStore';
        setBranchStoreMenuWarehouseData(sendData);
        setWarehouseToMenuVisible(true);
    };

    const initData = async () => {
        // const menuHeadId = data.menuHeadId;
        const currentData: initDataType = {
            apiId,
            // sendPageData: { menuHeadId: menuHeadId }
        }
        ref.current.initData(currentData);
    }

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '门店菜式仓库',
        apiId,
        columns,
        showStates: true,
        showEdit: true,
        pagination: true,
        isAutomaticRefresh: true,
        showDelete: true,
        showAdd: true,
        EditComponent: BranchStoreMenuWarehouseEdit,
        sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },

        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'branchStoreMenuWarehouseSpecs',
    };

    const setInventory = () => {
        fetchApi.POST(wmService, {
            apiId: "branchStoreMenuWarehouse",
            apiExtend: "setInventory",
            apiData: {

            }
        }).then(async (res) => {
            await initData()

        }).catch((err) => {

        })
    }

    //查看不同类型的菜式
    const foodTypeChange = (e: any) => {

        setFoodTypeIdData(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: { deptId: deptIdData, foodTypeId: e, branchStoreId: localStorage.getItem("currentBranchStoreId") }
        }
        ref.current.initData(currentData);
    }

    //查看不同部门的菜式
    const deptChange = (e: any) => {

        setDeptIdData(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: { deptId: e, foodTypeId: foodTypeIdData, branchStoreId: localStorage.getItem("currentBranchStoreId") }
        }
        ref.current.initData(currentData);
    }

    const onRefresh = (item: any) => {
        const { branchStoreId } = item
        const currentData: initDataType = {
            apiId,
            sendPageData: { deptId: deptIdData, foodTypeId: foodTypeIdData, branchStoreId }
        }
        ref.current.initData(currentData);
    }

    /**
     * 取消关联仓库
     */
    const cancelConnect = (record: any) => {
        fetchApi.GET(wmService, {
            apiId: "branchStoreMenuWarehouse",
            apiExtend: 'cancelConnect',
            apiVariable: record.id,
        }).then(async res => {
            if (res.success) {
                await initData();
            }
        });
    };

    return (
        <>
            <BranchStoreList onRefresh={onRefresh} setDeptData={setDeptData}></BranchStoreList>
            <br></br>
            <Space>

                <label>菜品类型 :  </label>
                <Select
                    style={{ width: 150 }}
                    showSearch
                    defaultValue="所有类型"
                    onChange={foodTypeChange}
                    placeholder="菜品类型"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {getList.generalEx(foodTypeData, {
                        includeNull: false
                    })}
                </Select>

                <label>部门 :  </label>
                <Select
                    style={{ width: 150 }}
                    showSearch
                    defaultValue="所有部门"
                    onChange={deptChange}
                    placeholder="部门"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {getList.general(deptData, "deptName")}
                </Select>
                {sessionStorage.getItem('isAdmin') === 'true'
                    ? (<Button type="primary" onClick={handleSelectMenuWarehouse}>选取仓库菜式</Button>)
                    : null}

                {sessionStorage.getItem('isAdmin') === 'true'
                    ? (<Button type="primary" onClick={handleCopy}>将门店所有菜式复制到其他门店</Button>)
                    : null}
                <Popconfirm title="确定要一键恢复(设置)库存吗?" onConfirm={setInventory}>
                    <Button type='primary' >一键恢复(设置)库存</Button>
                </Popconfirm>
            </Space>
            <br></br>
            <br></br>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>

            {synchronizeVisible
                ? (<Synchronize
                    closeModal={onCloseSynchronize} data={synchronizeData} />)
                : null}

            {branchStoreMenuWarehouseSpecVisible
                ? (<BranchStoreMenuWarehouseSpec
                    closeModal={onCloseBranchStoreMenuWarehouseSpec} data={branchStoreMenuWarehouseSpecData} />)
                : null}

            {warehouseToMenuVisible
                ? (<WarehouseToMenu
                    closeModal={onCloseWarehouseToMenu} data={branchStoreMenuWarehouseData} />)
                : null}

            {copyMenuWarehouseVisible
                ? (<CopyMenuWarehouse
                    closeModal={setCopyMenuWarehouseVisible} data={copyMenuWarehouseData} />)
                : null}
        </>
    )
}

export default BranchStoreMenuWarehouse;