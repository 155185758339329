import React from 'react'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import CookMethodEdit from './CookMethodEdit';

const CookMethod = (props: any) => {

    //表头
    const columnsHead = [        
        {
            title: '烹调方法',
            dataIndex: 'cookMethodName',
            sorter: true,
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '烹调方法',
        apiId: 'cookMethod',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: CookMethodEdit,
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}

export default CookMethod