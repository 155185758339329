import { Button, DatePicker, Form, Input, Select, Switch, Row, Col } from 'antd';
import { useEffect, useRef, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import system from "../../../config/systematic";
import dayjs from 'dayjs';
// import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
// import objectHandle from '../../utils/baseLib/objectHandle';
import WebFeastTeamFormList from '../../generalComponents/WebFeastTeamFormList';
import fetchApi from '../../../utils/api/fetchApi';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const { Option } = Select;

const FeastTeamMenuEdit = (props: any) => {
    //console.log(props)

    const [form] = Form.useForm(); //form 
    const { data, closeModal } = props
    const ref = useRef<any>();


    /** 上午是否需要分口味*/
    const [morningIsTogetherData, setMorningIsTogetherData] = useState<any>();

    /** 下午是否需要分口味*/
    const [afternoonIsTogetherData, setAfternoonIsTogetherData] = useState<any>();

    /** 上午原始口味数据*/
    const [morningFlavorData, setMorningFlavorData] = useState<any>([]);

    /** 下午原始口味数据*/
    const [afternoonFlavorData, setAfternoonFlavorData] = useState<any>([]);

    /** 早上的基础数据*/
    const [morningBaseData, setMorningBaseData] = useState<any>(data.type === "EDIT" ? true : false);

    /** 下午的基础数据*/
    const [afternoonBaseData, setAfternoonBaseData] = useState<any>(data.type === "EDIT" ? true : false);

    /** 上下午数据 */
    const [periodTimeData, setPeriodTimeData] = useState<any>();

    /** 显示上午口味长度 */
    const [morningFlavorTrueData, setMorningFlavorTrueData] = useState<any>();

    /** 显示下午口味长度 */
    const [afternoonFlavorTrueData, setAfternoonFlavorTrueData] = useState<any>();

    /** 记录操作转口味的key */
    const [changeData, setChangeData] = useState<any>();



    /** 日期的状态 */
    const [dateDisableData, setDateDisableData] = useState<any>(false);

    /**地区的状态 */
    // const [areaIdDisable, setAreaDisable] = useState<any>(false);

    /** 数据库中已有数据的日期数据 */
    // const [dateData, setDateData] = useState<any>([]);

    /**地区数据 */
    // const [areaData, setAreaData] = useState<any>();



    /**合并数据 */
    const [flavorDetailData, setFlavorDetailData] = useState<any>();

    const [tempDeleteData, setTempDeleteData] = useState<any>();

    const [formList, setFormList] = useState<any>({ form, type: data.type, ref, setFlavorDetailData, setTempDeleteData })


    const handleOk = async (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据Service */
    let wmService = system.wmService;

    useEffect(() => {
        if (ref.current) {
            // form.setFieldsValue({ morning: setFiledData(morningBaseData) });
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    //console.log(res.data);
                    setMorningIsTogetherData(res.data.morningIsTogether);
                    setAfternoonIsTogetherData(res.data.afternoonIsTogether);

                    let new_arr = [];
                    let morning_arr: any = [];
                    let afternoon_arr: any = [];

                    /** 点击编辑后，判断是否选择了多口味的，根据数据的内容来进行分析，筛选出之前有数据的口味 */
                    if (res.data.morningIsTogether) {
                        for (var i_data = 0, len_data = res.data.morning.length; i_data < len_data; i_data++) {
                            let str_data = "flavorId" + i_data;
                            form.setFieldsValue({ [str_data]: i_data });

                            if (res.data.morning[i_data].length === 0) {
                                morning_arr.push(i_data);
                            }
                            else {
                                morning_arr.push(999);
                            }
                        }

                        await fetchApi.GET(wmService, {
                            apiId: 'generalData',
                            apiVariable: 'flavor',
                            apiExtend: 'showList'
                            // eslint-disable-next-line no-loop-func
                        }).then(ress => {
                            new_arr = ress.data.map((item: any) => ({
                                id: item.id,                      //设置字段，在form.list下的form.item中指定的字段值
                                name: item.name,
                                visible: morning_arr[item.id] === item.id ? false : item.visible
                            }));

                            let morning_true: any = 0;
                            for (var morning_i = 0, len_morning = new_arr.length; morning_i < len_morning; morning_i++) {
                                if (new_arr[morning_i].visible === true) {
                                    morning_true = morning_true + 1;
                                }
                            }

                            setMorningFlavorTrueData(morning_true);
                            setMorningFlavorData(new_arr);
                        });
                    }

                    if (res.data.afternoonIsTogether) {
                        for (var i_data = 0, len_data = res.data.afternoon.length; i_data < len_data; i_data++) {
                            let str_data = "flavorId" + i_data;
                            form.setFieldsValue({ [str_data]: i_data });

                            if (res.data.afternoon[i_data].length === 0) {
                                afternoon_arr.push(i_data);
                            }
                            else {
                                afternoon_arr.push(999);
                            }
                        }

                        await fetchApi.GET(wmService, {
                            apiId: 'generalData',
                            apiVariable: 'flavor',
                            apiExtend: 'showList'
                            // eslint-disable-next-line no-loop-func
                        }).then(ress => {
                            new_arr = ress.data.map((item: any) => ({
                                id: item.id,                      //设置字段，在form.list下的form.item中指定的字段值
                                name: item.name,
                                visible: afternoon_arr[item.id] === item.id ? false : item.visible
                            }));

                            let afternoon_true: any = 0;
                            for (var afternoon_i = 0, len_afternoon = new_arr.length; afternoon_i < len_afternoon; afternoon_i++) {
                                if (new_arr[afternoon_i].visible === true) {
                                    afternoon_true = afternoon_true + 1;
                                }
                            }

                            setAfternoonFlavorTrueData(afternoon_true);
                            setAfternoonFlavorData(new_arr);
                        });
                    }
                }
            })
        }

        const fetchData = async () => {
            //console.log(data);

            form.setFieldsValue({ morningPeriodTimeId: 0 });
            form.setFieldsValue({ afternoonPeriodTimeId: 1 });

            if (data.type !== 'NEW') {
                setDateDisableData(true);
                // setAreaDisable(true);
            }

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'periodTime',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setPeriodTimeData(res.data);
                }
            });

            // fetchApi.GET(wmService, {
            //     apiId: "area",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {
            //         setAreaData(res.data)
            //     }
            // });

            // fetchApi.GET(wmService, {
            //     apiId: "feastTeamMenu",
            //     apiExtend: 'showDate'
            // }).then(res => {
            //     if (res.success) {
            //         setDateData(res.data);
            //     }
            // });

            // fetchApi.GET(wmService, {
            //     apiId: "feastTeamMenuWarehouse",
            //     apiExtend: 'getAreaId'
            // }).then(res => {
            //     if (res.success) {
            //         if (res.data.key !== 'manager') {
            //             setAreaDisable(true);
            //             form.setFieldsValue({ areaId: res.data.area.id });
            //         }
            //     }
            // });
        }
        fetchData()
    }, []);

    // const disabledData = (current: any) => {
    //     // Can not select days before today and today

    //     //将date转化为YYYYMMDD的格式
    //     const days = dayjs(current).format('YYYYMMDD');

    //     return current && current < dayjs().endOf('day') || dateData.includes(days);
    // };

    const handleIsTogether = (checked: any, key: any) => {

        let flavorDetail: any;
        if (checked) {

            if (form.getFieldsValue()[key] !== undefined) {
                let str_len = form.getFieldsValue()[key].length;

                for (var j_data = 0; j_data < str_len; j_data++) {
                    let removeData = form.getFieldsValue()[key][j_data];

                    if (removeData && removeData.id) {
                        let addDeleteData: any;
                        addDeleteData = { ...removeData, state: "delete" };

                        if (flavorDetail) {
                            flavorDetail.push(addDeleteData);
                        }
                        else {
                            let tmpData: any = [];
                            tmpData.push(addDeleteData);
                            flavorDetail = tmpData;
                        }
                    }
                }
            }

            /**打开多口味后，查找generalData来获取口味的数据，并对基础数据和最终提交数据设置默认值 */
            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'flavor',
                apiExtend: 'showList'
            }).then(res => {
                //console.log(res.data);
                if (key === "morning") {
                    setMorningFlavorData(res.data);
                    setMorningFlavorTrueData(res.data.length);
                }
                else {
                    setAfternoonFlavorData(res.data);
                    setAfternoonFlavorTrueData(res.data.length);
                }

                for (var i = 0, len = res.data.length; i < len; i++) {
                    let str = "flavorId" + res.data[i].id;
                    form.setFieldsValue({ [str]: res.data[i].id });
                }
            });
        }
        else {
            /** 关掉多口味后，如果之前有数据的，关掉多口味后，会将之前的数据的状态都设置为delete，并对基础数据和最终提交数据设置为新数据 */
            let flavorData: any;
            if (key === "morning") {
                flavorData = morningFlavorData;
            }
            else {
                flavorData = afternoonFlavorData;
            }
            for (var i_data = 0; i_data < flavorData.length; i_data++) {

                let str_data: any;

                if (key === "morning") {
                    str_data = "morning" + i_data;
                }
                else {
                    str_data = "afternoon" + i_data;
                }

                if (form.getFieldsValue()[str_data] !== undefined) {
                    let str_len = form.getFieldsValue()[str_data].length;

                    for (var j_data = 0; j_data < str_len; j_data++) {
                        let removeData = form.getFieldsValue()[str_data][j_data];

                        if (removeData && removeData.id) {
                            let addDeleteData: any;
                            addDeleteData = { ...removeData, state: "delete" };

                            if (flavorDetail) {
                                flavorDetail.push(addDeleteData);
                            }
                            else {
                                let tmpData: any = [];
                                tmpData.push(addDeleteData);
                                flavorDetail = tmpData;
                            }
                        }
                    }
                }
            }
            if (key === "morning") {
                setMorningFlavorData([]);
            }
            else {
                setAfternoonFlavorData([]);
            }
        }

        if (flavorDetail) {
            if (tempDeleteData) {
                // console.log("1、现在有数据,之前也删除过内容");
                flavorDetail = flavorDetail.concat(tempDeleteData);
            }
            else {
                // console.log("2、现在有数据,之前无删除过内容");
                flavorDetail = flavorDetail;
            }
        }
        else {
            if (tempDeleteData) {
                // console.log("3、无数据,之前也删除过内容");
                flavorDetail = tempDeleteData;
            }
            else {
                // console.log("4、无数据,之前无删除过内容");
            }
        }

        setTempDeleteData(flavorDetail);

        if (key === "morning") {
            setMorningBaseData(false);
            setMorningIsTogetherData(checked);
            setChangeData("morning");
        }
        else {
            setAfternoonBaseData(false);
            setAfternoonIsTogetherData(checked);
            setChangeData("afternoon");
        }
    };

    // const handleAfternoonIsTogether = (checked: any) => {

    //     let flavorDetail: any;
    //     if (checked) {

    //         if (form.getFieldsValue()["afternoon"] !== undefined) {
    //             let str_len = form.getFieldsValue()["afternoon"].length;

    //             for (var j_data = 0; j_data < str_len; j_data++) {
    //                 let removeData = form.getFieldsValue()["afternoon"][j_data];

    //                 if (removeData && removeData.id) {
    //                     let addDeleteData: any;
    //                     addDeleteData = { ...removeData, state: "delete" };

    //                     if (flavorDetail) {
    //                         flavorDetail.push(addDeleteData);
    //                     }
    //                     else {
    //                         let tmpData: any = [];
    //                         tmpData.push(addDeleteData);
    //                         flavorDetail = tmpData;
    //                     }
    //                 }
    //             }
    //         }

    //         /**打开多口味后，查找generalData来获取口味的数据，并对基础数据和最终提交数据设置默认值 */
    //         fetchApi.GET(wmService, {
    //             apiId: 'generalData',
    //             apiVariable: 'flavor',
    //             apiExtend: 'showList'
    //         }).then(res => {
    //             //console.log(res.data);
    //             setAfternoonFlavorData(res.data);

    //             for (var i = 0, len = res.data.length; i < len; i++) {
    //                 let str = "flavorId" + res.data[i].id;
    //                 form.setFieldsValue({ [str]: res.data[i].id });
    //             }
    //         });
    //     }
    //     else {
    //         /** 关掉多口味后，如果之前有数据的，关掉多口味后，会将之前的数据的状态都设置为delete，并对基础数据和最终提交数据设置为新数据 */
    //         for (var i_data = 0; i_data < afternoonFlavorData.length; i_data++) {

    //             let str_data = "afternoon" + i_data;

    //             if (form.getFieldsValue()[str_data] !== undefined) {
    //                 let str_len = form.getFieldsValue()[str_data].length;

    //                 for (var j_data = 0; j_data < str_len; j_data++) {
    //                     let removeData = form.getFieldsValue()[str_data][j_data];

    //                     if (removeData && removeData.id) {
    //                         let addDeleteData: any;
    //                         addDeleteData = { ...removeData, state: "delete" };

    //                         if (flavorDetail) {
    //                             flavorDetail.push(addDeleteData);
    //                         }
    //                         else {
    //                             let tmpData: any = [];
    //                             tmpData.push(addDeleteData);
    //                             flavorDetail = tmpData;
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //         setAfternoonFlavorData([]);
    //     }

    //     //console.log(tempDeleteData);

    //     if (flavorDetail) {
    //         if (tempDeleteData) {
    //             // console.log("1、现在有数据,之前也删除过内容");
    //             flavorDetail = flavorDetail.concat(tempDeleteData);
    //         }
    //         else {

    //             // console.log("2、现在有数据,之前无删除过内容");
    //             flavorDetail = flavorDetail;
    //         }
    //     }
    //     else {
    //         if (tempDeleteData) {
    //             // console.log("3、无数据,之前也删除过内容");
    //             flavorDetail = tempDeleteData;
    //         }
    //         else {
    //             // console.log("4、无数据,之前无删除过内容");
    //         }
    //     }

    //     // console.log(flavorDetail);
    //     setTempDeleteData(flavorDetail);

    //     setAfternoonBaseData(false);
    //     setAfternoonIsTogetherData(checked);
    // };

    /** 删掉某个口味，并将最终提交数据里面的该口味下的数据状态设置为delete */
    const cancelFlavor = (rows: any, time: any, key: any, formListName: any, deleteData: any) => {

        let finallyDetail: any;
        let deleteDetail: any;

        if (rows !== undefined) {
            let new_arr = [];
            new_arr = rows.map((item: any, index: any) => ({
                id: item.id,                      //设置字段，在form.list下的form.item中指定的字段值
                name: item.name,
                visible: index === key ? false : item.visible
            }));

            if (time === "morning") {
                setMorningFlavorData(new_arr);
            }
            else {
                setAfternoonFlavorData(new_arr);
            }
            let flavorData: any;
            let str_data: any;
            if (time === "morning") {
                flavorData = morningFlavorData;
            }
            else {
                flavorData = afternoonFlavorData;
            }

            for (var i_data = 0; i_data < flavorData.length; i_data++) {

                str_data = time + i_data;
                if (form.getFieldsValue()[str_data] !== undefined) {
                    //let str_len = form.getFieldsValue()[str_data].length;

                    if (str_data === formListName) {
                        // 将删除的内容的状态设为delete
                        if (form.getFieldsValue()[formListName] !== undefined) {
                            let str_len = form.getFieldsValue()[formListName].length;

                            for (var j_data = 0; j_data < str_len; j_data++) {
                                let removeData = form.getFieldsValue()[formListName][j_data];

                                if (removeData && removeData.id) {
                                    let addDeleteData: any;
                                    addDeleteData = { ...removeData, state: "delete" };

                                    if (deleteDetail) {
                                        deleteDetail.push(addDeleteData);
                                    }
                                    else {
                                        let tmpData: any = [];
                                        tmpData.push(addDeleteData);
                                        deleteDetail = tmpData;
                                    }
                                }
                            }
                        }
                    }
                    else {
                        if (finallyDetail) {
                            if (form.getFieldsValue()[str_data]) {
                                finallyDetail = finallyDetail.concat(form.getFieldsValue()[str_data]);
                            }
                        }
                        else {
                            finallyDetail = form.getFieldsValue()[str_data];
                        }
                    }
                }
            }

            if (deleteDetail) {
                if (tempDeleteData) {
                    // console.log("1、现在有数据,之前也删除过内容");

                    deleteDetail = deleteDetail.concat(tempDeleteData);
                    if (finallyDetail === undefined) {
                        finallyDetail = deleteDetail;
                    }
                    else {
                        finallyDetail = finallyDetail.concat(deleteDetail);
                    }
                }
                else {
                    // console.log("2、现在有数据,之前无删除过内容");
                    deleteDetail = deleteDetail;
                    finallyDetail = finallyDetail.concat(deleteDetail);
                }
            }
            else {
                if (tempDeleteData) {
                    // console.log("3、无数据,之前也删除过内容");
                    deleteDetail = tempDeleteData;
                    finallyDetail = finallyDetail.concat(deleteDetail);
                }
                else {
                    // console.log("4、无数据,之前无删除过内容");
                }
            }

            // console.log(finallyDetail);
            setTempDeleteData(deleteDetail);
            setFlavorDetailData(finallyDetail);

            let k = 0;
            for (var i = 0, len = new_arr.length; i < len; i++) {
                if (new_arr[i].visible === true) {
                    k = k + 1;
                }
            }

            if (time === "morning") {
                setMorningFlavorTrueData(k);
            }
            else {
                setAfternoonFlavorTrueData(k);
            }



            // 如果把所有口味删了，则将是否多口味重新设置为否
            // if (k <= 1) {
            //     //console.log('全删了');
            //     if (time === "morning") {
            //         form.setFieldsValue({ morningIsTogether: false });
            //         setMorningIsTogetherData(false);
            //     }
            //     else {
            //         form.setFieldsValue({ afternoonIsTogether: false });
            //         setAfternoonIsTogetherData(false);
            //     }
            // }

            if (time === "morning") {
                setMorningBaseData(false);
            }
            else {
                setAfternoonBaseData(false);
            }
        }
    };

    let extendProps: any = {
        ...props,
        form,
        apiId: 'feastTeamMenu',
        dateColumnName: ['selectDay']
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} totalData={flavorDetailData} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '福食菜单'}
                open={true}
                //footer={null}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={1080}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                >

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="ID"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="选择时间"
                                name="selectDay"
                                rules={[
                                    {
                                        required: true,
                                        message: '请选择时间',
                                    },
                                ]}
                            >
                                <DatePicker
                                    disabled={dateDisableData}
                                    format="YYYY-MM-DD"
                                    // disabledDate={disabledData}
                                />
                            </Form.Item>
                        </Col>

                        {/* <Col span={8}>
                            <Form.Item
                                label="所属区域"
                                name="areaId"
                                rules={[{ required: true, message: '请选择所属区域!' }]}>
                                <Select
                                    showSearch
                                    disabled={areaIdDisable}
                                    placeholder="所属区域"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(areaData, 'areaName')}
                                </Select>
                            </Form.Item>
                        </Col> */}

                        <Col span={24}>
                            <Form.Item
                                label="上午"
                                name={"morningPeriodTimeId"}
                            >
                                <Select
                                    showSearch
                                    disabled
                                    placeholder="上午"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(periodTimeData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="是否需要分口味"
                                name="morningIsTogether"
                                valuePropName="checked"
                                initialValue={false}
                            >
                                <Switch
                                    checkedChildren="是"
                                    unCheckedChildren="否"
                                    onChange={(e) => handleIsTogether(e, "morning")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                        <Col span={morningIsTogetherData ? 0 : 24}>
                            {
                                morningIsTogetherData === false
                                    ? (<WebFeastTeamFormList props={formList} formName='morning' dataName="morning" morningIsBase={morningBaseData} flavor={999} changeData={changeData} morningFlavorLength={morningFlavorData.length} afternoonFlavorLength={afternoonFlavorData.length} tempDeleteData={tempDeleteData} ></WebFeastTeamFormList>)
                                    :
                                    morningBaseData
                                        ? null
                                        : (<WebFeastTeamFormList props={formList} formName='morning' dataName="morning" morningIsBase={morningBaseData} flavor={888} changeData={changeData} morningFlavorLength={morningFlavorData.length} afternoonFlavorLength={afternoonFlavorData.length} tempDeleteData={tempDeleteData} ></WebFeastTeamFormList>)
                            }
                        </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                        {
                            morningFlavorData.map((item: any, index: any) => {

                                //console.log(morningIsTogetherData);
                                //console.log(flavorLengthData);
                                if (item.visible === true) {
                                    return (
                                        <>
                                            <Col span={morningIsTogetherData ? 12 : 0}>
                                                <Row gutter={[8, 0]}>
                                                    <Col span={morningFlavorTrueData > 2 ? 12 : 24}>
                                                        <Form.Item
                                                            label="口味"
                                                            name={"flavorId" + item.id}
                                                            rules={[{
                                                                required: morningIsTogetherData ? true : false,
                                                                message: '请输入口味!'
                                                            }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                disabled
                                                                placeholder="口味"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option: any) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                                {getList.general(morningFlavorData, "name")}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={morningFlavorTrueData > 2 ? 12 : 0}>
                                                        <Form.Item>
                                                            <Button type="primary" onClick={() => cancelFlavor(morningFlavorData, "morning", item.id, "morning" + item.id, tempDeleteData)} danger>取消该口味</Button>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={24}>
                                                        {
                                                            morningIsTogetherData === true
                                                                ? (<WebFeastTeamFormList props={formList} formName={'morning' + item.id} dataName="morning" morningIsBase={morningBaseData} flavor={item.id} changeData={changeData} morningFlavorLength={morningFlavorData.length} afternoonFlavorLength={afternoonFlavorData.length} tempDeleteData={tempDeleteData} ></WebFeastTeamFormList>)
                                                                : null
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </>
                                    )
                                }
                            })
                        }
                    </Row>

                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="下午"
                                name={"afternoonPeriodTimeId"}
                            >
                                <Select
                                    showSearch
                                    disabled
                                    placeholder="下午"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(periodTimeData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="是否需要分口味"
                                name="afternoonIsTogether"
                                valuePropName="checked"
                                initialValue={false}
                            >
                                <Switch
                                    checkedChildren="是"
                                    unCheckedChildren="否"
                                    onChange={(e) => handleIsTogether(e, "afternoon")}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                        <Col span={afternoonIsTogetherData ? 0 : 24}>
                            {
                                afternoonIsTogetherData === false
                                    ? (<WebFeastTeamFormList props={formList} formName='afternoon' dataName="afternoon" afternoonIsBase={afternoonBaseData} flavor={999} changeData={changeData} morningFlavorLength={morningFlavorData.length} afternoonFlavorLength={afternoonFlavorData.length} tempDeleteData={tempDeleteData} ></WebFeastTeamFormList>)
                                    :
                                    afternoonBaseData
                                        ? null
                                        : (<WebFeastTeamFormList props={formList} formName='afternoon' dataName="afternoon" afternoonIsBase={afternoonBaseData} flavor={888} changeData={changeData} morningFlavorLength={morningFlavorData.length} afternoonFlavorLength={afternoonFlavorData.length} tempDeleteData={tempDeleteData} ></WebFeastTeamFormList>)
                            }
                        </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                        {
                            afternoonFlavorData.map((item: any, index: any) => {

                                //console.log(afternoonIsTogetherData);
                                //console.log(flavorLengthData);
                                if (item.visible === true) {
                                    return (
                                        <>
                                            <Col span={afternoonIsTogetherData ? 12 : 0}>
                                                <Row gutter={[8, 0]}>
                                                    <Col span={afternoonFlavorTrueData > 2 ? 12 : 24}>
                                                        <Form.Item
                                                            label="口味"
                                                            name={"flavorId" + item.id}
                                                            rules={[{
                                                                required: afternoonIsTogetherData ? true : false,
                                                                message: '请输入口味!'
                                                            }]}
                                                        >
                                                            <Select
                                                                showSearch
                                                                disabled
                                                                placeholder="口味"
                                                                optionFilterProp="children"
                                                                filterOption={(input, option: any) =>
                                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                                {getList.general(afternoonFlavorData, "name")}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={afternoonFlavorTrueData > 2 ? 12 : 0}>
                                                        <Form.Item>
                                                            <Button type="primary" onClick={() => cancelFlavor(afternoonFlavorData, "afternoon", item.id, "afternoon" + item.id, tempDeleteData)} danger>取消该口味</Button>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={24}>
                                                        {
                                                            afternoonIsTogetherData === true
                                                                ? (<WebFeastTeamFormList props={formList} formName={'afternoon' + item.id} dataName="afternoon" afternoonIsBase={afternoonBaseData} flavor={item.id} changeData={changeData} morningFlavorLength={morningFlavorData.length} afternoonFlavorLength={afternoonFlavorData.length} tempDeleteData={tempDeleteData} ></WebFeastTeamFormList>)
                                                                : null
                                                        }
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </>
                                    )
                                }
                            })
                        }
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default FeastTeamMenuEdit;