import { Button, Col, Form, InputNumber, Select, Space } from 'antd'
import { useEffect, useState } from 'react';
import getList from '../universal/getList';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

//  福食formList通用组件
export default function WebFeastTeamFormList(props: any) {

  //console.log(props);

  const { formName, dataName, morningIsBase, afternoonIsBase, flavor, changeData, morningFlavorLength, afternoonFlavorLength, tempDeleteData } = props;
  const { form, type, ref, setFlavorDetailData, setTempDeleteData } = props.props;

  // const formListData = Form.useWatch(formName, form) as any;

  /**产品数据 */
  const [productData, setProductData] = useState<any>();

  const mergeFlavorDetail = (morningFlavorLength: any, afternoonFlavorLength: any, delData?: any) => {
    // console.log(flavorLength > 0 ? "morning1" : "morning");

    let flavorDetail: any;
    if (morningFlavorLength === 0) {
      if (afternoonFlavorLength === 0) {
        // console.log("上下午都是单口味");
        // console.log(morningFlavorLength);
        // console.log(afternoonFlavorLength);

        if (form.getFieldsValue()["morning"]) {
          flavorDetail = form.getFieldsValue()["morning"];
        }

        if (flavorDetail) {
          if (form.getFieldsValue()["afternoon"]) {
            flavorDetail = flavorDetail.concat(form.getFieldsValue()["afternoon"]);
          }
        }
        else {
          if (form.getFieldsValue()["afternoon"]) {
            flavorDetail = form.getFieldsValue()["afternoon"];
          }
        }

        // console.log(flavorDetail);
      }
      else {
        // console.log("上午是单口味，下午是多口味");
        // console.log(morningFlavorLength);
        // console.log(afternoonFlavorLength);

        if (form.getFieldsValue()["morning"]) {
          flavorDetail = form.getFieldsValue()["morning"];
        }

        if (flavorDetail) {
          for (var i_data = 0; i_data < afternoonFlavorLength; i_data++) {
            let str_data: any
            str_data = "afternoon" + i_data;
            if (form.getFieldsValue()[str_data]) {
              flavorDetail = flavorDetail.concat(form.getFieldsValue()[str_data]);
            }
          }
        }
        else {
          let k: any = 0;
          for (var i_data = 0; i_data < afternoonFlavorLength; i_data++) {
            let str_data: any;
            str_data = "afternoon" + i_data;
            if (k === 0) {
              if (form.getFieldsValue()[str_data]) {
                flavorDetail = form.getFieldsValue()[str_data];
                k = k + 1;
              }
            }
            else {
              if (form.getFieldsValue()[str_data]) {
                flavorDetail = flavorDetail.concat(form.getFieldsValue()[str_data]);
                k = k + 1;
              }
            }
          }
        }

        // console.log(flavorDetail);
      }

    }
    else {
      if (afternoonFlavorLength === 0) {
        // console.log("上午是多口味，下午是单口味");
        // console.log(morningFlavorLength);
        // console.log(afternoonFlavorLength);

        let k: any = 0;
        for (var i_data = 0; i_data < morningFlavorLength; i_data++) {
          let str_data: any;
          str_data = "morning" + i_data;
          if (k === 0) {
            if (form.getFieldsValue()[str_data]) {
              flavorDetail = form.getFieldsValue()[str_data];
              k = k + 1;
            }
          }
          else {
            if (form.getFieldsValue()[str_data]) {
              flavorDetail = flavorDetail.concat(form.getFieldsValue()[str_data]);
              k = k + 1;
            }
          }
        }

        if (flavorDetail) {
          if (form.getFieldsValue()["afternoon"]) {
            flavorDetail = flavorDetail.concat(form.getFieldsValue()["afternoon"]);
          }
        }
        else {
          if (form.getFieldsValue()["afternoon"]) {
            flavorDetail = form.getFieldsValue()["afternoon"];
          }
        }

        // console.log(flavorDetail);

      }
      else {
        // console.log("上午是多口味，下午是多口味");
        // console.log(morningFlavorLength);
        // console.log(afternoonFlavorLength);

        let k: any = 0;
        for (var i_data = 0; i_data < morningFlavorLength; i_data++) {
          let str_data: any;
          str_data = "morning" + i_data;
          if (k === 0) {
            if (form.getFieldsValue()[str_data]) {
              flavorDetail = form.getFieldsValue()[str_data];
              k = k + 1;
            }
          }
          else {
            if (form.getFieldsValue()[str_data]) {
              flavorDetail = flavorDetail.concat(form.getFieldsValue()[str_data]);
              k = k + 1;
            }
          }
        }

        if (flavorDetail) {
          for (var i_data = 0; i_data < afternoonFlavorLength; i_data++) {
            let str_data: any
            str_data = "afternoon" + i_data;
            if (form.getFieldsValue()[str_data]) {
              flavorDetail = flavorDetail.concat(form.getFieldsValue()[str_data]);
            }
          }
        }
        else {
          let i: any = 0;
          for (var ii_data = 0; ii_data < afternoonFlavorLength; ii_data++) {
            let strr_data: any;
            strr_data = "afternoon" + ii_data;
            if (i === 0) {
              if (form.getFieldsValue()[strr_data]) {
                flavorDetail = form.getFieldsValue()[strr_data];
                i = i + 1;
              }
            }
            else {
              if (form.getFieldsValue()[strr_data]) {
                flavorDetail = flavorDetail.concat(form.getFieldsValue()[strr_data]);
                i = i + 1;
              }
            }
          }
        }

        // console.log(flavorDetail);
      }
    }

    // console.log("------------------合并时，收到需要删除的数据------------------");
    // console.log(delData);

    if (delData) {
      flavorDetail = flavorDetail.concat(delData);
    }

    // console.log("------------------最后的总数据------------------");
    // console.log(flavorDetail);
    setFlavorDetailData(flavorDetail);
  };


  //remove 删除
  const removeList = (remove: Function, num: number, key: number, originData: any) => {

    //即将删除的数据
    let removeData = form.getFieldsValue()[formName][num];
    let concatData: any;

    if (removeData && removeData.id) {

      let addDeleteData: any;
      addDeleteData = { ...removeData, state: "delete" };

      if (originData) {
        originData.push(addDeleteData);
        concatData = originData;
      }
      else {
        let tmpData: any = [];
        tmpData.push(addDeleteData);
        concatData = tmpData;
      }

      setTempDeleteData(concatData);
    }
    else {
      if (originData) {
        concatData = originData;
      }

      setTempDeleteData(concatData);
    }

    remove(num);
    mergeFlavorDetail(morningFlavorLength, afternoonFlavorLength, concatData);
    delete productData[key];
  }

  const customizeAdd = (add: Function, flavor: any, originData: any) => {
    add();
    form.setFieldValue([formName, form.getFieldsValue()[formName].length - 1], { periodTimeId: dataName === "morning" ? 0 : 1, flavorId: flavor, amount: 1, state: "insert" });
    mergeFlavorDetail(morningFlavorLength, afternoonFlavorLength, originData);
  }

  /**将修改的内容的状态改为update 并和 需要删除的数据进行合并*/
  const handleChange = (form: any, e: any, name?: any, FormListName?: string, type?: string, originData?: any) => {
    if (type === "EDIT") {
      form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], state: form.getFieldsValue()[FormListName!][name].id ? "update" : "insert" })
    }
    mergeFlavorDetail(morningFlavorLength, afternoonFlavorLength, originData);
  }

  useEffect(() => {

    if (ref.current) {

      if (type === "NEW") {

        if (changeData === "morning") {
          if (flavor !== 999) {
            for (var i = 0; i < morningFlavorLength; i++) {
              let m_formName = "morning" + i;
              let newData: Array<any> = []
              form.setFieldsValue({ [m_formName]: newData });
              setProductData({});
            }
          }
          else {

            let newData: Array<any> = []
            form.setFieldsValue({ morning: newData });
            setProductData({});
          }
        }

        if (changeData === "afternoon") {
          if (flavor !== 999) {
            for (var i = 0; i < afternoonFlavorLength; i++) {
              let m_formName = "afternoon" + i;
              let newData: Array<any> = []
              form.setFieldsValue({ [m_formName]: newData });
              setProductData({});
            }
          }
          else {

            let newData: Array<any> = []
            form.setFieldsValue({ afternoon: newData });
            setProductData({});
          }
        }
      }

      ref.current.row.then(async (res: any) => {
        if (typeof res === 'undefined') { return }
        if (res.success === true) {
          let feastTeamMenuWarehouseObj: any = {};
          //自定义formList数据初始状态
          let newData: Array<any> = []

          if (morningIsBase) {

            if (flavor !== 999) {

              for (var i = 0; i < res.data.morning[flavor].length; i++) {
                //console.log('????????????',res.data.morning[flavor][i].feastTeamMenuWarehouse);

                feastTeamMenuWarehouseObj[i] = [res.data.morning[flavor][i].feastTeamMenuWarehouse];

                if (type === "EDIT") {
                  newData.push({
                    //...res.data[dataName][flavor][i],

                    id: res.data[dataName][flavor][i].id,
                    //feastTeamMenuId: res.data[dataName][flavor][i].feastTeamMenuId,
                    periodTimeId: res.data[dataName][flavor][i].periodTimeId,
                    flavorId: res.data[dataName][flavor][i].flavorId,
                    feastTeamMenuWarehouseId: res.data[dataName][flavor][i].feastTeamMenuWarehouseId,
                    amount: res.data[dataName][flavor][i].amount,
                    state: "origin"
                  })
                }
              }
            }
            else {
              for (var i = 0; i < res.data.morning.length; i++) {
                //console.log('????????????',res.data.morning);

                feastTeamMenuWarehouseObj[i] = [res.data.morning[i].feastTeamMenuWarehouse];

                if (type === "EDIT") {
                  newData.push({
                    //...res.data[dataName][i],

                    id: res.data[dataName][i].id,
                    //feastTeamMenuId: res.data[dataName][i].feastTeamMenuId,
                    periodTimeId: res.data[dataName][i].periodTimeId,
                    flavorId: res.data[dataName][i].flavorId,
                    feastTeamMenuWarehouseId: res.data[dataName][i].feastTeamMenuWarehouseId,
                    amount: res.data[dataName][i].amount,
                    state: "origin"
                  })
                }
              }
            }

            // console.log("---------------------------------------------------AM");
            // console.log("feastTeamMenuWarehouseObj");
            // console.log(feastTeamMenuWarehouseObj);
            // console.log("newData", newData);

            form.setFieldsValue({ [formName]: newData });            
            setProductData(feastTeamMenuWarehouseObj);            
          }
          else {
            form.setFieldsValue({ [formName]: newData });
            setProductData({});
          }

          if (afternoonIsBase) {
            //console.log(afternoonIsBase);

            if (flavor !== 999) {

              for (var i = 0; i < res.data.afternoon[flavor].length; i++) {
                //console.log('????????????',res.data.afternoon[flavor][i].feastTeamMenuWarehouse);

                feastTeamMenuWarehouseObj[i] = [res.data.afternoon[flavor][i].feastTeamMenuWarehouse];

                if (type === "EDIT") {
                  newData.push({
                    //...res.data[dataName][flavor][i],

                    id: res.data[dataName][flavor][i].id,
                    //feastTeamMenuId: res.data[dataName][flavor][i].feastTeamMenuId,
                    periodTimeId: res.data[dataName][flavor][i].periodTimeId,
                    flavorId: res.data[dataName][flavor][i].flavorId,
                    feastTeamMenuWarehouseId: res.data[dataName][flavor][i].feastTeamMenuWarehouseId,
                    amount: res.data[dataName][flavor][i].amount,
                    state: "origin"
                  })
                }
              }
            }
            else {
              for (var i = 0; i < res.data.afternoon.length; i++) {
                //console.log('????????????',res.data.afternoon);

                feastTeamMenuWarehouseObj[i] = [res.data.afternoon[i].feastTeamMenuWarehouse];

                if (type === "EDIT") {
                  newData.push({
                    //...res.data[dataName][i],

                    id: res.data[dataName][i].id,
                    //feastTeamMenuId: res.data[dataName][i].feastTeamMenuId,
                    periodTimeId: res.data[dataName][i].periodTimeId,
                    flavorId: res.data[dataName][i].flavorId,
                    feastTeamMenuWarehouseId: res.data[dataName][i].feastTeamMenuWarehouseId,
                    amount: res.data[dataName][i].amount,
                    state: "origin"
                  })
                }
              }
            }

            // console.log("-----------------------------------------PM");
            // console.log("feastTeamMenuWarehouseObj");
            // console.log(feastTeamMenuWarehouseObj);
            // console.log("newData", newData);

            form.setFieldsValue({ [formName]: newData });
            setProductData(feastTeamMenuWarehouseObj);
          }
          else {
            form.setFieldsValue({ [formName]: newData });
            // setProductData({});
          }
        }
      })
    }
  }, [morningFlavorLength, afternoonFlavorLength])

  return (
    <>
      <Col span={24}>
        <Form.List name={formName}>
          {(fields, { add, remove }) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }) => (
                  <Space
                    key={key}
                    style={{ display: 'flex', marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...restField}
                      label="菜式"
                      name={[name, 'feastTeamMenuWarehouseId']}
                      rules={[
                        {
                          required: true,
                          message: '请输入菜式',
                        },
                      ]}
                    >
                      <Select
                        //optionLabelProp="label"
                        style={{ width: 300 }}
                        showSearch
                        allowClear
                        onSearch={(e) => getList.searchEx('feastTeamMenuWarehouse', setProductData, e, productData, {
                          keyId: key,
                          isFormList: true,
                          form,
                          FormListName: formName,
                          name
                        })}
                        onChange={(e) => handleChange(form, e, name, formName, type, tempDeleteData)}
                        placeholder="菜式"
                        optionFilterProp="children"
                        // filterOption={(input, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                        filterOption={(input, option: any) => {
                          // console.log("option",option);
                          // console.log("input",input);
                          return true
                        }}
                      >
                        {getList.general(productData ? productData[key] ? productData[key] : [] : [], "foodName")}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      label="数量"
                      {...restField}
                      initialValue={1}
                      name={[name, 'amount']}
                      rules={[
                        {
                          required: true,
                          message: '请输入数量',
                        },
                      ]}
                    >
                      <InputNumber
                        style={{ width: 80 }}
                        onChange={(e) => handleChange(form, e, name, formName, type, tempDeleteData)}
                        placeholder="数量"
                        min={1} />
                    </Form.Item>

                    <MinusCircleOutlined onClick={() => removeList(remove, name, key, tempDeleteData)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => customizeAdd(add, flavor, tempDeleteData)}
                    block
                    icon={<PlusOutlined />}
                  >
                    添加菜式
                  </Button>
                </Form.Item>
              </>
            )
          }
          }
        </Form.List>
      </Col>
    </>
  )
}