import { getPageUrl } from './index';

//warehouse
import ProductUnit from '../components/internal/warehouse/ProductUnit';
import Product from '../components/internal/warehouse/Product';
import WarehouseCategories from '../components/internal/warehouse/WarehouseCategories';
import ClassificationWarehouse from '../components/internal/warehouse/ClassificationWarehouse';
import Buyer from '../components/internal/warehouse/Buyer';
import Entrepot from '../components/internal/warehouse/Entrepot';
import BuyerType from '../components/internal/warehouse/BuyerType';

//仓库报表
import EntrepotDailyReport from '../components/internal/warehouse/EntrepotReport/DailyReport';
import TransferReport from '../components/internal/warehouse/EntrepotReport/TransferReport';
import PurOrderQuery from '../components/internal/warehouse/EntrepotReport/PurOrderQuery';
import PurContrast from '../components/internal/costReport/CostPurContrast';
import EnPurStatistics from '../components/internal/warehouse/EntrepotReport/EnPurStatistics';
import PurQuery from '../components/internal/warehouse/EntrepotReport/PurQuery';
import TrQuery from '../components/internal/warehouse/EntrepotReport/TrQuery';

//仓库订单
import PurchaseOrder from '../components/internal/orderManagement/PurchaseOrder';
import TransferOrder from '../components/internal/orderManagement/TransferOrder';
import IncomeStatement from '../components/internal/orderManagement/IncomeStatement';
import OpeningInventory from '../components/internal/orderManagement/OpeningInventory';
import FinalInventory from '../components/internal/orderManagement/FinalInventory';
import ApplyPurchase from '../components/internal/orderManagement/ApplyPurchase';

import PurStatistics from '../components/internal/costReport/CostPurStatistics';
import TrOrderQuery from '../components/internal/warehouse/EntrepotReport/TrOrderQuery';
import EnTrStatistics from '../components/internal/warehouse/EntrepotReport/EnTrStatistics';

//成本订单
import CostTransferOrder from '../components/internal/cost/CostTransferOrder';
import CostPurchaseOrder from '../components/internal/cost/CostPurchaseOrder';
import DataImport from '../components/internal/cost/DataImport';
import DelData from '../components/internal/cost/DelData';

import CostTrOrderQuery from '../components/internal/costReport/CostTrOrderQuery';


//成本报表
import CostTrStatistics from '../components/internal/costReport/CostTrStatistics';
import ProductQuery from '../components/internal/warehouse/EntrepotReport/ProductQuery';
import CostPurOrderQuery from '../components/internal/costReport/CostPurOrderQuery';
import { RouteObject } from 'react-router-dom';
import EntrepotDelData from '../components/internal/warehouse/EntrepotDelData';
import ProcessingOrder from '../components/internal/orderManagement/ProcessingOrder';
import ProcessingStatistics from '../components/internal/orderManagement/ProcessingStatistics';
import ProcessingRequisition from '../components/internal/orderManagement/ProcessingRequisition';


const routes_warehouse = async (licenseList: Array<any>) => {
    const res: RouteObject[] = [
        //仓库资料
        {
            path: await getPageUrl(licenseList, 'product'),
            element: <Product></Product>
        },
      
        {
            path: await getPageUrl(licenseList, 'warehouseCategories'),
            element: <WarehouseCategories></WarehouseCategories>
        },
        {
            path: await getPageUrl(licenseList, 'classificationWarehouse'),
            element: <ClassificationWarehouse></ClassificationWarehouse>
        },
        {
            path: await getPageUrl(licenseList, 'buyer'),
            element: <Buyer></Buyer>
        },
        {
            path: await getPageUrl(licenseList, 'buyerType'),
            element: <BuyerType></BuyerType>
        },
        {
            path: await getPageUrl(licenseList, 'entrepot'),
            element: <Entrepot></Entrepot>
        },
        {
            path: await getPageUrl(licenseList, 'productUnit'),
            element: <ProductUnit></ProductUnit>
        },
         //仓库订单 
        {
            path: await getPageUrl(licenseList, 'purchaseOrder'),
            element: <PurchaseOrder></PurchaseOrder>
        },
        {
            path: await getPageUrl(licenseList, 'processingOrder'),
            element: <ProcessingOrder></ProcessingOrder>
        },
        {
            path: await getPageUrl(licenseList, 'processingStatistics'),
            element: <ProcessingStatistics></ProcessingStatistics>
        },
        {
            path: await getPageUrl(licenseList, 'processingRequisition'),
            element: <ProcessingRequisition></ProcessingRequisition>
        },
         
        {
            path: await getPageUrl(licenseList, 'applyPurchase'),
            element: <ApplyPurchase></ApplyPurchase>
        },

        // 
        // processingStatistics
        {
            path: await getPageUrl(licenseList, 'transferOrder'),
            element: <TransferOrder></TransferOrder>
        },
        {
            path: await getPageUrl(licenseList, 'incomeStatement'),
            element: <IncomeStatement></IncomeStatement>
        },
        {
            path: await getPageUrl(licenseList, 'openingInventory'),
            element: <OpeningInventory></OpeningInventory>
        },
        {
            path: await getPageUrl(licenseList, 'finalInventory'),
            element: <FinalInventory></FinalInventory>
        }, 
        {
            path: await getPageUrl(licenseList, 'entrepotDelData'),
            element: <EntrepotDelData></EntrepotDelData>
        },
        //仓库报表
         {
            path: await getPageUrl(licenseList, 'entrepotDailyReport'),
            element: <EntrepotDailyReport></EntrepotDailyReport>
        },

        {
            path: await getPageUrl(licenseList, 'transferReport'),
            element: <TransferReport></TransferReport>
        }, 
         {
            path: await getPageUrl(licenseList, 'purOrderQuery'),
            element: <PurOrderQuery></PurOrderQuery>
        },
        {
            path: await getPageUrl(licenseList, 'trOrderQuery'),
            element: <TrOrderQuery></TrOrderQuery>
        },
        {
            path: await getPageUrl(licenseList, 'enTrStatistics'),
            element: <EnTrStatistics></EnTrStatistics>
        },
        {
            path: await getPageUrl(licenseList, 'enPurStatistics'),
            element: <EnPurStatistics></EnPurStatistics>
        },
        {
            path: await getPageUrl(licenseList, 'trQuery'),
            element: <TrQuery></TrQuery>
        },
        {
            path: await getPageUrl(licenseList, 'purQuery'),
            element: <PurQuery></PurQuery>
        },
        {
            path: await getPageUrl(licenseList, 'productQuery'),
            element: <ProductQuery></ProductQuery>
        },
        //成本订单
        {
            path: await getPageUrl(licenseList, 'costTransferOrder'),
            element: <CostTransferOrder></CostTransferOrder>
        },
        {
            path: await getPageUrl(licenseList, 'costPurchaseOrder'),
            element: <CostPurchaseOrder></CostPurchaseOrder>
        },
        {
            path: await getPageUrl(licenseList, 'dataImport'),
            element: <DataImport></DataImport>
        },
        {
            path: await getPageUrl(licenseList, 'delData'),
            element: <DelData></DelData>
        },

          //成本报表
          {
            path: await getPageUrl(licenseList, 'purContrast'),
            element: <PurContrast></PurContrast>
        },
        {
            path: await getPageUrl(licenseList, 'purStatistics'),
            element: <PurStatistics></PurStatistics>
        },
        {
            path: await getPageUrl(licenseList, 'costTrStatistics'),
            element: <CostTrStatistics></CostTrStatistics>
        },
        {
            path: await getPageUrl(licenseList, 'costPurOrderQuery'),
            element: <CostPurOrderQuery></CostPurOrderQuery>
        },
        {
            path: await getPageUrl(licenseList, 'costTrOrderQuery'),
            element: <CostTrOrderQuery></CostTrOrderQuery>
        },
    ]
    return res;
};



export default routes_warehouse