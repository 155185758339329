import moment from 'moment';
import React from 'react';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'

import { columnsheadId } from '../../universal/columnshead';
import TimeTypeEdit from './TimeTypeEdit';

//组件 
const TimeType = (props: any) => {

    /** api服务id */
    const apiId = 'timeType';

    //表头
    const columnsHead = [
        {
            title: '时间段',
            dataIndex: 'scheduledTimeId',
            
        },
        {
            title:"名称",
            dataIndex:"typeName"
        },
        {
            title: '预订日期',
            dataIndex: 'bookingDate',
            render: (val: moment.MomentInput) =>
              <span>
                {moment(val).format('YYYY-MM-DD')}
              </span>,
          },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '市别',
        apiId,
        columns,
        showStates: true,
        pagination:true,
        EditComponent: TimeTypeEdit
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default TimeType;
