import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import PersonnelInformationEdit from './EmployeesEdit';
import { Switch } from 'antd';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { useRef } from 'react';

export default function Employees(props: any) {
    const ref = useRef<any>();
    const { deptId, closeModal, isModel } = props;
    //表头
    const columnsHead = [
        {
            title: '姓名',
            dataIndex: 'name',

        },
        {
            title: '性别',
            dataIndex: 'sexName',

        },
        {
            title: '职务',
            dataIndex: "headship"
        },
        {
            title: '部门',
            dataIndex: 'dept',
            render: (text: any) => (text ? text.deptName : "")
        },
        // {
        //     title: '身份证',
        //     dataIndex: 'IdCard',
        // },
        {
            title: "联系电话",
            dataIndex: "phone",
        },
        {
            title: "入职时间",
            dataIndex: "onJobTime",
        },
        {
            title:"财务签单人",
            dataIndex:"isHead",
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => ref.current.handleChangeOne(record.id, 'isHead', e, 'employees')}
                />
            )
        },
        {
            title: "在职",
            dataIndex: "isOnJob",
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => ref.current.handleChangeOne(record.id, 'isOnJob', e, 'employees')}
                />
            )
        },
    ];
    const handleOk = (e: any) => {
        closeModal(true);
    };
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '人员信息',
        apiId: 'employees',
        showStates: true,
        sendPageData: { deptId },
        columns,
        pagination: true,
        EditComponent: PersonnelInformationEdit,
    };

    return (
        <>
            {isModel ? <AntDraggableModal
                title={'人员信息'}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={800}>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </AntDraggableModal> : <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>}


        </>

    )
}
