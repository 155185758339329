import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import BuyerTypeEdit from './BuyerTypeEdit';
export default function Buyer(props: any) {
    //表头
    const columnsHead = [
        {
            title: '采购商类型',
            dataIndex: 'buyerTypeName',
        },
        {
            title: '区域',
            dataIndex: 'area',
            render: (text: any) => (text ? text.areaName : "")

        },
        {
            title: '排序',
            dataIndex: 'buyerTSort',

        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '采购商类型',
        apiId: 'buyerType',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: BuyerTypeEdit,
    };
    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>

    )
}
