import React from 'react';
import DisCountEdit from './DisCountEdit';
import WebUniversal, { WUExtendPropsType } from '../system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';

//组件 Brand 
const DisCount = (props: any) => {

  /** api服务id */
  const apiId = 'disCount';

  //表头
  const columnsHead = [
    {
      title: '折扣名称',
      dataIndex: 'disCountName',

    },
    {
      title: '折扣',
      dataIndex: 'disCount',

    },
  ];

  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '折扣',
    apiId,
    columns,
    showStates: true,
    pagination: true,
    EditComponent: DisCountEdit
  };

  return (
    <WebUniversal props={extendProps} />
  )
}

export default DisCount;
