import { Form, Input, InputNumber, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export default function ShopTypeEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const [shopCategoryData, setShopCategoryData] = useState<any>()
    const [discountData, setDiscountData] = useState<boolean>(true);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    if (res.data.isDiscount) {
                        setDiscountData(false);
                    }
                    form.setFieldsValue({ shopCategoryId: res.data.shopCategory.id })
                }
            })
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "shopCategory",
                apiExtend: 'showList',
                apiVariable: localStorage.getItem("currentBranchStoreId")
            }).then(res => {
                if (res.success) {
                    setShopCategoryData(res.data)
                }
            })
        }
        fetchData()
    }, [])

    /** 是否可以打折 */
    const handleDiscount = (e: any) => {
        setDiscountData(!e);
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'shopType'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '菜谱分类'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="菜谱大分类"
                        name="shopCategoryId"
                        rules={[{ required: true, message: '请选择菜式仓库大分类!' }]}>
                        <Select
                            showSearch
                            placeholder="菜式仓库大分类"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.generalEx(shopCategoryData, {
                                itemName: "shopCategoryName",
                                includeNull: false
                            })}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="菜谱分类"
                        name="shopTypeName"
                        rules={[{ required: true, message: '请输入菜谱分类!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="菜谱分类英文"
                        name="shopTypeNameEn"
                        rules={[{ required: true, message: '请输入菜谱分类英文!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="是否打折"
                        name="isDiscount"
                        valuePropName="checked"
                        initialValue={false}>
                        <Switch
                            checkedChildren="打折"
                            unCheckedChildren="不打折"
                            onChange={handleDiscount}
                        />
                    </Form.Item>

                    <Form.Item
                        label="最高折扣"
                        name="maxDiscount"
                        initialValue={1}
                        rules={[{ required: true, message: '请输入最高折扣!' }]}
                    >
                        <InputNumber min={0} max={1} disabled={discountData} />
                    </Form.Item>

                    <Form.Item
                        label="服务人员专用"
                        name="isStaff"
                        valuePropName="checked"
                        initialValue={false}>
                        <Switch
                            checkedChildren="是"
                            unCheckedChildren="否"
                        />
                    </Form.Item>

                    <Form.Item
                        label="排序"
                        name="sortBy">
                        <InputNumber min={1} />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        <Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                </Form>
            </AntDraggableModal>
        </>
    )
}