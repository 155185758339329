import antMessage from '../../utils/extend/AntdNotification'

/** 文件管理 */
const FilesManage = async () => { };

/** 上传文件数据类型 */
declare type uploadDataType = {
    /** 子目录 */
    subdirectory?: string,
    /** 第二子目录 */
    secondSubdirectory?: string,
    /** 文件上传后的回调方法(返回上传数据) */
    successCallback?: Function
};

/**
 * 上传文件
 * @param filesService 上传服务地址，例如 http://localHost:3009/test/file/service
 * @param serviceId 服务id
 * @param filesGroupId 文件组id
 * @param uploadData 上传文件数据
 * @returns 
 */
FilesManage.upload = async (filesService: string, serviceId: string,
    filesGroupId: string, uploadData?: uploadDataType) => {
    if (!uploadData) {
        uploadData = {};
    }
    
    const { subdirectory, secondSubdirectory, successCallback } = uploadData;
    
    console.log('上传文件：');
    console.log('[filesService]：' + filesService);
    console.log('[serviceId]：' + serviceId);
    console.log('[filesGroupId]：' + filesGroupId);
    console.log('[uploadData]：');
    console.log(uploadData);
    console.log('--------------');
    let upload = {
        name: 'file',
        action: filesService,
        headers: {
            'authorization': 'Bearer ' + localStorage.getItem('token') as string,
            filesGroupId,
            subdirectory,
            secondSubdirectory,
            apiId: 'files',
            apiExtend: 'uploadSingleFile',
            'serviceid': serviceId
        },
        async onChange(info: any) {
            if (info.file.status !== 'uploading') {
            }
            if (info.file.status === 'done') {
                if (info.file.response.success) {
                    antMessage('success', '文件上传成功', 'success');
                    //回调
                    if (successCallback) {
                        successCallback(
                            {
                                data: info.file.response.data,
                                filesGroupId,
                            }
                        );
                    }
                }
                else {
                    antMessage('error', '文件上传失败', info.file.response.message);
                }
            } else if (info.file.status === 'error') {
                antMessage('error', '文件上传失败', info.file.response.message);
            }
        },
    }
    return upload;
}

export default FilesManage;