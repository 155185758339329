import { useRef, useState } from 'react';
import UserEdit from './UserEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { Switch, Space, Button, Tooltip, Image, MenuProps, Dropdown } from 'antd';
import RoleOrUserLicense from '../license/RoleOrUserLicense';
import { columnsheadId } from '../../universal/columnshead';
import UserArea from './UserArea';
import UserBrand from './UserBrand';
import UserBranchStore from './UserBranchStore';
import UserEntrepot from './UserEntrepot';
import RoleOrUserLicenseEx from '../license/RoleOrUserLicenseEx';
import { DownOutlined } from '@ant-design/icons';

/** 用户组件 */
const WebComponent = (props: any) => {

    const [modalUserLicense, setModalUserLicense] = useState(false);
    const [userId, setUserId] = useState<string>();
    const [modalUserArea, setModalUserArea] = useState(false);
    const [modalUserBrand, setModalUserBrand] = useState(false);
    const [modalUserBranchStore, setModalUserBranchStore] = useState(false);
    const [modalUserEntrepot, setModalUserEntrepot] = useState(false);
    const [modalUserLicenseEx, setModalUserLicenseEx] = useState(false);
    const [userInfo, setUserInfo] = useState<any>();
    const ref = useRef<any>();

    /** 用户权限 */
    const handleUserLicense = (record: any) => {
        const userId = record.id;
        setUserInfo({
            roleOrUserId: userId,
            itemName: record.userName
        });
        setUserId(userId);
        setModalUserLicense(true)
    };
    /** 子组件回调函数，关闭用户权根 */
    const onCloseModalUserLicense = () => {
        setModalUserLicense(false)
    };

    /** 用户权限扩展 */
    const handleUserLicenseExpand = (record: any) => {
        const userId = record.id;
        setUserInfo({
            roleOrUserId: userId,
            itemName: record.userName
        });
        setUserId(userId);
        setModalUserLicenseEx(true)
    };
    /** 子组件回调函数，关闭用户权根扩展 */
    const onCloseModalUserLicenseEx = () => {
        setModalUserLicenseEx(false)
    };

    /** 用户地区管理 */
    const handleModalUserArea = (userId: string) => {
        setUserId(userId);
        setModalUserArea(true);
    };
    /** 子组件回调函数，关闭用户地区管理 */
    const onCloseModalModalUserArea = () => {
        setModalUserArea(false);
    };

    /** 用户品牌管理 */
    const handleModalUserBrand = (userId: string) => {
        setUserId(userId);
        setModalUserBrand(true);
    };
    /** 用户分店管理 */
    const handleModalUserBranchStore = (userId: string) => {
        setUserId(userId);
        setModalUserBranchStore(true);
    };
    /** 子组件回调函数，关闭用户品牌管理 */
    const onCloseModalModalUserBrand = () => {
        setModalUserBrand(false);
    };

    /** 子组件回调函数，关闭用户分店管理 */
    const onCloseModalModalUserBranchStore = () => {
        setModalUserBranchStore(false);
    };

    /** 用户仓库管理 */
    const handleModalUserEntrepot = (userId: string) => {
        setUserId(userId);
        setModalUserEntrepot(true);
    };
    /** 子组件回调函数，关闭用户仓库管理 */
    const onCloseModalModalUserEntrepot = () => {
        setModalUserEntrepot(false);
    };

    /**
     * 点击触发
     * @param param0 
     */
    const onManageItemsClick: MenuProps['onClick'] = ({ key }) => {
        let keyJson = JSON.parse(key);
        const { id, userId } = keyJson;
        switch (id) {
            case 'area':
                handleModalUserArea(userId);
                break;
            case 'brand':
                handleModalUserBrand(userId);
                break;
            case 'branchStore':
                handleModalUserBranchStore(userId);
                break;
            case 'entrepot':
                handleModalUserEntrepot(userId);
                break;
        }
    };


    /** 管理菜单项 */
    const manageItems = (userId: string, userName: string) => {
        const items: MenuProps['items'] = [
            {
                label: '【' + userName + '】地区管理',
                key: '{"userId":"' + userId + '","id":"area"}'
            },
            {
                label: '【' + userName + '】品牌管理',
                key: '{"userId":"' + userId + '","id":"brand"}'
            },
            {
                label: '【' + userName + '】分店管理',
                key: '{"userId":"' + userId + '","id":"branchStore"}'
            },
            {
                label: '【' + userName + '】仓库管理',
                key: '{"userId":"' + userId + '","id":"entrepot"}'
            }
        ];
        return items;
    };


    /** 表头内容 */
    const columnsHead = [
        {
            title: '用户名',
            dataIndex: 'userName',
            sorter: true,
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 6 ? text.substring(0, 6) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '用户组',
            dataIndex: 'userGroup',
            sorter: true,
            render: (text: any) => <Tooltip placement="topLeft" title={(text ? text.userGroupName : '')}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 5 ? text.substring(0, 5) + '......' : (text ? text.userGroupName : ''))
                    : ''
            }</Tooltip>
        },
        {
            title: '角色',
            dataIndex: 'roles_Name',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 6 ? text.substring(0, 6) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '联系电话',
            dataIndex: 'phone',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 5 ? text.substring(0, 5) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: "unionId",
            dataIndex: "unionId",
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: "socketOnlineId",
            dataIndex: "socketOnlineId",
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '员工',
            dataIndex: 'employees',
            render: (text: any) => <Tooltip placement="topLeft" title={(text ? text.name : "")}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : (text ? text.name : ""))
                    : ''
            }</Tooltip>
        },
        {
            title: '部门',
            dataIndex: 'employees',
            render: (text: any) => <Tooltip placement="topLeft" title={(text ? text.dept.deptName : "")}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : (text ? text.dept.deptName : ""))
                    : ''
            }</Tooltip>
        },
        {
            title: '签名',
            dataIndex: 'signatureUrl',
            render: (text: any, record: any) => (text ? <Image width={40} height={40} src={text}></Image> : null)
        },
        {
            title: '管理员',
            dataIndex: 'isAdmin',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => ref.current.handleChangeOne(record.id, 'isAdmin', e, 'user')}
                />
            )
        },
        {
            title: '权限',
            dataIndex: 'operation',
            key: 'operation',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => handleUserLicense(record)}>权限</Button>
                </Space>
        },
        {
            title: '特殊',
            dataIndex: 'userLicenseExpand',
            key: 'userLicenseExpand',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Tooltip placement="topLeft" title={'权限（特殊）扩展'}>
                        <Button size={"small"} type="primary" onClick={() => handleUserLicenseExpand(record)}>特殊</Button>
                    </Tooltip>
                </Space>
        },
        {
            title: '管理',
            dataIndex: 'userName',
            key: 'userName',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Dropdown menu={{ items: manageItems(record.id, text), onClick: onManageItemsClick }} trigger={['click']}>
                        <Space>
                            <Button size={"small"} type="dashed" >管理</Button>
                            <DownOutlined />
                        </Space>
                    </Dropdown>
                </Space >
        }
    ];

    /** 表头 */
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '用户',
        apiId: 'user',
        columns,
        showStates: true,
        pagination: true,
        EditComponent: UserEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} ref={ref} />
            {modalUserLicense ? (<RoleOrUserLicense
                closeModal={onCloseModalUserLicense}
                info={userInfo}
                useType={'userLicense'} />)
                : null}

            {modalUserArea ? (<UserArea
                closeModal={onCloseModalModalUserArea}
                userId={userId}
            ></UserArea>) : null}

            {modalUserBrand ? (<UserBrand
                closeModal={onCloseModalModalUserBrand}
                userId={userId}
            ></UserBrand>) : null}

            {modalUserBranchStore ? (<UserBranchStore
                closeModal={onCloseModalModalUserBranchStore}
                userId={userId}
            ></UserBranchStore>) : null}

            {modalUserEntrepot ? (<UserEntrepot
                closeModal={onCloseModalModalUserEntrepot}
                userId={userId}
            ></UserEntrepot>) : null}

            {modalUserLicenseEx ? (<RoleOrUserLicenseEx
                closeModal={onCloseModalUserLicenseEx}
                useType={'userLicense'}
                info={userInfo}
            ></RoleOrUserLicenseEx>) : null}

        </>
    )
}

export default WebComponent;
