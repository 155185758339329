import React, { useEffect, useRef } from 'react';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import CallWayEdit from './CallWayEdit';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
// import { Form } from 'antd';

const CallWay = (props: any) => {

    // const [form] = Form.useForm(); //form 
    const { closeModal, data } = props;
    const ref = useRef<any>();


    const handleOk = (e: any) => {
        closeModal(false);
    };

    /** api服务id */
    const apiId = 'callWay';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStore',
            render: (text: any, record: any) => {                
                // console.log("text",text);
                return (
                    <div>{record.queuingSetting.branchStore.brand.brandName + " " + record.queuingSetting.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: 'voiceName',
            dataIndex: 'voiceName',
            render: (text: any, record: any) =>
                <div>{record.aliVoice.voiceName + " (" + record.aliVoice.languageTypeName + ")"}</div>
        },
        {
            title: '音量',
            dataIndex: 'volume',
        },
        {
            title: '次数',
            dataIndex: 'times',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '叫号方式',
        apiId,
        columns,
        // showImage: true,
        // showUploadFile: true,
        showStates: true,
        pagination: true,
        EditComponent: CallWayEdit,
        sendPageData: { queuingSettingId: data.queuingSettingId }
    };

    return (
        <>
            <AntDraggableModal
                title={'叫号方式'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}            
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={1300}
            >
                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
            </AntDraggableModal>
        </>
    )
}

export default CallWay