
import { debounce } from 'lodash'
const orderCutKey = () => {

}


//键盘按下事件
orderCutKey.onKeyDown = debounce((e: any, ref: any, event?: any) => {
    if (typeof ref === "undefined") return;
    const { current } = ref;
    if (typeof current === "undefined" || !current) return

    
    const { altKey } = e;

    if (altKey) {
        switch (e.key) {

            case "a": // 新增订单数据
                ref.current.handleNew()
                break;
            // case "d": // 删除订单数据
            //     ref.current.handleDelete()
            //     break;
            case "m": // 编辑订单数据
                ref.current.handleEditEx()
                break;
            case "x": //退出
                ref.current.handleExit()
                break;
            case "p": //打印
            if (ref.current.currentRow) {
                event(ref.current.currentRow)
            }
                // if (ref.current.currentRow) {
                //     var timerEx: any = null;
                //     clearTimeout(timerEx);
                //     timerEx = setTimeout(() => {
                       
                //     }, 1000)
                // }

                break;

        }
    }

    if (typeof ref.current.maxLength === "undefined") return
    if (typeof ref.current.keyOnExpandClick === "undefined") return


    switch (e.key) {
        case "ArrowUp":
            let indexA = localStorage.getItem("num")
            let nowIndexA = Number(indexA) - 1;

            if (nowIndexA < 0) {

                nowIndexA = 0
            }
            localStorage.setItem("num", String(nowIndexA))
            ref.current.keyOnExpandClick()

            break;
        case "ArrowDown":


            let indexB = localStorage.getItem("num")
            let nowIndexB = Number(indexB) + 1;
            if (nowIndexB > Number(ref.current.maxLength)) {
                nowIndexB = Number(ref.current.maxLength)
            }
            localStorage.setItem("num", String(nowIndexB))

            ref.current.keyOnExpandClick(true)

            break;

    }

}, 500)






export default orderCutKey;