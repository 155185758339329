import React, { useEffect, useRef } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
export default function OrderBack(props: any) {
    const { data, closeModal } = props;
    /** api服务id */
    const apiId = 'orderBack';

    const ref = useRef<any>();
    const handleOk = (e: any) => {


        closeModal(true);
    };
    const onCancel = (e: any) => {
        closeModal(true);
    }
    useEffect(() => {

    }, [])

    //表头
    const columnsHead = [
        {
            title: '订单id',
            dataIndex: 'cateringOrderId',
        },
        {
            title: '退款id',
            dataIndex: 'payRefundId',
        },
        {
            title: '退款金额',
            dataIndex: 'refundAmount',
        },
        // {
        //     title: '退款原因',
        //     dataIndex: 'refundAmount',
        // },
        {
            title: '是否完成',
            dataIndex: 'isFinish',
            render: (text: any) => (text ? "是" : "否")
        },
        // {
        //     title: '查询',
        //     dataIndex: 'id',
        //     sorter: true,
        //     render: (text: any) => (
        //         <Button type='dashed' onClick={() => payOrderQuery(text)}>{'查询'}</Button>
        //     )
        // },

    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '退款详情',
        apiId,
        columns,
        sendPageData: { cateringOrderId: data?.orderId },
        showStates: true,
        pagination: true,
        showAdd: false,
        showDelete: false,
        showEdit: false,
    };


    return (
        <>
            <AntDraggableModal
                title={'退款详情'}
                open={true}
                okText='确定'
                onOk={handleOk}
                onCancel={onCancel}
                closable={false}
                maskClosable={false}
                width={1000}>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </AntDraggableModal>

        </>
    )
}
