import { Col, Form, Input, InputNumber, Row, Select } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState, } from 'react'
import systemConfig from '../../../config/systematic';
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import antMessage from '../../../utils/extend/AntdNotification'


import getList from '../../universal/getList';
import { bNumChange, chooseUnit, customizeProduct, totalPriceChange, univalenceChange } from '../../generalComponents/WebProductFormList';
// import { bNumChange, chooseUnit, customizeProduct, totalPriceChange, univalenceChange } from './PurchaseOrderEdit';


export default function ProductEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data, closeModal } = props;
    const { id, title, apiId, type,orderName } = data;



    const [isLoading, setIsLoading] = useState<boolean>(false);

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const handleOk = (e: any) => {
        setIsLoading(true)
        form.validateFields() //数据校验
            .then(async (values: any) => {
                
                switch (type) {
                    case "NEW":
                        fetchApi.POST(wmService, {
                            apiId,
                            apiData:{...values,[orderName]:id}
                        }).then(res => {

                            antMessage('success', '新增成功', 'success');
                            closeModal(true);
                        }).catch(err => {
                            antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
                        })
                        break;
                    case "EDIT":
                        fetchApi.PUT(wmService, {
                            apiId,
                            apiVariable: id,
                            apiData: values
                        }).then((res) => {
                            setIsLoading(false)
                            antMessage('success', '编辑成功', 'success');
                            closeModal(true);
                        }).catch((err => {
                            setIsLoading(false)
                            antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
                        }))

                }

            }).catch((err) => {
                setIsLoading(false)
                antMessage('warning', '无法通过数据校验', '请输入所需数据！');
            })
    };
    const handleCancel = (e: any) => {
        closeModal(false)
    };

    //获取数据
    const [row] = useState<any>(
        async () => {
            //默认获取数据
            if (id&&type==="EDIT") {
                
                const resData = await fetchApi.GET(wmService, {
                    apiId,
                    apiVariable: id,
                    apiExtend: "showSingle"
                });
                return resData;
            }
        });

    /**商品数据 */
    const [productData, setProductData] = useState<any>()
    useEffect(() => {

        const fetchData = async () => {
            httpGet(wmService, {
                apiId: "product",
                apiExtend: 'showList'
            }).then(res => {

                setProductData(res.data)
            })
        }
        fetchData()
    }, [])


    useEffect(() => {
        if (type !== "NEW") {
            row.then((res: any) => {
                if (res.success === true) {

                    form.setFieldsValue({
                        ...res.data,
                        productUnitId: res.data.product.productUnit.productUnitName
                    })
                }
            })
        }
    }, [])






    return (
        <>
            <AntDraggableModal
                confirmLoading={isLoading}
                title={(data.type === 'NEW' ? '新增' : '编辑') + `${title}`}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row >

                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="产品"
                                name="productId"
                                rules={[{ required: true, message: '请输入产品!' }]}
                            >
                                <Select
                                    disabled={type === "NEW" ? false : true}
                                    optionLabelProp="label"
                                    style={{ minWidth: 150 }}
                                    dropdownMatchSelectWidth={false}
                                    showSearch
                                    onChange={(value: any, option: any) => chooseUnit(form, value, option)}
                                    onSearch={(e) => getList.search('product', setProductData, e)}
                                    placeholder="产品"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                                >

                                    {getList.generalOption(productData,{
                                         itemId: "id",noId: false, label:"productName", children:customizeProduct
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="单位"
                                name='productUnitId'
                            >
                                <Input disabled placeholder="单位" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="规格"
                                name='productSpecifications'


                            >
                                <Input placeholder="规格" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="数量"
                                name='bNum'
                                rules={[{ required: true, message: '请输入数量' }]}
                            >
                                <InputNumber onChange={(e) => bNumChange(form, e)} placeholder="数量" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="单价"
                                name="univalence"
                                rules={[{ required: true, message: '请输入单价' }]}
                            >
                                <InputNumber onChange={(e) => univalenceChange(form, e)} min={0} placeholder="单价" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="总价格"
                                name="totalPrice"
                                rules={[{ required: true, message: '总价格' }]}
                            >
                                <InputNumber onChange={(e) => totalPriceChange(form, e)} min={0} placeholder="总价格" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="memo"
                            >
                                <Input min={0} placeholder="备注" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>

        </>
    )
}


