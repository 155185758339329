import systemConfig from '../../../../config/systematic';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import getList from '../../../universal/getList';
import fetchApi from '../../../../utils/api/fetchApi';
import dayjs from 'dayjs';
import { handleData } from './DailyReport';
import antMessage from '../../../../utils/extend/AntdNotification'
import DownloadExcel from '../../../report/DownloadExcel';



const { RangePicker } = DatePicker;

export default function EnPurStatistics() {
  const initialValues: any = {
    chooseTime: [dayjs(), dayjs()],
  };
  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const [form] = Form.useForm();

  const [user, setUser] = useState<any>("")
  const [deptData, setDeptData] = useState<any>();
  const [buyerData, setBuyerData] = useState<any>()
 const [classificationWarehouseData,setClassificationWarehouseData]=useState<any>();
  const [queryCriteria, setQueryCriteria] = useState<any>("")

  const [rows, setRows] = useState<any>([]);

  useEffect(() => {
    const fetchData = async () => {
      let deptData = await fetchApi.GET(wmService, {
        apiId: "dept",
        apiExtend: 'showList',
        apiData:{
                    
        }
      })
      if (deptData.success) {
        setDeptData(deptData.data)
      }

      let buyerData = await fetchApi.GET(wmService, {
        apiId: "buyer",
        apiExtend: 'showList'
      })

      if (buyerData.success) {
        setBuyerData(buyerData.data)
      }

      let classificationWarehouseData = await fetchApi.GET(wmService, {
        apiId: "classificationWarehouse",
        apiExtend: 'showList'
      })

      if (classificationWarehouseData.success) {
        setClassificationWarehouseData(classificationWarehouseData.data)
      }

      // 
    }
    fetchData()
  }, [])
  /** 表头内容 */
  let columnsHead: any = [
    {
      title: "品号",
      dataIndex: "selfId",
    },
    {
      title: "品名",
      dataIndex: "productName",
    },
    {
      title: "分类",
      dataIndex: "classificationWarehouseName",
    },
    {
      title: "单位",
      dataIndex: "productUnitName",
    },

    {
      title: "数量",
      dataIndex: "bNum",
    },
    {
      title: "均价",
      dataIndex: "univalence",
    },
    {
      title: "金额",
      dataIndex: "amount",
    },


  ]
  const onFinish = () => {
    form.validateFields()
      .then(async (values: any) => {
       
          
        fetchApi.GET(wmService, {
          apiId: "entrepotReport",
          apiExtend: 'enPurStatistics',
          apiData: {
            deptIds: handleData(values.deptIds, "deptId"),
            time: values.chooseTime ? {
              startTime: dayjs(values.chooseTime[0]).format("YYYY-MM-DD"),
              endTime: dayjs(values.chooseTime[1]).format("YYYY-MM-DD"),
            } : null,
            buyers: handleData(values.buyers, "buyerId"),
            selfs: values.selfStart && values.selfEnd ? {
              selfStart: values.selfStart,
              selfEnd: values.selfEnd
            } : null,
            classificationWarehouses: handleData(values.classificationWarehouses, "classificationWarehouseId"),
          }
          // classificationWarehouseId
        }).then((res: any) => {
          
          setRows(res.data.rows)
          setQueryCriteria(res.data.queryCriteria)
          setUser(res.data.user)
          antMessage('success', '搜索成功', 'success');

        }).catch((err: any) => {
          console.log("err", err);

          antMessage('error', '搜索失败', err.message + ' | status：' + err.errors.status);
        })
      })
      .catch((err) => {
        antMessage('warning', '无法通过数据校验', '请输入所需数据！');
      })





  }
  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="部门"
              name="deptIds"
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="请选择部门!"
                optionFilterProp="children"

                onSearch={(e) => getList.search('dept', setDeptData, e)}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(deptData, 'deptName')}
              </Select>
            </Form.Item>
          </Col>


          <Col span={12}>
            <Form.Item
              label="采购商"
              name="buyers"
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="采购商"
                optionFilterProp="children"
                onSearch={(e) => getList.search('buyer', setBuyerData, e)}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(buyerData, 'buyerName')}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="品号"
              name="selfStart"
            >
              <Input
              // onChange={handleChange}
              // onBlur={handleBlur}
              // placeholder="Input a number"
              />
            </Form.Item>
          </Col>

          
          <Col span={4}>
            <Form.Item
              colon={false}
              label="-"
              name="selfEnd"
            >
              <Input
              // onChange={handleChange}
              // onBlur={handleBlur}
              // placeholder="Input a number"
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="品类"
              name="classificationWarehouses"
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="品类"
                optionFilterProp="children"
                onSearch={(e) => getList.search('classificationWarehouse', setClassificationWarehouseData, e)}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(classificationWarehouseData, 'classificationWarehouseName')}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="chooseTime"
              initialValue={initialValues.chooseTime}
            >
              <RangePicker
                // defaultValue={initialValues.chooseTime}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item >
              <Space>
                <Button type="primary" htmlType="submit">
                  统计
                </Button >
                <Button onClick={() => DownloadExcel(rows, "enPurStatistics", queryCriteria)}>下载EXCEL文件</Button>
                {/* <Button onClick={() => entrepotPrint.printEnReport(rows, "entrepotDailyReportPrint", queryCriteria, user)} >
                  打印
                </Button> */}
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div style={{ marginTop: "20px" }}>
        <Table
          scroll={{ x: "100%", y: 650 }}
          pagination={false}
          rowKey={(record: any, index: any) => {
            return index
          }}
          columns={columnsHead}
          dataSource={rows}

        >
        </Table>

      </div>
    </>
  )
}
