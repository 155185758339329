import React, { useEffect, useRef, useState } from 'react';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import MenuWarehouseArticleEdit from './MenuWarehouseArticleEdit';
import { Button, Col, Form, Row, Select, Switch } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification';

const MenuWarehouseArticle = (props: any) => {

    const ref = useRef<any>();
    const [form] = Form.useForm(); //form

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const { closeModal, data } = props;

    /**仓库菜式文章大分类数据 */
    const [menuWarehouseArticleCategoryData, setMenuWarehouseArticleCategoryData] = useState<any>();

    /**仓库菜式文章小分类数据 */
    const [menuWarehouseArticleTypeData, setMenuWarehouseArticleTypeData] = useState<any>();

    /**记录选择前的仓库菜式文章大分类 */
    const [defaultArticleCategoryId, setDefaultArticleCategoryId] = useState<any>();

    /**文章小分类的状态 */
    const [articleDisable, setArticleDisable] = useState<any>(false);

    /** api服务id */
    const apiId = 'menuWarehouseArticle';

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {

            const menuWarehouseArticleTypeId = data.menuWarehouseArticleTypeId;

            if (menuWarehouseArticleTypeId) {
                fetchApi.GET(wmService, {
                    apiId: 'menuWarehouseArticleType',
                    apiVariable: menuWarehouseArticleTypeId
                }).then(res => {
                    if (res.success) {
                        form.setFieldsValue({ menuWarehouseArticleCategoryId: res.data.menuWarehouseArticleCategoryId });
                        form.setFieldsValue({ menuWarehouseArticleTypeId: data.menuWarehouseArticleTypeId });
                    }
                })
            }

            fetchApi.GET(wmService, {
                apiId: "menuWarehouseArticleType",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseArticleTypeData(res.data)
                }
            });

            fetchApi.GET(wmService, {
                apiId: "menuWarehouseArticleCategory",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseArticleCategoryData(res.data)
                }
            });

        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const handleOk = () => {
        form.validateFields()
            .then(async (values: any) => {
                fetchApi.GET(wmService, {
                    apiId: "menuWarehouseArticle",
                    apiExtend: 'updateMenuWarehouse',
                    apiData: {
                        ...values,
                        menuWarehouseId: data.id
                    }
                }).then((res) => {
                    if (res.success) {
                        antMessage('success', '修改成功', '修改成功！');
                    }
                }).catch((err) => {
                    antMessage("error", '错误', err);
                })
            })
            .catch((err) => {
                antMessage('warning', '无法通过数据校验', '请输入所需数据！');
            })
    }

    const getDefaultArticleCategoryToTypeData = (e: any) => {
        fetchApi.GET(wmService, {
            apiId: "menuWarehouseArticleCategory",
            apiExtend: 'getArticleTypeList',
            apiVariable: form.getFieldValue('menuWarehouseArticleCategoryId'),
        }).then(res => {
            if (res.success) {
                setMenuWarehouseArticleTypeData(res.data);
            }
        });
    }

    const getDefaultArticleCategoryData = (e: any) => {
        setDefaultArticleCategoryId(form.getFieldValue('menuWarehouseArticleCategoryId'));
    }

    const handleChange = (e: any) => {
        setArticleDisable(!e);
        // form.setFieldsValue({ menuWarehouseArticleCategoryId: "" });
        // form.setFieldsValue({ menuWarehouseArticleTypeId: "" });
    }

    const getArticleCategoryToTypeData = (menuWarehouseArticleCategoryId: string) => {

        if (menuWarehouseArticleCategoryId !== defaultArticleCategoryId) {
            form.setFieldsValue({ menuWarehouseArticleTypeId: "" });
        }

        fetchApi.GET(wmService, {
            apiId: "menuWarehouseArticleCategory",
            apiExtend: 'getArticleTypeList',
            apiVariable: menuWarehouseArticleCategoryId,
        }).then(res => {
            if (res.success) {
                setMenuWarehouseArticleTypeData(res.data)
            }
        });
    }

    //表头
    const columnsHead = [
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) =>
                <div>{record.menuWarehouse.foodName}</div>
        },
        {
            title: '文章标题',
            dataIndex: 'articleTitle',
        },
        {
            title: '文章链接',
            dataIndex: 'articleLink',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    // const handleOk = (status: Boolean) => {
    //     closeModal(false);
    //     if (status === true) {
    //         getData(true);
    //     }
    // };

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式仓库文章',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: MenuWarehouseArticleEdit,
        sendPageData: { menuWarehouseId: data.id }
    };

    return (
        <>
            <AntDraggableModal
                title={'仓库菜式文章'}
                open={true}
                okText='关闭'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={() => closeModal(true)}
                closable={false}
                maskClosable={false}
                width={1100}
            >
                <Form
                    form={form}
                    name="Edit"
                >
                    <Row gutter={[16, 0]}>
                        <Col span={3}>
                            <Form.Item
                                label="显示文章"
                                name="isShowArticle"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    onChange={handleChange}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="仓库菜式文章大分类"
                                name="menuWarehouseArticleCategoryId"
                            >
                                <Select
                                    showSearch
                                    disabled={articleDisable}
                                    placeholder="仓库菜式文章大分类"
                                    optionFilterProp="children"
                                    onSelect={getArticleCategoryToTypeData}
                                    onFocus={getDefaultArticleCategoryData}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(menuWarehouseArticleCategoryData, {
                                        itemName: "menuWarehouseArticleCategoryName",
                                        includeNull: true
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="仓库菜式文章小分类"
                                name="menuWarehouseArticleTypeId"
                            >
                                <Select
                                    disabled={articleDisable}
                                    showSearch
                                    placeholder="仓库菜式文章小分类"
                                    optionFilterProp="children"
                                    onMouseEnter={getDefaultArticleCategoryToTypeData}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(menuWarehouseArticleTypeData, {
                                        itemName: "menuWarehouseArticleTypeName",
                                        includeNull: true
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item
                                name="submit">
                                <Button size={"small"} type="primary" onClick={handleOk}>提交</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <br></br>
                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
            </AntDraggableModal >
        </>
    )
}

export default MenuWarehouseArticle