import { Form, Input, Switch, Select, Row, Col } from 'antd';
import React, { useRef, useEffect, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const [appPlatformData, setAppPlatformData] = useState<any>();
    const [appArchData, setAppArchData] = useState<any>();
    /** 后端数据wmService */
    let wmService: any = '';
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'appPlatform@appArch',
                    apiExtend: 'showLists'
                }).then(res => {
                    if (res.success) {
                        const { appPlatform, appArch } = res.data
                        setAppPlatformData(appPlatform);
                        setAppArchData(appArch)
                    }
                });
        };
        fetchData();
    }, []);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'appVersionManage',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + 'app版本管理'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="ID"
                                name="id"
                                tooltip='appId，例如：wdm_win32_x64'
                                rules={[{ required: true, message: '请输入appId!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="名称"
                                name="appName"
                                tooltip='app应用名称'
                                rules={[{ required: true, message: '请输入app名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="平台"
                                name="appPlatformId">
                                <Select
                                    showSearch
                                    placeholder="选择平台"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(appPlatformData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="架构"
                                name="appArchId">
                                <Select
                                    showSearch
                                    placeholder="选择架构"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(appArchData)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="版本号"
                                name="appVersion"
                                tooltip='app版本号，例如：1.0.3'
                                rules={[{ required: true, message: '请输入版本号!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="更新文件地址"
                                name="updateFileUrl"
                                tooltip='更新文件所在的服务器地址'
                                rules={[{ required: true, message: '请输入更新文件地址!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={13}>
                            <Form.Item
                                label="备注"
                                name="memos">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="必需更新"
                                name="isNeedUpdated"
                                tooltip='如果app必需更新才能使用，请选择此项'
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;