import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import SetMealEdit from './SetMealEdit';

const SetMeal = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '所属分店',
            dataIndex: 'branchStoreName',
        },
        {
            title: '套餐 / 菜单名称',
            dataIndex: 'setMealName',
        },
        {
            title: '顾客姓名',
            dataIndex: 'userName',
        },
        {
            title: '联系方式',
            dataIndex: 'telephone',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '套餐 / 菜单',
        apiId: 'setMeal',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: SetMealEdit,
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}

export default SetMeal