import React, { useEffect, useState } from 'react';
import { Transfer, Typography } from 'antd';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import system from '../../config/systematic'
import fetchApi, { httpGet, httpPut } from '../../utils/api/fetchApi';
import antMessage from '../../utils/extend/AntdNotification'


const ProductTransfer = (props: any) => {
    /**后端数据vmService */
    const wmService = system.wmService

    const { closeModal, data } = props;
    console.log("data",data);
    
    const [loadingData, setLoadingData] = useState<any>(false);
    const [keys, setKeys] = useState<any>([]);

    // const [allKeys, setAllKeys] = useState<any>([]);

    const [leftInitKeys, setLeftInitKeys] = useState<Array<any>>([]);
    const [rightInitKeys, setRightInitKeys] = useState<Array<any>>([]);

    const [rows, setRows] = useState<Array<any>>([]);
    useEffect(() => {
        /**查找部门名称 */
        httpGet(wmService, {
            apiId: "product",
            apiExtend: 'showMenu',
             apiData:{
                productHeadId:data.productHeadId
             }
        }).then(res => {
            
            if (res.success) {
                //目标数据的keys数组
                const targetKeys = [];
                //待拉去的产品数据
                const productData = [];

                //左边数据keys数组;
                const leftKeys = [];
             

                for (let j = 0; j < res.data.length; j++) {
                    // allKeys.push(res.data[j].id)
                    const newData = {
                        key: res.data[j].id.toString(),
                        id: res.data[j].id,
                        status: "origin",
                        direction: "left",
                        productName: res.data[j].productName,
                        productUnitName: res.data[j].productUnit.productUnitName,

                        chosen: res.data[j].isMenu,
                    };
                    if (newData.chosen) {
                        newData.direction = "right"
                        targetKeys.push(newData.key);
                    } else {
                        leftKeys.push(newData.key)
                    }
                    productData.push(newData);
                }
                // setAllKeys(allKeys)
                setKeys(targetKeys);
                setRows(productData)
                setRightInitKeys(targetKeys)
                setLeftInitKeys(leftKeys)
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleOk = (e: any) => {
        setLoadingData(true);
        let keys:Array<any>=[];
        let leftKeys:Array<any>=[];

        rows.forEach((item) => {
            if(item.status === "update"){
                
                   if(item.direction === "right"){
                    keys.push(item)
                   }
                   if(item.direction === "left"){
                    leftKeys.push(item.id)
                   }

            }
            //  && item.direction === "right")
        });
 
        fetchApi.POST(wmService, {
            apiId: "deptProduct",
            apiExtend: 'menuAdd',
            apiData: {
                keys,
                leftKeys,
                productHeadId: data.productHeadId

            }
        }).then((res: any) => {
            setLoadingData(false)
            if (res.success) {
                antMessage('success', '添加成功', 'success');
                closeModal(true)
            }

        }).catch((err:any)=>{
            setLoadingData(false)
        })




    };

    const handleCancel = (e: any) => {
        closeModal();
    };


    const onChange = (keys: any, direction: any, moveKeys: any) => {
        let newArray = rows.map(item => {
            if (direction === "left") {
                if (moveKeys.includes(item.id)) {
                    if (leftInitKeys.includes(item.id)) {
                        return { ...item, direction, status: "origin" };
                    } else {
                        return { ...item, direction, status: "update" };
                    }
                } else {
                    return {
                        ...item
                    };
                }
            }
            if (direction === "right") {
                if (moveKeys.includes(item.id)) {
                    if (rightInitKeys.includes(item.id)) {
                        return { ...item, direction, status: "origin" };
                    } else {
                        return { ...item, direction, status: "update" };
                    }
                } else {
                    return {
                        ...item
                    };
                }
            }
        });
        setRows(newArray)
        setKeys(keys);

    };

    return (
        <>
            <AntDraggableModal
                title={'产品管理'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}
                confirmLoading={loadingData}
            >
                <Transfer
                    dataSource={rows}
                    titles={['仓库产品', "部门产品"]}
                    showSearch
                    targetKeys={keys}
                    onChange={onChange}
                    render={item => `${item.productName}  ${item.productUnitName}`}
                    pagination
                />

            </AntDraggableModal>

        </>

    );
};

export default ProductTransfer;