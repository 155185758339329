import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import ApplyPurchaseEdit from './ApplyPurchaseEdit';
import { Button, DatePicker, Select, Space, Tooltip } from 'antd';
import moment from 'moment';
import systemConfig from '../../../config/systematic';

// import ProductEdit from './ProductEdit';
import { useEffect, useRef, useState } from 'react';
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import dayjs from 'dayjs';
export default function ApplyPurchase(props: any) {
    const ref = useRef<any>();
    const apiId = "applyPurchase";
    let wmService: any = systemConfig.wmService;
    const [orderDate, setOrderDate] = useState<any>();
  

  const [deptData,setDeptData]=useState<any>();

  const [selectDeptIds,setSelectDeptIds]=useState<Array<any>>([])

    const [selectAPState, setSelectAPState] = useState<any>();

    const [aPState, setAPState] = useState<any>()
    //表头
    const columnsHead = [
      

        {
            title: "日期",
            dataIndex: "sysDate",
            align: "center",
            render: (val: any, record: any) =>
                <span>
                    {moment(val.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>,

        },
        {
            title: '流程',
            dataIndex: 'aPStateName',
            align: "center",
            // width: 90,
        },
        {
            title: '部门',
            dataIndex: 'deptName',
            align: "center",
        },
        {
            title: '申购人',
            dataIndex: 'userName',
            align: "center",
        },


    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    /** 子表头内容 */
    const expandableColumnsHead: Array<any> = [
        {
            title: "id",
            dataIndex: "id",
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 10 ? text.substring(0, 10) + '......' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: "商品名称",
            dataIndex: "productSpec",
            render: (text: any) => (text ? text.product.productName : "")
        },
        {
            title: "规格",
            dataIndex: "productSpec",
            render: (text: any) => (text ? text.productSpecName : "")
        },
     
        {
            title: "数量",
            dataIndex: "bNum"
        },

        {
            title: "备注",
            dataIndex: "memo"
        },

    ]
    let initData: any = ref.current?.initData;
    useEffect(() => {



        if (ref.current) {


            wmService = ref.current.wmService;
            initData = ref.current.initData;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'aPState',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setAPState(res.data)
                    }
                })

                fetchApi.GET(wmService,
                    {
                        apiId: 'dept',
                        apiExtend: 'showList'
                    }).then(res => {
                        if (res.success) {
                            setDeptData(res.data)
                        }
                    })

        }
        fetchData()

    }, [])
   


    /** 子表头 */
    const expandableColumns = expandableColumnsHead;
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '申购单',
        apiId,
        // showStates: true,
        columns,
        pagination: true,
        EditComponent: ApplyPurchaseEdit,
        sendPageData: { aPState: selectAPState,orderDate,deptIds:selectDeptIds },
        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'applyPurchaseProducts',
    };
    const aPStateChange = (e: any) => {

        setSelectAPState(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { aPState: e,orderDate,deptIds:selectDeptIds }
        }
        ref.current.initData(currentData);
    }
     //根据日期回显数据
     const handleDate = (e: any) => {
        let orderDateEx;
        if (e) {
            orderDateEx = dayjs(e).format("YYYY-MM-DD")
            setOrderDate(orderDateEx)

        }
        const currentData: initDataType = {
            apiId,
            sendPageData: { aPState:selectAPState, orderDate: orderDateEx,deptIds:selectDeptIds}
        }

        ref.current.initData(currentData);
    }

    const deptChange=(e:any)=>{
        setSelectDeptIds(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { aPState:selectAPState, orderDate,deptIds:e}
        }

        ref.current.initData(currentData);
          
    }
    return (
        <>
        <Space>
            {/* <Button onClick={aa}>菜品处理</Button> */}
            <label>流程 :  </label>
            <Select
            allowClear
                showSearch
                onChange={aPStateChange}
                popupMatchSelectWidth={150}
                placeholder="状态"
                optionFilterProp="children"
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                options={getList.generalSelcetOption(aPState, "name")}
            >
            </Select>
            <label>部门 :  </label>
            <Select
                allowClear
                showSearch
                style={{width:300}}
                mode="multiple"
                onChange={deptChange}
                filterOption={(input, option) =>
                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                }
                placeholder="部门"
                options={getList.generalSelcetOption(deptData,"deptName")}
            >
            </Select>
            <label>日期 :  </label>
            <DatePicker
                allowClear
                // defaultValue={moment(data.bookingDate)}
                format="YYYY-MM-DD"
                onChange={handleDate}
            />
            </Space>
            <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>

            {/* {modalProductOpen ? (<ProductEdit data={productData} closeModal={onCloseProductOpen}
            />)
                : null} */}
        </>

    )
}
