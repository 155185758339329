import { Card, List } from 'antd'

export default function TempRoomList(props: any) {
    const { listObj } = props.props;
 

    return (
        <List
            {
            ...listObj

            }
        />
    )
}
