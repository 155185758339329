import { Col, DatePicker, Form, Input, Row, Select } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useRef, useState, } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';

export default function AccountantEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();
    /** 后端数据Service */
    let wmService: any = "";
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'accountant',
        sendPageData: { entrepotId: data.sendPageData.entrepotId },
        dateColumnName: ["startData", "endData"]
    };
    useEffect(() => {
         

        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
        //    if(data.type!=="NEW"){
            
        //     form.setFieldsValue({endData:dayjs()})
        //    }
        }
        fetchData()
    }, [])

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '会计'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>


                        <Col span={12}>
                            <Form.Item
                                label="期初日期"
                                name="startData"
                                rules={[{ required: true, message: '请输入期初日期!' }]}>
                                <DatePicker
                                    format="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="期末日期"
                                name="endData"
                                
                                rules={[{ required: false, message: '请输入期末日期!' }]}>
                                <DatePicker

                                    disabled={data.type === 'NEW' ? true : false}
                                    format="YYYY-MM-DD"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>

        </>
    )
}
