import './login.css';
import { Form, Input, Button, Checkbox, Modal, Space } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification';
import system from '../../../config/systematic';
import { useNavigate } from 'react-router-dom'
import {  useContext, useState } from 'react';
import {  SocketContext, wsConnect } from '../../../App';

// import jwt from "jsonwebtoken";
//组件 Login 
const Login = (props: any) => {
    const navigator = useNavigate()
    const [form] = Form.useForm(); //form 
    //判断是否选中
    // const [ischecked, setIsChecked] = useState<boolean>(true)
    /** 后端数据wmService */
    let wmService: any = system.wmService;
    // const useContextData = useContext(SocketContext);

    // const { wsServer } = useContextData;
    
    const [deviceId, setDeviceId] = useState<any>(localStorage.getItem("deviceId"))
    const [deviceCode, setDeviceCode] = useState<any>(localStorage.getItem("deviceCode"))

    // const { contextData,setContextData } = useContextData;
    const onFinish = async (e: any) => {
        form.validateFields()
            .then(async (values: any) => {

                let dataEx = {
                    apiId: 'login',
                    apiExtend: 'login',
                    apiData: {
                        logId: values.logId,
                        logPass: values.logPass,
                        deviceId,
                        deviceCode
                    }
                }
                fetchApi.GET(wmService,
                    dataEx).then(res => {
                        if (res.success === true) {
                            sessionStorage.setItem('branchStoreName', res.data.branchStoreName);
                            sessionStorage.setItem('isAdmin', res.data.isAdmin);
                            sessionStorage.setItem('token', res.data.jwtSign.token);
                            sessionStorage.setItem('areaId', res.data.areaId);
                            sessionStorage.setItem('userId', res.data.userId);
                            sessionStorage.setItem('userName', res.data.userName);
                            sessionStorage.setItem('branchStoreId', res.data.branchStoreId);
                            sessionStorage.setItem('deptId', res.data.deptId);
                            sessionStorage.setItem('brandId', res.data.brandId);
                            sessionStorage.setItem('brandName', res.data.brandName);

                            navigator("/")

                            localStorage.setItem('num', "0");
                            //wsServer
                            wsConnect('tokenLogin', { token: res.data.jwtSign.token });

                        }
                        else {
                            antMessage('error', '登录失败', '');
                        }
                    }).catch(err => {
                        antMessage('error', '登录失败', err.message + ' status:' + err.errors.status);
                    })
            })
            .catch(err=>{

                antMessage('warning', '无法通过数据校验', '请输入所需数据！');
            })
    };


    // const getDeviceId = () => {
    //     fetchApi.POST(wmService, {
    //         apiId: "deviceManagement",
    //         apiExtend: "register",
    //         apiVariable:deviceId,
    //         apiData: {
    //             deviceCode,
    //             isRequiresDeviceCode: true //是否返回设备码
    //         }
    //     }).then((res) => {
            
    //         setDeviceId(res.data.id)
    //         setDeviceCode(res.data.deviceCode)
    //         localStorage.setItem("deviceId", res.data.id)
    //         localStorage.setItem("deviceCode", res.data.deviceCode)
    //     })
    // }
    return (
        <>

            <Modal
                title='登录'
                open={true}
                footer={<>
                    <Space>
                        {/* <Button disabled={deviceId?false:true} onClick={onFinish}>登录</Button> */}
                        <Button onClick={onFinish}>登录</Button>
                        {/* {deviceId ? null : <Button onClick={getDeviceId}>获取设备码</Button>} */}
                    </Space>
                </>}
                keyboard
                //    onOk={onFinish}
                closable={false}
                maskClosable={false}
                width={400}
                centered
            >
                <Form
                    form={form}
                    name="normal_login"
                    className="login-form"
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') { onFinish(e) }
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="logId"
                        label="账号"
                        rules={[
                            {
                                required: true,
                                message: '请输入登录ID!',
                            },
                        ]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="登录ID" />
                    </Form.Item>
                    <Form.Item
                        name="logPass"
                        label="密码"
                        rules={[
                            {
                                required: true,
                                message: '请输入密码!',
                            },
                        ]}
                    >
                        <Input
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="密码"
                        />
                    </Form.Item>
                  


                    {/* <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            登 录
                        </Button>
                    
                    </Form.Item> */}
                </Form>


            </Modal>
        </>

    );
}

export default Login;