import UserAndUserGroupManageComponent, { UserAndUserGroupManagePropsType } from './UserAndUserGroupManageComponent';

const CustomComponent = (props: any) => {
    const { userGroupId, closeModal } = props;
    const apiId = 'userGroupEntrepot';
    //表头
    const columnsHead = [
        {
            title: '用户组',
            dataIndex: 'userGroup',
            render: (text: any) => (typeof text !== "undefined" && text !== null ?
                text.userGroupName :
                '')
        },
        {
            title: '仓库',
            dataIndex: 'entrepot',
            render: (text: any) => (typeof text !== "undefined" && text !== null ?
                text.entrepotName :
                '')
        }
    ];
    let extendProps: UserAndUserGroupManagePropsType = {
        mode: 'userGroup',
        titleName: '用户组仓库管理',
        apiId,
        userOrUserGroupId: userGroupId,
        columnsHead,
        closeModal,
        options: {
            showDefault: true
        }
    }

    return (
        <>
            <UserAndUserGroupManageComponent extendData={extendProps} ></UserAndUserGroupManageComponent>
        </>
    )
}

export default CustomComponent;