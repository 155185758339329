import { Button, Form, Input, InputNumber, Select, Space, Switch, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import { useLocation } from 'react-router';
import getList from '../../universal/getList';

const PrinterEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const { state } = useLocation();

    const [printerData, setPrinterData] = useState<Array<any>>([])


    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    if (data.type === "EDIT") {
                        let ipAddressRow = res.data.ipAddress.split('.');
                        for (var i = 0; i < ipAddressRow.length; i++) {
                            let str = "ipAddress_" + i
                            form.setFieldValue([str], ipAddressRow[i]);
                        }
                    }
                }
            })
        }
        const fetchData = async () => {

        }
        if (state) {
            let { printerList } = state as any;
            if (printerList) setPrinterData(printerList)
        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'printer',
        mountDataOptions: {
            userMount: {
                isDept: true,
            }
        },
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '打印机'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={600}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="打印机名称"
                        name="printerName"
                        rules={[{ required: true, message: '请输入打印机名称!' }]}>
                        <Input />
                    </Form.Item>



                    {/* <Form.Item
                        label="window打印机名称"
                        name="windowPrinterName"
                        rules={[{ required: true, message: '请输入window打印机名称!' }]}>
                        <Input />



                    </Form.Item> */}


                    <Form.Item
                        label="windows打印机名称"
                        name="windowsPrinterName"
                        rules={[{ required: true, message: '请输入windows打印机名称!' }]}>
                        <Select
                            showSearch
                            placeholder="请输入windows打印机名称"
                            optionFilterProp="children"
                        >
                            {getList.general(printerData, undefined, undefined, true, false)}
                        </Select>

                    </Form.Item>



                    <Form.Item label="IP地址" >
                        <Form.Item
                            name="ipAddress_0"
                            rules={[{ required: true, message: '请输入IP地址!' }]}
                            style={{ display: 'inline-block' }}
                        >
                            <InputNumber min={0} max={255} />
                        </Form.Item>
                        &nbsp;
                        .
                        &nbsp;
                        <Form.Item
                            name="ipAddress_1"
                            rules={[{ required: true, message: '请输入IP地址!' }]}
                            style={{ display: 'inline-block' }}
                        >
                            <InputNumber min={0} max={255} />
                        </Form.Item>
                        &nbsp;
                        .
                        &nbsp;
                        <Form.Item
                            name="ipAddress_2"
                            rules={[{ required: true, message: '请输入IP地址!' }]}
                            style={{ display: 'inline-block' }}
                        >
                            <InputNumber min={0} max={255} />
                        </Form.Item>
                        &nbsp;
                        .
                        &nbsp;
                        <Form.Item
                            name="ipAddress_3"
                            rules={[{ required: true, message: '请输入IP地址!' }]}
                            style={{ display: 'inline-block' }}
                        >
                            <InputNumber min={0} max={255} />
                        </Form.Item>
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        < Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                    <Form.Item
                        label="排序"
                        name="sortBy">
                        <InputNumber min={1} />
                    </Form.Item>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default PrinterEdit;