import ClientUserEdit from './ClientUserEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { getColumnsheadId } from '../../universal/columnshead';

const WebWxUser = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '昵称',
            dataIndex: 'nickName',
            sorter: true,
        },
        {
            title: '手机号码',
            dataIndex: 'mobile',
        },
        {
            title: '同时在线人数',
            dataIndex: 'sameTimeOnlines',
        },
        {
            title: '关联用户',
            dataIndex: 'user',
            render:(text:any)=>(text?text.userName:"")
        },
    ];

    const columns = getColumnsheadId('客户', 'unionId')
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '客户',
        apiId: 'clientUser',
        columns,
        showStates: true,
        primaryKeyName: 'unionId',
        pagination: true,
        showAdd: false,
        EditComponent: ClientUserEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default WebWxUser;
