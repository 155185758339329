import { Form, Input, InputNumber, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
export default function MenuWarehouseArticleEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    /** 菜式仓库数据 */
    const [menuWarehouseData, setMenuWarehouseData] = useState<any>();

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            form.setFieldsValue({ menuWarehouseId: data.sendPageData.menuWarehouseId });

            fetchApi.GET(wmService, {
                apiId: 'menuWarehouse',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseData(res.data);
                }
            });
        }
        fetchData()
    }, [])

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuWarehouseArticle'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '仓库菜式文章'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="菜式名称"
                        name="menuWarehouseId">
                        <Select
                            disabled
                            showSearch
                            placeholder="菜式名称"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(menuWarehouseData, 'foodName')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="文章标题"
                        name="articleTitle"
                        rules={[{ required: true, message: '请输入文章标题!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="文章链接"
                        name="articleLink"
                        rules={[{ required: true, message: '请输入文章链接!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="排序"
                        name="sortBy">
                        <InputNumber min={1} />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        <Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                </Form>
            </AntDraggableModal>
        </>
    )
}