import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import TextArea from 'antd/es/input/TextArea';
import WebSetMealCategoryFormList from '../../generalComponents/WebSetMealCategoryFormList';

export default function SetMealEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const [setMealTypeData, setSetMealTypeData] = useState<any>();
    const [appellationData, setAppellationData] = useState<any>();
    const [productUnitData, setProductUnitData] = useState<any>();
    const [banquetData, setBanquetData] = useState<any>();
    const [roomData, setRoomData] = useState<any>();
    const [sbData, setSbData] = useState<any>();
    const [setMealData, setSetMealData] = useState<any>();

    const [categoryFormList, setCategoryFormList] = useState<any>({ categoryForm: form, categoryType: data.type, categoryRef: ref, setSetMealData });

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'setMealType',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setSetMealTypeData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: "appellation",
            }).then(res => {
                if (res.success) {
                    setAppellationData(res.data)
                }
            });

            fetchApi.GET(wmService, {
                apiId: "banquet",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setBanquetData(res.data)
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'room',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setRoomData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'productUnit',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setProductUnitData(res.data);
                }
            });
        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'setMeal'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '套餐 / 菜单分类'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={1200}
            >
                <Form
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row>
                        <Col span={6}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="套餐 / 菜单名称"
                                name="setMealName"
                                rules={[{ required: true, message: '请输入套餐 / 菜单名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="类型"
                                name="setMealTypeId"
                                rules={[{ required: true, message: '请选择类型!' }]}>
                                <Select
                                    showSearch
                                    placeholder="类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(setMealTypeData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="宴会类型"
                                name="banquetId"
                                rules={[{ required: true, message: '请选择宴会类型!' }]}>
                                <Select
                                    showSearch
                                    placeholder="宴会类型"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(banquetData, 'banquetName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="副标题"
                                name="subtitle">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="顾客姓名"
                                name="username">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="称呼"
                                name="appellationId"
                                rules={[{ message: '请选择称呼!', required: true }]}>
                                <Select
                                    showSearch
                                    placeholder="选择称呼"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(appellationData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="手机号码"
                                name="telephone">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="房间号"
                                name="roomId">
                                <Select
                                    disabled
                                    showSearch
                                    placeholder="选择房间号"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(roomData, 'roomName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="市别"
                                name="sb">
                                <Select
                                    disabled
                                    showSearch
                                    placeholder="选择市别"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(sbData, 'sbName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="预订人数"
                                name="people"
                                rules={[{ message: '请输入预订人数!', required: true }]}
                            >
                                <InputNumber min={0}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="单位"
                                name="productUnitId">
                                <Select
                                    showSearch
                                    placeholder="选择单位"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(productUnitData, 'productUnitName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="售价"
                                name="sellPrice"
                                rules={[{ message: '请输入售价!', required: true }]}
                            >
                                <InputNumber min={0}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="原价"
                                name="originalPrice"
                                rules={[{ message: '请输入原价!', required: true }]}
                            >
                                <InputNumber min={0}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="诗句/时间地点"
                                name="mealTail">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <WebSetMealCategoryFormList props={categoryFormList} categoryFormName='setMealCategory' setMealData={setMealData}></WebSetMealCategoryFormList>
                        </Col>

                        {/* <Col span={24}>
                            <Form.List name="setMealCategory">
                                {(fields, { add, remove, move }) => {
                                    return (
                                        <>
                                            {fields.map(({ key, name, ...restField }) => (
                                                <Space
                                                    key={key}
                                                    style={{ display: 'flex', marginBottom: 8 }}
                                                    align="baseline"
                                                >
                                                    <Form.Item
                                                        {...restField}
                                                        label="菜式分组"
                                                        name={[name, 'setMealCategoryId']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: '请选择菜式分组',
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            //optionLabelProp="label"
                                                            style={{ width: 1000 }}
                                                            showSearch
                                                            allowClear
                                                            // onChange={(e) => handleChange(form, e, name, formName, type, tempDeleteData)}
                                                            placeholder="菜式分组"
                                                            optionFilterProp="children"
                                                            // filterOption={(input, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                                                            filterOption={(input, option: any) => {
                                                                // console.log("option",option);
                                                                // console.log("input",input);
                                                                return true
                                                            }}
                                                        >
                                                            {getList.general(mealCategoryData, "setMealCategoryName")}
                                                        </Select>
                                                    </Form.Item>
                                                    <CaretDownOutlined onClick={() => move(name, name + 1)} />
                                                    <CaretUpOutlined onClick={() => move(name, name - 1)} />
                                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                                </Space>
                                            ))}
                                            <Form.Item>
                                                <Button
                                                    type="dashed"
                                                    onClick={() => add()}
                                                    block
                                                    icon={<PlusOutlined />}
                                                >
                                                    添加套餐/菜单分组
                                                </Button>
                                            </Form.Item>
                                        </>
                                    )
                                }
                                }
                            </Form.List>
                        </Col> */}

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="memo"
                            >
                                <TextArea
                                    placeholder="备注"
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="是否在店销售"
                                name="isSale"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="是否普通菜单"
                                name="isMenu"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}