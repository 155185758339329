import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';

//布局
// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 16 },
// };
export default function MenuSpecSpecialEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    /** 菜谱规格数据 */
    const [menuSpecData, setMenuSpecData] = useState<any>();

    /**单位数据 */
    const [productUnitData, setProductUnitData] = useState<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            // form.setFieldsValue({ menuSpecId: data.sendPageData.menuSpecId });

            fetchApi.GET(wmService, {
                apiId: 'menuSpec',
                apiExtend: 'showMenuSpecList',
                apiVariable: data.sendPageData.menuSpecId,
            }).then(res => {
                if (res.success) {
                    setMenuSpecData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: "productUnit",
                apiExtend: 'showList'
            }).then(res => {
                setProductUnitData(res.data)
            });
        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuSpecSpecial',
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '菜谱规格特殊属性'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    // {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label={data.sendPageData.foodType === 'setMeal' ? "套餐类别" : "菜谱规格"}
                                name="menuSpecId">
                                <Select
                                    disabled
                                    showSearch
                                    placeholder={data.sendPageData.foodType === 'setMeal' ? "套餐类别" : "菜谱规格"}
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(menuSpecData, 'menuSpecName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label={data.sendPageData.foodType === 'setMeal' ? "套餐菜式" : "特殊属性"}
                                name="menuSpecSpecialName"
                                rules={[{ required: true, message: data.sendPageData.foodType === 'setMeal' ? "请输入套餐菜式" : '请输入特殊属性!' }]}>
                                <Input disabled={sessionStorage.getItem('isAdmin') ? false : data.sendPageData.foodType === 'setMeal' ? false : true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label={data.sendPageData.foodType === 'setMeal' ? "价格" : "加价"}
                                name="price"
                                rules={[{ required: true, message: data.sendPageData.foodType === 'setMeal' ? "请输入价格" : '请输入加价金额!' }]}>
                                <InputNumber min={0} />
                            </Form.Item>
                        </Col>

                        <Col span={data.sendPageData.foodType === 'setMeal' ? 24 : 0}>
                            <Form.Item
                                label="数量"
                                name="amount"
                                rules={[{ required: data.sendPageData.foodType === 'setMeal' ? true : false, message: '请输入数量!' }]}>
                                <InputNumber min={0} />
                            </Form.Item>
                        </Col>

                        <Col span={data.sendPageData.foodType === 'setMeal' ? 24 : 0}>
                            <Form.Item
                                label="单位"
                                name="productUnitId"
                            // rules={[{ required: data.sendPageData.foodType === 'setMeal' ? true : false, message: '请选择单位!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="单位"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(productUnitData, {
                                        itemName: "productUnitName",
                                        includeNull: true,
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={data.sendPageData.foodType === 'setMeal' ? 24 : 0}>
                            <Form.Item
                                label="强制数量"
                                name="isMandatory"
                                tooltip="当遇到销售位式套餐时，部分菜式不按位上改按份上，这里选择“强制”后，各单据会强制使用设定好的数量，并不会乘以人数。例如：该位式套餐点了6位，其中有菜式需要1份来上，这里选择强制，单据就会显示1份了。"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="强制"
                                    unCheckedChildren="不强制" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="是否客服专用"
                                name="isStaff"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch
                                    // disabled={requiredState}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="是否默认"
                                name="isDefault"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch
                                    // disabled={requiredState}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}