import { Form, Input, InputNumber, Switch } from 'antd';
import React, { useEffect, useRef } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function DeviceTypeEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            
        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'deviceType'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '设备类型'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="设备类型"
                        name="deviceTypeName"
                        rules={[{ required: true, message: '请输入设备类型!' }]}>
                        <Input />
                    </Form.Item>
                    
                    <Form.Item
                        label="排序"
                        name="sortBy">
                        <InputNumber min={1} />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        <Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>
                </Form>
            </AntDraggableModal>
        </>
    )
}