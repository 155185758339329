import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';
import WebProductFormList from '../../generalComponents/WebProductFormList';


export default function TransferOrderEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const [deleteData, setDeleteData] = useState<any>();

    
    /**会计期禁用 */
    // const [accountantDisable, setAccountantDisable] = useState<any>(true)
    const [formList, setFormList] = useState<any>({ formName: "costTransferProducts", form, type: data.type, ref, setDeleteData })
    

    /**调入部门数据 */
    const [importDeptData, setImportDeptData] = useState<any>();


    /**调出部门数据 */
    const [exportDeptData, setExportDeptData] = useState<any>();

    /**仓库数据 */
    const [entrepotData, setEntrepotData] = useState<any>();

   

    useEffect(() => {
        let deptId = localStorage.getItem("deptId")

        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        if (deptId && data.type === "NEW") {
            form.setFieldsValue({
                "exportDeptId": deptId
            })
            httpGet(wmService, {
                apiId: "dept",
                apiExtend: 'showList',
                apiData:{
                    
                }
            }).then(res => {

                res.data.forEach((item: any) => {
                    if (item.id === deptId) {
                        setExportDeptData([item])

                    }
                })
            })

            // httpGet(wmService, {
            //     apiId: "entrepot",
            //     apiExtend: 'getDeptIdToEntrepot',
            // }).then(res => {

            //     if (res.success) {
            //         form.setFieldsValue({
            //             "exportEntrepotId": res.data.id
            //         })
            //     }
            // }).catch((err)=>{
            //     console.log("无数据");

            // })
        }
        const fetchData = async () => {
            // httpGet(wmService, {
            //     apiId: "entrepot",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     setEntrepotData(res.data)
            // })
            if (data.type !== "NEW") {
                httpGet(wmService, {
                    apiId: "entrepot",
                    apiExtend: 'showList'
                }).then(res => {
                    setEntrepotData(res.data)
                })
                httpGet(wmService, {
                    apiId: "dept",
                    apiExtend: 'showList',
                    apiData:{
                    
                    }
                }).then(res => {
                    setImportDeptData(res.data)
                    setExportDeptData(res.data)

                })
            }

        }
        fetchData()
    }, [])
    const handleOk = (e: any) => {
        ref.current.handleOk();

    };
    const handleCancel = (e: any) => {


        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'costTransferOrder',
        formListName: "costTransferProducts",
        changeOrderTime:{
            isTime:true,
            time:sessionStorage.getItem("date")
        }
    };

    return (
        <>
            <WebEditUniversal ref={ref} totalData={deleteData} props={extendProps}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={"65%"}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.altKey) {
                            if (e.key === "s" && e.altKey) {
                                handleOk(e)
                            }
                        }

                    }}
                >
                    <Row >
                        <Col span={6}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="调出部门"
                                name="exportDeptId"
                                rules={[{ required: true, message: '请输入调出部门!' }]}>
                                <Select
                                    // disabled={data.type === "NEW" ? false : true}
                                    showSearch
                                    placeholder="请输入调出部门"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.searchEx('dept', setExportDeptData, e, undefined, {
                                        form,
                                        setObj: ["exportDeptId"],
                                        setObjValue: ["id"],
                                    })}
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}>
                                    {getList.general(exportDeptData, 'deptName')}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="调入部门"
                                name="importDeptId"
                                rules={[{ required: true, message: '请输入调入部门!' }]}>
                                <Select
                                    // disabled={data.type === "NEW" ? false : true}
                                    showSearch
                                    onSearch={(e) => getList.searchEx('dept', setImportDeptData, e, undefined, {
                                        form,
                                        setObj: ["importDeptId"],
                                        setObjValue: ["id"],
                                    })}
                                    placeholder="请输入调入部门"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}>
                                    {getList.general(importDeptData, 'deptName')}
                                </Select>
                            </Form.Item>
                        </Col>

                      

                   

                      
                        <WebProductFormList props={formList}></WebProductFormList>


                    </Row>
                </Form>
            </AntDraggableModal>

        </>
    )
}


