import { Col, Form, Input, Popover, Row, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react'
import systemConfig from '../../../config/systematic';
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification';
import dayjs from 'dayjs';
const { TextArea } = Input;

// import('../../utils/extend/AntdNotification').then()
export default function SendMessage(props: any) {
    const { closeModal, data } = props;

    const [form] = Form.useForm(); //form 
    const isInvitationLink = Form.useWatch("isInvitationLink", form) as any;
    const smsCustomTypeId = Form.useWatch("smsCustomTypeId", form) as any;

    // smsCustomTypeId
    const [branchStoreData, setbranchStoreData] = useState<any>()
    const [smsCustomTypeData, setSmsCustomTypeData] = useState<any>();

    const [invitationData, setInvitationData] = useState<any>();
    const wmService = systemConfig.wmService;

    const [argument, setArgument] = useState<any>()
    const handleOk = (e: any) => {
        form.validateFields() //数据校验
            .then(async (values: any) => {
                fetchApi.POST(wmService, {
                    apiId: "smsManage",
                    apiExtend: "send",
                    apiData: {
                        branchStoreId: values.branchStoreId,
                        phones: values.phones,
                        content: values.content,
                        customerReserveId: data.customerReserveId
                    }
                }).then(async res => {
                    if (res.success === true) {

                        if (values.smsCustomTypeId === 2) {
                            await fetchApi.POST(wmService, {
                                apiId: "tempRoom",
                                apiExtend: 'isReconfirm',
                                apiData: {
                                    reconfirmId: 2,
                                    customerReserveId: data.customerReserveId,
                                    timeTypeId: data.timeTypeId,

                                }
                            })
                          
                        }

                        localStorage.removeItem("rows")
                        antMessage('success', '发送成功', 'success');
                        closeModal(true)



                    }
                }).catch(err => {
                    console.log("err", err);

                    antMessage('error', '错误，无法发送', err.message + ' | status：' + err.errors.status);
                })
            })
            .catch(err => {
                antMessage("warning", '错误检验不通过', err.message );

            })

    };
    const handleCancel = (e: any) => {
        localStorage.removeItem("rows")
        closeModal(true, data.customerReserveId, undefined, "add",)

    };

    /**获取邀请函信息列表 */
    const getInvitationList = async () => {
        let defaultInvitationId;
        let invitationData = await fetchApi.GET(wmService, {
            apiId: "customerReserve",
            apiExtend: 'getInvitationList',
            apiData: {
                customerReserveId: data.customerReserveId
            }
        })
        if (invitationData.success) {
            let dataEx = invitationData.data.rows
            setInvitationData(dataEx);
            if (dataEx.length === 1) {
                let id = dataEx[0].id;
                defaultInvitationId = id
                form.setFieldsValue({ invitationLetterId: id })

            }
            if(dataEx.length===0)  form.setFieldsValue({ isInvitationLink: false })
        }
        return defaultInvitationId
    }
    useEffect(() => {
        const fetchData = async () => {
            if (data) {
                form.setFieldsValue({ phones: data.phones })
                form.setFieldsValue({ branchStoreId: data.branchStoreId })
                form.setFieldsValue({ smsCustomTypeId: 1 })
                let invitationLetterId = await getInvitationList();
                let customerReserveRoomData = await fetchApi.GET(
                    wmService, {
                    apiId: "customerReserve",
                    apiExtend: "getCustomerReserveRoom",
                    apiData: {
                        customerReserveId: data.customerReserveId
                    }
                }
                )
                if (customerReserveRoomData.success) {
                    let timeTypeName = `${customerReserveRoomData.data.timeType?.bookingDate}(${dayjs(customerReserveRoomData.data.timeType?.bookingDate).format('ddd')})`;
                    let waitingTime=`${customerReserveRoomData.data.timeType?.scheduledTime.timeName}${dayjs(customerReserveRoomData.data.arriveTime).format("HH:mm")}`;
                    setArgument({ ...customerReserveRoomData.data, timeTypeName,invitationLetterId,waitingTime })

                    await initContent({
                        smsCustomTypeId: 1,
                        invitationLetterId,
                        waitingTime,
                        roomName: customerReserveRoomData.data.roomName,
                        currentCustomerName: data.currentCustomerName,
                        timeTypeName
                    })

                }



            }
            httpGet(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList'
            }).then(res => {
                setbranchStoreData(res.data)
            })

            httpGet(wmService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: "smsCustomType",
            }).then(res => {
                if (res.success) {

                    setSmsCustomTypeData(res.data)
                }
            })


        }
        fetchData()
    }, [])

    const initContent = async (apiData: any) => {
        const { invitationLetterId, smsCustomTypeId,isInvitationLink, tServiceId, currentCustomerName, timeTypeName, roomName,waitingTime } = apiData;
        let contentData = await fetchApi.GET(wmService, {
            apiId: "smsManage",
            apiExtend: 'getContent',
            apiData: {
                invitationLetterId:invitationLetterId?invitationLetterId:(isInvitationLink?argument.invitationLetterId:null) ,
                contactCall: data.phones,
                branchStoreId: data.branchStoreId,
                smsCustomTypeId,
                timeTypeName,
                waitingTime,
                // floorName: data.floorId,
                roomName: roomName ? roomName : argument.roomName,
                tServiceId,
                currentCustomerName
            }
        })
         
        if (contentData.success) {
            form.setFieldsValue({ content: contentData.data })
        }
    }

    //根据smsCustomTypeId获取订制内容
    const changeSmsCustomType = async (e: any) => {
        //t服务 对wms发起请求
        //t服务返回的数据及wms返回的数据
        let tServiceId: any;

        if (e === 2) {
            if (data.passageId) {
                tServiceId = data.passageId;

            } else {

                let passageData = await fetchApi.PUT(wmService, {
                    apiId: "smsManage",
                    apiExtend: 'insertTservice',
                    apiData: {
                        customerReserveId: data.customerReserveId,
                        timeTypeId: data.timeTypeId,
                        tempRoomId: data.tempRoomId
                    }
                })

                tServiceId = passageData.data.data.id;
            }

        }

        await initContent({
            timeTypeName: argument.timeTypeName,
            invitationLetterId: argument.invitationLetterId,
            smsCustomTypeId: e,
            tServiceId,

        })


        // if (contentData.success) {
        //     form.setFieldsValue({ content: contentData.data })
        // }

    }

    /**选中邀请函 */
    const selectInvitation = async (e: any) => {
        await initContent({
            timeTypeName: argument.timeTypeName,
            invitationLetterId: e,
            smsCustomTypeId,

        })
        setArgument({ ...argument, invitationLetterId: e })

    }
    const invitationLinkChage = (e: any) => {
        let invitationLetterIdEx
        if (e) {
            invitationLetterIdEx = argument.invitationLetterId
        } else {
            invitationLetterIdEx = undefined
        }
        //  console.log("invitationLetterIdinvitationLetterId",invitationLetterId);

        initContent({
            timeTypeName: argument.timeTypeName,
            smsCustomTypeId,
            invitationLetterId: invitationLetterIdEx,
            isInvitationLink:e
        })

    }
    return (
        <>
            <AntDraggableModal
                title={`信息发送`}
                open={true}
                okText='确认'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>

                        <Col span={24}>
                            <Popover content="手机号码，用“;”（分号）分隔，最后一个不带“;”（分号）">
                                <Form.Item
                                    label="手机号码"
                                    name="phones"
                                    rules={[{ message: '手机号码!', required: true }]}>
                                    <Input placeholder='手机号码，用“;”（分号）分隔，最后一个不带“;”（分号）' />
                                </Form.Item>
                            </Popover>
                        </Col>


                        <Col span={12}>
                            <Form.Item
                                label="分店"
                                name="branchStoreId"
                                rules={[{ message: '请选择分店', required: true }]}
                            >
                                <Select
                                    disabled
                                    showSearch
                                    placeholder="选择分店"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(branchStoreData, "branchStoreName")}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="短信订制"
                                name="smsCustomTypeId"
                                rules={[{ message: '请选择短信订制', required: true }]}
                            >
                                <Select
                                    onChange={changeSmsCustomType}
                                    placeholder="请选择短信订制"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(smsCustomTypeData, "name")}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="邀请函链接"
                                name="isInvitationLink"
                                valuePropName="checked"
                                initialValue={true}

                            >
                                <Switch
                                    checked={true}
                                    onChange={invitationLinkChage}
                                    checkedChildren="是" unCheckedChildren="否"
                                ></Switch>
                            </Form.Item>
                        </Col>

                        {isInvitationLink ?
                            <Col span={16}>
                                <Form.Item
                                    label="邀请函"
                                    name="invitationLetterId"
                                    rules={[{ message: '请选择邀请函', required: true }]}
                                >
                                    <Select
                                        onChange={selectInvitation}
                                        placeholder="请选择邀请函"
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {getList.general(invitationData, "title")}
                                    </Select>
                                </Form.Item>
                            </Col> : null}




                        <Col span={24}>
                            <Form.Item
                                label="信息"
                                name="content"
                                rules={[{ message: '请输入发送信息', required: true }]}
                            >
                                <TextArea
                                    placeholder="信息"
                                    autoSize={{ minRows: 2, maxRows: 20 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>

        </>
    )
}
