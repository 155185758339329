import { Form, Input, InputNumber, Switch, ColorPicker, Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function MenuLabelEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    /**字体颜色 */
    const [fontColorData, setFontColorData] = useState<any>();

    /**背景颜色 */
    const [backgroundColorData, setBackgroundColorData] = useState<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });

            ref.current.row.then(async (res: any) => {

                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    if (data.type === "EDIT") {
                        setFontColorData(res.data.fontColor);
                        form.setFieldValue("fontColorToString", res.data.fontColor);
                        setBackgroundColorData(res.data.backgroundColor);
                        form.setFieldValue("backgroundColorToString", res.data.backgroundColor);
                    }
                }
            })
        }

        const fetchData = async () => {
            // fetchApi.GET(wmService, {
            //     apiId: "brand",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {
            //         setBrandData(res.data)
            //     }
            // });

            // fetchApi.GET(wmService, {
            //     apiId: "menuWarehouse",
            //     apiExtend: 'getBrandId'
            // }).then(res => {
            //     if (res.success) {
            //         if (res.data.key !== 'manager') {
            //             setBrandIdDisable(true);
            //             form.setFieldsValue({ brandId: res.data.brand.id });
            //         }
            //     }
            // });

        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuLabel'
    };

    const colorToString = (e: any, key: any) => {
        // console.log(e.toHexString());
        form.setFieldValue(key, e.toHexString());
    }

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '菜式标签'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999, "fontColorToString": fontColorData ? fontColorData : "#FFFFFF", "fontColor": fontColorData ? fontColorData : "#FFFFFF", "backgroundColorToString": backgroundColorData ? backgroundColorData : "#FFFFFF", "backgroundColor": backgroundColorData ? backgroundColorData : "#FFFFFF" }}
                    name="Edit"
                    onFinish={handleOk}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="门店Id"
                                name="branchStoreId"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="标签名称"
                                name="menuLabelName"
                                rules={[{ required: true, message: '请输入菜式标签名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="字体颜色"
                                name="fontColorToString">
                                <ColorPicker size="large" onChangeComplete={(e) => colorToString(e, "fontColor")} showText />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item name="fontColor">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="背景颜色"
                                name="backgroundColorToString">
                                <ColorPicker size="large" onChangeComplete={(e) => colorToString(e, "backgroundColor")} showText />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item name="backgroundColor">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}