import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import SetMealCategoryEdit from './SetMealCategoryEdit';

const SetMealCategory = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '套餐 / 菜单分类',
            dataIndex: 'setMealCategoryName',
        },
        {
            title: '套餐 / 菜单分类英文',
            dataIndex: 'setMealCategoryEnName',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '套餐 / 菜单分类',
        apiId: 'setMealCategory',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: SetMealCategoryEdit,
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}

export default SetMealCategory