import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import FeastTeamMenuWarehouseEdit from './FeastTeamMenuWarehouseEdit';
import MenuWarehouseIngredient from '../menu/MenuWarehouseIngredient';
import { useEffect, useRef, useState } from 'react'
import { Button, Space } from 'antd';

const FeastTeamMenuWarehouse = (props: any) => {

    const ref = useRef<any>();
    const apiId = "feastTeamMenuWarehouse";

    //住配料窗口
    const [menuWarehouseIngredientVisible, setMenuWarehouseIngredientVisible] = useState(false);

    //数据
    const [ingredientData, setIngredientData] = useState<any>();
    
    useEffect(() => {
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
        foodName: '',
        productUnitId: '',
        productUnitName: '',
        path: 'FeastTeamMenuWarehouse',
    }

    //打开添加主配料
    const HandleIngredient = (record: any) => {
        //console.log(record);
        sendData.id = record.id;
        sendData.foodName = record.foodName;
        sendData.productUnitId = record.productUnitId;
        sendData.productUnitName = record.productUnit.productUnitName;
        setIngredientData(sendData);
        setMenuWarehouseIngredientVisible(true);
    };

    //表头
    const columnsHead = [
        {
            title: '所属区域',
            dataIndex: 'areaName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.area.areaName}</div>
                )
            }
        },
        {
            title: '福食菜式类别',
            dataIndex: 'feastTeamMenuWarehouseCategoryName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.feastTeamMenuWarehouseCategory.feastTeamMenuWarehouseCategoryName}</div>
                )
            }
        },
        {
            title: '福食菜式名称',
            dataIndex: 'foodName',
        },
        {
            title: "单位",
            dataIndex: "productUnitName",
            render: (text: any, record: any) => {
                return (
                    <div>{record.productUnit.productUnitName}</div>
                )
            }
        },
        // {
        //     title: "做法",
        //     dataIndex: "cookMethodName",
        // },
        {
            title: '设置主配料',
            dataIndex: 'ingredient',
            key: 'ingredient',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => HandleIngredient(record)}>设置主配料</Button>
                </Space>
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '福食菜式仓库',
        apiId,
        showImage: false,
        showUploadFile: false,
        showStates: false,
        disabledAdd: false,
        showOperation: true,
        showAdd: true,
        pagination: true,
        columns,
        EditComponent: FeastTeamMenuWarehouseEdit,
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>       

            {menuWarehouseIngredientVisible
                ? (<MenuWarehouseIngredient
                    closeModal={setMenuWarehouseIngredientVisible} data={ingredientData} />)
                : null}     
        </>
    )
}

export default FeastTeamMenuWarehouse