import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
// import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import rootSaga from '../sagas/index'

const configureStore = (preloadedState?: any) => {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(logger, sagaMiddleware)
    // composeWithDevTools(applyMiddleware(logger, sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);

  if (process.env.REACT_APP_NODE_ENV !== "production") {
    if ((module as any).hot) {
      (module as any).hot.accept('./rootReducer', () => {
        store.replaceReducer(rootReducer)
      })
    }
  }
  console.log("development mode! (开发模式...)")
  return store;
};

export default configureStore;
