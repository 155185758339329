import React, { useEffect, useState } from 'react';
import { Button, Card, DatePicker, List, QRCode, Segmented, Tooltip } from 'antd';
import system from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';
import DateRoom from './DateRoom';
import antMessage from '../../../utils/extend/AntdNotification'
import dayjs from 'dayjs';
import { useLocation } from 'react-router';

//组件 RoomReserve 
const RoomReserve = (props: any) => {
    const { state } = useLocation();
 

    /** 后端数据wmService */
    let wmService: any = system.wmService;
    /** 数据刷新中 */
    let loading = false;
    const [rows, setRows] = useState<any>([]);
    const [isDynamic, setIsDynamic] = useState<boolean>()
    //当天日期
    const [bookingDate, setBookingDate] = useState<any>(dayjs())
    /**打开分店房间窗口 */
    const [modalDatebranchStoreRoomopen, setModalDatebranchStoreRoomopen] = useState<Boolean>(false)
    //传递的数据
    const [data, setData] = useState<any>();



    useEffect(() => {
        const fetchData = async () => {
            if (state) {
                const { bookingDate, customerReserveId, timeTypeId, system, operation } = state as any
                // console.log("state", bookingDate, bookingDate1);
                if (system === "wdm") {
                    sendData.system = "wdm";
                    //查看预订中心
                    if (operation === "callerCustomerReservation") {

                        sendData.operation = "callerCustomerReservation";
                        localStorage.setItem('callerCustomerData', JSON.stringify(state));
                        getData(
                            dayjs().format("YYYY-MM-DD"),

                        )
                    }


                    //查看房间
                    if (operation === "showReserve") {
                        sendData.operation = "showReserve"
                        if (customerReserveId && timeTypeId) {
                            if (bookingDate) {
                                await handleDate(bookingDate)
                                await handleShow({ bookingDate, customerReserveId, id: timeTypeId })

                            }
                            else {
                                let day = dayjs().format("YYYY-MM-DD")
                                await handleDate(dayjs().format("YYYY-MM-DD"))
                                await handleShow({ bookingDate: day, customerReserveId, id: timeTypeId })
                            }

                        } else {
                            getData(
                                dayjs().format("YYYY-MM-DD"),

                            )
                        }
                    }



                }




            } else {
                getData(
                    dayjs(bookingDate).format("YYYY-MM-DD"),

                )
            }

        };

        fetchData();
    }, []);
    //传关参数
    let sendData = {
        type: "EDIT",
        customerReserveId: null,
        timeTypeId: "",
        system: "wm",
        operation: ""
    }
    const handleShow = async (e: any) => {
        localStorage.removeItem("rows")
        sendData.customerReserveId = e.customerReserveId;
        sendData.timeTypeId = e.id;
        // sendData.system
        setData(sendData)
        setModalDatebranchStoreRoomopen(true)

    }

    const handleDate = async (e: any) => {

        if (e) {

            if (typeof e === "object") {


                setBookingDate(dayjs(e))
                getData(
                    dayjs(e).format("YYYY-MM-DD"),
                )
            }
            if (typeof e === "string") {

                setBookingDate(dayjs(e))
                getData(
                    e,
                )
            }

        } else {
            setBookingDate(dayjs())
            getData(
                dayjs().format("YYYY-MM-DD")
            )

        }



    }

    //获取数据
    const getData = async (bookingDate: any,
    ) => {

        if (loading === true) { return }
        loading = true;
        fetchApi.GET(wmService, {
            apiId: "roomReserve",
            apiExtend: 'getDateToTimeData',
            apiData: {
                bookingDate,
                mountDataOptions:{
                    userMount:{
                       isDept:true,
                    }
               },
            }
        }).then(res => {
                 
            if (res.success === true) {
              
                setIsDynamic(res.data.isDynamic)
                setRows(res.data.rows);
                loading = false;
            }
        }).catch((err)=>{
            console.log("err",err);
            
        })
    }
    /**生成市别id以及该天的房间数据 */
    const getTimeDataAndRoom = (bookingDate: string, scheduledTimeStatus: number) => {
        let date: any;
        if (typeof bookingDate !== "string") {
            date = dayjs(bookingDate).format("YYYY-MM-DD")
        } else {
            date = bookingDate;
        }
        
          
        fetchApi.POST(
            wmService, {
            apiId: "timeType",
            apiExtend: "getTimeDataAndRoom",
            apiData: {
                bookingDate: date,
                scheduledTimeStatus
            }
        }
        ).then((res => {

            if (res.success === true) {
                getData(date).then(res => {
                    antMessage('success', '动态生成数据成功!', 'success');

                })
            }

        })).catch(err => {
         console.log("err",err);
         
            antMessage('error',"错误", err.message);
            console.log("不执行吗？");
            
        })

    }


    const egmentedOnChange = (value: string | number) => {

        handleDate(value);

    }

  
    return <>
        <label>预订日期 :  </label>
        <DatePicker
            value={bookingDate}
            format="YYYY-MM-DD dddd"
            onChange={handleDate}
        />


        <span>     </span>
        {rows.length === 0 && isDynamic === true ? <>
            {/* <Button onClick={() => getTimeDataAndRoom(bookingDate, 0)} >
                <Tooltip placement="top" title={<div>此按钮用于生成预订配置<span style={{ color: "red" }}>内</span>的市别数据</div>}>生成市别id#1</Tooltip>
            </Button>
            <span>  </span> */}
            <Button onClick={() => getTimeDataAndRoom(bookingDate, 1)} >
                <Tooltip placement="top" title={<div>此按钮用于手动生成预订配置<span style={{ color: "red" }}>内</span>的市别数据</div>}>手动生成</Tooltip>

            </Button>
        </> : null}
        {/* <Button onClick={test}>测试</Button> */}
        <Segmented
            options={[
                {
                    label: (
                        <div style={{ padding: 4 }}>
                            <div>今天</div>
                            <div>{dayjs().format("M月D日")} <span>{dayjs().format("ddd")}</span></div>
                        </div>
                    ),
                    value: dayjs().format("YYYY-MM-DD"),
                },
                {
                    label: (
                        <div style={{ padding: 4 }}>
                            <div>明天</div>
                            <div>{dayjs().add(1, "days").format("M月D日")} <span>{dayjs().add(1, "days").format("ddd")}</span> </div>
                        </div>
                    ),
                    value: dayjs().add(1, "days").format("YYYY-MM-DD"),
                },
                {
                    label: (
                        <div style={{ padding: 4 }}>
                            <div>后天</div>
                            <div>{dayjs().add(2, "days").format("M月D日")} <span>{dayjs().add(2, "days").format("ddd")}</span> </div>
                        </div>
                    ),
                    value: dayjs().add(2, "days").format("YYYY-MM-DD"),
                },
                {
                    label: (
                        <div style={{ padding: 4 }}>
                            <div>{dayjs().add(3, "days").format("M月D日")}</div>
                            <div>{dayjs().add(3, "days").format("ddd")} </div>
                        </div>
                    ),
                    value: dayjs().add(3, "days").format("YYYY-MM-DD"),
                },
                {
                    label: (
                        <div style={{ padding: 4 }}>
                            <div>{dayjs().add(4, "days").format("M月D日")}</div>
                            <div>{dayjs().add(4, "days").format("ddd")} </div>
                        </div>
                    ),
                    value: dayjs().add(4, "days").format("YYYY-MM-DD"),
                },
                {
                    label: (
                        <div style={{ padding: 4 }}>
                            <div>{dayjs().add(5, "days").format("M月D日")}</div>
                            <div>{dayjs().add(5, "days").format("ddd")} </div>
                        </div>
                    ),
                    value: dayjs().add(5, "days").format("YYYY-MM-DD"),
                },

                {
                    label: (
                        <div style={{ padding: 4 }}>
                            <div>{dayjs().add(6, "days").format("M月D日")}</div>
                            <div>{dayjs().add(6, "days").format("ddd")} </div>
                        </div>
                    ),
                    value: dayjs().add(6, "days").format("YYYY-MM-DD"),
                },


            ]}
            value={dayjs(bookingDate).format("YYYY-MM-DD")}
            onChange={egmentedOnChange}
        />
        <div style={{ marginTop: "20px" }}>
            <List
                grid={{ gutter: 20, column: 4 }}
                dataSource={rows}
                renderItem={(item: any) => (
                    <List.Item>
                        <Card title={item.typeName}>
                            <Button onClick={() => handleShow(item)}>点击查看</Button>
                        </Card>
                    </List.Item>
                )}
            />
        </div>

        {modalDatebranchStoreRoomopen ? (<DateRoom data={data} closeModal={setModalDatebranchStoreRoomopen}
        />)
            : null}


    </>

}

export default RoomReserve;
