import UserAndUserGroupManageComponent, { UserAndUserGroupManagePropsType } from './UserAndUserGroupManageComponent';

const CustomComponent = (props: any) => {
    const { userGroupId, closeModal } = props;
    const apiId = 'userGroupBrand';
    //表头
    const columnsHead = [
        {
            title: '用户组',
            dataIndex: 'userGroup',
            render: (text: any) => (typeof text !== "undefined" && text !== null ?
                text.userGroupName :
                '')
        },
        {
            title: '品牌',
            dataIndex: 'brand',
            render: (text: any) => (typeof text !== "undefined" && text !== null ?
                text.brandName :
                '')
        }
    ];
    let extendProps: UserAndUserGroupManagePropsType = {
        mode: 'userGroup',
        titleName: '用户组品牌管理',
        apiId,
        userOrUserGroupId: userGroupId,
        columnsHead,
        closeModal,
        options: {
            showDefault: true
        }
    }

    return (
        <>
            <UserAndUserGroupManageComponent extendData={extendProps} ></UserAndUserGroupManageComponent>
        </>
    )
}

export default CustomComponent;