// import "dotenv/config";

/** 开发模式 */
const isDevelopmentEnvironment = process.env.REACT_APP_NODE_ENV === "development";

/** 文件服务器 */
const serverUrl = (isDevelopmentEnvironment
    ? process.env.REACT_APP_DEV_FILESSERVER
    : process.env.REACT_APP_FILESSERVER)
    || "http://localhost:3009";

/** 文件服务器API */
const serverApiUrl = serverUrl + '/' +
    (isDevelopmentEnvironment
        ? process.env.REACT_APP_DEV_FILESURL
        : process.env.REACT_APP_FILESURL)

/** 后端数据api */
const apiUrl =
    (isDevelopmentEnvironment
        ? process.env.REACT_APP_DEV_APIURL
        : process.env.REACT_APP_APIURL)
    || "http://localhost:3005/test";

/** ws 服务器 url */
const wsServerUrl = (isDevelopmentEnvironment
    ? process.env.REACT_APP_DEV_WSSERVER
    : process.env.REACT_APP_WSSERVER)
    || "ws://localhost:6005";

/** ws 服务器 path */
const wsServerPath = (isDevelopmentEnvironment
    ? process.env.REACT_APP_DEV_WSPATH
    : process.env.REACT_APP_WSPATH)
    || "/wsTest";

/** 房间二维码 path */
const roomPath = (isDevelopmentEnvironment
    ? process.env.REACT_APP_DEV_WXROOMPAT
    : process.env.REACT_APP_WXROOMPATH)


/**wm映射地址，本地使用 */
// const apiUrlWmEx =
//     (isDevelopmentEnvironment
//         ? process.env.REACT_APP_DEV_SERVERURL
//         : process.env.REACT_APP_DEV_SERVERURL)
//     || "http://wisca.2288.org:3005/test"

/** 地址配置文件 */
const systematic = {
    /** 后端数据api */
    apiUrl,
    /** 后端数据bmService */
    bmService: apiUrl + '/bm/service',
    /** 后端数据wmService*/
    wmService: apiUrl + "/wm/service",
    // apiUrlWmExUrl: apiUrlWmEx + "/wm/service",

    /** 文件服务器 */
    filesServer: {
        /** 文件服务器地址 */
        serverUrl,
        /** 服务id */
        serviceId: process.env.REACT_APP_FILESERVICEID,
        /** 文件服务器api地址 */
        filesApiUrl:
            serverApiUrl + '/' + process.env.REACT_APP_FILEEXTENDURL
        ,
        /** 文件服务器api管理地址 */
        filesManageApiUrl:
            serverApiUrl + '/' + process.env.REACT_APP_FILEMANAGEEXTENDURL
        ,
    },

    /** ws服务器 */
    wsServer: {
        /** 地址 */
        url: wsServerUrl,
        /** server path */
        path: wsServerPath,
        /** 服务（socket.io的命名空间） */
        service: {
            /** wm */
            wm: process.env.REACT_APP_WSWMSERVICE || ''
        }
    },

    /** 微信二维码路径 */
    wxQrCodeUrl: {
        /** 房间二维码路径 */
        path: roomPath,

    },

    /** 签名 */
    auth: {
        /** 密匙 */
        secretKey: process.env.JWT_SECRET_KEY || "HFC2020"
    },
};

export default systematic;
