import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebUniversalNewEx from '../../system/WebUniversalNewEx';
import { useRef } from 'react';
import PrintConfigurationEdit from './PrintConfigurationEdit';
import { Switch } from 'antd';

//组件 
const PrintConfiguration = (props: any) => {
    
    /** api服务id */
    const apiId = 'printConfiguration';
    const ref = useRef<any>();

    const { roomId,deptId, closeModal, isModel,roomTypeId,branchStoreId } = props;
     
    //表头
    const columnsHead = [
        {
            title: '打印单类型',
            dataIndex: 'printTypeName',
        },
        {
            title: '部门',
            dataIndex: 'dept',
            render: (text: any, record: any) => (<div>{text?.deptName?text.deptName:""}</div>)
        },
        {
            title: '房间',
            dataIndex: 'roomId',
        },
        {
            title: '房间类型',
            dataIndex: 'roomTypeId',
        },
        {
            title: '打印机',
            dataIndex: 'printer',
            render: (text: any, record: any) => (<div>{text.printerName?text.printerName:""}</div>)
        },
        {
            title: '页数',
            dataIndex: 'copies',
        },
        {
            title: "静默打印",
            dataIndex: "isSilent",
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => ref.current.handleChangeOne(record.id, 'isSilent', e, apiId)}
                />
            )
        },
        {
            title: "显示打印页设置",
            dataIndex: "isPrinterPage",
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => ref.current.handleChangeOne(record.id, 'isPrinterPage', e, apiId)}
                />
            )
        },
       
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '打印配置',
        apiId,
        columns,
        sendPageData: { roomId,deptId,roomTypeId,branchStoreId },
        showStates: true,
        pagination: true,
        EditComponent: PrintConfigurationEdit
    };
    const handleOk = (e: any) => {
        closeModal(true);
    };
    return (
        
        <>
        {isModel ? <AntDraggableModal
            title={'打印配置'}
            open={true}
            okText='确定'
            cancelButtonProps={{ style: { display: "none" } }}
            onOk={handleOk}
            closable={false}
            maskClosable={false}
            width={1000}>
            <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
        </AntDraggableModal> : <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>}


    </>
    )
}

export default PrintConfiguration;