import OtherSystemAccountEdit from './OtherSystemAccountEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 
const WebComponents = (props: any) => {

    /** api服务id */
    const apiId = 'otherSystemAccount';

    //表头
    const columnsHead = [
        {
            title: '帐号名称',
            dataIndex: 'accountName',
            sorter: true,
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '其它帐号',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: OtherSystemAccountEdit
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebComponents;
