import React from 'react'
import BeOnDuty from './BeOnDuty';


/**个人值班列表信息 */
export default function PerBeOnDuty(props: any) {
    /** api服务id */
    return (
        <BeOnDuty isUser={true}></BeOnDuty>
    )
}
