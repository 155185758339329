import { useEffect, useRef, useState } from 'react';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { Button, Checkbox, Popconfirm, Switch, Tooltip } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification'

/** UserAndUserGroupManageProps 数据类型 */
export declare type UserAndUserGroupManagePropsType = {
    /** 类型 */
    mode: 'user' | 'userGroup';
    /** 标题 */
    titleName: string;
    /** apiId */
    apiId: string;
    /** 用户或用户组id */
    userOrUserGroupId: string;
    /** 表头 */
    columnsHead: Array<any>;
    /** 关闭 */
    closeModal: Function;
    /** 选项 */
    options?: {
        /** 显示默认 */
        showDefault?: boolean
    }
};

const CustomComponent = (props: any) => {
    const ref = useRef<any>();
    let { mode, titleName, apiId, userOrUserGroupId, columnsHead, closeModal, options } = props.extendData;
    if (typeof options === 'undefined') {
        options = {};
    }
    let { showDefault } = options;
    if (typeof showDefault === 'undefined') {
        showDefault = false;
    }

    const [isAllData, setIsAllData] = useState(false);

    /** 后端数据defaultService */
    let defaultService: any = '';
    /** 刷新数据 */
    let initData: any;
    let handleChangeOne: any;
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            handleChangeOne = ref.current.handleChangeOne

        }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAllData]); //初始化数据

    //表头
    const useDefault = [
        {
            title: '默认',
            dataIndex: 'isDefault',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record['id']}
                    onChange={(e) =>
                        typeof handleChangeOne !== "undefined" ?
                            handleChangeOne(record['id'], 'isDefault', e, apiId) :
                            undefined
                    }
                />
            )
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as [])
        .concat(useDefault as []);

    const handleOk = (e: any) => {
        if (closeModal) {
            closeModal(true);
        }
    };

    let sendPageData;
    switch (mode) {
        case 'user':
            sendPageData = {
                userId: userOrUserGroupId
            }
            break;
        case 'userGroup':
            sendPageData = {
                userGroupId: userOrUserGroupId
            }
            break;
    }

    /** 更新数据 */
    const handleUpdate = () => {
        fetchApi.PUT(defaultService,
            {
                apiId,
                apiExtend: 'updateData',
                apiVariable: userOrUserGroupId
            }).then(res => {
                if (res.success) {
                    initData();
                    antMessage('success', '更新数据成功', 'success');
                }
            });
    };

    /** 清除所有空数据 */
    const handlClearEmptyData = () => {
        fetchApi.DELETE(defaultService,
            {
                apiId,
                apiExtend: 'clearEmptyData',
                apiData: {
                    isAllData,
                    id: userOrUserGroupId
                }
            }).then(res => {
                if (res.success) {
                    initData();
                    antMessage('success', '清除空数据成功', 'success');
                }
            });
    };


    let extendProps: WUExtendPropsType = {
        ...props,
        titleName,
        apiId,
        columns,
        showStates: true,
        sendPageData,
        showOperation: true,
        showEdit: false,
        showAdd: false,
        showQuery: false,
        pagination: true,
        // showDefault,
        extendIdName: mode + "Id",
    };

    return (
        <>
            <AntDraggableModal
                title={
                    <>
                        {titleName}
                        <Tooltip placement="topLeft" title={'选择后会对所有数据的空数据清除'}>
                            <Checkbox onChange={(e) => {
                                setIsAllData(e.target.checked);
                            }} style={{ float: "right" }}>清空所有数据</Checkbox>
                        </Tooltip>
                        <span style={{ float: "right" }}>&nbsp;</span>
                        <Popconfirm
                            title="是否清除空数据？"
                            description={`将会清除${isAllData === true ? titleName : '[' + mode + ']' + userOrUserGroupId}空数据！`}
                            trigger="click"
                            onConfirm={handlClearEmptyData}>
                            <Button style={{ float: "right", color: 'red' }}>清除空数据</Button>
                        </Popconfirm >
                        <span style={{ float: "right" }}>&nbsp;</span>
                        <Button style={{ float: "right" }} onClick={handleUpdate}>更新数据</Button>
                    </>}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={800}>
                <WebUniversal props={extendProps} ref={ref}></WebUniversal>
            </AntDraggableModal>
        </>
    )
}

export default CustomComponent;