import { Table, Button, Space, Input, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { connect } from 'react-redux';
import { InitData, WipeData } from '../../store/system/showPage';
import fetchApi from '../../utils/api/fetchApi'
import antMessage from '../../utils/extend/AntdNotification'
import WebBehaviors, { behaviorsDataType, deleteButtonConfigType } from './behaviors/WebBehaviorsNew';
import systematic from '../../config/systematic';
import DownloadExcel from '../report/DownloadExcel';

/** serviceIdType */
export declare type serviceIdType = 'bm' | 'pay' | 'message';

export const getService = (serviceId: string) => {
    /** 默认service */
    var defaultService = systematic.wmService
  
    return defaultService;
};

/** WebUniversal ExtendProps数据类型 */
export declare type WUExtendPropsType = {
    /** 图片数据数组名称 */
    arrayFilesName?: string,
    /** 编辑组件 */
    EditComponent?: any,
    /** 文件上传组件 */
    UploadFileComponent?: any,
    /** 标题 */
    titleName: string,
    /** api服务id */
    apiId: string,
    /** 主键名称 */
    primaryKeyName?: string,
    /** 是否显示文件上传按键 */
    showUploadFile?: boolean,
    /** 是否显示增加键 */
    showAdd?: boolean,
    /** 是否显示查询 */
    showQuery?: boolean,
    /** 查询的提示信息 */
    queryTitle?: string,
    /** 是否显示图片 */
    showImage?: boolean,
    /** 是否显示状态 */
    showStates?: boolean,
    /** 是否显示操作区域 */
    showOperation?: boolean,
    /** 是否显示删除键 */
    showDelete?: boolean,
    /** 是否显示编辑键 */
    showEdit?: boolean,
    /** 是否显示打印键 */
    showPrint?: boolean,
    /** 是否禁用增加键 */
    disabledAdd?: boolean,
    /** 是否禁用上传键 */
    disabledUpload?: boolean,
    /** 上传文件的数据 */
    uploadSendData?: any,
    /** 表头 */
    columns: Array<any>,
    /** 页面传送其它数据 */
    sendPageData?: any,
    /** 是否有子表格？
     * （表格内容较多，如参数expandableDataName：undefined时，可分两行展示）
     */
    isExpandable?: boolean,
    /** 子表格表头 */
    expandableColumns?: Array<any>,
    /** 子表格数据数组对象名称，当为undefined时，如表格内容较多，可分两行展示 */
    expandableDataName?: any,
    /** 总结栏 */
    summaryRender?: any,
    /** 是否展开所有数据栏 */
    // isExpandAllRows?: boolean,
    /** 删除按钮配置 */
    deleteButtonConfig?: deleteButtonConfigType,
    /** 删除按钮中的checkBox为true时执行方法(handleDelete执行之前执行) */
    beforeHandleDeleteCheckBox?: Function,
    /** 删除按钮中的checkBox为true时执行方法(handleDelete执行成功后才执行) */
    afterHandleDeleteCheckBox?: Function,
    /** 覆盖删除方法 */
    coverHandleDelete?: Function,
    /** 后台服务地址 */
    serviceUrl?: string,
    /** 后台服务数据 */
    serviceId?: serviceIdType
};

/** 刷新数据的类型 */
export declare type initDataType = {
    /** 服务id */
    apiId?: string,
    /** 页面传送的数据 */
    sendPageData?: any
};

//Redux-React
const mapStateToProps = (state: {}) => {
    return {
        ...state,
    };
};
const mapDispatchToProps = { InitData, WipeData }

/**
 * 通用Web组件 WebUniversal 
 * @param props 参数对象
 * @param ref 返回的对象
 * @returns 
 */
let WebUniversal = (props: any, ref: any) => {
    let extendProps: WUExtendPropsType;
    extendProps = props.props;
    var {
        arrayFilesName,
        EditComponent,
        UploadFileComponent,
        titleName,
        apiId,
        showUploadFile,
        showAdd,
        showQuery,
        queryTitle,
        showImage,
        showStates,
        showOperation,
        showDelete,
        showEdit,
        showPrint,
        disabledAdd,
        disabledUpload,
        uploadSendData,
        sendPageData,
        isExpandable,
        summaryRender,
        // isExpandAllRows,
        deleteButtonConfig,
        beforeHandleDeleteCheckBox,
        afterHandleDeleteCheckBox,
        coverHandleDelete,
        serviceUrl,
        serviceId
    } = extendProps;
    
    //主键名称
    let primaryKeyName = extendProps.primaryKeyName!;
    if (typeof primaryKeyName === 'undefined') {
        primaryKeyName = 'id';
    };
    if (typeof serviceId === 'undefined') {
        serviceId = 'bm';
    }

   /** 默认service */
   const defaultService = serviceUrl ? serviceUrl : getService(serviceId);

   

    /** 子表格表头 */
    const [expandableColumnsData, setExpandableColumnsData] = useState<any>();
    /** 子表格数据数组对象名称*/
    const expandableDataName = extendProps.expandableDataName;
    //子表格数据
    const [expandedData, setExpandedData] = useState<any>();


    //黑白样式的数据
    const [cssLightData, setCssLightData] = useState<any>();
    const [cssDarkData, setCssDarkData] = useState<any>();
    //记录点击黑白切换的次数
    const [clickNumData, setClickNumData] = useState(0);

    /**
     * 获取子表格内容
     * @param item row
     * @returns 
     */
    const getExpandedRowRender = (item: any) => {
        if (typeof expandedData === 'undefined') {
            return undefined;
        }
        /** 合并新旧对象 */
        // let expandedDataMerge = { ...expandedData, [item.id]: item[expandableDataName] }
        let expandedDataMerge = { ...expandedData }


        return (
            <Table
                columns={expandableColumnsData}
                rowKey={row => (primaryKeyName ? row[primaryKeyName] : row.id)}
                pagination={false}
                dataSource={
                    expandedDataMerge[item.id] ?
                        expandedDataMerge[item.id].constructor === Array ?
                            expandedDataMerge[item.id] :
                            [expandedDataMerge[item.id]]
                        : undefined}
            />
        )
    };

    /**
     * 展开时触发的回调
     * @param status 是否展开状态
     * @param item 当前一条数据
     * @returns 
     */
    const onExpandClick = (status: any, item: any) => {
        if (!status) return
        //传入相关数据，如果expandableDataName存在，则传入相关数据，
        //否则传入当前数据（用于当表格内容较多不能一次性完全展示）
        setExpandedData({
            ...expandedData,
            [item[primaryKeyName]]: expandableDataName
                ? item[expandableDataName]
                : item
        })
    };
    // 参数
    const { InitData, WipeData, showPageData } = props;
    /** 初始值 */
    let data: any = {
        pageSize: 10,
        current: 1,
        queryString: undefined,
        total: 0,
        rows: undefined,
        extendData: {}
    };

    try {
        if (typeof showPageData[apiId] !== "undefined") {
            data = showPageData[apiId].data;
        }
    } catch { }
    let { pageSize,
        current,
        total } = data;
    /** 数据集合 */
    // let rows = data.rows;
    let [rows, setRows] = useState();
    rows = data.rows;
    const [modalVisible, setModalVisible] = useState(false);
    const [modalUploadVisible, setModalUploadVisible] = useState(false);
    const [modalData, setModalData] = useState({});
    const [queryInput, setQueryInput] = useState(showPageData.queryString);
    const [uploadFilesData, setUploadFilesData] = useState<any>();
    /** 页面数据刷新状态 */
    let loading = false;
    let initialState = {
        apiId
    } as any;

    if (total === 0) { rows = undefined };
    useEffect(() => {
        const initStartData = async () => {
            if (typeof apiId !== "undefined") {
                initialState[apiId] = {
                    data: {
                        current: 1,
                        pageSize: 10,
                        queryString: '',
                        orderByString: ''
                    },
                    fetchUrl: defaultService,
                    sendPageData,
                };
                InitData(initialState);
            }
        }
        initStartData();
        //设置子表格表头
        setExpandableColumnsData(extendProps.expandableColumns)
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /**
     * 改变单一项数据
     * @param id 主键id
     * @param columnName 字段名称，例如：isUse，isCheck...
     * @param value 所要改变的内容
     * @param apiId 接口名称
     * @returns 
     */
    const handleChangeOne = async (id: any, columnName: string, value: boolean, apiId: string) => {
        const bodys = {
            apiId: apiId,
            apiVariable: id,
            apiExtend: 'updateOne',
            apiData: {
                columnName,
                value,
            }
        };

        if (await fetchApi.PUT(defaultService, bodys)) {
            antMessage('success', 'updateOne编辑成功',
                'apiId：' + bodys.apiId + '，columnName：' +
                bodys.apiData.columnName + '，value：' + bodys.apiData.value);
            InitData({
                ...showPageData,
            });
            return true;
        }
        else {
            antMessage('error', '删编辑失败', 'error');
            return false;
        }
    };

    //ref 可使用WebUniversal(父)组件函数
    useImperativeHandle(ref, () => ({

        /** 清空数据 */
        wipeData: () => {
            initialState[apiId] = {
                data: {
                    current: 1,
                    pageSize: 10,
                    queryString: '',
                    orderByString: ''
                },
                fetchUrl: defaultService,
                sendPageData,
            };
            WipeData(initialState);
        },
        /**
         * 更改一项数据
         * @param id 
         * @param columnName 
         * @param value 
         * @param urlName 
         * @returns 
         */
        handleChangeOne: (id: any, columnName: string, value: boolean, urlName: string) =>
            handleChangeOne(id, columnName, value, urlName),
        /**
         * 刷新数据
         * @param currentData
         * @returns 
         */
        initData: (currentData: initDataType) => {
            if (typeof currentData !== 'undefined') {
                const currentApiId = currentData.apiId;
                const currnetSendPageData = currentData.sendPageData;
                if (typeof currentApiId !== 'undefined') {
                    showPageData.apiId = currentApiId;
                    if (typeof currnetSendPageData !== 'undefined') {
                        showPageData[currentApiId].sendPageData = currnetSendPageData
                    }
                }
            }
            InitData({
                ...showPageData,
            })
        },
        /**
         * 传入子表格表头
         * @param exColumns 子表格表头
         */
        setExpandableColumns: (exColumns: any) => {
            setExpandableColumnsData(exColumns);
        },
        /** 后端数据defaultService */
        defaultService,

    }));

    /**
     * 删除动作
     * @param record 记录集合
     * @param isChecked checkBox checked
     * @returns 
     */
    const handleDelete = async (record: any, isChecked: boolean) => {
        if (typeof coverHandleDelete !== 'undefined') {
            coverHandleDelete(record, isChecked);
            return
        }
        let id = record[primaryKeyName]

        if (isChecked === true) {
            if (typeof beforeHandleDeleteCheckBox !== 'undefined') {
                await beforeHandleDeleteCheckBox(record);
            }
        }
        fetchApi.DELETE(defaultService, {
            apiId: apiId,
            apiVariable: id,
        }).then(async res => {
            if (res.success === true) {
                if (isChecked === true) {
                    if (typeof afterHandleDeleteCheckBox !== 'undefined') {
                        await afterHandleDeleteCheckBox(record);
                    }
                }
                antMessage('success', '删除成功', 'success');
                //刷新表格内容
                InitData({
                    ...showPageData,
                });
            }
            else {
                antMessage('error', '删除失败', 'error');
            }
        }).catch(err => {
            antMessage('error', '删除失败', err.message + '，[' + err.errors.status + ']');
        })
    };

    //编辑
    const handleEdit = (recordData: any) => {
        let id = recordData[primaryKeyName]
        openModal('EDIT', id);
    };

    //文件上传
    const hanldUpload = async (e: any) => {
        setUploadFilesData({
            id: e,
            disabledUpload,
            uploadSendData,
            // handleChangeOne,

        })
        setModalUploadVisible(true);
    };

    //关闭上传
    const onCloseUpload = (state: boolean) => {
        setModalUploadVisible(false);
        // if (state === true) {
        //刷新表格内容
        InitData({
            ...showPageData,
        });
        // }
    };

    //排序
    const handleSort = (sort: any) => {
        let orderByString = '';
        switch (sort.order) {
            case 'descend':
                orderByString = sort.field + '@DESC'
                break;
            case 'ascend':
                orderByString = sort.field + '@ASC'
                break;
            default:
                break;
        }
        //刷新表格内容
        if (loading === false) {
            initialState[apiId] = {
                data: {
                    orderByString,
                },
            };
            InitData(initialState);
            loading = true;
        }
    };

    let behaviorsData: behaviorsDataType = {
        showImage,
        showUploadFile,
        showStates,
        showOperation,
        showDelete,
        showEdit,
        hanldUpload,
        handleDelete,
        handleEdit,
        handleChangeOne,
        apiId,
        primaryKeyName,
        behaviorsColumns: props.props.columns,
        arrayFilesName,
        deleteButtonConfig
    };

    let columns = WebBehaviors(behaviorsData);

    let sendData = {
        type: '',
        id: '',
        sendPageData: {},

    }

    //打开[新增-编辑]框
    const openModal = (type: string, id: string) => {
        sendData.type = type;
        sendData.id = id;
        sendData.sendPageData = sendPageData;
        setModalData(sendData);
        setModalVisible(true);
    }

    //子组件回调函数，关闭modal
    const onCloseModal = (state: boolean) => {
        setModalVisible(false)
        if (state === true) {
            //刷新表格内容
            InitData({
                ...showPageData,
            });
        }
    }

    /** 查询 */
    const handleQuery = (e: any) => {
        let value = e.target.value;
        setQueryInput(value);
    };

    const queryData = (queryString: any) => {
        initialState[apiId] = {
            data: {
                queryString: typeof queryString === 'undefined' ? '' : queryString,
                current: data.queryString === queryString ? data.current : 1,
            },
        };
        InitData(initialState);
    };

    // 表格分页属性
    const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: false,
        showTotal: () => `共${total}条`,
        pageSize,
        current,
        total,
        onShowSizeChange: (current: number, pageSize: number) => changePageSize(pageSize, current),
        onChange: (current: number) => changePage(current, pageSize),
    };

    // 回调函数，切换页数
    const changePage = (current: number, pageSize: number) => {
        if (loading === false) {
            initialState[apiId] = {
                data: {
                    current,
                    pageSize,
                },
            };
            InitData(initialState);
            loading = true;
        }
    }

    // 回调函数,每页显示多少条
    const changePageSize = (pageSize: number, current: number) => {
        if (loading === false) {
            initialState[apiId] = {
                data: {
                    current,
                    pageSize,
                },
            };
            InitData(initialState);
            loading = true;
        }
    };

    /** 打印 */
    const handlePrint = (id: string) => {

        /** 获取\<head>\</head>里面的内容 */
        let head = document.getElementsByTagName("head")[0];
        /** 获取<head>下面所有<style></style>内容 */
        const getStyle = head.getElementsByTagName('style');

        /** 前提先console一下，黑色主题和白色主题所在位置(在数组里面的第几个节点) */
        if (getStyle.length > 0) {
            if (clickNumData > 1) {

                /** 由于前两次的点击已经将原来第3(白色)、第4(黑色)节点的样式删除，所以之后多次切换主题，都是在最后一个节点进行修改主题 */
                /** 删除最后一个节点的主题 */
                getStyle[getStyle.length - 1].remove();

                /** 在最后添加白色主题 */
                let styleDom = document.createElement("style");
                styleDom.innerHTML = cssLightData;
                head.appendChild(styleDom);
            }
            else {
                /** 当未点击过切换黑白主题时，直接删除黑色主题就可以了 */
                if (clickNumData === 0) {
                    /** 根据之前的console,知道黑色主题在getStyle[3](第4个节点) */
                    /** 由于App.css里是先 "import antd.css",再 "import antd.dark.css"，所以黑色样式在第4个节点(getStyle[3])，所以将这个样式移除就可以变成白色了 */
                    getStyle[3].remove();
                }
            }
        }
        window.document.body.innerHTML = window.document.getElementById(id)?.innerHTML!;
        window.print();
        window.location.reload();
    };

    /** 切换白黑样式 */
    // const changeSkin = (checked: any) => {
    //     /** 记录点击黑白切换的次数，用于删除原来的黑白主题样式的位置，主要由于原来黑白主题的位置不方便修改，所以要将原来的删除并在最后添加以便修改 */
    //     setClickNumData(clickNumData + 1);
    //     /** 获取<head></head>里面的内容 */
    //     let head = document.getElementsByTagName("head")[0];
    //     /** 获取<head>下面所有<style></style>内容 */
    //     const getStyle = head.getElementsByTagName('style');

    //     const getLink = head.getElementsByTagName('link');
    //     console.log(getLink[3].href);
    //     console.log(getLink[4].href);
    //     getLink[4].remove();

    //     /** 前提先console一下，黑色主题和白色主题所在位置(在数组里面的第几个节点) */
    //     if (getStyle.length > 0) {
    //         if (clickNumData < 2) {         // 当点击黑白切换次数小于2则往下执行
    //             if (checked) {
    //                 /** 主要作用是当点击第一次时，将黑白数据保存，并将黑色主题删掉 */
    //                 if (clickNumData === 0) {
    //                     /** 白色主题样式set一下，记录好数据； */
    //                     setCssLightData(head.getElementsByTagName('style')[2].textContent);
    //                     /** 将黑色主题样式set一下，记录好数据； */
    //                     setCssDarkData(head.getElementsByTagName('style')[3].textContent);
    //                     /** 删除黑色的主题样式    根据之前的console,知道黑色主题在getStyle[3](第4个节点) */
    //                     getStyle[3].remove();
    //                 }
    //             }
    //             else {
    //                 /** 主要作用是当点击第二次时，准备将白色主题删掉，所以需要提前将黑色主题添加到最后，再删除白色主题 */
    //                 if (clickNumData === 1) {
    //                     /** 在最后添加黑色主题 */
    //                     let styleDom = document.createElement("style");
    //                     styleDom.innerHTML = cssDarkData;
    //                     head.appendChild(styleDom);

    //                     /** 删除白色的主题样式    根据之前的console,知道白色主题在getStyle[2](第3个节点) */
    //                     getStyle[2].remove();
    //                 }
    //             }
    //         }
    //         else {
    //             /** 由于前两次的点击已经将原来第3(白色)、第4(黑色)节点的样式删除，所以超过2次的点击切换主题后，都是在最后一个节点进行修改主题 */
    //             /** 删除最后一个节点的主题 */
    //             getStyle[getStyle.length - 1].remove();

    //             /** 在最后添加一个的主题(什么主题根据checked来判断) */
    //             let styleDom = document.createElement("style");
    //             styleDom.innerHTML = checked ? cssLightData : cssDarkData;
    //             head.appendChild(styleDom);
    //         }
    //     }
    // };

    return (
        <>
            <Space style={{ marginBottom: 16 }}>
                {showAdd === true || typeof showAdd === 'undefined' ?
                    <Button disabled={disabledAdd} onClick={() => openModal('NEW', '')}>新增{titleName}</Button>
                    : ''}
                {showQuery === true || typeof showQuery === 'undefined' ?
                    <>
                        <Tooltip placement="topLeft" title={queryTitle}>
                            <Input
                                placeholder="请输入查询内容"
                                defaultValue={queryInput}
                                onChange={(e: any) => handleQuery(e)}
                               onKeyDown={(e: any) => {
                                    if (e.key === 'Enter')
                                        queryData(queryInput)
                                }} />
                        </Tooltip>
                        <Button icon={<SearchOutlined />} onClick={() => queryData(queryInput)}>查询</Button>

                    </>
                    : ''}
                {showPrint ?
                    <>
                        <Button onClick={() => handlePrint('table')} >打印</Button>
                        <Button onClick={() => DownloadExcel(props, rows)} >下载EXCEL文件</Button>
                    </>
                    : ''}
            </Space>
            <Table
                id='table'
                rowKey={row => row[primaryKeyName]}
                columns={columns}
                dataSource={rows}
                pagination={paginationProps}
                size="small"
                onChange={(_a, _b, par) => handleSort(par)}
                //子表格
                expandable={
                    isExpandable ?
                        { expandedRowRender: getExpandedRowRender }
                        : undefined}
                // 展开时触发的回调
                onExpand={onExpandClick}
                // defaultExpandAllRows={true}
                //总结栏
                summary={() => {
                    if (typeof summaryRender === 'undefined') {
                        return null;
                    }
                    let sumData
                    if (data['extendData']) {
                        sumData = data['extendData'][summaryRender.sumDataName]
                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0}>总计:</Table.Summary.Cell>
                                <Table.Summary.Cell index={0}>
                                    {sumData}
                                </Table.Summary.Cell >
                            </Table.Summary.Row>
                        )
                    }
                }}
            //
            />
            {modalVisible ? (<EditComponent closeModal={onCloseModal} data={modalData} />) : null}
            {modalUploadVisible ? (<UploadFileComponent onClose={onCloseUpload} data={uploadFilesData} />) : null}
        </>
    )
}

/**
 * 通用Web组件 WebUniversal 
 * @param props 
 * @param ref 
 * @returns 
 */
export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true })
    (forwardRef(WebUniversal));
