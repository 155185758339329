import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import ShopTypeEdit from './ShopTypeEdit';
import { Switch } from 'antd';

const ShopType = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.shopCategory.branchStore.brand.brandName + " " + record.shopCategory.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '大分类',
            dataIndex: 'shopCategoryName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.shopCategory.shopCategoryName}</div>
                )
            }
        },
        {
            title: '小分类名称',
            dataIndex: 'shopTypeName',
        },
        {
            title: '小分类英文',
            dataIndex: 'shopTypeNameEn',
        },
        {
            title: '服务人员专用',
            dataIndex: 'isStaff',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) => handleChange(record.id, 'isStaff', e)}
                />
            )
        },
        {
            title: '是否打折',
            dataIndex: 'isDiscount',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '最高折扣',
            dataIndex: 'maxDiscount',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜谱分类',
        apiId: 'shopType',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: ShopTypeEdit,
        sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}

export default ShopType