import moment from "moment";

const setOrderDate = () => {

}
setOrderDate.date=(e:any)=>{
   
     let choseDate;
     if(e.$d){
        choseDate=moment(e.$d).format("YYYY-MM-DD")

     }else{
        choseDate=moment().format("YYYY-MM-DD")
     }

     
     
        // console.log("moment().toDate()",moment());
    
        
    sessionStorage.setItem("date",choseDate)
}

export default setOrderDate