import io, { Socket } from 'socket.io-client';
import { defaultRtn, methodType, mountDataOptionsType } from '../../types/general';

/** mode类型 */
export declare type modeType = 'login' | 'defaultLogin' | 'tokenLogin' | 'wxJscodeLogin';

export declare type authType = {
    /** 模式, 默认是 'tokenLogin' */
    mode?: modeType,
    /** 登录id */
    logId?: string,
    /** 登录密码 */
    logPass?: string,
    /** 微信unionId */
    unionId?: string,
    /** token */
    token?: string,
    /** 通过wxTypeId获取微信相关帐号资料，由wxType@wxId组成，例如：wxMiniApp@pinHui */
    wxTypeId?: string,
    /** 微信jscode */
    wxJscode?: string,
    /** 装载数据选项 */
    mountDataOptions?: mountDataOptionsType
};

/** 连接类型 */
export declare type connectType = {
    /** 服务地址 */
    serverUrl: string,
    /** 命名空间 */
    nameSpace: string,
    /** path */
    path: string,
    /** 监听id，双向通信 */
    listenerId: string,
    /** 权限id */
    licenseTypeId?: string,
    /** 房间类型 */
    roomType?: string,
    /** 房间id */
    roomId?: string,
    /** 授权 */
    auth?: authType
};

/** socket.emit 发送数据类型 */
export declare type emitWsDataType = {
    /** 方法：'GET', 'POST', 'PUT', 'DELETE' ... */
    method?: methodType,
    licenseTypeId?: string,
    /** apiId */
    apiId: string,
    /** apiExtend */
    apiExtend?: string,
    /** apiVariable */
    apiVariable?: string,
    /* apiData **/
    apiData?: any
};

/** 返回事件函数类型 */
export declare type eventFunctionType = {
    /** 事件名称列表 */
    evs?: Array<string>,
    /** 事件列表的回调函数：{ ev: Function } */
    evsListeners?: any,
    /** 连接成功后触发的回调函数 */
    connectSuccessListener?: Function,
    /** 连接错误时触发的回调函数 => err */
    connectErrorListener?: Function,
    /** 断开连接 disconnectListener(socket, { reason, details }) */
    disconnectListener?: Function
};
/**
 * wsServer
 */
class wsServer {
    /** 是否连接 */
    connected: boolean = false;
    /** socket */
    socket!: Socket;
    /** socketId */
    socketId: string | undefined;
    /** 连接信息 */
    private connectInfo!: connectType;
    /** 错误信息 */
    private error: any;

    /**
     * 增加监听器
     * @param evs ['msg'] 事件名称列表
     * @param evsListeners evListener 根据事件名称列表的回调函数  {[env]:()=>{}}
     * @returns 
     */
    addListener = (evs: Array<string>, evsListeners: any) => {
        let rtn = defaultRtn({
            success: true
        });
        if (this.socket) {

            if (evs) {
                evs.map((ev: any) => {
                    if (evsListeners) {
                        const evListener = evsListeners[ev];
                        if (evListener) {
                            const a = this.socket.listeners(ev);
                            if (a.length === 0) {
                                this.socket.on(ev, (payload: any, callback: Function) => {

                                    if (evListener) { evListener(payload, callback); }
                                })
                            }
                        }
                    }
                })
            }
        } else {
            rtn.success = false;
        }
        return rtn;
    };

    /**
     * 删除监听器
     * @param evs ['msg'] 事件名称列表
     */
    removeListener = (evs: Array<string>) => {
        let rtn = defaultRtn();
        if (this.socket) {
            if (evs) {
                evs.map((ev: any) => {
                    this.socket.off(ev);
                    this.socket.removeAllListeners(ev);
                })
            }
            rtn.success = true;
        }
        return rtn;
    };

    /**
     * 连接 wsServer
     * @param connectInfo 连接数据 
     * @param evsFun 回调函数
     * @returns connected: boolean, socket: Object OR error: any
     * @example
     * const ws = new wsServer();
     * ws.connect({
     * serverUrl: 'wss://wms.wisca.cn',
     * nameSpace: '/wmService'
     * path: 'wsApi',
     * listenerId: 'wcm', //监听id
     * licenseTypeId: 'wdm', //权限类型id
     * roomId: 'XX', //房间id
     * roomType: subsystemId, //房间类型
     * auth: {  //授权
     * logId: '1', //登录id
     * logPass: '1', //登录密码
     * token: '' //权限
     * }}, 
     * {
     * evs: ['msg'], //事件名称列表
     * evsListeners: { msg: evListener } //根据事件名称列表的回调函数 socket.on(ev, (payload, callback) => { evListener(payload, callback) })
     * connectSuccessListener, //连接成功后触发的回调函数
     * connectErrorListener //连接错误时触发的回调函数
     * disconnectListener //断开连接
     * })
     */
    connect = async (connectInfo: connectType, evsFun?: eventFunctionType) => {

        return new Promise((resolve, reject) => {
            let rtn = defaultRtn();
            const { serverUrl, nameSpace, path, listenerId, licenseTypeId, roomType, roomId, auth } = connectInfo;
            var evsListeners: any;
            var connectSuccessListener: any;
            var connectErrorListener: any;
            var evs: any;
            var disconnectListener: any;
            if (evsFun) {
                evs = evsFun.evs;
                evsListeners = evsFun.evsListeners;
                connectSuccessListener = evsFun.connectSuccessListener;
                connectErrorListener = evsFun.connectErrorListener;
                disconnectListener = evsFun.disconnectListener;
            }
            const wsQuery = {
                listenerId,
                licenseTypeId,
                roomType,
                roomId
            }
            /** 连接wsServer */
            const socket = io(serverUrl + nameSpace, {
                path,
                auth,
                query: wsQuery
            });
            socket.on('connect', async () => {

                this.connected = true;
                this.socket = socket;
                this.socketId = socket.id;
                this.error = undefined;
                this.connectInfo = connectInfo;




                if (connectSuccessListener) {
                    connectSuccessListener(this.socket);
                }
                //回调函数列表
                this.addListener(evs, evsListeners);
                rtn.success = this.connected;
                rtn.data = {
                    socket: this.socket
                }
                resolve(rtn)
            });
            /** 取消所有监听 */
            const evsRemoveListener = () => {
                if (evs) {
                    evs.map((ev: any) => {
                        try {
                            this.socket.removeListener(ev);
                        } catch { }
                    })
                }
            }
            //连接断开
            socket.on("disconnect", (reason: any, details: any) => {
                evsRemoveListener();
                if (disconnectListener) {
                    disconnectListener(this.socketId, { reason, details });
                }
                this.connected = false;
                this.socketId = undefined;
                rtn.success = this.connected;
                rtn.message = reason + JSON.stringify(details);
                reject(rtn);
            });
            //连接错误
            socket.on("connect_error", (err) => {
                evsRemoveListener();
                if (connectErrorListener) {
                    connectErrorListener(err);
                }
                this.error = err;
                rtn.success = this.connected;
                rtn.message = JSON.stringify(this.error);
                reject(rtn);
            });
        })
    };

    /**
     * 获取状态
     * @returns connected: boolean, socket: Object, connectInfo: Object
     */
    getState = () => {
        return defaultRtn(
            {
                success: true,
                data: {
                    connected: this.connected,
                    socket: this.socket,
                    connectInfo: this.connectInfo,
                }
            })
    };

    /** 断开连接 */
    disconnect = async () => {
        if (this.connected === true) {
            //离线
            const socketId = this.socket.id;
            this.socket.disconnect();
            this.connected = false;
            this.error = undefined;
            console.log('WS Server Disconnection, socketId: ' + socketId);
        }
        return defaultRtn({
            success: true
        })
    };

    /**
     * 发送数据
     * @param emitWsData 发送数据的内容
     * @param options 选项
     * @example ws.emit({<any>}, {
     * callback: function
     * })
     * @returns 
     */
    emit = async (emitWsData: emitWsDataType, options?: {
        /** 返回数据的方法，callback(data: any) */
        callback?: Function,
    }) => {
        return new Promise(async (resolve, reject) => {
            let rtn = defaultRtn();
            if (typeof options === 'undefined') {
                options = {};
            }
            let { callback } = options;

            const { listenerId } = this.connectInfo;

            //发送数据
            if (this.connected === true) {
                this.socket.emit(listenerId, {
                    ...emitWsData
                }, (response: any) => {
                    if (callback) {
                        callback(response);
                    }
                });
                rtn.success = true;
                rtn.message = '发送成功';
                resolve(rtn);
            } else {
                rtn.success = false;
                rtn.message = '发送失败';
                reject(rtn);
            }
        })
    };


};

export default wsServer;


