import { Badge, Button, Card, Checkbox, Divider, Input, List, Modal, Popconfirm, Popover, Select, Space, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react'
import system from '../../../config/systematic';
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import DateRoomDetail, { screeningChecked } from './DateRoomDetail';
import antMessage from '../../../utils/extend/AntdNotification'
import ChooseBookingDate from './ChooseBookingDate';
import moment from 'moment';
import SendMessage from '../messageManage/SendMessage';
import arrayHandle from '../../../utils/baseLib/arrayHandle';
import dayjs from 'dayjs';
import ViewMes from './ViewMes';
import RoomCateringOrder from '../cateringOrder/RoomCateringOrder';
import TempRoomList from '../../generalComponents/TempRoomList';
export default function DateRoom(props: any) {


    const { closeModal, data } = props;
    /**后端数据vmService */
    const wmService = system.wmService
    const [rows, setRows] = useState<any>();
    const [currentData, setCurrentData] = useState(1);
    const [pageSizeData, setPageSizeData] = useState(12);
    const [totalData, setTotalData] = useState(0);
    const [queryData, setQueryData] = useState('');
    const [parameter, setParameter] = useState<any>();

    /**
     * 当前勾选房间的详情
     */
    const [currentRoom, setCurrentRoom] = useState<object>();

    //气泡打开设置
    const [open, setOpen] = useState<any>(true);
    //选择气泡的id
    const [openId, setOpenId] = useState<any>();
    /**二次确认来源数据 */
    const [reconfirm, setReconfirm] = useState<any>();

    /**楼层Id */
    const [floorId, setfloorId] = useState<any>("998")
    /**
     * 房间类型Id
     */
    const [roomTypeId, setRoomTypeId] = useState<any>("999")
    /** 房间状态*/
    const [roomStatus, setRoomStatus] = useState<any>(-1)

    const [floorData, setFloorData] = useState<any>();
    const [roomTypeData, setRoomTypeData] = useState<any>();
    const [roomStatusData, setRoomStatusData] = useState<any>();
    /** 数据刷新中 */
    let loading = false;

    /**打开信息发送窗口 */

    const [modalSendMessagelopen, setModalSendMessageopen] = useState<Boolean>(false)

    //发送信息窗口数据
    const [sendMessagelData, setSendMessagelData] = useState<any>()

    /**打开分店房间详情窗口 */
    const [modalDateRoomDetailopen, setModalDateRoomDetailopen] = useState<Boolean>(false)

    const [viewMesOpen, setViewMesOpen] = useState<boolean>(false)

    const [roomCateringOrderOpen, setRoomCateringOrderOpen] = useState<boolean>(false)



    /**打开分店房间时间选择窗口 */
    const [modalChooseBookingDateopen, setModalChooseBookingDateopen] = useState<Boolean>(false)
    /**分店房间详情数据 */
    const [dateRoomDetailData, setdateRoomDetailData] = useState<any>();


    /**分店房间选择数据 */
    const [replaceReserveDateRoom, setReplaceReserveDateRoom] = useState<any>();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [viewMesData, setViewMesData] = useState<Array<any>>([]);

    const [roomCateringOrderData, setRoomCateringOrderData] = useState<any>([]);

    const handleOk = (e: any) => {
        closeModal();

    };

    const handleCancel = (e: any) => {
        closeModal(false);
    };
    useEffect(() => {
        const fetchData = async () => {

            getData(
                roomTypeId,
                roomStatus,
                floorId,
                currentData,
                pageSizeData,
                queryData
            )

            httpGet(wmService, {
                apiId: "branchStoreFloor",
                apiExtend: 'showList',
                apiData: {
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    },
                }
            }).then(res => {
                if (res.success) {
                    let newData = [{ id: "998", floor: "所有楼层" }, ...res.data]
                    setFloorData(newData)
                }
            })

            httpGet(wmService, {
                apiId: "roomType",
                apiExtend: 'showList',
                apiData: {
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    },
                    extendObj: {
                        menuHeadsIdNum: -1,

                    }
                }
            }).then(res => {
                if (res.success) {

                    let newData = [{ id: "999", roomType: "所有类型" }, ...res.data]
                    setRoomTypeData(newData)
                }
            })
            httpGet(wmService, {
                apiId: "generalData",
                apiExtend: 'showLists',
                apiVariable: "roomStatus@reconfirm",
            }).then(res => {
                if (res.success) {
                    const { roomStatus, reconfirm } = res.data
                    setRoomStatusData(roomStatus)
                    setReconfirm(reconfirm)
                }
            })




        }

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const roomTypeChange = (e: any) => {

        setRoomTypeId(e)
        getData(
            e,
            roomStatus,
            floorId,
            1,
            pageSizeData,
            queryData
        )

    }
    const reserveStatusChange = (e: any) => {

        setRoomStatus(e)
        getData(
            roomTypeId,
            e,
            floorId,
            1,
            pageSizeData,
            queryData
        )

    }
    const floorChange = (e: any) => {
        setfloorId(e)
        getData(
            roomTypeId,
            roomStatus,
            e,
            1,
            pageSizeData,
            queryData
        )
    }

    //获取数据
    const getData = (roomTypeId: string, roomStatus: number, floorId: string, current: number,
        pageSize: number,
        queryString: string, customerReserveId?: any, operation?: string, system?: any) => {
        if (loading === true) { return }
        loading = true;
        fetchApi.GET(
            wmService,
            {
                apiId: "tempRoom",
                apiExtend: 'query',
               
               
                apiData: {
                    roomTypeId,
                    roomStatus: Number(roomStatus),
                    floorId,
                    current,
                    pageSize,
                    queryString,
                    timeTypeId: data ? data.timeTypeId : "",
                    customerReserveId: data.customerReserveId ? data.customerReserveId : customerReserveId,
                    operation: data.operation ? data.operation : operation ? operation : "view",
                    system: data.system ? data.system : system ? system : "wm",
                    isReserve: true,
                    isReserveRoom: true,
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    }

                },

            }).then(res => {

                if (res.success === true) {

                    loading = false;
                    let rows;
                    let data: any = localStorage.getItem("rows");

                    if (data !== null) {

                        rows = arrayHandle.merge(res.data.rows, JSON.parse(data))

                        setRows(rows)
                    } else {
                        setRows(res.data.rows)


                    }

                    setCurrentData(res.data.current);
                    setPageSizeData(res.data.total);
                    setTotalData(res.data.total);
                    setQueryData(res.data.queryString);
                    setParameter(res.data.findTime)


                }

            })
    }


    const initData = () => {
        getData(
            roomTypeId,
            roomStatus,
            floorId,
            currentData,
            pageSizeData,
            queryData
        )
    }
    // const cc = (arr1: Array<any>) => {
    //     // localStorage.getItem("xxx")
    //     const dd:any = localStorage.getItem("rows")
    //     let vv:any;

    //     if( dd !==null){
    //         vv=JSON.parse(dd)
    //     }
    //     console.log("dd", dd);

    //     // console.log("dd",dd);
    //     var newArr;
    //     if (typeof arr1 !== "undefined") {
    //         newArr = arrayHandle.merge(arr1, vv)

    //     }
    //     return newArr
    // }
    // 表格分页属性
    const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: false,
        showTotal: () => `共${totalData}条`,
        pageSize: pageSizeData,
        current: currentData,
        total: totalData,
        onShowSizeChange: (current: number, pageSize: number) => {

            getData(roomTypeId,
                roomStatus,
                floorId,
                current,
                pageSize,
                queryData)

        },
        onChange: (current: number) => {
            getData(roomTypeId,
                roomStatus,
                floorId,
                current,
                pageSizeData,
                queryData)
        }
    };

    //相关参数
    let sendData = {
        type: "NEW",
        id: '',
        customerReserveId: "",
        originalTimeTypeId: "",
        bookingDate: ""
    }


   

    //预订房间
    const handleChooseRoom = (item: any) => {

        //当前打开房间
        setCurrentRoom(item);

        //本地存储数据
        let storageData: any = localStorage.getItem("rows");
        let arr: Array<any> = [];
        let nowData = { ...item, isChecked: true }
        let res: any;
        if (storageData !== null) {
            res = JSON.parse(storageData)
            let newData: any = arrayHandle.merge(res, [nowData])

            arr = newData;
        } else {
            arr.push(nowData)
        }
        localStorage.setItem("rows", JSON.stringify(arr))

        setdateRoomDetailData({
            id: item.id,
            timeTypeId: item.timeTypeId,
            timeTypeName: `${item.timeType.bookingDate}(${dayjs(item.timeType.bookingDate).format('ddd')})${item.timeType.scheduledTime.timeName}`,
            bookingDate:item.timeType.bookingDate,
            branchStoreId: item.timeType.scheduledTime.branchStoreId,
            timeName: "",
            type: "NEW",
            operation: "add",
            passageId: item.passageId
        })
        setModalDateRoomDetailopen(true)
        //获取最新本地数据
        let nowLocalStorage: any = localStorage.getItem("rows")
        let nowLocalStorageData = JSON.parse(nowLocalStorage)

        let newRows = arrayHandle.merge(rows, nowLocalStorageData)
        setRows(newRows)
        // setdateRoomDetailData(sendData)


    }

    //占位
    const sitt = (item: any, isChecked: boolean) => {
        //本地存储数据
        let storageData: any = localStorage.getItem("rows");
        let arr: Array<any> = [];
        let nowData = { ...item, isChecked }
        let res: any;
        if (storageData !== null) {
            res = JSON.parse(storageData)
            let newData: any = arrayHandle.merge(res, [nowData])

            arr = newData;
        } else {
            arr.push(nowData)
        }
        localStorage.setItem("rows", JSON.stringify(arr))
        //获取最新本地数据
        let nowLocalStorage: any = localStorage.getItem("rows")
        let nowLocalStorageData = JSON.parse(nowLocalStorage)

        let newRows = arrayHandle.merge(rows, nowLocalStorageData)
        setRows(newRows);

        if (isChecked) {
            let data = screeningChecked()
            const { newData } = data
            fetchApi.PUT(wmService, {
                apiId: "tempRoom",
                apiExtend: "sittRoom",
                apiData: {
                    rows: newData
                }

            }).then((res) => {
                if (res.success) {
                    localStorage.removeItem("rows")
                    initData()
                    antMessage("success", "占用成功", 'success');
                }

            }).catch((err) => {
                antMessage("error", err, '错误');
            })

        }


    }

    //取消占位
    const caSitt = (item: any) => {

        fetchApi.PUT(wmService, {
            apiId: "tempRoom",
            apiExtend: "caSittRoom",
            apiData: {
                id: item.id
            }

        }).then((res) => {
            if (res.success) {
                localStorage.removeItem("rows")
                initData()
                antMessage("success", "取消成功", 'success');
            }

        }).catch((err) => {
            antMessage("error", err, '错误');
        })
    }


    //更改预订
    const handleReplaceRoom = (item: any) => {
        sendData.id = item.id;
        sendData.bookingDate = item.timeType.bookingDate;
        sendData.customerReserveId = item.customerReserveId;
        sendData.originalTimeTypeId = item.timeTypeId;
        sendData.type = "replaceRoom";
        setReplaceReserveDateRoom(sendData)
        setModalChooseBookingDateopen(true)

    }

    const handleViewMes = (data: Array<any>) => {

        setViewMesData(data)
        setViewMesOpen(true)
    }





    //添加房间
    // const addRoom = (item: any) => {

    //     setAddRoomData({
    //         customerReserveId: item.customerReserveId,
    //         timeTypeId: item.timeTypeId,
    //         roomId: item.roomId
    //     })
    //     setModalBookingNumopen(true)

    // }

    //关闭预坐人数窗口刷新数据
    // const closeBookingNumopen = (state: Boolean) => {
    //     setModalBookingNumopen(false)
    //     if (state === true) {
    //         getData(
    //             roomTypeId,
    //             roomStatus,
    //             floorId,
    //             currentData,
    //             pageSizeData,
    //             queryData
    //         )
    //     }
    // }
    //关闭房间详情窗口刷新数据
    const closeDateRoomDetailopen = (state: Boolean, customerReserveId?: any, system?: any, operation?: string) => {
        setModalDateRoomDetailopen(false)
        if (state === true) {
            getData(
                roomTypeId,
                roomStatus,
                floorId,
                currentData,
                pageSizeData,
                queryData,
                customerReserveId,
                operation,
                system
            )
        }
        //  else {
        //     checkRoom(currentRoom, state)
        // }
    }

    const closeSendMessageopen = (state: boolean) => {
        setModalSendMessageopen(false)
        if (state === true) {
            getData(
                roomTypeId,
                roomStatus,
                floorId,
                currentData,
                pageSizeData,
                queryData
            )
        }
    }
    //关闭查看短信
    const closeViewMessOpen = (state: boolean) => {
        setViewMesOpen(false)
        if (state === true) {
            getData(
                roomTypeId,
                roomStatus,
                floorId,
                currentData,
                pageSizeData,
                queryData
            )
        }
    }

    //关闭查看房间订单
    const closeRoomCateringOpen = (state: boolean) => {
        setRoomCateringOrderOpen(false)
        if (state === true) {
            getData(
                roomTypeId,
                roomStatus,
                floorId,
                currentData,
                pageSizeData,
                queryData
            )
        }
    }
    /**信息发送 */
    const sendMessage = (item: any) => {

           

        setSendMessagelData(
            {
                customerReserveId: item.customerReserveId,
                phones: item.customerReserve.contactCall,
                branchStoreId: item.timeType.scheduledTime.branchStoreId,
                timeTypeId: item.timeTypeId,
                // timeTypeName: `${item.timeType.bookingDate}(${dayjs(item.timeType.bookingDate).format('ddd')})${item.timeType.scheduledTime.timeName}${dayjs(item.reserveTempRoom.arriveTime).format("HH:mm")}`,
                floorName: item.room.floorId,
                roomName: item.room.roomName,
                currentCustomerName: item.customerReserve.contact,
                tempRoomId: item.id,
                passageId: item.passageId
            })
        setModalSendMessageopen(true)

    }

    /**修改预订信息 */
    const changedTempRoom = (item: any) => {

        if (!item.customerReserveId) return
        setModalDateRoomDetailopen(true)
        setdateRoomDetailData({
            branchStoreId: item.timeType.scheduledTime.branchStoreId,
            type: "EDIT",
            id: item.id
        })
    }

    /**回拨 */
    const callBack = (item: any) => {

        let socketId = localStorage.getItem("wdmSocketId")
        let nameSpace = localStorage.getItem("nameSpace")
        let subsystemId = localStorage.getItem("subsystemId")


        console.log("参数", socketId, nameSpace);
        console.log('system.wsServer')
        console.log(system.wsServer);

        fetchApi.GET(wmService, {
            apiId: "caller",
            apiExtend: 'callbackPhone',
            apiData: {
                phone: item.customerReserve.contactCall,
                subsystemId
            }
        }).then((res: any) => {

        }).catch((err: any) => {
            console.log(err);

        })


    }


    //勾选
    const checkRoom = (item: any, e: any) => {

        let arr: Array<any> = [];

        let checked = e.target ? e.target.checked : e
        let itemData = item ? item : currentRoom;

        //勾选的数据
        const aa = { ...itemData, isChecked: checked }

        let data: any;

        //本地存储数据
        let storageData: any = localStorage.getItem("rows");

        if (storageData !== null) {
            data = JSON.parse(storageData)
            let newData: any = arrayHandle.merge(data, [aa])
            arr = newData;
        } else {

            if (checked) {
                arr.push(aa)
            }
        }

        localStorage.setItem("rows", JSON.stringify(arr))


        //获取最新本地数据
        let nowLocalStorage: any = localStorage.getItem("rows")
        let nowLocalStorageData = JSON.parse(nowLocalStorage)

        let newRows = arrayHandle.merge(rows, nowLocalStorageData)
        setRows(newRows)

    }



    //取消
    const cancelOpen = () => {
        setOpen(false)
    }

    const handleOpenChange = (item: any, newOpen: boolean) => {

        setOpen(newOpen);
        setOpenId(item.id)
    };

    //取消房间   1所有 2单个
    const cancelTempRoom = (item: any,cancelType:number) => {
        const { customerReserveId,id } = item;
        setIsLoading(true)
        fetchApi.PUT(
            wmService,
            {
                apiId: "tempRoom",
                apiExtend: "cancelAllRoom",
                apiData: {
                    customerReserveId,
                    cancelType,
                    tempRoomId:id
                }
            }
        ).then(res => {

            if (res.success === true) {
                setIsLoading(false)

                localStorage.removeItem("rows")
                antMessage('success', '退房成功', 'success');
                //刷新数据
                getData(
                    roomTypeId,
                    roomStatus,
                    floorId,
                    1,
                    pageSizeData,
                    queryData
                )
                cancelOpen()
            }
        }).catch(err => {
            console.log(err);

            setIsLoading(false)
        })

    }



    //选择二次确认信息
    const reconfirmData = (item: any, e: any) => {


        fetchApi.POST(wmService, {
            apiId: "tempRoom",
            apiExtend: 'isReconfirm',
            apiData: {
                reconfirmId: e,
                customerReserveId: item.customerReserveId,
                timeTypeId: item.timeTypeId
            }
        }).then(res => {

            if (res.success === true) {


                getData(
                    roomTypeId,
                    roomStatus,
                    floorId,
                    currentData,
                    pageSizeData,
                    queryData
                )

            }

        }).catch(err => {
            console.log("err", err);
        })
    }


    const findOrderTempRoom = (rows: Array<any>) => {
        let roomName: string = "";
        for (var i = 0; i < rows.length; i++) {
            roomName ? roomName += `,${rows[i].timeType.bookingDate}(${rows[i].timeType.typeName})--${rows[i].room.roomNum}(${rows[i].room.roomName})` : roomName = `${rows[i].timeType.bookingDate}(${rows[i].timeType.typeName})--${rows[i].room.roomNum}(${rows[i].room.roomName})`;
        }
        return roomName
    }
    return (
        <>
            <Modal
                // style={{ maxHeight: '100%'}}
                title={
                    <div >
                        <div>{parameter ? `${parameter.bookingDate}(${dayjs(parameter.bookingDate).format('ddd')})--${parameter.typeName}--房间管理` : ""}</div>
                        <div> <label>房间类型 :  </label>
                            <Select
                                showSearch
                                onChange={roomTypeChange}
                                placeholder="房间类型"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.general(roomTypeData, "roomType")}
                            </Select>
                            <span> - </span>
                            <label>房间状态 :  </label>
                            <Select
                                showSearch
                                onChange={reserveStatusChange}
                                placeholder="房间状态"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.general(roomStatusData, "name")}
                            </Select>


                            <span> - </span>
                            <label>房间楼层 :  </label>
                            <Select
                                showSearch
                                onChange={floorChange}
                                placeholder="楼层"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.general(floorData, "floor")}
                            </Select>

                            <span> - </span>
                            <label>查询 :  </label>
                            <Space>
                                <Input
                                    style={{ width: 100 }}
                                   onKeyDown={(e: any) => {
                                        if (e.key === 'Enter')
                                            getData(roomTypeId,
                                                roomStatus,
                                                floorId,
                                                currentData,
                                                pageSizeData,
                                                queryData)
                                    }}
                                    onChange={(e: any) => {

                                        setQueryData(e.target.value)
                                    }
                                    }
                                />
                            </Space></div>
                    </div>
                }
                centered
                // zIndex={1}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={true}
                maskClosable={false}
                width={"90%"}


            >

                <div style={{ marginTop: "20px", maxHeight: '700px', overflowY: 'auto', overflowX: "hidden" }}>
                    <TempRoomList props={{
                        listObj: {
                            grid: { gutter: 16, column: 4 },
                            dataSource: rows,
                            itemLayout: "horizontal",
                            pagination: paginationProps,
                            renderItem: (item: any) => {
                                return (
                                    // <></>
                                    <List.Item
                                    >
                                        <Card
                                            style={{ backgroundColor: item.color }}
                                            title={<div onClick={() => changedTempRoom(item)} style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    <Badge count={item.customerReserveId ? item.customerReserve.tempRooms.length >= 2 ? "拼" : 0 : 0} >
                                                        <Tooltip placement="topLeft" title={`${item.room.roomName}`}>{
                                                            <div style={{ fontSize: "30px", cursor: item.customerReserveId ? "pointer" : "" }}>{(typeof `${item.room.roomName}` !== "undefined" && `${item.room.roomName}` !== null) ?
                                                                (`${item.room.roomName}`.length > 8 ? `${item.room.roomName}`.substring(0, 8) + '..' : `${item.room.roomName}`)
                                                                : ''}</div>
                                                        }</Tooltip>
                                                    </Badge>



                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    <div style={{ fontSize: "18px" }}>{item.customerReserveId ? `${item.customerReserve.contact}${item.customerReserve.appellationName}` : item.roomStatusName} </div>
                                                    <div style={{ fontSize: "16px" }}>
                                                        {item.customerReserveId ? item.customerReserve.contactCall : null}
                                                    </div>
                                                </div>
                                            </div>}
                                            actions={
                                                item.customerReserveId ? [
                                                    <Popover title={

                                                        <div style={{ maxWidth: "250px" }}>
                                                            <div >{`确定要退房吗？`}</div>
                                                            <div >{`${item.customerReserve.tempRooms.length === 1 ? `该订单有1间房` : `该订单共有${item.customerReserve.tempRooms.length}间房,分别是${findOrderTempRoom(item.customerReserve.tempRooms)}`}`}</div>
                                                        </div>
                                                    } content={
                                                        <>
                                                            <Button onClick={cancelOpen}>取消</Button>
                                                            <span> </span>
                                                            <Button loading={isLoading} onClick={() => cancelTempRoom(item,2)}>{item.customerReserve.tempRooms.length === 1 ? "确认" : "取消单间"}</Button>
                                                            <span> </span>
                                                            {item.customerReserve.tempRooms.length > 1 ? <Button loading={isLoading} onClick={() => cancelTempRoom(item,1)}>取消所有</Button> : null}
                                                        </>

                                                    } onOpenChange={handleOpenChange.bind(props, item)} trigger="click" open={item.id === openId && open}

                                                    >
                                                        <div>取消预订</div>
                                                    </Popover>,
                                                    <div onClick={() => handleReplaceRoom(item)}>更改预订</div>,
                                                    <div onClick={() => sendMessage(item)} >发送信息</div>,
                                                    <div onClick={() => callBack(item)} style={{ cursor: "pointer" }}>回拨</div>


                                                ] : item.roomStatus === 0 ? [
                                                    <div onClick={() => handleChooseRoom(item)}>预订房间</div>,
                                                    <Popconfirm
                                                        title="占用房间"
                                                        onConfirm={() => sitt(item, true)}
                                                        okText="是"
                                                        cancelText="否"
                                                        onCancel={() => sitt(item, false)}
                                                    >
                                                        <div >占位</div>
                                                    </Popconfirm>,

                                                    <Checkbox checked={item.isChecked} onChange={checkRoom.bind(props, item)}>勾选</Checkbox>,
                                                ] : [
                                                    <Popconfirm
                                                        title="取消占用"
                                                        onConfirm={() => caSitt(item)}
                                                        okText="是"
                                                        cancelText="否"

                                                    >
                                                        <div >取消</div>
                                                    </Popconfirm>,
                                                    // <div onClick={() => handleRoomCateringOrder(item)}>查看订单</div>
                                                ]
                                            }
                                        >
                                            <div style={{ height: "80px" }}>
                                                {item.customerReserveId ? <>
                                                    {/* <div style={{ display: "flex", justifyContent: "space-between", fontSize: "13px", }}>
    
                                                    </div> */}
                                                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: "13px", }}>
                                                        <div>人数：{item.sitNumber ? item.sitNumber : item.customerReserve.bookingNum}</div>
                                                        <div>到达时间：{item.customerReserve.arriveTime ? moment(item.customerReserve.arriveTime).format("HH:mm") : null}</div>
                                                        <div>经手人：{item.customerReserve ? item.customerReserve.handlers : null}</div>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: "13px", }}>
                                                        <div>信息：{item.customerReserve.smsManages.length === 0 ? "未发送" : "发送成功"}</div>
                                                        <div>状态：{item.roomStatusName}</div>
                                                    </div>
                                                    <div>备注：<Tooltip placement="top" title={item.customerReserve.memo}>{
                                                        (typeof item.customerReserve.memo !== "undefined" && item.customerReserve.memo !== null) ?
                                                            (item.customerReserve.memo.length >= 15 ? item.customerReserve.memo.substring(0, 15) + '......' : item.customerReserve.memo)
                                                            : ''
                                                    }</Tooltip>
                                                    </div>
                                                    <div style={{ display: "flex", justifyContent: "space-between", fontSize: "13px" }}>
                                                        <div>二次确认：  <Select
                                                            size="small"
                                                            dropdownMatchSelectWidth={false}
                                                            onChange={reconfirmData.bind(props, item)}
                                                            value={item.reconfirmId}
                                                            showSearch
                                                            placeholder="确认来源"
                                                            optionFilterProp="children"
                                                            filterOption={(input, option: any) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {getList.general(reconfirm, 'name')}
                                                        </Select> </div>
                                                        <div><Button size='small' disabled={item.customerReserve.smsManages && item.customerReserve.smsManages.length > 0 ? false : true} onClick={() => handleViewMes(item.customerReserve.smsManages)}>查看短信</Button></div>
                                                    </div>


                                                </> : null}
                                            </div>
                                        </Card>
                                    </List.Item>

                                )
                            }
                        },

                    }}>

                    </TempRoomList>

                    {/**房间预订 */}
                    {modalDateRoomDetailopen ? (<DateRoomDetail getData={getData} data={dateRoomDetailData} closeModal={closeDateRoomDetailopen}
                    />)
                        : null}

                    {/**房间预订提示 */}
                    {/* {modalTipsopen ? (<Tip getData={getData} data={dateRoomDetailData} closeModal={setModalTipsopen}
                    />)
                        : null} */}

                    {/**发送信息 */}
                    {modalSendMessagelopen ? (<SendMessage data={sendMessagelData} closeModal={closeSendMessageopen}
                    />)
                        : null}
                    {/**更改预订 */}
                    {modalChooseBookingDateopen ? (<ChooseBookingDate getData={initData} data={replaceReserveDateRoom} setModalChooseBookingDateopen={setModalChooseBookingDateopen}
                    />)
                        : null}
                    {/**查看短信 */}
                    {viewMesOpen ? (<ViewMes data={viewMesData} closeModal={closeViewMessOpen}
                    />)
                        : null}

                    {/**查看房间订单 */}
                    {roomCateringOrderOpen ? (<RoomCateringOrder data={roomCateringOrderData} closeModal={closeRoomCateringOpen}
                    />)
                        : null}
                </div>

            </Modal>
        </>
    )
}
