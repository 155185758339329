import { Col, Form, Input, InputNumber, Popover, Row, Select, Switch, TimePicker } from 'antd';
import { useEffect, useState } from 'react'
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import system from '../../../config/systematic'
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification'
import dayjs from 'dayjs';

import SendMessage from '../messageManage/SendMessage';

const { TextArea } = Input;
export default function DateRoomDetail(props: any) {
  /**后端数据vmService */
  const wmService = system.wmService;
  const { closeModal, data, setModalChooseBookingDateopen } = props;


  const [form] = Form.useForm(); //form 

  /**最小人数 */
  const [minNum, setMinNum] = useState<any>();
  /**最大人数 */
  const [maxNum, setMaxMum] = useState<Number>();
  /**参数数据 */
  const [parameter, setParameter] = useState<any>();

  const [appellationData, setAppellationData] = useState<any>();

  /**分店房间详情数据 */
  // const [branchStoreRoomDetailData, setbranchStoreRoomDetailData] = useState<any>();


  /**打开信息发送窗口 */

  const [modalSendMessagelopen, setModalSendMessageopen] = useState<Boolean>(false)
  //发送信息窗口数据
  const [sendMessagelData, setSendMessagelData] = useState<any>()
  const [roomTypeData, setRoomTypeData] = useState<any>();
  const [banquetData, setBanquetData] = useState<any>();
  const [roomData, setRoomData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [roomMergeName, setRoomMergeName] = useState<any>("")

  /**预订时间数据 */
  const [timeTypeIdData, setTimeTypeIdData] = useState<any>();

  const handleOk = async (e: any) => {

    form.validateFields() //数据校验
      .then(async (values: any) => {
        let nowData = values;
        const editData = {
          ...nowData,
          roomCounting: 0,

        }
      

        setIsLoading(true)
        //新增
        if (data.type === "NEW") {
          let newData = screeningChecked();
          //创建顾客订单
          fetchApi.POST(
            wmService, {
            apiId: "customerReserve",
            apiData: {
              ...editData,
              containMinNum: parameter.containMinNum,
              containMaxNum: parameter.containMaxNum,
              bookingDate: data.bookingDate,
              timeTypeId: data.timeTypeId,
              rows: newData.newData,
              reserveStatus: 0,
              mountDataOptions: {
                userMount: {
                  isUserLogin: true,
                }
              },
            }
          }
          ).then(res => {

            localStorage.removeItem("callerCustomerData")
            if (res.success === true) {
              fetchApi.PUT(wmService, {
                apiId: "callerCustomer",
                apiExtend: "addOrUpdate",
                apiData: {
                  phoneNumber: editData.contactCall,
                  customerName: editData.contact,
                  appellationId: editData.appellationId,
                  branchStoreId: data.branchStoreId
                }
              }).then(res => {

              }).catch(err => {
                console.log("err", err);

              })

              if (editData.isSend) {
                setIsLoading(false)
                setSendMessagelData({
                  phones: editData.contactCall,
                  currentCustomerName: editData.contact,
                  branchStoreId: data.branchStoreId,
                  isDateRoomDetail: true,
                  customerReserveId: res.data.id,
                  timeTypeId: editData.timeTypeId,
                  tempRoomId: data.id
                })
                setModalSendMessageopen(true)
              } else {
                //刷新数据
                setIsLoading(false)
                antMessage('success', '预订成功!!', 'success');
                localStorage.removeItem("rows")
                closeModal(true, res.data.id, undefined, "add",)
              }

            }


          }).catch(err => {
            console.log("err", err);
            antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
            localStorage.removeItem("rows")
            setIsLoading(false)
            closeModal(true)


          })
        }
        //编辑
        if (data.type === "EDIT") {

          fetchApi.PUT(
            wmService,
            {
              apiId: "tempRoom",
              apiExtend: "update",
              apiData: {
                ...editData,
                customerReserveId: parameter.customerReserveId,
                customerReserve: {
                  appellationId: editData.appellationId,
                  banquetId: editData.banquetId,
                  contact: editData.contact,
                  contactCall: editData.contactCall,
                  email: editData.email,
                  memo: editData.memo,
                  arriveTime: editData.arriveTime
                }
              }
            }).then(res => {

              if (res.success === true) {
                fetchApi.PUT(wmService, {
                  apiId: "callerCustomer",
                  apiExtend: "addOrUpdate",
                  apiData: {
                    phoneNumber: editData.contactCall,
                    customerName: editData.contact,
                    appellationId: editData.appellationId,
                    branchStoreId: data.branchStoreId
                  }
                }).then(res => {

                }).catch(err => {
                  console.log("err", err);

                })
                //刷新数据
                localStorage.removeItem("rows")
                setIsLoading(false)
                antMessage('success', '修改成功!!', 'success');
                closeModal(true)
              }
            }).catch(err => {
              setIsLoading(false)
              antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
            })
        }
        //更换房间
        if (data.type === "replaceRoom") {
          //检查是否能更换房间


          let situation = depositSituation(parameter, editData.skipDeposit)

          if (situation) {
            fetchApi.PUT(
              wmService,
              {
                apiId: "tempRoom",
                apiExtend: "replaceRoom",
                apiData: {
                  id: data.nowId,
                  customerReserveId: data.customerReserveId,
                  originalTimeTypeId: data.originalTimeTypeId,
                  timeTypeId: data.timeTypeId,
                  deposit: parameter.deposit,

                  // originData: parameter.originData,
                  replaceData: parameter.replaceData,
                  originTempRoomId: parameter.originTempRoomId,
                  replaceTempRoomId: parameter.replaceTempRoomId,
                  editData: {
                    ...editData,
                    unionId: parameter.unionId,
                    telePhone: parameter.telePhone,

                  }
                }
              }).then(res => {
                if (res.success === true) {
                  //刷新数据
                  setIsLoading(false)
                  closeModal(true)
                  setModalChooseBookingDateopen(false)
                  console.log("换房成功!!");
                  antMessage('success', '换房成功!!', 'success');
                }
              }).catch(err => {
                console.log("更换房间失败");
                setIsLoading(false)
                antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);

              })
          } else {
            setIsLoading(false)
          }


        }

        //分配房间
        if (data.type === "distribute") {
          console.log("这是分配房间");

          fetchApi.POST(wmService, {
            apiId: "tempRoom",
            apiExtend: "distributionRoom",
            apiData: {
              customerReserveId: data.customerReserveId,
              bookingNum: editData.bookingNum,
              tempRoomId: data.nowId,

            }
          }).then((res) => {
            if (res.success === true) {
              setIsLoading(false)
              closeModal(true)
              antMessage('success', res.message, "success");
            }

          }).catch((err) => {
            setIsLoading(false)
            antMessage('error', err.message, 'error');

          })

          // closeModal(true)

        }
      }

      )
      .catch((err: any) => {
        console.log(err);
        setIsLoading(false)
        antMessage('warning', '无法通过数据校验', '请输入所需数据！');
      }
      );

    // closeModal()
  };
  const handleCancel = (e: any) => {

    if (data.type === "distribute") {

      closeModal(false)
    }
    if (data.type === "replaceRoom" || data.type === "EDIT" || data.type === "NEW") {
      closeModal(false)

    }


  };
  const closeSendMessageopen = (state: boolean) => {
    closeModal(false)
    setModalSendMessageopen(false)
    if (state === true) {
      closeModal(true)
    }
  }

  /**检查是否可以更换房间 */
  const depositSituation = (parameter: any, skipDeposit: any) => {
    if (!parameter) return false
    //都需要定金的情况
    if (parameter.isDeposit && parameter.originalIsDeposit) {
      console.log("都需要");
      if (parameter.skipDeposit) {
        console.log("之前的房间属于已跳过定金");
        if (skipDeposit) {
          console.log("可换");

          return true
        } else {
          console.log("不可换");

          return false
        }

      } else {
        if (parameter.originalDeposit > parameter.deposit) {
          console.log("可以换");
          return true

        } else {
          if (skipDeposit) {
            console.log("跳过定金");
            console.log("可以换");
            return true
          } else {
            console.log("不跳过定金");
            console.log("不可换");
            return false
          }


        }
      }



    }
    //都不需要定金的情况
    if (!parameter.isDeposit && !parameter.originalIsDeposit) {
      console.log("都不需要");
      console.log("随便换");
      return true

    }

    //原来的需要，新的不需要的情况
    if (parameter.originalIsDeposit && !parameter.isDeposit) {
      //
      console.log("原来的需要，新的不需要");
      console.log("可以换");
      return true

    }
    //原来的不需要，新的需要的情况
    if (!parameter.originalIsDeposit && parameter.isDeposit) {
      console.log("原来不需要，新的需要");
      if (skipDeposit) {
        console.log("跳过定金");
        console.log("可换");
        return true

      } else {
        console.log("不能换");
        return false
      }



    }
  }
  /**跳过人数限制 */
  const checkNumber = (e: any) => {

    if (e) {
      if (parameter) {

        setMinNum(parameter.containMinNum)
        setMaxMum(parameter.containMaxNum)
      }
    } else {
      setMinNum(1)
      setMaxMum(100)
    }

  }
  //获取数据
  const [row] = useState<any>(
    async () => {
      if (data.id) {
        const resData = await fetchApi.GET(wmService, {
          apiId: "tempRoom",
          apiVariable: data.id,
          apiExtend: "showSingle"
        });
        return resData
      }
    }
  )



  useEffect(() => {
    form.setFieldsValue({ isSend: true })


    const fetchData = async () => {
      httpGet(wmService, {
        apiId: "room",
        apiExtend: 'showList',
        apiData: {
          mountDataOptions: {
            userMount: {
              isDept: true,
            }
          },
          extendObj: {
            menuHeadsIdNum: -1,
            printersIdNum: -1,
          }
        }
      }).then(res => {
        setRoomData(res.data)
      })




      httpGet(wmService, {
        apiId: "timeType",
        apiExtend: 'showList',
        apiData: {
          mountDataOptions: {
            userMount: {
              isDept: true,
            }
          },
        }
      }).then(res => {

        if (res.success) {

          setTimeTypeIdData(res.data)
        }
      })
      httpGet(wmService, {
        apiId: "roomType",
        apiExtend: 'showList',
        apiData: {
          mountDataOptions: {
            userMount: {
              isDept: true,
            }
          },
          extendObj: {
            menuHeadsIdNum: -1,

          }
        }
      }).then(res => {
        if (res.success) {
          setRoomTypeData(res.data)
        }
      })


      httpGet(wmService, {
        apiId: "banquet",
        apiExtend: 'showList',
        apiData: {
          mountDataOptions: {
            userMount: {
              isBrand: true,
            }
          },
        }
      }).then(res => {
        if (res.success) {
          setBanquetData(res.data)
          res.data.map((item: any) => {
            if (data.type === "NEW") {
              if (item.isDefault) {

                form.setFieldsValue({ banquetId: item.id })
              }
            }
          })
        }
      })
      httpGet(wmService, {
        apiId: "generalData",
        apiExtend: 'showList',
        apiVariable: "appellation",
      }).then(res => {
        if (res.success) {

          setAppellationData(res.data)
        }
      })

      if (data.type === "NEW") {

        let pp = screeningChecked();
        const { containMaxNum, containMinNum, isDeposit, roomName } = pp
        setRoomMergeName(roomName)

        row.then((res: any) => {
          let today = dayjs().format("YYYY-MM-DD") + " " + res.data.timeType.scheduledTime.guestArrTime;
          if (res.success === true) {
            form.setFieldsValue({
              // id: res.data.id,
              timeTypeId: res.data.timeTypeId,
              arriveTime: dayjs(today),
              bookingNum: containMinNum,
            })
            // setMinNum(res.data.room.roomType.containMinNum)
            // setMaxMum(res.data.room.roomType.containMaxNum)
            checkNumber(true)
            setMinNum(containMinNum)
            setMaxMum(containMaxNum)
            setParameter({
              // bookingNum:containMinNum,
              // roomNum: res.data.room.roomNum,
              // roomName: res.data.room.roomName,
              isDeposit,
              containMinNum: containMinNum,
              containMaxNum: containMaxNum,
              checkNumber: true,
              startTime: res.data.timeType.scheduledTime.startTime,
              endTime: res.data.timeType.scheduledTime.endTime

            })



          }

        })

        const callerCustomerData = localStorage.getItem("callerCustomerData")
        if (callerCustomerData) {
          let callerCustomerDataEx = JSON.parse(callerCustomerData)
          const { customerName, phoneNumber, logId, appellationId } = callerCustomerDataEx;

          form.setFieldsValue({
            contact: customerName,
            contactCall: phoneNumber,
            handlers: logId,
            appellationId: appellationId ? Number(appellationId) : null
          })
        }






        form.setFieldsValue({ appellationId: 1 })
      }
      if (data.type === "EDIT") {
        row.then((res: any) => {

          if (res.success === true) {
            form.setFieldsValue({
              id: res.data.id,
              timeTypeId: res.data.timeTypeId,
              roomTypeId: res.data.room.roomTypeId,
              roomId: res.data.roomId,
              banquetId: res.data.customerReserve.banquetId,
              bookingNum: res.data.sitNumber,
              contact: res.data.customerReserve.contact,
              contactCall: res.data.customerReserve.contactCall,
              email: res.data.customerReserve.email,
              memo: res.data.customerReserve.memo,
              checkNumber: res.data.checkNumber,
              skipDeposit: res.data.skipDeposit,
              appellationId: res.data.customerReserve.appellationId,
              arriveTime: res.data?.customerReserve?.arriveTime ? dayjs(res.data?.customerReserve?.arriveTime) : null
            })
            setMinNum(res.data.room.roomType.containMinNum)
            setMaxMum(res.data.room.roomType.containMaxNum)
            setParameter({
              containMinNum: res.data.room.roomType.containMinNum,
              containMaxNum: res.data.room.roomType.containMaxNum,
              roomNum: res.data.room.roomNum,
              roomName: res.data.room.roomName,
              customerReserveId: res.data.customerReserveId,
              isDeposit: res.data.room.roomType.isDeposit,
              startTime: res.data.timeType.scheduledTime.startTime,
              endTime: res.data.timeType.scheduledTime.endTime

            })
            checkNumber(res.data.checkNumber)
          }

        })





      }

      //更换房间
      if (data.type === "replaceRoom") {
        //之前的数据
        let originalData = await row;

        //现在的数据
        const nowData = await fetchApi.GET(wmService, {
          apiId: "tempRoom",
          apiVariable: data.nowId,
          apiExtend: "showSingle"
        });

        if (nowData.success === true) {
          let today = dayjs().format("YYYY-MM-DD") + " " + nowData.data.timeType.scheduledTime.guestArrTime

          form.setFieldsValue({
            id: nowData.data.id,
            timeTypeId: nowData.data.timeTypeId,
            roomTypeId: nowData.data.room.roomTypeId,
            roomId: nowData.data.roomId,
            bookingNum: nowData.data.room.roomType.containMinNum,
            arriveTime: dayjs(today)
          })

          setMinNum(nowData.data.room.roomType.containMinNum)
          setMaxMum(nowData.data.room.roomType.containMaxNum)

          checkNumber(nowData.data.checkNumber)
        }
        if (originalData.success) {


          form.setFieldsValue({
            contact: originalData.data.customerReserve.contact,
            contactCall: originalData.data.customerReserve.contactCall,
            banquetId: originalData.data.customerReserve.banquetId,
            memo: originalData.data.customerReserve.memo,
            appellationId: originalData.data.customerReserve.appellationId
          })




        }
        setParameter({
          originTempRoomId: originalData.data.id,
          replaceTempRoomId: nowData.data.id,
          containMinNum: nowData.data.room.roomType.containMinNum,
          containMaxNum: nowData.data.room.roomType.containMaxNum,
          roomNum: nowData.data.room.roomNum,
          roomName: nowData.data.room.roomName,
          skipDeposit: originalData.data.skipDeposit,
          isDeposit: nowData.data.room.roomType.isDeposit,
          originalIsDeposit: originalData.data.room.roomType.isDeposit,
          nowRoomTypeId: nowData.data.room.roomTypeId,
          originalRoomTypeId: originalData.data.room.roomTypeId,
          originalDeposit: originalData.data.room.roomType.deposit,
          deposit: nowData.data.room.roomType.deposit,
          replaceData: nowData.data,
          // originData: originalData.data,
          startTime: nowData.data.timeType.scheduledTime.startTime,
          endTime: nowData.data.timeType.scheduledTime.endTime,
          unionId: originalData.data.customerReserve.unionId,
          dataSource: originalData.data.customerReserve?.dataSource,




        })




      }

      //分配房间
      if (data.type === "distribute") {
        //现在的数据
        const nowData = await fetchApi.GET(wmService, {
          apiId: "tempRoom",
          apiVariable: data.nowId,
          apiExtend: "showSingle"
        });

        if (nowData.success === true) {
          let today = dayjs().format("YYYY-MM-DD") + " " + nowData.data.timeType.scheduledTime.guestArrTime

          form.setFieldsValue({
            id: nowData.data.id,
            timeTypeId: nowData.data.timeTypeId,
            roomTypeId: nowData.data.room.roomTypeId,
            roomId: nowData.data.roomId,
            bookingNum: nowData.data.room.roomType.containMinNum,
            arriveTime: dayjs(today)
          })
          setMinNum(nowData.data.room.roomType.containMinNum)
          setMaxMum(nowData.data.room.roomType.containMaxNum)
          checkNumber(nowData.data.checkNumber)
          setParameter({
            containMinNum: nowData.data.room.roomType.containMinNum,
            containMaxNum: nowData.data.room.roomType.containMaxNum,
            roomNum: nowData.data.room.roomNum,
            roomName: nowData.data.room.roomName,
            isDeposit: nowData.data.room.roomType.isDeposit,


          })
        }
      }


    }

    fetchData()




    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableTime = (e: any) => {

    let disabledHoursArr: Array<any> = [];
    let startTimeHours = Number(parameter.startTime.split(":")[0]);
    let endTimeHours = Number(parameter.endTime.split(":")[0]);

    for (var i = 0; i <= 23; i++) {
      if (i < startTimeHours || i > endTimeHours) {
        disabledHoursArr.push(i)

      }
    }

    return {
      disabledHours: () => disabledHoursArr,
      disabledMinutes: (selectedHour: number) => getDisabledMinutes(selectedHour)

    }
  }

  let getDisabledMinutes = (selectedHour: number): any => {

    let startTimeMinutes = Number(parameter.startTime.split(":")[1]);
    let endTimeMinutes = Number(parameter.endTime.split(":")[1]);
    let minutes: Array<any> = []

    if (selectedHour === Number(parameter.startTime.split(":")[0])) {
      for (var i = 0; i < startTimeMinutes; i++) {
        if (startTimeMinutes === 0) {

          minutes.push(i - 1)
        } else {
          minutes.push(i)
        }

      }
    }

    if (minutes.length === 0) {
      if (selectedHour === Number(parameter.endTime.split(":")[0])) {
        for (var j = 1; j <= 60; j++) {
          if (j > endTimeMinutes) {
            minutes.push(j)
          }
        }
      }
    }

    return minutes
  }

  return (
    <>
      <AntDraggableModal
        // title={`${parameter && data.type !== "NEW" ? parameter.roomNum : roomMergeName}(${parameter && data.type !== "NEW" ? parameter.roomName : "房间预订"})--房间详情`}
        title={`${parameter && data.type !== "NEW" ? `${parameter.roomNum}(${parameter.roomName})` : roomMergeName}--房间详情`}

        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        width={"45%"}
        maskClosable={false}
        confirmLoading={isLoading}
      >
        <Form
          form={form}
          name="Edit"
          onFinish={handleOk}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}>
          <Row >

            <Col span={12}>
              <Form.Item
                label="id"
                name="id">
                <Input disabled={true} />
              </Form.Item>
            </Col>

            {data.type !== "NEW" ? <Col span={12}>
              <Form.Item
                label="房间"

                name="roomId">
                <Select

                  disabled
                  showSearch
                  placeholder="选择房间"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(roomData, "roomName")}
                </Select>
              </Form.Item>
            </Col> : null}

            <Col span={12}>
              <Form.Item
                label="市别"
                name="timeTypeId"
                rules={[{ message: '请选择市别!', required: true }]}
              >
                <Select

                  disabled
                  showSearch
                  placeholder="选择市别"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(timeTypeIdData, "typeName")}
                </Select>
              </Form.Item>
            </Col>

            {data.type !== "NEW" ? <Col span={12}>
              <Form.Item
                label="类型"
                name="roomTypeId"
                rules={[{ message: '请选择房间类型!', required: true }]}>
                <Select
                  disabled
                  showSearch
                  placeholder="房间类型"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(roomTypeData, 'roomType')}
                </Select>
              </Form.Item>
            </Col> : null}

            {data.type === "distribute" ? null : <Col span={12}>
              <Form.Item
                label="宴名"
                name="banquetId"
                rules={[{ message: '请输入宴会!', required: true }]}
              >
                <Select

                  placeholder="宴名"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(banquetData, 'banquetName')}
                </Select>
              </Form.Item>
            </Col>}


            <Col span={12}>
              <Popover content={`建议人数范围为${minNum ? minNum : ""}~${maxNum ? maxNum : ""}`}>
                <Form.Item
                  label="人数"
                  name="bookingNum"
                  rules={[{ message: '请输入预订人数!', required: true }]}
                >
                  <InputNumber
                    inputMode='numeric'
                    min={minNum}
                    max={maxNum}
                    addonAfter={<span>{minNum ? minNum : ""}~{maxNum ? maxNum : ""}</span>}
                  />

                </Form.Item>
              </Popover>
            </Col>
            {data.type === "distribute" ? null : <Col span={12}>
              <Form.Item
                label="联系人"
                name="contact"

                rules={[{ message: '请输入联系人!', required: true }]}
              >
                <Input allowClear maxLength={20} />
              </Form.Item>
            </Col>}

            {data.type === "distribute" ? null :

              <Col span={12}>
                <Form.Item
                  label="称呼"
                  name="appellationId"
                  rules={[{ message: '请选择称呼!', required: true }]}>
                  <Select
                    //  onFocus={(e) => {e.preventDefaultdocument.activeElement.blur()}}
                    // mode=""
                    // showSearch={false}
                    placeholder="选择称呼"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {getList.general(appellationData, 'name')}
                  </Select>
                </Form.Item>
              </Col>}
            {data.type === "distribute" ? null : <Col span={12}>
              <Form.Item
                label="联系电话"
                name="contactCall"
                rules={[{ message: '请输入联系电话!', required: true }]}
              >
                <Input inputMode='numeric' allowClear />
              </Form.Item>
            </Col>}

            {data.type === "distribute" ? null : <Col span={12}>
              <Form.Item
                label="邮箱"
                name="email"
              >
                <Input allowClear type={"email"} />
              </Form.Item>
            </Col>}




            {data.type === "NEW" ? <Col span={12}>
              <Form.Item
                label="经手人"
                name="handlers"
                rules={[{ message: '请输入经手人!', required: false }]}
              >
                <Input maxLength={10} />
              </Form.Item>
            </Col> : null}

            {data.type === "distribute" ? null : <Col span={12}>
              <Form.Item
                label="到达时间"
                name="arriveTime">
                <TimePicker
                  inputReadOnly
                  //  showNow={false}
                  allowClear={true}
                  disabledTime={disableTime}
                  minuteStep={15}
                  format={"HH:mm"}
                />
              </Form.Item>
            </Col>}


            {/* <Col span={12}>
              <Form.Item
                label="定金状态"
                name="depositStatus"
                rules={[{ message: '请选择定金状态!', required: true }]}>
                <Select
                  showSearch
                  placeholder="选择定金状态"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(depositStatusData, 'name')}
                </Select>
              </Form.Item>
            </Col> */}
            {parameter && parameter.isDeposit && data.type !== "EDIT" && data.type !== "distribute" ?
              <Col span={12}>
                <Form.Item
                  label="跳过定金"
                  name="skipDeposit"
                  valuePropName="checked"
                  initialValue={true}
                >
                  <Switch
                    checked={true}
                    checkedChildren="是" unCheckedChildren="否"
                  ></Switch>
                </Form.Item>
              </Col> : null
            }

            <Col span={12}>
              <Form.Item
                label="检测人数"
                name="checkNumber"
                valuePropName="checked"
                initialValue={true}
              >
                <Switch
                  checked={true}
                  onChange={checkNumber}
                  checkedChildren="是" unCheckedChildren="否"
                ></Switch>
              </Form.Item>
            </Col>

            {data.type === "NEW" ? <Col span={12}>
              <Form.Item
                label="发送信息"
                name="isSend"
                valuePropName="checked"
                initialValue={true}
              >
                <Switch
                  checked={true}
                  checkedChildren="是" unCheckedChildren="否"
                ></Switch>
              </Form.Item>
            </Col> : null}


            {data.type === "NEW" ? <Col span={12}>
              <Form.Item
                label="生成邀请函"
                name="isInvitation"
                valuePropName="checked"
                initialValue={true}
              >
                <Switch
                  checked={true}
                  checkedChildren="是" unCheckedChildren="否"
                ></Switch>
              </Form.Item>
            </Col> : null}


            <Col span={24}>
              <Form.Item
                label="备注"
                name="memo">
                <TextArea
                  readOnly={data.type === "distribute" ? true : false}
                  placeholder="备注"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
            </Col>




          </Row>


        </Form>
        {/**发送信息 */}
        {modalSendMessagelopen ? (<SendMessage data={sendMessagelData} closeModal={closeSendMessageopen}
        />)
          : null}
      </AntDraggableModal>
    </>
  )
}
//筛选出已勾选的房间
export const screeningChecked = () => {
  //获取本地缓存数据
  let data: any = localStorage.getItem("rows");
  let rows = JSON.parse(data);
  let newData: Array<any> = [];
  let isDeposit = false;
  let containMinNum = 0;
  let containMaxNum = 0;
  rows.map((item: any) => {
    if (item.isChecked) {
      newData.push(item)
    }

  })
  let roomName: string = "";
  newData.forEach((item) => {

    if (item.room.roomType.isDeposit) {
      isDeposit = true
    }
    containMinNum += item.room.roomType.containMinNum;
    containMaxNum += item.room.roomType.containMaxNum;
    roomName ? roomName += `--${item.room.roomName}` : roomName = `${item.room.roomName}`;
  })

  return {
    isDeposit,
    newData,
    containMinNum,
    containMaxNum,
    roomName
  }

}