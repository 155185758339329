import { Button, Col, Form, Input, InputNumber, Row, Select, Space } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import systemConfig from '../../../config/systematic';
import WebProductFormList from '../../generalComponents/WebProductFormList';

export default function IncomeStatementEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();
    const [accountantData, setAccountantData] = useState<any>();
    const [deleteData,setDeleteData]=useState<any>();
    const [formList,setFormList]=useState<any>({formName:"incomeStatementProducts",form,type:data.type,ref,setDeleteData,orderType:"incomeStatement"})
    /**产品数据 */
    const [productData, setProductData] = useState<any>()
    /**仓库数据 */
    const [entrepotData, setEntrepotData] = useState<any>();
    /** 后端数据Service */
    let wmService: any = systemConfig.wmService;
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'incomeStatement'
    };
    useEffect(() => {
        let deptId = localStorage.getItem("deptId")
        if (deptId) {
           
            httpGet(wmService, {
                apiId: "entrepot",
                apiExtend: 'getDeptIdToEntrepot',
            }).then(res => {
                
                if (res.success) {
                    form.setFieldsValue({
                        "entrepotId": res.data.id
                    })
                }
            }).catch((err)=>{
                console.log("无数据");
                
            })
        }
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            httpGet(wmService, {
                apiId: "accountant",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAccountantData(res.data)
                }
            })

            httpGet(wmService, {
                apiId: "entrepot",
                apiExtend: 'showList'
            }).then(res => {
                setEntrepotData(res.data)
            })
            // httpGet(wmService, {
            //     apiId: "product",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     setProductData(res.data)
            // })


        }
        fetchData()
    }, [])

   
   
    return (
        <>
            <WebEditUniversal totalData={deleteData} ref={ref} props={extendProps}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '溢损单'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={"55%"}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row >

                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>


                        <Col span={12}>
                            <Form.Item
                                label="仓库"
                                name="entrepotId"
                                rules={[{ required: true, message: '请输入仓库!' }]}>
                                <Select
                                    // onChange={onChangeEntrepotIdToAccountantData}
                                    disabled={data.type === "NEW" ? false : true}
                                    showSearch
                                    placeholder="仓库"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>

                                    {getList.general(entrepotData, 'entrepotName')}
                                </Select>
                            </Form.Item>
                        </Col>

                    
                   
                          <WebProductFormList props={formList}></WebProductFormList> 

                    </Row>
                </Form>
            </AntDraggableModal>

        </>
    )
}
