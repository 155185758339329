import { Button, Col, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd'
import { useEffect, useState } from 'react';
import antMessage from '../../utils/extend/AntdNotification'
import getList from '../universal/getList';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import fetchApi from '../../utils/api/fetchApi';
import systemConfig from '../../config/systematic';
import './WebProductFormList.css'
//产品formList通用组件
export default function WebProductFormList(props: any) {
  const { formName, form, num, type, ref, setDeleteData, orderType, data, apiId } = props.props;

  const [cycle, setCycle] = useState<boolean>(true)

  const formListData = Form.useWatch(formName, form) as any;






  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;


  const [listDeleteData, setListDeleteData] = useState<Array<any>>([]);

  /**产品数据 */
  const [productData, setProductData] = useState<any>();


  //统计总价格
  const statisticsPrice = () => {
    let totalPrice = 0;
    if (formListData) {
      formListData.map((item: any) => {
        if (item.totalPrice && item.productId) {
          totalPrice += Number(item.totalPrice)
        }
      })
    }


    return totalPrice.toFixed(2)
  }

  //remove 删除
  const removeList = (remove: Function, num: number, key: number) => {
    //即将删除的数据
    let removeData = form.getFieldsValue()[formName!][num];
    if (removeData && removeData.id) {
      setListDeleteData([...listDeleteData, { ...removeData, state: "delete" }])
      setDeleteData({
        [formName]: [...listDeleteData, { ...removeData, state: "delete" }]
      })
    }
    remove(num)
    delete productData[key]
  }

  const customizeAdd = (add: Function) => {
    add()
    form.setFieldValue([formName, form.getFieldsValue()[formName].length - 1], { state: "insert" })
  }

  useEffect(() => {


    if (ref && ref.current) {


      ref.current.row.then(async (res: any) => {

        if (typeof res === 'undefined') { return }
        if (res.success === true) {
          let productObj: any = {};
          //自定义formList数据初始状态
          let newData: Array<any> = [];

          for (var i = 0; i < res.data[formName].length; i++) {
            if (res.data[formName][i].product) {


              productObj[i] = [res.data[formName][i].product]
              if (type === "EDIT" || type === "VIEW") {
                newData.push({
                  ...res.data[formName][i],
                  isCal: true,
                  state: "origin",
                  productUnitId: res.data[formName][i].product.productUnit!.productUnitName,
                  selfId: res.data[formName][i].product.selfId

                })

              }

            }


          }

          form.setFieldsValue({
            ...res.data,
          })
          form.setFieldsValue({ [formName]: newData })
          setProductData(productObj)

        }
      })
    }
    if (data && data.id && apiId) {


      fetchApi.GET(wmService, {
        apiId,
        apiVariable: data.id,
        apiExtend: "showSingle"
      }).then((res) => {
        let productObj: any = {};
        //自定义formList数据初始状态
        let newData: Array<any> = []
        for (var i = 0; i < res.data[formName].length; i++) {

          if (res.data[formName][i].product) {
            productObj[i] = [res.data[formName][i].product];
            if (type === "EDIT" || type === "CANCEL") {

              newData.push({
                isCal: true,
                ...res.data[formName][i],
                state: "origin",
                productUnitId: res.data[formName][i].product.productUnit.productUnitName,
                selfId: res.data[formName][i].product.selfId
              })
            }
          }

        }



        form.setFieldsValue({
          ...res.data,
        })
        form.setFieldsValue({ [formName]: newData })

        setProductData(productObj)

      })
    }
    if (type === "NEW") initFields(num ? Number(num) : 8)

  }, [])

  useEffect(() => {
    if (type === "EDIT") addFields(num ? Number(num) : 8)
    if (type === "CANCEL") addFields(num ? Number(num) : 8)

  }, [formListData])

  const initFields = (num: number) => {

    if (num === 0) return false
    if (cycle) {

      let arr = [];
      for (var i = 0; i < num; i++) {
        arr.push({
          name: i,
          key: i,
          fieldKey: i,
          isListField: true,
          state: "insert",
          isCal: true,
        })
      }

      form.setFieldsValue({
        [formName]: arr
      })
      setCycle(false)
    }

  }
  const addFields = (num: number) => {

    if (formListData && formListData.length > 0) {
      if (num === 0) return false
      if (cycle) {

        let arr = [];
        for (var i = formListData.length; i < num; i++) {
          arr.push({
            name: i,
            key: i,
            fieldKey: i,
            isListField: true,
            state: "insert",
            isCal: true,
          })
        }

        form.setFieldsValue({
          [formName]: [...formListData, ...arr]
        })
        setCycle(false)


      }
    }


  }
  /**改变计算方式 */
  const isCalChange = (form: any, e: any, name?: any, isFormList?: boolean, FormListName?: string, type?: string) => {


    form.setFieldValue([FormListName, name, "isCal"], e)

  }

  return (
    <>

      <Row style={{ width: "100%" }} gutter={1} align="middle" justify="space-between">

        <Col className="gutter-row" span={4}>
          <div style={{ textAlign: "center" }}>品名</div>
        </Col>
        <Col className="gutter-row" span={2}>
          <div style={{ textAlign: "center" }}>品号</div>
        </Col>
        <Col className="gutter-row" span={2}>
          <div style={{ textAlign: "center" }}>单位</div>
        </Col>

        {
          orderType === "incomeStatement" ? null : <Col className="gutter-row" span={2}>
            <div style={{ textAlign: "center" }}>数量</div>
          </Col>
        }
        {
          orderType === "incomeStatement" || orderType === "processingOrder"|| orderType==="applyPurchase"? null : <Col className="gutter-row" span={2}>
            <div style={{ textAlign: "center" }}>单价</div>
          </Col>
        }
        {
          orderType === "processingOrder" ? <Col className="gutter-row" span={2}>
            <div style={{ textAlign: "center" }}>起率</div>
          </Col> : null
        }
        {
          orderType === "incomeStatement" || orderType === "processingOrder"|| orderType==="applyPurchase" ? null :
            <Col className="gutter-row" span={2}>
              <div style={{ textAlign: "center" }}>总价格</div>
            </Col>
        }
        {orderType === "processingOrder" ? null : <Col className="gutter-row" span={4}>
          <div style={{ textAlign: "center" }}>备注</div>
        </Col>}
        {orderType === "processingOrder" ? null : <Col className="gutter-row" span={3}>
          <div style={{ textAlign: "center" }}>规格</div>
        </Col>
        }



        {
          orderType === "incomeStatement" || orderType === "processingOrder" || orderType==="applyPurchase" ? null :
            <Col className="gutter-row" span={2}>
              <div style={{ textAlign: "center" }}>单价计算</div>
            </Col>
        }


        <Col className="gutter-row" span={1}>
          <div style={{ textAlign: "center" }}></div>
        </Col>

      </Row>
      <Form.List
        name={formName}
        rules={[
          {
            validator: async (_, names) => {

              if (!names || names.length < 1) {

                //   let message: any;
                //   if (names.length < 1) {
                //     message = "至少要一个商品"
                //   }
                //   if (type === "EDIT") {
                //     if (names.length > 8) {

                //       message = "至多8个商品"
                //     }
                //   }

                antMessage('error', "至少要一个商品", 'error');
                return Promise.reject(new Error("至少要一个商品"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Row style={{ width: "100%" }} gutter={4} justify="space-between">
                  {/* <Row align={"middle"} justify="center" style={{ display: "flex", justifyContent: "space-between" }}> */}
                  <Col className="gutter-row" span={4}>
                    <Form.Item

                      {...restField}
                      label=""
                      name={[name, 'productId']}
                      rules={[{ required: formListData?.length > 0 && formListData[name] ? formListData[name].productId ? true : false : false, message: '请输入产品!' }]}
                    >
                      <Select
                        optionLabelProp="label"
                        style={{ minWidth: 150 }}
                        popupMatchSelectWidth={260}
                        showSearch
                        allowClear
                        onSearch={(e) => getList.searchEx('product', setProductData, e, productData, {
                          keyId: key,
                          isFormList: true,
                          form,
                          setObj: ["productId", "productUnitId", "selfId","miniRate"],
                          setObjValue: ["id", "productUnit#productUnitName", "selfId","miniRate"],
                          FormListName: formName,
                          name,
                          type,
                          changeFun: chooseUnit
                        })}
                        onChange={(value: any, option: any) => chooseUnit(form, value, option, name, true, formName, type, false)}
                        placeholder="品名"
                        optionFilterProp="children"
                        // onInputKeyDown={(e:any) => getList.search('product', setProductData, e, productData, key, true)}
                        filterOption={(input, option: any) => {
                          return true
                        }}
                      >
                        {getList.generalOption(productData ? productData[key] ? productData[key] : [] : [], {
                          itemId: "id", noId: false, label: "productName", children: customizeProduct
                        })}
                      </Select>


                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={2}>
                    <Form.Item

                      {...restField}
                      label=""
                      name={[name, 'selfId']}

                    >


                      <Input disabled placeholder="品号" />


                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2}>
                    <Form.Item
                      {...restField}
                      // label="单位"
                      name={[name, 'productUnitId']}

                    >
                      <Input disabled style={{ "color": "yellow" }} placeholder="单位" />
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" span={2}>
                    <Form.Item
                      {...restField}
                      name={[name, 'bNum']}
                      // label="数量"
                      rules={[{ required: formListData?.length > 0 && formListData[name] ? formListData[name].productId ? true : false : false, message: '请输入数量' }]}
                    >
                      <InputNumber onChange={(e) => bNumChange(form, e, name, true, formName, type)} placeholder="数量" />
                    </Form.Item>
                  </Col>


                  {orderType === "incomeStatement" || orderType === "processingOrder" || orderType==="applyPurchase"? null :
                    <Col className="gutter-row" span={2}>
                      <Form.Item
                        {...restField}
                        // label="单价"
                        name={[name, 'univalence']}
                        rules={[{ required: formListData?.length > 0 && formListData[name] ? formListData[name].productId ? true : false : false, message: '请输入单价' }]}

                      >
                        <InputNumber onChange={(e) => univalenceChange(form, e, name, true, formName, type)} placeholder="单价" />
                      </Form.Item>
                    </Col>
                  }
                  {orderType === "processingOrder" ?
                    <Col className="gutter-row" span={2}>
                      <Form.Item
                        {...restField}
                        // label="单价"
                        name={[name, 'miniRate']}
                        rules={[{ required: formListData?.length > 0 && formListData[name] ? formListData[name].productId ? true : false : false, message: '请输入起率' }]}

                      >
                        <InputNumber max={1} step={0.1} onChange={(e) => univalenceChange(form, e, name, true, formName, type)} placeholder="起率" />
                      </Form.Item>
                    </Col> : null
                  }
                  {
                    orderType === "incomeStatement" || orderType === "processingOrder" || orderType==="applyPurchase"? null :
                      <Col span={2}>
                        <Form.Item
                          {...restField}
                          // label="总价格"
                          name={[name, 'totalPrice']}
                          rules={[{ required: formListData?.length > 0 && formListData[name] ? formListData[name].productId ? true : false : false, message: '总价格' }]}
                        >
                          <InputNumber className='sadasdd' onChange={(e) => totalPriceChange(form, e, name, true, formName, type)} placeholder="总价格" />
                        </Form.Item>
                      </Col>
                  }

                  {
                    orderType === "processingOrder" ? null :
                      <Col span={4}>
                        <Form.Item
                          {...restField}
                          name={[name, 'memo']}
                        // label="备注"
                        >
                          <Input onChange={(e) => changeMemo(form, e, name, true, formName, type)} placeholder="备注" />
                        </Form.Item>
                      </Col>
                  }

                  {
                    orderType === "processingOrder" ? null :
                      <Col className="gutter-row" span={3}>
                        <Form.Item
                          {...restField}
                          // label="规格"
                          name={[name, 'productSpecifications']}
                        >
                          <Input onChange={(e) => changeSpecifications(form, e, name, true, formName, type)} placeholder="请输入规格" />
                        </Form.Item>
                      </Col>
                  }



                  {orderType === "incomeStatement" || orderType === "processingOrder" || orderType==="applyPurchase"? null : <Col span={2} >
                    <Form.Item
                      // valuePropName={}
                      valuePropName="checked"
                      style={{ width: "100%", textAlign: "center" }}
                      label=""
                      name={[name, 'isCal']}
                    // initialValue={true}
                    >
                      < Switch
                        // checked={true}
                        onChange={(e) => isCalChange(form, e, name, true, formName, type)}
                        checkedChildren="是"
                        unCheckedChildren="否" />
                    </Form.Item>
                  </Col>}


                  {type === "EDIT" || type === "CANCEL" ?
                    <Col span={1}>
                      <MinusCircleOutlined onClick={() => removeList(remove, name, key)} /></Col> : <Col className="gutter-row" span={1}>
                      <div style={{ textAlign: "center" }}></div>
                    </Col>}

                </Row>
              ))}
              {(type === "EDIT" || type === "CANCEL") && (formListData?.length < 8) ? <Col span={24}>
                <Form.Item>
                  <Button type="dashed" onClick={() => customizeAdd(add)} block icon={<PlusOutlined />}>
                    添加商品
                  </Button>
                </Form.Item>
              </Col> : null}

            </>
          )
        }
        }
      </Form.List>
      <Row style={{ width: "100%" }} gutter={1} align="middle" justify="space-between">

        <Col className="gutter-row" span={4}>
          <div style={{ textAlign: "center" }}></div>
        </Col>
        <Col className="gutter-row" span={2}>
          <div style={{ textAlign: "center" }}></div>
        </Col>
        <Col className="gutter-row" span={2}>
          <div style={{ textAlign: "center" }}></div>
        </Col>
        {/* <Col className="gutter-row" span={3}>
          <div style={{ textAlign: "center" }}></div>
        </Col> */}
        {
          orderType === "incomeStatement" ? null : <Col className="gutter-row" span={2}>
            <div style={{ textAlign: "center" }}></div>
          </Col>
        }
        {
          orderType === "incomeStatement" ? null : <Col className="gutter-row" span={2}>
            <div style={{ textAlign: "center" }}></div>
          </Col>
        }

        {
          orderType === "processingOrder" ? null : <Col className="gutter-row" span={2}>
            <div style={{ textAlign: "center" }}>{statisticsPrice()}</div>
          </Col>
        }

        <Col className="gutter-row" span={4}>
          <div style={{ textAlign: "center" }}></div>
        </Col>


        <Col className="gutter-row" span={3}>
          <div style={{ textAlign: "center" }}></div>
        </Col>
        <Col className="gutter-row" span={2}>
          <div style={{ textAlign: "center" }}></div>
        </Col>
        <Col className="gutter-row" span={1}>
          <div style={{ textAlign: "center" }}></div>
        </Col>
      </Row>

    </>

  )
}
/**自定义显示产品数据 */
export let customizeProduct = (item: any) => {
      

  return <div style={{ display: "flex", justifyContent: "space-between" }}>
    <div>{`${item.selfId ? item.selfId : item.product?.selfId ? item.product?.selfId : "无"}`}</div>
    <div>{`${item.productName ? item.productName : item.product.productName}`}</div>
    <div>{item.productUnit?.productUnitName ? item.productUnit?.productUnitName : item.product?.productUnit.productUnitName ? item.product?.productUnit.productUnitName : ""}</div>
    <div style={{display:"none"}}>{`${item.miniRate ? item.miniRate : 1}`}</div>
  </div>
}


export const chooseUnit = (form: any, value: any, selectedOptions: any, name?: any, isFormList?: boolean, FormListName?: string, type?: string, isAuto?: boolean) => {
 

  if (isAuto) {

    if (type === "EDIT") {

      form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], state: form.getFieldsValue()[FormListName!][name].id ? "update" : "insert" })
    }

  } else {
    const miniRate = selectedOptions?.children.props.children[3].props.children;
        
    const unitName = selectedOptions?.children.props.children[2].props.children;
    const productName = selectedOptions?.children.props.children[1].props.children;
    const selfId = selectedOptions?.children.props.children[0].props.children;
    



    if (isFormList) {
      form.setFieldValue([FormListName, name, "productUnitId"], unitName);
      form.setFieldValue([FormListName, name, "selfId"], selfId);
      form.setFieldValue([FormListName, name, "miniRate"], miniRate);




      if (type === "EDIT") {
        form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], state: form.getFieldsValue()[FormListName!][name].id ? "update" : "insert", productName })
      }
      if (type === "NEW") {
        form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], productName, })
      }

    } else {
      form.setFieldsValue({
        "selfId": selfId,
        "productUnitId": unitName
      });
      // form.setFieldValue("productUnitId", unitName);
    }

  }



}



/**改变bNum */
export const bNumChange = (form: any, e: any, name?: any, isFormList?: boolean, FormListName?: string, type?: string) => {
  if (isFormList) {
    let univalence = form.getFieldValue([FormListName, name, "univalence"])
    let bNum = form.getFieldValue([FormListName, name, "bNum"])
    if (univalence && Number(univalence) !== 0 && bNum && Number(bNum) !== 0) {
      let newTotalPrice = Number(univalence) * e
      //设置
      form.setFieldValue([FormListName, name, "totalPrice"], newTotalPrice.toFixed(2))
    }


    if (type === "EDIT") form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], state: form.getFieldsValue()[FormListName!][name].id ? "update" : "insert" })


  } else {
    let bNum = form.getFieldValue("bNum")

    let univalence = form.getFieldValue("univalence")
    if (univalence && Number(univalence) !== 0 && bNum && Number(bNum) !== 0) {
      let newTotalPrice = Number(univalence) * e;

      //设置
      form.setFieldValue("totalPrice", newTotalPrice.toFixed(2))
    }

  }



}

/**改变单价 */
export const univalenceChange = (form: any, e: any, name?: any, isFormList?: boolean, FormListName?: string, type?: string) => {


  if (isFormList) {
    let univalence = form.getFieldValue([FormListName, name, "univalence"])

    let bNum = form.getFieldValue([FormListName, name, "bNum"])

    if (bNum && Number(bNum) !== 0 && univalence && Number(univalence) !== 0) {
      let newTotalPrice = Number(bNum) * e
      //设置总价格
      form.setFieldValue([FormListName, name, "totalPrice"], newTotalPrice.toFixed(2))
    }



    if (type === "EDIT") form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], state: form.getFieldsValue()[FormListName!][name].id ? "update" : "insert" })

  } else {
    //设置总价格
    let univalence = form.getFieldValue("univalence")

    let bNum = form.getFieldValue("bNum")
    if (bNum && Number(bNum) !== 0 && univalence && Number(univalence)) {
      let newTotalPrice = Number(bNum) * e
      form.setFieldValue("totalPrice", newTotalPrice.toFixed(2))

    }



  }

}


/**改变总价格 */
export const totalPriceChange = (form: any, e: any, name?: any, isFormList?: boolean, FormListName?: string, type?: string) => {
  let isCal = form.getFieldValue([FormListName, name, "isCal"])

  if (isCal) {
    if (isFormList) {
      let bNum = form.getFieldValue([FormListName, name, "bNum"])

      let newUnivalence: any;
      newUnivalence = e / Number(bNum)
      if (bNum && Number(bNum) !== 0) {
        // if (Number(newUnivalence) < 0) {
        //   form.setFieldValue([FormListName, name, "bNum"], -bNum.toFixed(2))
        //   newUnivalence = Math.abs(Number(newUnivalence))
        // }
        form.setFieldValue([FormListName, name, "univalence"], newUnivalence.toFixed(2))
      }
      //设置数量
      if (type === "EDIT") form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], state: form.getFieldsValue()[FormListName!][name].id ? "update" : "insert" })

    } else {
      let univalence = form.getFieldValue("univalence")

      let newUnivalence = e / Number(univalence);
      //设置单价
      form.setFieldValue("univalence", newUnivalence.toFixed(2))
    }
  }



}

/**改变规格 */
const changeSpecifications = (form: any, e: any, name?: any, isFormList?: boolean, FormListName?: string, type?: string) => {
  if (type === "EDIT") form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], state: form.getFieldsValue()[FormListName!][name].id ? "update" : "insert" })
}

/**改变备注 */
const changeMemo = (form: any, e: any, name?: any, isFormList?: boolean, FormListName?: string, type?: string) => {
  if (type === "EDIT") form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], state: form.getFieldsValue()[FormListName!][name].id ? "update" : "insert" })
}