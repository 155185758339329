import SmsManageEdit from './SmsManageEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Button, Tooltip } from 'antd';
import { useEffect, useRef } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification'
import moment from 'moment';

export default function SmsConfig(props: any) {
  /** api服务id */
  const apiId = 'smsManage';

  const ref = useRef<any>();
  /** 更改一项数据 */
  let handleChangeOne: any;
  /** 默认后台service */
  let defaultService: any;
  /** 刷新 */
  let initData: any;

  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      handleChangeOne = ref.current.handleChangeOne;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      defaultService = ref.current.defaultService;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      initData = ref.current.initData;
    }
    //禁用规则
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //初始化数据

  /**
   * 重发
   * @param record 
   */
  const reSend = async (record: any) => {
    if (typeof record !== 'undefined') {
      const branchStoreId = record.smsConfig.branchStoreId;
      const phones = record.phones;
      const content = record.content;
      const bodys = {
        apiId: 'smsManage',
        apiExtend: 'send',
        apiData: {
          branchStoreId,
          phones,
          content
        }
      }
      const res = await fetchApi.POST(defaultService, bodys)
      if (res.success === true) {
        antMessage('success', '发送成功', 'success');
        // const id = record.id;
        //更新发送状态
        // await handleChangeOne(id, 'isSend', true, apiId)
        //刷新页面数据
        initData();
      }
    }

  }

  //表头
  const columnsHead = [
    {
      title: '短信配置',
      dataIndex: 'smsConfig',
      render: (text: any) => (text ? text.configName : "")
    },
    {
      title: '手机号码',
      dataIndex: 'phones',
    },
    {
      title: '时间',
      dataIndex: 'sysDate',
      render: (text: any) => (text ? moment(text.createdAt).format('YYYY-MM-DD HH:mm:ss') : "")
    },
    {
      title: '发送内容',
      dataIndex: 'content',
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 20 ? text.substring(0, 20) + '......' : text)
          : ''
      }</Tooltip>
    },
    {
      title: '',
      dataIndex: 'id',
      render: (_text: any, record: any) =>
        <Button title={'向' + record.phones + '发送信息'} onClick={async () => await reSend(record)}>
          发信息
        </Button>

    }

  ];

  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '短信管理',
    apiId,
    columns,
    showStates: true,
    pagination: true,
    showSend: true,
    showAdd: false,
    EditComponent: SmsManageEdit
  };

  return (
    <WebUniversal props={extendProps} ref={ref} />
  )
}

