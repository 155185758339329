/**
 * 请求的类型
 * @type GET（SELECT）：从服务器查询，可以在服务器通过请求的参数区分查询的方式。
 * @type POST（CREATE）：在服务器新建一个资源，调用insert操作。
 * @type PUT（UPDATE）：在服务器更新资源，调用update操作。
 * @type DELETE（REMOVE）：从服务器删除资源，调用delete语句
 * @type HEAD：请求一个与GET请求的响应相同的响应，但没有响应体.
 * @type CONNECT ：建立一个到由目标资源标识的服务器的隧道。
 * @type OPTIONS ： 用于描述目标资源的通信选项。
 * @type TRACE ： 沿着到目标资源的路径执行一个消息环回测试。
 * @type PATCH ： 用于对资源应用部分修改。
 */
export declare type methodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'HEAD' | 'CONNECT'
    | 'OPTIONS' | 'TRACE' | 'PATCH';

/** 返回数据类型 */
export declare type rtnType = {
    /** 成功标志 */
    success: boolean,
    /** 数据 */
    data?: any,
    /** 错误代码 */
    errorCode?: string,
    /** 信息 */
    message?: string,
};

/**
 * 默认rtn {
 * success: false,
 * data: undefined,
 * errorCode: undefined,
 * message: ''
 * }
 * @param options
 * @returns 
 */
export const defaultRtn = (options?: rtnType) => {
    if (typeof options === 'undefined') {
        options = {
            success: false
        };
    }
    const {
        success, data, errorCode, message
    } = options;
    const rtn: rtnType = {
        /** 成功标志 */
        success: (typeof success !== 'undefined') ? success : false,
        /** 数据 */
        data: (typeof data !== 'undefined') ? data : undefined,
        /** 错误代码 */
        errorCode: (typeof errorCode !== 'undefined') ? errorCode : '',
        /** 信息 */
        message: (typeof message !== 'undefined') ? message : ''
    }
    return rtn;
};


/** 装载数据选项客户类型 */
declare type mountDataOptionsClientUserType = {
    /** 允许所有资料 */
    isAll?: boolean,
    /** 允许用户资料 */
    isUser?: boolean,
    /** 允许用户登录资料 */
    isUserLogin?: boolean,
    /** 允许员工资料 */
    isEmployees?: boolean,
    /** 允许部门资料 */
    isDept?: boolean
};

/** 装载数据选项用户类型 */
declare type mountDataOptionsUserType = {
    /** 允许所有资料 */
    isAll?: boolean,
    /** 允许用户登录资料 */
    isUserLogin?: boolean,
    /** 允许socketOnline资料 */
    isSocketOnline?: boolean,
    /** 允许用户区域资料 */
    isUserAreas?: boolean,
    /** 允许用户品牌资料 */
    isUserBrands?: boolean,
    /** 允许用户仓库资料 */
    isUserEntrepots?: boolean,
    /** 允许用户分店资料 */
    isUserBranchStores?: boolean,
    /** 允许员工资料 */
    isEmployees?: boolean,
    /** 允许部门资料 */
    isDept?: boolean,
    /** 允许分店资料 */
    isBranchStore?: boolean,
    /** 允许品牌资料 */
    isBrand?: boolean,
    /** 允许区域资料 */
    isArea?: boolean,
    /** 允许用户组资料 */
    isUserGroup?: boolean,
    /** 允许用户组区域资料 */
    isUserGroupAreas?: boolean,
    /** 允许用户组品牌资料 */
    isUserGroupBrands?: boolean,
    /** 允许用户组仓库资料 */
    isUserGroupEntrepots?: boolean,
    /** 允许用户组分店资料 */
    isUserGroupBranchStores?: boolean,
    /** 允许区域管理资料 */
    isAreasManage?: boolean,
    /** 允许品牌管理资料 */
    isBrandsManage?: boolean,
    /** 允许仓库管理资料 */
    isEntrepotsManage?: boolean,
    /** 允许分店管理资料 */
    isBranchStoresManage?: boolean
};

/** 装载数据选项扩展类型 */
declare type mountDataOptionsExtendedType = {
    /** 检查区域管理 */
    isCheckAreasManage?: boolean,
    /** 当前区域管理id */
    currentAreaId?: string,
    /** 检查品牌管理 */
    isCheckBrandsManage?: boolean,
    /** 当前品牌管理id */
    currentBrandId?: string,
    /** 检查仓库管理 */
    isCheckEntrepotsManage?: boolean,
    /** 当前仓库管理id */
    currentEntrepotId?: string,
    /** 检查分店管理 */
    isCheckBranchStoresManage?: boolean,
    /** 当前分店管理id */
    currentBranchStoreId?: string,
};

/** 装载数据选项类型 */
export declare type mountDataOptionsType = {
    /** 客户 */
    clientUserMount?: mountDataOptionsClientUserType,
    /** 用户 */
    userMount?: mountDataOptionsUserType,
    /** 扩展数据 */
    extended?: mountDataOptionsExtendedType
};