import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

export default function SmsService(props: any) {
  /** api服务id */
  const apiId = 'smsService';

  //表头
  const columnsHead = [
    {
      title: '服务名称',
      dataIndex: 'serviceName',
    },

  ];

  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '短信服务',
    apiId,
    columns,
    showStates: false,
    pagination: true,
    showAdd:false,
    showDelete:false,
    showEdit:false

    
  };

  return (
    <WebUniversal props={extendProps} />
  )
}

