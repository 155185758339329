import WebUniversal, { WUExtendPropsType } from '../WebUniversalNewEx'
import { columnsheadId, showText } from '../../universal/columnshead';
import dayjs from 'dayjs';
import { Button, Tooltip } from 'antd';
import LoggerClear from './LoggerClear';
import { useState } from 'react';

/**
 * 日志
 * @param props 
 * @param options 选项
 * @returns 
 */
const Logger = (props: any, options?: {
    /** apiId */
    sendApiId?: 'systemLogger' | 'opLogger'
}) => {
    let { data } = props;
    if (typeof data === 'undefined') {
        data = {};
    }
    let { isQuery, queryType, queryId, isScope} = data;
    
    if (typeof options === 'undefined') {
        options = {};
    }
    let { sendApiId } = options;
    /** api服务id */
    const apiId = sendApiId ? sendApiId : 'systemLogger';
    if (typeof isQuery === 'undefined') {
        isQuery = false;
    }
    const [modalVisibleLoggerClear, setModalVisibleLoggerClear] = useState(false);
    const loggerClearVisible = (visible: boolean) => {
        setModalVisibleLoggerClear(visible);
    }
    //表头
    const columnsHead = [
        {
            title: '时间',
            dataIndex: 'createdAt',
            render: (val: any) =>
                <span>
                    {dayjs(val).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
            sorter: true,
        },
        {
            title: '级别',
            dataIndex: 'logsLevel_Name',
            sorter: true,
            render: (text: any, record: any) => {
                let showText;
                switch (record.logsLevelId) {
                    case 0:
                        showText = (<span style={{ color: 'red' }}>{text}</span>);
                        break;
                    case 1:
                        showText = (<span style={{ color: 'yellow' }}>{text}</span>);
                        break;
                    case 2:
                        showText = (<span style={{ color: 'green' }}>{text}</span>);
                        break;
                    case 4:
                        showText = (<span style={{ color: 'blue' }}>{text}</span>);
                        break;
                    default:
                        <>{text}</>
                        break;
                }
                return showText;
            }
        },
        {
            title: '来源',
            dataIndex: 'scope',
            sorter: true,
            render: (text: any) => {
                return showText(text, 15, 'suffix');
            }
        },
        {
            title: '信息',
            dataIndex: 'info',
            sorter: true,
            render: (text: any) => {
                return showText(text, 20);
            }
        },
        {
            title: '详情',
            dataIndex: 'details',
            render: (text: any) => {
                return showText(text, 50);
            }
        },
        {
            title: '代码',
            dataIndex: 'errorCodeId',
        },
        {
            title: '操作人',
            dataIndex: 'userName',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 10 ? text.substring(0, 10) + '......' : text)
                    : ''
            }</Tooltip>
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '日志',
        apiId,
        columns,
        sendPageData: { queryType, queryId,isScope },
        showOperation: !isQuery,
        showAdd: false,
        showEdit: false,
        showQuery: !isQuery,
        pagination: true,
    };

    return (
        <>
            {isQuery ? null : <Button onClick={() => loggerClearVisible(true)}>清除日志</Button>}
            <WebUniversal props={extendProps} />
            {isQuery ? null : (modalVisibleLoggerClear ? (<LoggerClear apiId={apiId} onClose={loggerClearVisible} />) : null)}
        </>
    )
}

export default Logger;


