import { useEffect, useState } from "react";
import { columnsheadId } from "../../universal/columnshead";
import BranchStoreUploadFileEdit from "./BranchStoreUploadFileEdit";
import systemExpand from "../../../config/systemExpand";
import UploadFileListUniversal, { UFLUExtendPropsType } from "../../system/UploadFileListUniversalNewEx";


//组件 RoomUploadFile
const BranchStoreUploadFile = (props: any) => {
    //extendProps
    const [extendProps, setExtendProps] = useState<UFLUExtendPropsType>();
    //表头
    const columnsHead = [
        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any) => (text.branchStoreName)
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },

    ];

    const filesColumns = columnsheadId
        .concat(columnsHead as []);

    useEffect(() => {
        systemExpand.getConfig({
            id: 'config',
            nodeId: 'files'
        }).then(res => {
              
            const { branchStoreFilesGroupId } = res as any;
            setExtendProps({
                ...props,
                titleName: '门店图片',
                apiId: 'branchStore',
                filesGroupId: branchStoreFilesGroupId,
                //+ File
                //上传 + UploadFile
                //删除 + DeleteFile
                filesColumns,
                showImage: true,
                showDownload: true,
                showStates: true,
                showDefault: true,
                showPromo:true,
                fileType:"files",
                extendIdName:"branchStoreId",
                EditComponent: BranchStoreUploadFileEdit,
               
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  
    if (extendProps) {
        return (
            <UploadFileListUniversal props={extendProps} />
        )
    }
}

export default BranchStoreUploadFile;