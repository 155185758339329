import React, { useEffect, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import { Card, List, Select, Space } from 'antd'
import fetchApi from '../../../utils/api/fetchApi';
import system from '../../../config/systematic';
import getList from '../../universal/getList';
export default function InvitationLetter(props: any) {
    const { closeModal, data } = props;
    const [rows, setRows] = useState<any>();

    const [inviLetStatus, setInviLetStatus] = useState<any>();

    const [selectInviLetStatus, setSelectInviLetStatus] = useState<any>();
    /** 数据刷新中 */
    let loading = false;
    /**后端数据vmService */
    const wmService = system.wmService;
    const handleOk = async (e: any) => {
        closeModal(true)
    }
    const handleCancel = (e: any) => {
        closeModal(false)
    };
    useEffect(() => {
        initData({})
        const fetchData = async () => {
            fetchApi.GET(
                wmService,
                {
                    apiId: "generalData",
                    apiExtend: "showLists",
                    apiVariable: "inviLetStatus"

                }
            ).then((res) => {
                const { inviLetStatus } = res.data;
                setInviLetStatus(inviLetStatus)
            })
        }

        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initData = (apiData: any) => {
        const { inviLetStatus } = apiData;


        fetchApi.GET(
            wmService,
            {
                apiId: "invitationLetter",
                apiExtend: 'showPage',
                apiData: {
                    customerReserveId: data.id,
                    inviLetStatus
                }

            }).then(res => {

                if (res.success === true) {

                    loading = false;
                    setRows(res.data.rows);
                    // setCurrentData(res.data.current);
                    // setPageSizeData(res.data.pageSize);
                    // setTotalData(res.data.total);
                    // setQueryData(res.data.queryString)
                }

            })
    }
    const inviLetStatusChange = (e: any) => {

        setSelectInviLetStatus(e)
        initData({ inviLetStatus: e })
    }
    return (
        <>
            <AntDraggableModal
                title="邀请函"
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={true}

                width={"90%"}
            >

                <>
                    <label>状态 :  </label>
                    <Select
                        showSearch
                        allowClear
                        onChange={inviLetStatusChange}
                        placeholder="状态"
                        popupMatchSelectWidth={100}
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {getList.general(inviLetStatus)}
                    </Select>
                    {/* <span> - </span> */}
                </>
                <div style={{ marginTop: "20px" }}>
                    <List
                        grid={{ gutter: 12, column: 4 }}
                        dataSource={rows}
                        renderItem={(item: any) => {
                            return (
                                <List.Item>
                                    <Card title={<div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div>{item.title}</div>
                                        <div>状态:{item.inviLetStatusName}</div>
                                    </div>}>
                                        <div>id:{item.id}</div>
                                        {/* <Button onClick={() => handlebranchStoreRoom(item)}>确认状态</Button> */}
                                        {/* <InputNumber addonBefore="预座人数" min={item.containMinNum} max={item.containMaxNum} onChange={onChange1.bind(props, item)} value={item.sitNumber===0?item.containMinNum:item.sitNumber} /> */}
                                        {/* <Checkbox checked={item.isChecked} onChange={onChange.bind(props, item)}>勾选房间</Checkbox> */}
                                    </Card>
                                </List.Item>

                            )
                        }}
                    />
                </div>

            </AntDraggableModal>
        </>
    )
}
