import React from 'react';
import MoonTypeEdit from './MoonTypeEdit';
import WebUniversal, { WUExtendPropsType } from '../../components/system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';
//组件 MoonType 
const MoonType = (props: any) => {

    /** api服务id */
    const apiId = 'moonType';

    //表头
    const columnsHead = [
        {
            title: '票据类型',
            dataIndex: 'moonType',
        },
        {
            title: '票号类型',
            dataIndex: 'ticketNoType',
        },
        {
            title: '价格',
            dataIndex: 'amount',
        },
        {
           title:"分店",
           dataIndex:"branchStore_Name"
        },

    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '票据类型',
        apiId,
        columns,
        showStates: true,
        pagination:true,
        EditComponent: MoonTypeEdit
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default MoonType;
