import UserlicenseExpandEdit from './UserLicenseExpandEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { Tooltip } from 'antd';

//组件 
const WebComponents = (props: any) => {

    /** api服务id */
    const apiId = 'userLicenseExpand';

    //表头
    const columnsHead = [
        {
            title: '用户',
            dataIndex: 'user',
            render: (text: any) => (
                text ? text.userName : ''
            )
        },
        {
            title: '权限（特殊）扩展',
            dataIndex: 'licenseExpand',
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 8) + '......' : text)
                    : ''
            }</Tooltip>
        },
    ];

    const columns = columnsHead;

    let extendProps: WUExtendPropsType = {
        ...props,
        primaryKeyName: 'userId',
        titleName: '用户权限（特殊）扩展',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: UserlicenseExpandEdit
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebComponents;
