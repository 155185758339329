import { Form, Input, Row, Col, Switch } from 'antd';
import { useEffect, useRef } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';

//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 }, 
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    useEffect(() => {
    }, []);

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'otherSystemAccount'
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '其它帐号'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}>

                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Row gutter={[8, 0]}>
                        <Col span={9}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={15}>
                            <Form.Item
                                label="帐号名称"
                                name="accountName"
                                rules={[{ required: true, message: '帐号名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="key1名称"
                                name="key1Name">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={15}>
                            <Form.Item
                                label="key1变量"
                                name="key1Value">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="key2名称"
                                name="key2Name">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={15}>
                            <Form.Item
                                label="key2变量"
                                name="key2Value">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="key3名称"
                                name="key3Name">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={15}>
                            <Form.Item
                                label="key3变量"
                                name="key3Value">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="key4名称"
                                name="key4Name">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={15}>
                            <Form.Item
                                label="key4变量"
                                name="key4Value">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={9}>
                            <Form.Item
                                label="key5名称"
                                name="key5Name">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={15}>
                            <Form.Item
                                label="key5变量"
                                name="key5Value">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;