import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import ProductUnitEdit from './ProductUnitEdit';



export default function ProductUnit(props: any) {
    //表头
    const columnsHead = [
        {
            title: '单位名称',
            dataIndex: 'productUnitName',
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '产品单位',
        apiId: 'productUnit',
        showStates: true,
        columns,
        pagination:true,
        EditComponent: ProductUnitEdit,
    };
    return ( <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>)
}
