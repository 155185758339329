import dayjs from 'dayjs';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import { Col, DatePicker, Form, Row, Select, Space, Typography } from 'antd';
import BranchStoreList from '../../generalComponents/BranchStoreList';
import { useEffect, useRef, useState } from 'react';
import getList from '../../universal/getList';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import systemConfig from '../../../config/systematic';

const { Text } = Typography;

const MenuOPLog = (props: any) => {

    const ref = useRef<any>();
    const [form] = Form.useForm();
    const { closeModal, data } = props;

    /** api服务id */
    const apiId = 'menuOPLog';

    /**菜式数据 */
    const [menuData, setMenuData] = useState<any>();

    /**是否弹窗 */
    const [isModalData, setIsModalData] = useState<any>(data !== undefined ? data.isModal : false);

    /**是否弹窗 */
    const [sendPageDatas, setSendPageDatas] = useState<any>({
        menuId: (data !== undefined && data.isModal) ? data.menuId : null,
        branchStoreId: localStorage.getItem("currentBranchStoreId")
    });

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }

        //同步获取数据 async - await
        const fetchData = async () => {

        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: 'apiId',
            dataIndex: 'apiId',
        },
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '操作菜式',
            dataIndex: 'menuId',
            render: (text: any, record: any) => {
                if (record.menu) {
                    return (
                        <div>{"【" + record.menu.menuHead.menuHeadName + "】" + record.menu.foodName}</div>
                    )
                }
            }
        },
        {
            title: '操作详情',
            dataIndex: 'details',
            render: (text: any, record: any) => {
                switch (record.modeId) {
                    case 'create':
                        return (
                            <div>
                                <text style={{ color: 'green' }}>【新增】</text>
                                <Text style={{ whiteSpace: 'pre-wrap' }}>
                                    {text}
                                </Text>
                            </div>
                        )
                        break;
                    case 'update':
                        return (
                            <div>
                                <text style={{ color: 'yellow' }}>【修改】</text>
                                <Text style={{ whiteSpace: 'pre-wrap' }}>
                                    {text}
                                </Text>
                            </div>
                        )
                        break;
                    case 'remove':
                        return (
                            <div>
                                <text style={{ color: 'red' }}>【删除】</text>
                                <Text style={{ whiteSpace: 'pre-wrap' }}>
                                    {text}
                                </Text>
                            </div>
                        )
                        break;
                    default:
                        <>
                            <Text style={{ whiteSpace: 'pre-wrap' }}>
                                {text}
                            </Text>
                        </>
                        break;
                }
            }
        },
        {
            title: '操作时间',
            dataIndex: 'createdAt',
            render: (text: any, record: any) =>
                <span>
                    {dayjs(record.sysDate.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                </span>,
        },
        {
            title: '操作人',
            dataIndex: 'userName',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式操作日志',
        apiId,
        showStates: false,
        showAdd: false,
        showEdit: false,
        showDelete: !isModalData,
        showQuery: !isModalData,
        columns,
        pagination: true,
        sendPageData: sendPageDatas,
    };

    const onRefresh = (branchStoreId: string) => {
        const currentData: initDataType = {
            apiId,
            sendPageData: { branchStoreId }
        }
        ref.current.initData(currentData);
    }

    //根据日期回显数据
    const handleDate = (e: any) => {
        const currentData: initDataType = {
            apiId,
            sendPageData: { opDate: dayjs(e).format("YYYY-MM-DD"), branchStoreId: localStorage.getItem("currentBranchStoreId") }
        }
        ref.current.initData(currentData);
    }

    const handleOk = (e: any) => {
        closeModal(false);
    };

    if (isModalData) {
        return (
            <>
                <AntDraggableModal
                    title={"【 " + data.foodName + " 】操作日志"}
                    open={true}
                    okText='关闭'
                    cancelButtonProps={{ style: { display: "none" } }}
                    onOk={handleOk}
                    onCancel={handleOk}
                    closable={false}
                    // maskClosable={false}
                    width={1600}
                >
                    <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
                </AntDraggableModal>
            </>
        )
    }
    else {
        return (
            <>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <Form form={form}>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="bookingDate"
                                    >
                                        <DatePicker
                                            format="YYYY-MM-DD"
                                            onChange={handleDate}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div>
                        <BranchStoreList onRefresh={onRefresh}></BranchStoreList>
                    </div>
                </div>
                <br></br>
                <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
            </>
        )
    }
}

export default MenuOPLog