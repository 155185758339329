import React, { useState, useEffect } from 'react'
import { Select, Button, Col, Image, Form, Row, Space, Table, Tooltip, DatePicker } from 'antd';
import systemConfig from '../../../../config/systematic';
import fetchApi from '../../../../utils/api/fetchApi';
import getList from '../../../universal/getList';
import moment from 'moment';
import antMessage from '../../../../utils/extend/AntdNotification'
import dayjs from 'dayjs';
import { handleData } from './DailyReport';

import TransferOrderEdit from '../../orderManagement/TransferOrderEdit';
import entrepotPrint from '../../../../generalMethod/entrepotPrint';
import survey from '../../../../generalMethod/survey';
import WebUniversalReport, { WUExtendPropsType } from '../../../system/WebUniversalReport';


const { RangePicker } = DatePicker;

export default function TrQuery() {
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const [deptData, setDeptData] = useState<any>();
    const [form] = Form.useForm();
    const [deptModel, setDeptModel] = useState<any>([
        { id: "exDept", name: "调出部门" },
        { id: "imDept", name: "调入部门" },

    ])

    const [initValues, setInitValues] = useState<any>();





    useEffect(() => {
        const fetchData = async () => {
            form.setFieldValue("chooseTime", [dayjs(), dayjs()])
            let deptData = await fetchApi.GET(wmService, {
                apiId: "dept",
                apiExtend: 'showList',
                apiData: {

                }
            })
            if (deptData.success) {
                setDeptData(deptData.data)
            }
        }
        fetchData()
    }, [])
    /** 表头内容 */
    let columnsHead: any = [
        {
            title: "单号",
            dataIndex: "id",
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 8 ? text.substring(0, 6) + '....' : text)
                    : ''
            }</Tooltip>
            // render: (text: any) => (text ? text.selfId ? text.selfId : "" : "")
        },
        {
            title: "调出部门",
            dataIndex: "exportDept",
            render: (text: any) => (text ? text.deptName : ""),
            align: "center"
        },
        {
            title: "调入部门",
            dataIndex: "importDept",
            render: (text: any) => (text ? text.deptName : ""),
            align: "center"
        },
        {
            title: "日期",
            dataIndex: "sysDate",
            align: "center",

            render: (val: any, record: any) =>
                <span>
                    {moment(val.createdAt).format('YYYY-MM-DD')}
                </span>,

        },
        {
            title: '调出仓库',
            dataIndex: 'exportEntrepot',
            render: (text: any) => (text ? text.entrepotName : ""),
            // ellipsis:true
            align: "center"

        },
        {
            title: '调入仓库',
            dataIndex: 'importEntrepot',
            render: (text: any) => (text ? text.entrepotName : ""),
            // ellipsis:true
            align: "center"

        },
        {
            title: '流程',
            dataIndex: 'totalOrderProcessName',
            align: "center",
            width: 70,
            render: (text: any, record: any) => <div style={record.orderState === -99 ? { color: "red", "fontSize": 12.5 } : { "fontSize": 12.5 }}>{text ? text : ""}</div>

        },
        {
            title: '订单状态',
            dataIndex: 'orderStatusName',
            align: "center",
            width: 75,
            render: (text: any, record: any) => {

                return <div style={record.orderStatus === 4 ? { color: "red", "fontSize": 12.5 } : { "fontSize": 12.5 }}>{text ? text : ""}</div>

            }

        },

        {
            title: "金额",
            align: "center",
            dataIndex: 'sumTotalPrice',
            render: (text: any) => <div>{text ? text.sumPrice : ""}</div>
        },


    ]


    const onFinish = () => {
        form.validateFields()
            .then((values: any) => {
                setInitValues({
                    deptIds: handleData(values.deptIds, "deptId"),
                    time: values.chooseTime ? {
                        startTime: dayjs(values.chooseTime[0]).format("YYYY-MM-DD"),
                        endTime: dayjs(values.chooseTime[1]).format("YYYY-MM-DD"),
                    } : null,
                    deptModel: values.deptModel
                })

            }).catch((err) => {
                antMessage('warning', '无法通过数据校验', '请输入所需数据！');

            })






    }


    let extendProps: WUExtendPropsType = {
        apiId: 'entrepotReport',
        apiExtend: "trQuery",
        columns: columnsHead,
        pagination: true,
        values: initValues,
        primaryKeyName: "id",
        printType: "transferOrderPrint",
        typeProducts: "transferProducts",
        printApi: "transferOrder",
        EditComponent: TransferOrderEdit,
    };
    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Row>
                    <Col span={16}>
                        <Form.Item
                            label="部门"
                            name="deptIds"
                            rules={[{ required: true, message: '请选择部门!' }]}
                        >
                            <Select
                                // orderTypeData
                                mode="multiple"
                                showSearch
                                placeholder="请选择部门!"
                                optionFilterProp="children"

                                onSearch={(e) => getList.search('dept', setDeptData, e)}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(deptData, 'deptName')}
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col span={4}>
                        <Form.Item
                            label="部门模式"
                            name="deptModel"
                            rules={[{ required: true, message: '请选择部门模式!' }]}
                        >
                            <Select
                                showSearch
                                placeholder="部门模式"
                                optionFilterProp="children"

                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(deptModel, 'name')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            label="日期"
                            name="chooseTime"
                        >
                            <RangePicker
                                defaultValue={[dayjs(), dayjs()]}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </Col>



                    <Col span={2}>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    统计
                                </Button >
                                {/* <Button onClick={() => DownloadExcel(prop, prop.data)} >
                                  下载EXCEL文件
                              </Button> */}
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>


            {/* report */}
            {/* <Button onClick={() => DownloadExcel(rows, "moonReport",queryCriteria)}>下载EXCEL文件</Button> */}
            <WebUniversalReport props={extendProps}></WebUniversalReport>
        </>
    )
}
