import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType, initDataType } from '../../system/WebUniversalNewEx';
import MenuWarehouseEdit from './MenuWarehouseEdit';
import MenuWarehouseIngredient from './MenuWarehouseIngredient';
// import CopyMenuWarehouse from './CopyMenuWarehouse';
import MenuWarehouseArticle from './MenuWarehouseArticle';
import MenuWarehouseSpec from './MenuWarehouseSpec';
import MenuWarehouseUploadFile from './MenuWarehouseUploadFile';
import { useEffect, useRef, useState } from 'react';
import { Button, Space, Switch, Tooltip } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';

const MenuWarehouse = (props: any) => {

    const ref = useRef<any>();
    const apiId = "menuWarehouse";

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    //规格窗口
    const [menuWarehouseSpecVisible, setMenuWarehouseSpecVisible] = useState(false);

    //文章窗口
    const [menuWarehouseArticleVisible, setMenuWarehouseArticleVisible] = useState(false);

    //主配料窗口
    const [menuWarehouseIngredientVisible, setMenuWarehouseIngredientVisible] = useState(false);

    // //复制菜式窗口
    // const [copyMenuWarehouseVisible, setCopyMenuWarehouseVisible] = useState(false);

    // //复制菜式数据
    // const [copyMenuWarehouseData, setCopyMenuWarehouseData] = useState<any>();

    //规格数据
    const [specData, setSpecData] = useState<any>();

    //文章数据
    const [articleData, setArticleData] = useState<any>();

    //主配料数据
    const [ingredientData, setIngredientData] = useState<any>();

    useEffect(() => {
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
        foodName: '',
        path: 'MenuWarehouse',
        key: '',
        brandId: '',
        menuWarehouseArticleTypeId: ''
    }

    const initData = async () => {
        // const menuHeadId = data.menuHeadId;
        const currentData: initDataType = {
            apiId,
            // sendPageData: { menuHeadId: menuHeadId }
        }
        ref.current.initData(currentData);
    }

    //关闭仓库菜式规格页面
    const onCloseMenuWarehouseSpec = async (status: boolean) => {
        setMenuWarehouseSpecVisible(false);
        if (status) {
            await initData();
        }
    }

    //关闭仓库菜式文章页面
    const onCloseMenuWarehouseArticle = async (status: boolean) => {
        setMenuWarehouseArticleVisible(false);
        if (status) {
            await initData();
        }
    }

    //打开添加规格
    const handleMenuWarehouseSpec = (record: any) => {
        //console.log(record)
        sendData.id = record.id;
        sendData.foodName = record.foodName;
        setSpecData(sendData);
        setMenuWarehouseSpecVisible(true);
    };

    //改变数据
    const handleChange = async (id: any, columnName: string, value: boolean) => {
        const bodys = {
            apiId: "menuWarehouse",
            apiVariable: id,
            apiExtend: "updateOne",
            apiData: {
                columnName,
                value
            }
        }

        fetchApi.PUT(wmService, bodys).then(res => {
            antMessage('success', '编辑成功', 'success');
            initData();
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };

    //打开添加文章
    const handleMenuWarehouseArticle = (record: any) => {
        //console.log(record)
        sendData.id = record.id;
        sendData.foodName = record.foodName;
        sendData.menuWarehouseArticleTypeId = record.menuWarehouseArticleTypeId;
        setArticleData(sendData);
        setMenuWarehouseArticleVisible(true);
    };

    //打开添加主配料
    const handleIngredient = (record: any) => {
        //console.log(record)
        sendData.id = record.id;
        sendData.foodName = record.foodName;
        setIngredientData(sendData);
        setMenuWarehouseIngredientVisible(true);
    };

    //表头
    const columnsHead = [
        {
            title: '品牌',
            dataIndex: 'brandName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.brand.brandName}</div>
                )
            }
        },
        {
            title: '大分类',
            dataIndex: 'menuWarehouseCategory',
            render: (text: any, record: any) => {
                return (
                    <div>{record.menuWarehouseType.menuWarehouseCategory.menuWarehouseCategoryName}</div>
                )
            }
        },
        {
            title: '小分类',
            dataIndex: 'menuWarehouseType',
            render: (text: any, record: any) => {
                return (
                    <div>{text.menuWarehouseTypeName}</div>
                )
            }
        },
        {
            title: '烹调方法',
            dataIndex: 'cookMethod',
            render: (text: any, record: any) => {
                return (
                    <div>{text.cookMethodName}</div>
                )
            }
        },
        {
            title: '菜式名称',
            dataIndex: 'foodName',
            render: (text: any, record: any) => {
                return (
                    <Tooltip placement="topLeft" title={record.foodNameEn ? record.foodNameEn : ""}>
                        <div>{text}</div>
                    </Tooltip>
                )
            }
        },
        {
            title: '创作时间地区',
            dataIndex: 'created',
            render: (text: any, record: any) => {
                if (text) {
                    return (
                        <div>{text + "年创于" + record.area.areaName}</div>
                    )
                }
            }
        },
        {
            title: "单位",
            dataIndex: "productUnitName",
            render: (text: any, record: any) => {
                return (
                    <div>{record.productUnit.productUnitName}</div>
                )
            }
        },
        {
            title: '规格',
            dataIndex: '',
            key: 'menuWarehouseSpec',
            render: (text: any, record: any) => {
                if (record.menuWarehouseSpecs.length > 0) {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" onClick={() => handleMenuWarehouseSpec(record)}>规格</Button>
                        </Space>
                    )
                }
                else {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" danger onClick={() => handleMenuWarehouseSpec(record)}>规格</Button>
                        </Space>
                    )
                }
            }
        },
        {
            title: '文章',
            dataIndex: 'article',
            key: 'article',
            render: (text: any, record: any) => {
                if (record.isShowArticle) {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" onClick={() => handleMenuWarehouseArticle(record)}>文章</Button>
                        </Space>
                    )
                }
                else {
                    return (
                        <Switch checkedChildren="是" unCheckedChildren="否"
                            checked={text} key={record.id}
                            onChange={(e) => handleChange(record.id, 'isShowArticle', e)}
                        />
                    )
                }
            }
        },
        {
            title: '主配料',
            dataIndex: 'ingredient',
            key: 'ingredient',
            render: (text: any, record: any) => {
                if (record.menuWarehouseSpecs.length > 0) {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" onClick={() => handleIngredient(record)}>主配料</Button>
                        </Space>
                    )
                }
            }
        },
        // {
        //     title: '复制',
        //     dataIndex: 'copy',
        //     key: 'copy',
        //     render: (text: any, record: any) => {
        //         if (record.menuWarehouseSpecs.length > 0) {
        //             return (
        //                 <Tooltip placement="topLeft" title={'复制【' + record.foodName + '】到其他品牌'}>
        //                     <Space size="small">
        //                         <Button size={"small"} type="primary" onClick={() => handleCopy(record, 'single')}>复制</Button>
        //                     </Space>
        //                 </Tooltip>
        //             )
        //         }
        //     }
        // },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];


    // /**
    //  * 复制菜式
    //  * @param id 
    //  */

    // const handleCopy = (record: any, key: any) => {

    //     if (key === 'single') {
    //         sendData.id = record.id;
    //         sendData.foodName = record.foodName;
    //         sendData.brandId = record.brand.id;
    //     }
    //     sendData.key = key;
    //     setCopyMenuWarehouseData(sendData);
    //     setCopyMenuWarehouseVisible(true);

    //     // fetchApi.POST(wmService, {
    //     //     apiId: 'menuWarehouse',
    //     //     apiExtend: 'copy',
    //     //     apiVariable: record.id
    //     // }).then(res => {
    //     //     console.log(res.data);
    //     // })
    // };

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式仓库',
        apiId,
        showImage: true,
        showUploadFile: true,
        showStates: false,
        disabledAdd: false,
        showOperation: true,
        showAdd: true,
        pagination: true,
        columns,
        EditComponent: MenuWarehouseEdit,
        arrayFilesName: 'menuWarehouseFiles',
        UploadFileComponent: MenuWarehouseUploadFile,
        sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
    };

    return (
        <>
            {/* <Popconfirm
                title="批量复制菜式到其他品牌"
                description="考虑中。。。。。。。。。。。。。。。。。"
            // onConfirm={() => handleCopy(undefined, 'all')}
            >
                <Button type="primary">复制菜式到其他品牌</Button>
            </Popconfirm> */}
            {/* <Button type="primary" onClick={() => handleCopy(undefined, 'all')}>复制菜式到其他品牌</Button> */}
            {/* <span>&nbsp;&nbsp;</span> */}

            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>

            {menuWarehouseIngredientVisible
                ? (<MenuWarehouseIngredient
                    closeModal={setMenuWarehouseIngredientVisible} data={ingredientData} />)
                : null}

            {menuWarehouseSpecVisible
                ? (<MenuWarehouseSpec
                    closeModal={onCloseMenuWarehouseSpec} data={specData} />)
                : null}

            {menuWarehouseArticleVisible
                ? (<MenuWarehouseArticle
                    closeModal={onCloseMenuWarehouseArticle} data={articleData} />)
                : null}

            {/* {copyMenuWarehouseVisible
                ? (<CopyMenuWarehouse
                    closeModal={setCopyMenuWarehouseVisible} data={copyMenuWarehouseData} />)
                : null} */}
        </>
    )
}

export default MenuWarehouse