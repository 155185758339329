import { useRef } from 'react';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Button, Tooltip } from 'antd';
import objectHandle from '../../../utils/baseLib/objectHandle';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';

/** 组件 */
const WebComponent = (props: any) => {
    const ref = useRef<any>();
     /** 后端数据wmService */
     let wmService: any = systemConfig.wmService;
    /** 表头内容 */
    const columnsHead = [
        {
            title: '监听id',
            dataIndex: 'listenerId'
        },
        {
            title: '断开连接',
            // dataIndex: 'listenerId'
            render:(text:any,record:any)=>(<Button onClick={()=>dd(record)}>断开连接</Button>)
        },
        {
            title: '命名空间',
            dataIndex: 'nameSpace',
            sorter: true,
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 16 ? text.substring(0, 14) + '....' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '房间id',
            dataIndex: 'roomId',
            sorter: true,
            render: (text: any) => <Tooltip placement="topLeft" title={text}>{
                (typeof text !== "undefined" && text !== null) ?
                    (text.length >= 16 ? text.substring(0, 14) + '....' : text)
                    : ''
            }</Tooltip>
        },
        {
            title: '路径',
            dataIndex: 'path'
        },
        {
            title: '用户',
            dataIndex: 'user',
            render: (text: any) => {
                const userName = text ? text.userName : '';
                return (<Tooltip placement="topLeft" title={userName}>{
                    (objectHandle.isEmpty(userName) !== true) ?
                        (userName.length >= 4 ? userName.substring(0, 4) + '....' : userName)
                        : ''
                }</Tooltip>)
            }
        },
        {
            title: '客户',
            dataIndex: 'clientUser',
            render: (text: any) => (text ? text.userName : '')
        },
        {
            title: 'ip',
            dataIndex: 'ip',
        },
        {
            title: '协议',
            dataIndex: 'protocol',
        }
    ];

    const dd=(record:any)=>{
        
         fetchApi.POST(wmService,{
            apiId:"socketOnline",
            apiExtend:"forcedDisconnect",
            apiData:{
                socketId:record.id,
                nameSpace:record.nameSpace
            }
         }).then((res)=>{
            ref.current.initData();
            
         }).catch((err)=>{
           console.log("ree",err);
           
         })
    }

    /** 表头 */
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: 'socket在线',
        apiId: 'socketOnline',
        columns,
        showStates: true,
        pagination: true,
        showAdd: false,
        showEdit: false
    };

    return (
        <>
            <WebUniversal  props={extendProps} ref={ref} />
        </>
    )
}

export default WebComponent;
