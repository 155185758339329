// import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import ReactDOM from 'react-dom/client'
import configureStore from './store/configureStore';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ConfigProvider, theme } from 'antd';
import 'dayjs/locale/zh-cn';
import zhCN from 'antd/locale/zh_CN';
import './components/css/formItem.css';

import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData"
import 'moment/locale/zh-cn';
import moment from 'moment';


moment.locale('zh-cn');
dayjs.locale('zh-cn')
dayjs.extend(weekday)
dayjs.extend(localeData)
const store = configureStore();
const container = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(container!);


root.render(
  <Provider store={store} >
    <BrowserRouter>
      <ConfigProvider
        locale={zhCN}
        theme={{
          algorithm: theme.darkAlgorithm,
          // token: {
          //   // colorPrimary: 'blue',//改变某些组件的背景颜色 例如 sitch 以及导航栏点击时候的背景颜色
          //   // colorBgBase:"blue",
          //   colorTextBase:"red" ,//基础文本色(字体颜色)
          //   colorBgContainer:"#000" //组件容器背景色
          //   // colorPrimaryBg: '#000',
          //   // colorPrimaryText:"#fff"
          //   // colorText:"#fff", //文本字体颜色
          //   // colorWhite:"#fff"//与导航栏颜色相关
          // }
        }}
      >
        {/* <Suspense fallback={<>loading</>}> */}
        <App />
        {/* </Suspense> */}
      </ConfigProvider>
    </BrowserRouter>
  </Provider>
);



// ReactDOM.render(
//   // <React.StrictMode>
//   <BrowserRouter>
//     <ConfigProvider>
//       <Provider store={store}>
//         <App />
//       </Provider>
//     </ConfigProvider>
//   </BrowserRouter>,
//   // </React.StrictMode>,
//   container
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
