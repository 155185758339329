import { useEffect, useState } from "react";
import { columnsheadId } from "../../universal/columnshead";
import RoomUploadFileEdit from "./RoomUploadFileEdit";
import systemExpand from "../../../config/systemExpand";
import UploadFileListUniversal, { UFLUExtendPropsType } from "../../system/UploadFileListUniversalNewEx";


//组件 RoomUploadFile
const RoomUploadFile = (props: any) => {
    //extendProps
    const [extendProps, setExtendProps] = useState<UFLUExtendPropsType>();
    //表头
    const columnsHead = [
        {
            title: '房间',
            dataIndex: 'room',
            render: (text: any) => (text.roomName)
        },
        {
            title: '视频',
            dataIndex: 'movieId',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },

    ];

    const filesColumns = columnsheadId
        .concat(columnsHead as []);

    useEffect(() => {
        systemExpand.getConfig({
            id: 'config',
            nodeId: 'files'
        }).then(res => {

            const { roomFilesGroupId } = res as any;
            setExtendProps({
                ...props,
                titleName: '房间图片',
                apiId: 'room',
                filesGroupId: roomFilesGroupId,
                //+ File
                //上传 + UploadFile
                //删除 + DeleteFile
                filesColumns,
                showImage: true,
                showDownload: true,
                showStates: true,
                showDefault: true,
                showPromo:true,
                fileType:"files",
                extendIdName:"roomId",
                EditComponent: RoomUploadFileEdit,
               
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // let extendProps: UFLUExtendPropsType = {
    //     ...props,
    //     titleName: '房间图片',
    //     apiId: 'room',
    //     //+ File
    //     //上传 + UploadFile
    //     //删除 + DeleteFile
    //     filesColumns,
    //     showImage: true,
    //     showDownload: true,
    //     showStates: true,
    //     showDefault: true,
    //     EditComponent:RoomUploadFileEdit
    // };
    if (extendProps) {
        return (
            <UploadFileListUniversal props={extendProps} />
        )
    }
}

export default RoomUploadFile;