import React, { useEffect, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import fetchApi from '../../../utils/api/fetchApi';
import systematic from '../../../config/systematic';
import { Col, Form, InputNumber, Row } from 'antd';
import antMessage from '../../../utils/extend/AntdNotification'
export default function OrderBack(props: any) {
    const { data, closeModal } = props;


    const [form] = Form.useForm(); //form 
    const refundMoney = Form.useWatch("refundMoney", form) as any;
    const [maxRefundMoney,setMaxRefundMoney]=useState<any>(0)
    /** 后端数据defaultService */
    let defaultService: any = systematic.wmService;
    const handleOk = (e: any) => {
 
        if (Number(refundMoney) === 0) {
            antMessage("warning", '退款金额为0,不可进行退款');
            return false
        }
        fetchApi.POST(defaultService,
            {
                apiId: 'payOrder',
                apiExtend: 'orderBack',
                apiData: {
                    orderId: data.orderId,
                    refundMoney: Number(refundMoney)
                }
            }).then(res => {
                if (res.success) {
                    antMessage('success', '已发起退款', 'success');
                    //执行退款
                    fetchApi.PUT(defaultService, {
                        apiId: 'payOrder',
                        apiExtend: 'executeRefund',
                        apiData: {
                            orderBackId: res.data.orderBackId
                        }
                    }).then((res) => {
                        closeModal(true)
                        antMessage('success', '退款成功', 'success');

                    }).catch((err) => {
                        antMessage("error", '退款失败', err);

                    })
                    // initData()
                }
            }).catch((err) => {
                antMessage("error", '发起退款失败', err);

            })
        // closeModal(true);
    };
    const onCancel = (e: any) => {
        closeModal(true);
    }
    useEffect(() => {
        initData()
    }, [])



    const initData = () => {
        let refundMoney=data.realMoney-data.refundAmount
        form.setFieldsValue({
            ...data,
            refundMoney:refundMoney
        })
        setMaxRefundMoney(refundMoney)
        // fetchApi.GET(defaultService,
        //     {
        //         apiId: 'cateringOrder',
        //         apiExtend: 'getOrderIdByMenu',
        //         apiData: {
        //             orderId: data.orderId
        //         }
        //     }).then(res => {
        //         console.log("res", res);

        //         if (res.success) {
        //             setRow(res.data)

        //         }
        //     })
    }
  
    return (
        <>
            <AntDraggableModal
                title={'整单退款'}
                open={true}
                okText='确定'
                onOk={handleOk}
                onCancel={onCancel}
                closable={false}
                maskClosable={false}
                width={1000}>
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row>

                        <Col span={24}>
                            <Form.Item
                                label="实付金额"

                                name="realMoney">
                                <InputNumber precision={2} disabled addonAfter={"元"} ></InputNumber>
                            </Form.Item>
                        </Col>



                        <Col span={24}>
                            <Form.Item
                                label="已退金额"
                                name="refundAmount">
                                <InputNumber precision={2} addonAfter={"元"} disabled ></InputNumber>
                            </Form.Item>
                        </Col>

                     

                        <Col span={24}>
                            <Form.Item
                                label="待退金额"
                                name="refundMoney"
                                
                                >
                                <InputNumber disabled precision={2} min={0} max={maxRefundMoney} addonAfter={"元"} ></InputNumber>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

            </AntDraggableModal>

        </>
    )
}
