import { Form, Input, InputNumber, Switch } from 'antd';
import React, { useRef } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const BackupConfigurationEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    
    const ref = useRef<any>();
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'backupConfiguration',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '备份配置'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >        
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                  
                    <Form.Item
                        label="表名"
                        name="tableName"
                        rules={[{ required: true, message: '请输入表名!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="备份天数"
                        name="backupTime"
                        rules={[{ required: true, message: '备份天数!' }]}>
                        <InputNumber />
                    </Form.Item>
                    <Form.Item
                                label="删除数据源"
                                name="isDeleteSource"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        < Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                </Form>
            </AntDraggableModal>
        </>
    )
}

export default BackupConfigurationEdit;



