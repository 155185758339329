import UserLoginEdit from './UserLoginEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'

//组件 UserLogin
const UserLogin = (props: any) => {
    const { data } = props;
    let type, id;
    if (typeof data !== 'undefined') {
        type = data.type;
        id = data.id;
    }
    //表头
    const columns = [
        {
            title: '登录ID',
            dataIndex: 'logId',
            sorter: true,
        },
        {
            title: '用户',
            dataIndex: 'user',
            render: (text: any) => (
                text ? text.userName : ''
            )
        },
    ];
    let extendProps: WUExtendPropsType = type === 'EDIT' ? {
        ...props,
        titleName: '用户登录',
        apiId: 'userLogin',
        columns,
        primaryKeyName: 'logId',
        showStates: false,
        pagination: false,
        showAdd: false,
        showOperation: false,
        showQuery: false,
        sendPageData: { type, id }
    } : {
        ...props,
        titleName: '用户登录',
        apiId: 'userLogin',
        columns,
        primaryKeyName: 'logId',
        showStates: true,
        pagination: true,
        EditComponent: UserLoginEdit,
    };

    return (
        <>
            <WebUniversal props={extendProps} />
        </>
    )
}

export default UserLogin;
