import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import system from "../../../config/systematic";
import objectHandle from '../../../utils/baseLib/objectHandle';

const { TextArea } = Input;

export default function MenuWarehouseEdit(props: any) {

    /**单位数据 */
    const [productUnitData, setProductUnitData] = useState<any>();

    /**菜式仓库大分类数据 */
    const [menuWarehouseCategoryData, setMenuWarehouseCategoryData] = useState<any>();

    /**菜式仓库小分类数据 */
    const [menuWarehouseTypeData, setMenuWarehouseTypeData] = useState<any>();

    /**烹调方法数据 */
    const [cookMethodData, setCookMethodData] = useState<any>();

    /**地区数据 */
    const [areaData, setAreaData] = useState<any>();

    /**品牌数据 */
    // const [brandData, setBrandData] = useState<any>();

    /**记录选择前的菜式仓库大分类 */
    const [defaultCategoryId, setDefaultCategoryId] = useState<any>();

    /**小分类的状态 */
    const [disable, setDisable] = useState<any>(true);

    /**创作年份的文本框状态 */
    const [createdDisable, setCreatedDisable] = useState<any>(true);

    /**用餐性质数据 */
    const [sceneData, setSceneData] = useState<any>();
    const [initialScenesId, setInitialScenesId] = useState<any>([]);

    /**品牌的状态 */
    // const [brandIdDisable, setBrandIdDisable] = useState<any>(false);

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据Service */
    let wmService = system.wmService;


    /**数据 */
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });

            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    const menuWarehouseTypeId = res.data.menuWarehouseTypeId;

                    if (res.data.created >= 1) {
                        setCreatedDisable(false);
                        form.setFieldsValue({ areaId: res.data.area.id });
                    }
                    else {
                        setCreatedDisable(true);
                    }

                    fetchApi.GET(wmService, {
                        apiId: 'menuWarehouseType',
                        apiVariable: menuWarehouseTypeId
                    }).then(res => {
                        if (res.success) {
                            //console.log(res.data)
                            form.setFieldsValue({ menuWarehouseCategoryId: res.data.menuWarehouseCategoryId });
                            //form.setFieldsValue({ menuWarehouseTypeId: res.data.id });
                        }
                    })

                    const scenesId = res.data.scenesId;
                    let sceneRows;
                    if (objectHandle.isEmpty(scenesId) === false) {
                        sceneRows = scenesId.split('@');
                    }
                    setInitialScenesId(sceneRows);
                    form.setFieldsValue({
                        scenesId: sceneRows
                    })
                }
            })
        }

        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "menuWarehouseCategory",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseCategoryData(res.data)
                }
            });

            fetchApi.GET(wmService, {
                apiId: "menuWarehouseType",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseTypeData(res.data)
                }
            });

            fetchApi.GET(wmService, {
                apiId: "cookMethod",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setCookMethodData(res.data)
                }
            });

            if (data.type !== 'NEW') {
                setDisable(false);
                // setBrandIdDisable(true);
            }

            fetchApi.GET(wmService, {
                apiId: "productUnit",
                apiExtend: 'showList'
            }).then(res => {
                setProductUnitData(res.data)
            });

            fetchApi.GET(wmService, {
                apiId: "area",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAreaData(res.data)
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'scene',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setSceneData(res.data);
                }
            });

            // fetchApi.GET(wmService, {
            //     apiId: "brand",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {
            //         setBrandData(res.data)
            //     }
            // })

            // fetchApi.GET(wmService, {
            //     apiId: "menuWarehouse",
            //     apiExtend: 'getBrandId'
            // }).then(res => {
            //     if (res.success) {
            //         console.log(res.data);

            //         if (res.data.key !== 'manager') {
            //             setBrandIdDisable(true);
            //             form.setFieldsValue({ brandId: res.data.brand.id });
            //         }
            //     }
            // });
        }
        fetchData()
    }, [])

    const created = (e: any) => {
        if (form.getFieldValue('created') >= 1) {
            setCreatedDisable(false);
        }
        else {
            setCreatedDisable(true);
        }
    }

    const getDefaultCategoryToTypeData = (e: any) => {
        fetchApi.GET(wmService, {
            apiId: "menuWarehouseCategory",
            apiExtend: 'getTypeList',
            apiVariable: form.getFieldValue('menuWarehouseCategoryId'),
        }).then(res => {
            if (res.success) {
                setMenuWarehouseTypeData(res.data)
            }
        });
    }

    const getDefaultCategoryData = (e: any) => {
        setDefaultCategoryId(form.getFieldValue('menuWarehouseCategoryId'));
    }

    const getCategoryToTypeData = (menuWarehouseCategoryId: string) => {
        setDisable(false);

        if (menuWarehouseCategoryId !== defaultCategoryId) {
            form.setFieldsValue({ menuWarehouseTypeId: "" });
        }

        fetchApi.GET(wmService, {
            apiId: "menuWarehouseCategory",
            apiExtend: 'getTypeList',
            apiVariable: menuWarehouseCategoryId,
        }).then(res => {
            if (res.success) {
                setMenuWarehouseTypeData(res.data)
            }
        });
    }

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuWarehouse'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '菜式仓库'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={1300}
            >
                <Form
                    form={form}
                    initialValues={{ "price": 999, "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                // onKeyPress={(e: any) => {
                //     if (e.key === 'Enter') {
                //         handleOk(e)
                //     }
                // }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="门店Id"
                                name="branchStoreId"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        {/* <Col span={8}>
                            <Form.Item
                                label="品牌"
                                name="brandId"
                                rules={[{ required: true, message: '请输入品牌!' }]}>
                                <Select
                                    showSearch
                                    disabled={brandIdDisable}
                                    placeholder="品牌"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(brandData, 'brandName')}
                                </Select>
                            </Form.Item>
                        </Col> */}

                        <Col span={12}>
                            <Form.Item
                                label="作品号"
                                name="opusNumber">
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="创作年份"
                                name="created">
                                <InputNumber min={1992} max={2992} onChange={created} addonBefore="创于" addonAfter="年" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="创作地"
                                name="areaId"
                                rules={[{ required: createdDisable ? false : true, message: '请选择创作地!' }]}
                            >
                                <Select
                                    disabled={createdDisable}
                                    placeholder="创作地"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(areaData, {
                                        itemName: "areaName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="菜名"
                                name="foodName"
                                rules={[{ required: true, message: '请输入菜名!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="菜式英文名"
                                name="foodNameEn"
                                rules={[{ required: true, message: '请输入菜式英文名!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="菜式仓库大分类"
                                name="menuWarehouseCategoryId"
                                rules={[{ required: true, message: '菜式仓库大分类!' }]}>
                                <Select
                                    showSearch
                                    placeholder="菜式仓库大分类"
                                    optionFilterProp="children"
                                    onSelect={getCategoryToTypeData}
                                    onFocus={getDefaultCategoryData}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(menuWarehouseCategoryData, {
                                        itemName: "menuWarehouseCategoryName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="菜式仓库小分类"
                                name="menuWarehouseTypeId"
                                rules={[{ required: true, message: '菜式仓库小分类!' }]}>
                                <Select
                                    disabled={disable}
                                    showSearch
                                    placeholder="菜式仓库小分类"
                                    optionFilterProp="children"
                                    onMouseEnter={getDefaultCategoryToTypeData}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(menuWarehouseTypeData, {
                                        itemName: "menuWarehouseTypeName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="曾用菜名"
                                name="usedFoodName">
                                <Input disabled={sessionStorage.getItem('isAdmin') ? false : true} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="曾用英文菜名"
                                name="usedFoodNameEn">
                                <Input disabled={sessionStorage.getItem('isAdmin') ? false : true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="菜式描述"
                                name="description"
                            >
                                <Input placeholder="如：外脆内嫩 鲜甜香滑 / 精酿啤酒、椰子汁、姜汁" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="用餐性质"
                                name="scenesId"
                                initialValue={initialScenesId} >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择用餐性质"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(sceneData, 'sceneName', 'id', undefined, false)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="烹调方法"
                                name="cookMethodId"
                                rules={[{ required: true, message: '烹调方法!' }]}>
                                <Select
                                    showSearch
                                    placeholder="烹调方法"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(cookMethodData, {
                                        itemName: "cookMethodName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="单位"
                                name="productUnitId"
                                rules={[{ required: true, message: '请输入单位!' }]}>
                                <Select
                                    showSearch
                                    placeholder="单位"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(productUnitData, {
                                        itemName: "productUnitName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="价格"
                                name="price">
                                <InputNumber min={0} addonBefore="¥" addonAfter="元" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="memo"
                            >
                                <TextArea
                                    placeholder="备注"
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="介绍"
                                name="introduction"
                            >
                                <TextArea
                                    placeholder="菜式介绍"
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="创作理念"
                                name="creativeConcept"
                            >
                                <TextArea
                                    placeholder="创作理念"
                                    autoSize={{ minRows: 2, maxRows: 5 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}