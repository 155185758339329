import { DatePicker, Form, Input, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';


//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const TimeTypeEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    /** 后端数据wmService */
    let wmService: any = "";
    /**时间段数据 */
    const [scheduledTimeData, setScheduledTimeData] = useState<any>()
    const ref = useRef<any>();
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        dateColumnName: ["bookingDate"],
        apiId: 'timeType',
    };
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;

        }
        const fetchData = async () => {

            httpGet(wmService, {
                apiId: "scheduledTime",
                apiExtend: 'showList',
            }).then(res => {
                if (res.success) {

                    setScheduledTimeData(res.data)
                }
            })


        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '市别'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="预订日期"
                        name="bookingDate"
                        rules={[{ message: '请输入预订日期!', required: true }]}
                    >
                        <DatePicker
                            format="YYYY-MM-DD"
                        />
                    </Form.Item>


                    <Form.Item
                        label="时间段"
                        name="scheduledTimeId"
                        rules={[{ required: true, message: '请选择时间段!' }]}>
                        <Select
                            showSearch
                            placeholder="选择时间段"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            options={getList.generalSelcetOption(scheduledTimeData, "timeName")}
                        >

                        </Select>
                    </Form.Item>


                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        < Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                </Form>
            </AntDraggableModal >
        </>
    )
}

export default TimeTypeEdit;