import React, { useEffect, useState } from 'react';
import { Spin, Transfer } from 'antd';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import system from "../../../config/systematic";
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification';

const WarehouseToMenu = (props: any) => {

    /**后端数据wmService */
    const wmService = system.wmService;

    const { data, closeModal } = props;

    // 推广菜式
    const [promoData, setPromoData] = useState<any>();

    // 门店菜式
    const [menuData, setMenuData] = useState<any>();

    // 仓库菜式
    const [menuWarehouseData, setMenuWarehouseData] = useState<any>([]);

    // 门店菜式
    const [branchStoreMenuWarehouseData, setBranchStoreMenuWarehouseData] = useState<any>([]);

    const [menusData, setMenusData] = useState<any>();



    const [branchStoreMenuWarehouseKeys, setBranchStoreMenuWarehouseKeys] = useState<any>([]);

    const [menuKeys, setMenuKeys] = useState<any>([]);

    const [promoFoodKeys, setPromoFoodKeys] = useState<any>([]);

    const [loadingData, setLoadingData] = useState<any>(false);

    let modalTitle;
    let transferTitle;
    let transferDataSource;
    let transferTarget;

    useEffect(() => {

        switch (data.key) {
            case 'menuWarehouseToBranchStore':
                getDataBaseData('menuWarehouse', 'showMenuWarehouse', setBranchStoreMenuWarehouseKeys, setMenuWarehouseData, undefined);
                break;

            case 'branchStoreMenuWarehouseToMenu':
                getDataBaseData('branchStoreMenuWarehouse', 'showBranchStoreMenuWarehouse', setMenuKeys, setBranchStoreMenuWarehouseData, data);
                break;

            case 'menuToPromo':
                getDataBaseData('menu', 'showMenu', setPromoFoodKeys, setMenusData, data);
                break;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataBaseData = (apiId: any, apiExtend: any, setKeys: any, setData: any, propsData: any) => {

        fetchApi.GET(wmService, {
            apiId,
            apiExtend,
            branchStoreId: propsData ? propsData.branchStoreId : localStorage.getItem("currentBranchStoreId"),
            menuHeadId: propsData ? propsData.menuHeadId : '',
            promoCategoryId: propsData ? propsData.promoCategoryId : ''
        }).then(res => {
            if (res.success) {
                setData(res.data);
                const newBranchStoreMenuWarehouseKeys: any = [];
                const newFoodWarehouseData: any = [];

                for (let j = 0; j < res.data.length; j++) {
                    const newData = {
                        key: res.data[j].id.toString(),
                        foodName: res.data[j].foodName,
                        brandName: res.data[j].brand ? res.data[j].brand.brandName : null,
                        menuHeadName: res.data[j].menuHead ? res.data[j].menuHead.menuHeadName : null,
                        disabled: (res.data[j].promoCategoryId === data.promoCategoryId || res.data[j].promoCategoryId === null) ? false : true,
                        chosen: res.data[j].isMenu,
                    };
                    if (newData.chosen) {
                        newBranchStoreMenuWarehouseKeys.push(newData.key);
                    }
                    newFoodWarehouseData.push(newData);
                }

                setKeys(newBranchStoreMenuWarehouseKeys);
                setData(newFoodWarehouseData);
            }
        });
    }

    const handleAction = (apiId: any, apiExtend: any, totalData: any, propsData: any) => {

        fetchApi.POST(wmService, {
            apiId,
            apiExtend,
            totalData,
            branchStoreId: propsData ? propsData.branchStoreId : localStorage.getItem("currentBranchStoreId"),
            menuHeadId: propsData ? data.menuHeadId : '',
            promoCategoryId: propsData ? data.promoCategoryId : ''
        }).then((res: any) => {
            if (res.data.message) {
                antMessage('error', res.data.message, 'error');
                setLoadingData(false);
                closeModal(true);
            }
            else {
                antMessage('success', '操作成功', 'success');
                setLoadingData(false);
                closeModal(true);
            }
        })
    }

    const handleOk = async (e: any) => {
        setLoadingData(true);
        switch (data.key) {
            case 'menuWarehouseToBranchStore':
                await handleAction('branchStoreMenuWarehouse', 'branchStoreMenuWarehouseAdd', branchStoreMenuWarehouseData, undefined);
                break;

            case 'branchStoreMenuWarehouseToMenu':
                await handleAction('menu', 'menuAdd', menuData, data);
                break;

            case 'menuToPromo':
                await handleAction('promoCategory', 'promoAdd', promoData, data);
                break;
        };
    };

    // /**关闭窗口并刷新数据 */
    // const handleCancel = (status: Boolean) => {
    //     closeModal(false)
    //     if (status === true) {
    //         getData(true);
    //     }
    // }

    const onChange = (newBranchStoreMenuWarehouseKeys: any, direction: any, moveKeys: any) => {
        switch (data.key) {
            case 'menuWarehouseToBranchStore':
                setBranchStoreMenuWarehouseKeys(newBranchStoreMenuWarehouseKeys);
                setBranchStoreMenuWarehouseData(newBranchStoreMenuWarehouseKeys);
                break;

            case 'branchStoreMenuWarehouseToMenu':
                setMenuKeys(newBranchStoreMenuWarehouseKeys);
                setMenuData(newBranchStoreMenuWarehouseKeys);
                break;

            case 'menuToPromo':
                setPromoFoodKeys(newBranchStoreMenuWarehouseKeys);
                setPromoData(newBranchStoreMenuWarehouseKeys);
                break;
        };
    };

    switch (data.key) {

        case 'menuWarehouseToBranchStore':
            modalTitle = "从菜式仓库添加到门店";
            transferTitle = ['菜式仓库', '门店菜式'];
            transferDataSource = menuWarehouseData;
            transferTarget = branchStoreMenuWarehouseKeys;
            break;

        case 'branchStoreMenuWarehouseToMenu':
            modalTitle = data.brandName + " " + data.branchStoreName + "【" + data.menuHeadName + "】—— 添加菜式";
            transferTitle = ['门店菜式', '现有菜式'];
            transferDataSource = branchStoreMenuWarehouseData;
            transferTarget = menuKeys;
            break;

        case 'menuToPromo':
            modalTitle = data.brandName + " " + data.branchStoreName + "【" + data.promoCategoryName + "】—— 添加推广菜式";
            transferTitle = ['门店菜式', '推广菜式'];
            transferDataSource = menusData;
            transferTarget = promoFoodKeys;
            break;
    };

    return (
        <>
            <AntDraggableModal
                title={modalTitle}
                open={true}
                okText='确定'
                cancelText='取消'
                cancelButtonProps={{ disabled: loadingData }}
                okButtonProps={{ disabled: loadingData }}
                onOk={handleOk}
                onCancel={() => closeModal(false)}
                closable={false}
                maskClosable={false}
                width={700}
            >
                {loadingData ? (
                    <Spin size="large" tip="Loading...">
                        <Transfer
                            dataSource={transferDataSource}
                            titles={transferTitle}
                            showSearch
                            listStyle={{ width: 400 }}
                            targetKeys={transferTarget}
                            onChange={onChange}
                            render={item => `${item.foodName + (item.menuHeadName ? "（" + item.menuHeadName + "）" : "") + (item.brandName ? "（" + item.brandName + "）" : "")}`}
                            // render={item => `${item.foodName + (item.specs > 0 ? "" : " ----【没有设置规格】")}`}
                            pagination
                        />
                    </Spin>
                ) :
                    (
                        <div>
                            <Transfer
                                dataSource={transferDataSource}
                                titles={transferTitle}
                                showSearch
                                listStyle={{ width: 400 }}
                                targetKeys={transferTarget}
                                onChange={onChange}
                                render={item => `${item.foodName + (item.menuHeadName ? "（" + item.menuHeadName + "）" : "") + (item.brandName ? "（" + item.brandName + "）" : "")}`}
                                // render={item => `${item.foodName + (item.specs > 0 ? "" : " ----【没有设置规格】")}`}
                                pagination
                            />
                        </div>
                    )}
            </AntDraggableModal>
        </>
    );
};

export default WarehouseToMenu;