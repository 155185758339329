import React, { useRef, useState } from 'react';
import RoomTypeEdit from './RoomTypeEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import RoomTypeUploadFile from './RoomTypeUploadFile';
import { Button } from 'antd';
import PrintConfiguration from '../../basic/printManagement/PrintConfiguration';

//组件 RoomType 
const RoomType = (props: any) => {

  /** api服务id */
  const apiId = 'roomType';
  const ref = useRef<any>();
  const [roomTypeId, setRoomTypeId] = useState<string>();
  const [modalPrintConfiguration, setModalPrintConfiguration] = useState(false);
  //表头
  const columnsHead = [
    {
      title: '分店',
      dataIndex: 'branchStore',
      render: (text: any) => (text ? text.branchStoreName : "")

    },
    // menuHeads_Name
    {
      title: '菜谱头',
      dataIndex: 'menuHeads_Name',
    },
    {
      title: '最低容纳人数',
      dataIndex: 'containMinNum',

    },
    {
      title: '最高容纳人数',
      dataIndex: 'containMaxNum',

    },
    {
      title: '房间类型',
      dataIndex: 'roomType',
    },
    {
      title: '最低消费',
      dataIndex: 'minConsumption',
    },
    {
      title: '预定金',
      dataIndex: 'deposit',
    },
    {
      title: '点餐模式',
      dataIndex: 'orderingModeName',
    },
    {
      title: '打印配置',
      render: (text: any, record: any) => {
        return <Button onClick={() => handlePrintConfiguration(record)} size={"small"} type="primary" >打印配置</Button>
      }
    }

  ];
  const handlePrintConfiguration = (record: any) => {
    setRoomTypeId(record.id)
    setModalPrintConfiguration(true)
  }

  const closePrintConfiguration = (status: boolean) => {
    setModalPrintConfiguration(false)
    if (status) {

    }
  }

  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '房间类型',
    apiId,
    columns,
    showStates: true,
    pagination: true,
    showReserve: true,
    showImage: true,
    showUploadFile: true,
    showDeposit: true,
    EditComponent: RoomTypeEdit,
    arrayFilesName: 'roomTypeFiles',
    mountDataOptions:{
      userMount:{
         isDept:true,
      }
 },
    UploadFileComponent: RoomTypeUploadFile,
  };

  return (
    <>
      <WebUniversal props={extendProps} ref={ref} />
      {modalPrintConfiguration ? (<PrintConfiguration
        closeModal={closePrintConfiguration}
        roomTypeId={roomTypeId}
        isModel={true}
      ></PrintConfiguration>) : null}
    </>
  )
}

export default RoomType;
