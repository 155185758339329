/** 数字处理 */
const numberHandle = () => { };

/**
 * 生成从[最小数]到[最大数]的随机数
 * @param minNum 最小数
 * @param maxNum 最大数
 * @returns 返回随机数
 */
numberHandle.rangeNumber = (minNum: number, maxNum: number) => {
    /** 取值范围的差 */
    var range = maxNum - minNum;
    /** 小于1的随机数 */
    var random = Math.random();
    /** 最小数与随机数和取值范围求和，返回想要的随机数字 */
    return minNum + Math.round(random * range);
}

export default numberHandle;