import { Col, Form, Input, InputNumber, Radio, RadioChangeEvent, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';




export default function PaymentListEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;

    const ref = useRef<any>();
    /**支付方式数据 */
    const [paymentMethod, setPaymentMethod] = useState<any>()
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const [value, setValue] = useState<number>(1);
    const handleCancel = (e: any) => {


        ref.current.handleCancel();
    };
    /** 后端数据wmService */
    let wmService: any = "";
    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'paymentList'
    };
    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            httpGet(wmService, {
                apiId: "paymentMethod",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {

                    setPaymentMethod(res.data)
                }
            })

        }
        fetchData()
    }, [])

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '支付方式'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={600}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row >
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled />
                            </Form.Item>

                        </Col>


                        <Col span={12}>
                            <Form.Item
                                label="支付方式"
                                name="paymentMethodId"
                                rules={[{ required: true, message: '请输入支付方式!' }]}>
                                <Select
                                    showSearch
                                    placeholder="支付方式"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(paymentMethod, 'paymentMethodName')}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="线上支付"
                                name="isOnlinePayment"
                                valuePropName="checked"
                                initialValue={false}
                            >
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="支付二维码"
                                name="isPayCode"
                                valuePropName="checked"
                                initialValue={false}
                            >
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>




                    </Row>
                </Form>
            </AntDraggableModal>

        </>
    )
}


