import { Button, Checkbox, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewExEx';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';
import WebProductFormList from '../../generalComponents/WebProductFormList';
import dayjs from 'dayjs';
export default function PurchaseOrderEdit(props: any) {


    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const [deleteData, setDeleteData] = useState<any>();

    /**部门数据 */
    const [deptData, setDeptData] = useState<any>();




    /**采购商数据 */
    const [buyerData, setBuyerData] = useState<any>();



    const [formList, setFormList] = useState<any>({ formName: "purchaseProducts", form, type: data.type, ref, setDeleteData })
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        if (data.type === "NEW") {
            httpGet(wmService, {
                apiId: "buyerType",
                apiExtend: 'getAreaIdToBuyer',
            }).then((res1) => {
                setBuyerData(res1.data)
            })
        }
        if (ref.current) {
            ref.current.row.then((res: any) => {
                if (typeof res === 'undefined') return
                if (res.success) {
                    form.setFieldsValue({
                        userName: res.data.userLogin.userName,
                        orderTime: dayjs(res.data.sysDate.createdAt).format("YYYY-MM-DD")
                    })
                }
            })

            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            // httpGet(wmService, {
            //     apiId: "entrepot",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     setEntrepotData(res.data)
            // })
            if (data.type !== "NEW") {
                httpGet(wmService, {
                    apiId: "buyer",
                    apiExtend: 'showList'
                }).then(res => {
                    setBuyerData(res.data)
                })
            }
            if (data.type !== "NEW") {
                httpGet(wmService, {
                    apiId: "dept",
                    apiExtend: 'showList',
                    apiData: {

                    }
                }).then(res => {

                    setDeptData(res.data)
                })
            }

        }
        fetchData()
    }, [])




    const handleOk = (e: any) => {

        setLoading(true)
        var timer: any = null;
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (ref.current?.handleOk) ref.current.handleOk();

        }, 1000)

    };

    const handleCancel = (e: any) => {

        ref.current.handleCancel();
    };
    // const onChangeEntrepotIdToBuyerData = () => {
    //     if (!selectAreaId) return false;


    //     httpGet(wmService, {
    //         apiId: "buyerType",
    //         apiExtend: 'getAreaIdToBuyer',
    //         areaId: selectAreaId
    //     }).then((res) => {
    //         setBuyerData(res.data)

    //     })
    //     form.resetFields(["buyerId"])

    // }




    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'purchaseOrder',
        formListName: "purchaseProducts",
        changeOrderTime: {
            isTime: true,
            time: sessionStorage.getItem("date")
        }

    };

    // const children = (isLoading: boolean) => {
    //     return
    // }
    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} totalData={deleteData} setLoading={setLoading}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : data.type === "EDIT" ? "编辑" : "查看") + '订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                okButtonProps={{ disabled: data.type === 'VIEW' ? true : false }}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={"70%"}
                confirmLoading={loading}
            >
                <Form
                    form={form}
                    name="Edit"
                    onKeyDown={(e: any) => {
                        if (e.altKey) {
                            if (e.key === "s" && e.altKey) {
                                if (loading === false) {
                                    handleOk(e)

                                }
                            }
                        }

                    }}
                >
                    <Row >

                        <Col span={8}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={data.level === 1 ? false : true} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="开单人"
                                name="userName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="日期"
                                name="orderTime">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="部门"
                                name="deptId"
                                rules={[{ required: true, message: '请输入部门!' }]}>
                                <Select
                                    // disabled={data.type === "NEW" ? false : true}
                                    showSearch
                                    placeholder="部门"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.searchEx('dept', setDeptData, e, undefined,
                                        {
                                            form,
                                            setObj: ["deptId"],
                                            setObjValue: ["id"],
                                        }

                                    )}
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}>
                                    {getList.general(deptData, 'deptName')}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                label="采购商"
                                name="buyerId"
                                rules={[{ required: true, message: '请输入采购商!' }]}>
                                <Select
                                    onSearch={(e) => getList.searchEx('buyer', setBuyerData, e, buyerData, {
                                        setObj: ["buyerId"],
                                        setObjValue: ["id"],
                                        form
                                    })}
                                    // disabled={data.type === "NEW" ? false : true}
                                    showSearch
                                    placeholder="请输入采购商"
                                    optionFilterProp="children"
                                    filterOption={(input, option) => {
                                        return true
                                    }
                                    }
                                    options={getList.generalSelcetOption(buyerData, "buyerName")}
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item
                                label="限制部门"
                                name="isLimitDept"
                                valuePropName="checked"
                                initialValue={true}
                            >
                                < Switch
                                    checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Form.Item
                            style={{ display: "none" }}
                            label="仓库"
                            name="entrepotId" 
                        >
                               <Input disabled />
                        </Form.Item>
                        {/* {data.level === 2 ?
                          : null} */}

                        {data.level === 1 && data.type === "EDIT" ? <Col span={8}>
                            <Form.Item
                                label="收货签名"
                                name="acDeptSignatureUrl">
                                <Input />
                            </Form.Item>
                        </Col> : null}


                        <Col span={24}>
                            <WebProductFormList props={formList}></WebProductFormList>
                        </Col>


                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}


