import { useImperativeHandle, forwardRef, useEffect, useState } from 'react';
import fetchApi from '../../utils/api/fetchApi'
import antMessage from '../../utils/extend/AntdNotification'
import validator from 'validator';
import objectHandle from '../../utils/baseLib/objectHandle';
import system from '../../config/systematic';
import dayjs from 'dayjs';
import { mountDataOptionsType } from '../../types/general';
/** WebEditUniversal ExtendProps数据类型 */
export declare type WEUExtendPropsType = {
    /** api服务id */
    apiId: string,
    /** 数据 */
    data: any,
    /** 表单 */
    form: any,
    /** 自动转换表单识别的日期格式 */
    isDateMoment: Boolean,
    /** 是否禁止显示 */
    disabledShow: Boolean,
    /** 是否自定义新增订单时间 */
    changeOrderTime:{
        isTime:boolean,
        time:any
    }
    /** 是否禁止更新 */
    disabledUpdate: Boolean,
    /** 手动输入表单识别的日期格式字段名称数组 */
    dateColumnName: [any],
    /** 是否提取sysDate对象内容到数据中 */
    isExtractSysDate: Boolean,
    /** 页面传送的数据对象 */
    sendPageData: any,
    mountDataOptions?:mountDataOptionsType

};

const WebEditUniversal = (props: any, ref: any) => {
    //totalData 新增
    let { totalData, children } = props;
      
         
    let {
        closeModal,
        data,
        form,
        apiId,
        isDateMoment,
        disabledShow,
        disabledUpdate,
        dateColumnName,
        isExtractSysDate,
        formListName,
        changeOrderTime,
        mountDataOptions

    } = props.props; //从父组件传来

    //sendPageData 默认的传送数据
    const { sendPageData,extendedParameter } = data;
          
     
    /** 后端数据wmService */
    const wmService = system.wmService;
    const [loading, setLoading] = useState<boolean>(false)
    /** 默认service */
    // const defaultService = serviceUrl ? serviceUrl : getService(serviceId);
    //获取数据
    const [row] = useState<any>(
        async () => {
            //默认获取数据
            if (data.id) {

                const resData = await fetchApi.GET(wmService, {
                    apiId,
                    apiVariable: data.id,
                    apiExtend: "showSingle"
                });



                let resEx;
                if (isExtractSysDate) {
                    resEx = {
                        ...resData,
                        data: {
                            ...resData.data,
                            createdAt: resData.data.sysDate.createdAt,
                            updatedAt: resData.data.sysDate.updatedAt,
                        }
                    }
                    delete resEx.data.sysDate;
                }
                return resEx ? resEx : resData;
            }
        });


    //初始化
    useEffect(() => {
          
        //异步获取数据
        if ((data.type === 'EDIT' || data.type === "COPY"||data.type==="VIEW") && disabledShow !== true) {
            
            row.then((res: any) => {
                
                if (res.success === true) {
                    //检查日期类型，转为monent格式
                    if (typeof dateColumnName !== "undefined") {
                        for (let columnName in res.data) {
                            if (dateColumnName.includes(columnName) &&
                                res.data[columnName] !== null) {
                                if (dayjs(res.data[columnName]).isValid()) {
                                    res.data[columnName] = dayjs(res.data[columnName])
                                } else {
                                    let today = dayjs().format("YYYY-MM-DD") + "T" + res.data[columnName];

                                    res.data[columnName] = dayjs(today)

                                }


                                //日期转换moment格式

                                // res.data[columnName] = moment(res.data[columnName])

                            }
                        }
                    }
                    else {
                        if (isDateMoment === true) {
                            for (let columnName in res.data) {
                                if (typeof res.data[columnName] !== "undefined" && res.data[columnName] !== null) {
                                    if (validator.isISO8601(res.data[columnName].toString()) &&
                                        res.data[columnName] !== null) {
                                        //日期转换dayjs格式
                                        res.data[columnName] = dayjs(res.data[columnName])
                                    }
                                }
                            }
                        }
                    }
                    //显示数据
                    //设置表单默认数据  
                 
                   
                    form.setFieldsValue({
                        ...res.data,
                    })
                }
            })
        }
        // if(data.type==="COPY"&& disabledShow !== true){

        // }
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
   
    let mergeData = objectHandle.mergeObject(sendPageData, data.sendPageData);
     
    useImperativeHandle(ref, () => ({
        /** 确认 */
        handleOk: () => handleOkEdit(),
        /** 取消 */
        handleCancel: () => closeModal(false),
        /** 获取的数据 */
        row,
        /** 后端数据wmService */
        wmService,
        /** 后端defaultService */
        // defaultService
    }))

    const handleOkEdit = () => {
        
        setLoading(true)
        
        if (disabledUpdate !== true) {
            form.validateFields() //数据校验
                .then(async (values: any) => {
 
                    //新增
                    let nowData;

                    if (totalData) {
                        if (formListName) {
                            //传入删除数据
                            nowData = { ...values, [formListName]: values[formListName].concat(totalData[formListName]) }
                        } else {
                            nowData = { ...values, totalData};
                        }

                    } else {
                        nowData = {...values}
                    }
                    
                    const editData = {
                        //新增
                        ...nowData,
                        sendPageData: mergeData,
                    }

                    switch (data.type) {
                        case 'NEW':
                            
                            fetchApi.POST(wmService, {
                                apiId,
                                apiData: {...editData,createdAt:changeOrderTime?.time,mountDataOptions}
                            }).then(res => {
                                setLoading(false)
                                antMessage('success', '新增成功', 'success');
                                closeModal(true);
                            }).catch(err => {
                                setLoading(false)

                                antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
                            })
                            break;
                        case 'EDIT':
                         
                            fetchApi.PUT(wmService, {
                                apiId,
                                apiVariable: data.id,
                                apiData: editData
                            }).then(res => {
                                setLoading(false)

                                antMessage('success', '编辑成功', 'success');
                                closeModal(true);
                            }).catch(err => {
                                setLoading(false)

                                console.log("err", err);
                                antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
                            })
                            break;

                        case "COPY":
                            fetchApi.POST(wmService, {
                                apiId,
                                apiData:{...extendedParameter,...editData} ,
                                apiExtend:"copy"
                            }).then(res => {
                                setLoading(false)
                                antMessage('success', '复制成功', 'success');
                                closeModal(true);
                            }).catch(err => {
                                setLoading(false)

                                antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
                            })
                            break;
                    }
                })
                .catch((err: any) => {
                    console.log("err",err);
                    
                    setLoading(false)
                    antMessage('warning', '无法通过数据校验', '请输入所需数据！');
                });
        }
        else {
            closeModal(true);
        }
    };
    return (
        <>

            {children ? children(loading) : null}
        </>
    )
}

export default forwardRef(WebEditUniversal);