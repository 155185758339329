import React from 'react'
import { Form, Input, Select, Upload } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import systematic from '../../../config/systematic';
import FilesManage from '../../filesSystem/FilesManage';
import systemExpand from '../../../config/systemExpand';
export default function EntrepotProcessEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const [imageUrl, setImageUrl] = useState<string>();
    const [bb, setBa] = useState<any>();


    const ref = useRef<any>();
    /**订单类型数据 */
    const [orderTypeData, setOrderTypeData] = useState<any>()
    /** 后端数据Service */
    let wmService: any = "";
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    const uploadButton = (
        <div>

            <div style={{ marginTop: 8 }}>一般盖章文件</div>
        </div>
    );
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then((res: any) => {
                if (typeof res === 'undefined') return
                setImageUrl(res.data.geReGoodsUrl)

            })
        }
        const fetchData = async () => {
            let data = await systemExpand.filesServer();


            const { apiServiceUrl, serviceId } = data;

          


            let uploadData = await FilesManage.upload(apiServiceUrl!, serviceId!, "wiscaSpeReGoods", { successCallback: returnBackData })
            setBa(uploadData)
            httpGet(wmService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: "orderType",
            }).then(res => {
                if (res.success) {
                    setOrderTypeData(res.data)
                }
            })

        }
        fetchData()
    }, [])
    const returnBackData = (data: any) => {

        form.setFieldValue("geSealObj", data.data)

    }
    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'entrepotProcess'
    };
    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '订单类型'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label="抬头"
                        name="head"
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="订单类型"
                        name="orderType"
                        rules={[{ required: true, message: '请选择订单类型!' }]}>
                        <Select
                            showSearch
                            placeholder="请选择订单类型"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(orderTypeData, 'name')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label=""
                        name="geSealObj"

                    >
                        <Upload
                            maxCount={1}
                            {...bb}
                            listType="picture-card"
                        >
                            {imageUrl ? <img width={"100%"} src={imageUrl} alt="avatar" /> : uploadButton}
                        </Upload>
                    </Form.Item>

                </Form>
            </AntDraggableModal>

        </>
    )
}
