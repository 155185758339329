import React from 'react';
import FloorEdit from './FloorEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 Floor 
const Floor = (props: any) => {

    /** api服务id */
    const apiId = 'floor';

    //表头
    const columnsHead = [
        {
            title: '楼层',
            dataIndex: 'floor',
            sorter: true,
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '楼层',
        apiId,
        columns,
        showStates: true,
        pagination:true,
        EditComponent: FloorEdit
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default Floor;
