import React from 'react';
import RoomTypeEdit from './RoomTypeEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import ScheduledTimeEdit from './ScheduledTimeEdit';

//组件 ScheduledTime 
const ScheduledTime = (props: any) => {

  /** api服务id */
  const apiId = 'scheduledTime';

  //表头
  const columnsHead = [
    {
      title: '所属大市别',
      dataIndex: 'sb',
      render:(text:any)=>(text?text.sbName:"")
     
    },
    {
      title: '时间名称',
      dataIndex: 'timeName',
     
    },
    {
      title: '分店',
      dataIndex: 'branchStore',
      render:(text:any)=>(text?text.branchStoreName:"")
     
    },
    // week_Name
    {
      title: '周期',
      dataIndex: 'week_Name',
      // render:(text:any)=>(text?text.branchStoreName:"")
     
    },
    {
      title: '开始时间',
      dataIndex: 'startTime',
      render: (val:any) =>
      <span>
           {val.substring(0,5)}
      </span>,
    },
    {
      title: '结束时间',
      dataIndex: 'endTime',
      render: (val:any) =>
      <span>
           {val.substring(0,5)}
      </span>,
    },
    {
      title: '延迟点餐时间',
      dataIndex: 'delayedTime',
      render: (text: any, record: any) => {
          return (
              <div>{text+ "分钟"}</div>
          )
      }
  },
    {
      title: '默认客人到达时间',
      dataIndex: 'guestArrTime',
      render: (val:any) =>
      <span>
           {val?val.substring(0,5):""}
      </span>,
    },
    {
      title: '时间段状态',
      dataIndex: 'scheduledTimeStatusName',
    },
  ];

  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '时间段',
    apiId,
    columns,
    showStates: true,
    pagination: true,
    EditComponent: ScheduledTimeEdit,
    mountDataOptions:{
      userMount:{
         isDept:true,
      }
 },
  };

  return (
    <WebUniversal props={extendProps} />
  )
}

export default ScheduledTime;
