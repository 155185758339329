import { RouteObject } from 'react-router-dom';
import { getPageUrl } from './index';


import Printer from '../components/basic/printManagement/Printer';
import PrintConfiguration from '../components/basic/printManagement/PrintConfiguration';
import SpecialPrintConfiguration from '../components/basic/printManagement/SpecialPrintConfiguration';

const routes_printManagement = async (licenseList: Array<any>) => {
    const res: RouteObject[] = [
        //打印管理
        {
            path: await getPageUrl(licenseList, 'printer'),
            element: <Printer />
        },
        {
            path: await getPageUrl(licenseList, 'printConfiguration'),
            element: <PrintConfiguration />
        },
        {
            path: await getPageUrl(licenseList, 'specialPrintConfiguration'),
            element: <SpecialPrintConfiguration />
        },

        // {
        //     path: await getPageUrl(licenseList, 'moonReport'),
        //     element: <MoonReport></MoonReport>
        // },
        // {
        //     path: await getPageUrl(licenseList, 'disCount'),
        //     element: <DisCount></DisCount>
        // },

    ]
    return res;
}



export default routes_printManagement