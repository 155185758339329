import React, { useEffect, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import fetchApi from '../../../utils/api/fetchApi';
import systematic from '../../../config/systematic';
import { Button, Card, Checkbox, Col, Drawer, InputNumber, Input, Radio, List, Popconfirm, Row, Space, Tag, Divider, RadioChangeEvent, TabsProps, Tabs } from 'antd';
import arrayHandle from '../../../utils/baseLib/arrayHandle';
import antMessage from '../../../utils/extend/AntdNotification';
const { TextArea } = Input;
export default function OrderBack(props: any) {
    const { data, closeModal } = props;
    const [rows, setRow] = useState<Array<any>>([])
    const [checkedList, setCheckedList] = useState<Array<any>>([]);
    const [checkAll, setCheckAll] = useState(false);
    const [allData, setAllData] = useState<Array<any>>([]);

    const [memos, setMemos] = useState<Array<any>>([]);
    const [refundMethodData, setRefundMethodData] = useState<Array<any>>([])
    const [textAreaValue, setTextAreaValue] = useState<string>('');
    const [selectedTags, setSelectedTags] = React.useState<string[]>([]);


    const [radioValue, setRadioValue] = useState(2);
    /** 后端数据defaultService */
    let defaultService: any = systematic.wmService;
    const handleOk = (e: any) => {

        const selectedItems = rows.filter((item: any) => checkedList.includes(item.id));

        if (selectedItems.length === 0) {
            antMessage("warning", '请选择需要退款菜品');
            return false
        }
        if (!radioValue) {
            antMessage("warning", '请选择退款方式');
            return false
        }
        if (!textAreaValue || textAreaValue === '') {
            antMessage("warning", '请输入退款原因');
            return false
        }


        fetchApi.POST(defaultService,
            {
                apiId: 'payOrder',
                apiExtend: 'orderBack',
                apiData: {
                    orderId: data.orderId,
                    detailIds: selectedItems,
                    isExecuteRefund: true,
                    refundMethodId: radioValue,
                    refundReason: textAreaValue,
                    refundMoney: sum()
                }
            }).then(res => {
                if (res.success) {
                    antMessage('success', '退款成功', 'success');
                    if (selectedItems.length === rows.length) closeModal(true)
                    setCheckAll(false)
                    setTextAreaValue("")
                    setCheckedList([])
                    initData()
                }
            }).catch((err) => {
                antMessage("error", '发起退款失败', err);

            })
        // closeModal(true);
    };
    const onChangeRadioValue = (e: RadioChangeEvent) => {
        setRadioValue(e.target.value);
    };
    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'sys_memos',
                    apiExtend: 'showList',
                    apiData: {
                        sendPageData: {
                            memosTypeId: "repastRefund"
                        }
                    }
                }).then(res => {

                    if (res.success) {
                        setMemos(res.data)

                    }
                })


            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'refundMethod',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {

                        setRefundMethodData(res.data)

                    }
                });
        };
        fetchData();
        initData()

    }, [])
    const tabItems: TabsProps['items'] = [
        {
            key: 'refund',
            label: '退款(菜)',
            children: <List
                dataSource={rows}
                renderItem={(item: any, index: number) => {
                    return (
                        <List.Item
                            key={item.id}
                        >
                            <List.Item.Meta
                                title={<div style={{ fontSize: "30px", display: "flex", alignItems: "center" }}>
                                    <Checkbox style={{ transform: 'scale(2.5)', width: "3%" }} disabled={item.chargebackStatusId === 0 ? false : true} key="checkbox"
                                        checked={checkedList.includes(item.id)} onChange={e => handleItemCheck(e.target.checked, item)}>
                                    </Checkbox>
                                    <div style={{ width: "57%" }}>{`${index + 1}.${item.menuName}(${item.menuSpecName})`}</div>
                                    <div style={{ width: "20%" }}>{`x${item.amount}`}</div>
                                    <div style={{ width: "20%" }}>{`￥${item.price}`}</div>
                                </div>}
                                description={<div style={{ fontSize: "25px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <div style={{ width: "40%" }}>{item.nonDishName}</div>
                                    {item.chargebackStatusId === 0 ? <div style={{ width: "60%" }}>退款金额:<InputNumber onChange={(e) => changeRefundPrice(e, item)} min={0.01} max={item.price} defaultValue={item.refundPrice}></InputNumber></div>
                                        :
                                        <div style={{ width: "60%" }}>
                                            <Space style={{fontSize:15}}>
                                                <span>
                                                    {item.refundMethodName}
                                                </span>
                                                <span >
                                                    退款金额:{item.refundAmount}
                                                </span>
                                                <span>
                                                    退款原因:{item.refundReason}
                                                </span>
                                            </Space>




                                        </div>
                                    }
                                </div>}
                            />

                        </List.Item>
                    )
                }}
            />,
        },
        {
            key: 'refundOrder',
            label: '退款(整单)',
            children: '正在开发中',
        },
    ];
    const handleChange = (tag: string, checked: boolean) => {
        const nextSelectedTags = checked
            ? [...selectedTags, tag]
            : selectedTags.filter((t) => t !== tag);
        setSelectedTags(nextSelectedTags);
        if (checked) {

            setTextAreaValue((prevValue) => `${prevValue}${prevValue ? ',' : ''}${tag}`);
        }


    };
    const handleItemCheck = (checked: any, item: any) => {

        const newCheckedList = [...checkedList];
        if (checked) {
            newCheckedList.push(item.id);
        } else {
            const index = newCheckedList.indexOf(item.id);
            newCheckedList.splice(index, 1);
        }

        setCheckedList(newCheckedList);
     

        // 如果所有项都被选中，则全选也应该被选中
        if (newCheckedList.length === rows.length) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }


    };
    const initData = () => {
        fetchApi.GET(defaultService,
            {
                apiId: 'cateringOrder',
                apiExtend: 'getOrderIdByMenu',
                apiData: {
                    orderId: data.orderId
                }
            }).then(res => {
                let data = res.data;

                let newAllData: Array<string> = [];
                for (let i = 0; i < data.length; i++) {
                    if (data[i].chargebackStatusId === 0) newAllData.push(data[i].id)

                }
                setAllData(newAllData)
                if (res.success) {
                    setRow(data)

                }
            })
    }
    const changeRefundPrice = (e: any, item: any) => {
        let nowData = [{ ...item, refundPrice: e }]
        let newData: any = arrayHandle.merge(rows, nowData)
        setRow(newData)
    }

    //计算总数和总价格
    const sum = () => {
        const selectedItems = rows.filter((item: any) => checkedList.includes(item.id));
        if (selectedItems.length === 0) return 0;
        let totalPrice = 0;
        selectedItems.forEach((item) => {
            totalPrice += item.refundPrice
        })

        return totalPrice.toFixed(2)
    }
    const tabOnChange = (key: string) => {
        console.log(key);
    };

    const handleSelectAllChange = (e: any) => {
        // 全选/取消全选
        const checked = e.target.checked;
        setCheckAll(checked);

        if (checked) {

            setCheckedList(allData);
        } else {
            setCheckedList([]);
        }
    };
    return (
        <>
            <Drawer closable={false} onClose={() => closeModal(true)} width={"85%"}
                extra={
                    <Space style={{ marginLeft: "30px" }}>
                        <>
                            <Popconfirm id='popconfirmOne' placement={"bottom"} title={<div style={{ fontSize: "30px" }}>确定要退款吗？</div>} onConfirm={handleOk} okButtonProps={{ size: "large", danger: true }} cancelButtonProps={{ size: "large", }} okText="确定" cancelText="取消">
                                <Button style={{ width: 100, height: 50 }} size="large" danger>退款</Button>
                            </Popconfirm>
                            <Button style={{ width: 100, height: 50 }} size="large" onClick={() => closeModal(true)}>取消</Button>
                        </>
                    </Space>
                }
                title={
                    <>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Checkbox style={{ transform: 'scale(2.5)', marginLeft: 30 }} onChange={handleSelectAllChange} checked={checkAll} >
                                全选
                            </Checkbox>
                            <div>
                                <div style={{ fontSize: 35 }}>{`退款合计:${sum()}`}</div>

                            </div>
                            <div>
                                <div style={{ fontSize: 35 }}>{data.roomNum}</div>

                            </div>
                        </div>
                    </>

                } placement="left" open={true}>
                <Row>
                    <Col span={16}>
                        <Tabs defaultActiveKey="refund" items={tabItems} onChange={tabOnChange} />
                    </Col>
                    <Col span={8}>
                        <Card style={{ height: "100%" }} title="退款信息" extra={<a href="#">More</a>} >
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>选择退款方式</div>
                                <div>
                                    <Radio.Group onChange={onChangeRadioValue} value={radioValue}>
                                        {refundMethodData?.map((item) => {
                                            return <Radio value={item.id}>{item.name}</Radio>
                                        })}
                                    </Radio.Group>
                                </div>
                            </div>
                            <div>
                                <h3>退款原因</h3>
                                <TextArea
                                    value={textAreaValue}
                                    showCount
                                    onChange={(e) => setTextAreaValue(e.target.value)}
                                    maxLength={100}
                                    // onChange={onChange}
                                    placeholder="请自定义理由"
                                    style={{ height: 60, resize: 'none' }}
                                    allowClear
                                />
                                <Divider plain>快捷理由</Divider>
                                {memos.map<React.ReactNode>((memos) => (
                                    <Tag.CheckableTag
                                        key={memos.id}
                                        checked={false}
                                        onChange={(checked) => handleChange(memos.content, checked)}
                                    >
                                        {memos.content}
                                    </Tag.CheckableTag>
                                ))}
                            </div>
                        </Card>
                    </Col>

                </Row>
            </Drawer>
        </>
    )
}
