import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import BuyerEdit from './BuyerEdit';
export default function Buyer(props: any) {
    //表头
    const columnsHead = [
        {
            title: '专属id',
            dataIndex: 'selfId',
        },
        {
            title: '采购商电话',
            dataIndex: 'buyerPhone',
        },

        {
            title: '采购商',
            dataIndex: 'buyerName',

        },
        {
            title: '采购商类型',
            dataIndex: 'buyerType',
            render:(text:any)=>(text?text.buyerTypeName:"")

        },
      

    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '采购商',
        apiId: 'buyer',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: BuyerEdit,
    };
    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>

    )
}
