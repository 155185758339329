import * as icons from '@ant-design/icons';
import React from 'react';

const AntdIcon = (props: any) => {

    const { iconName } = props;
    const getIcon = (par: string) => {
        switch (par) {
            case 'DatabaseOutlined':
                return (< icons.DatabaseOutlined />);
            case 'DollarCircleOutlined':
                return (< icons.DollarCircleOutlined />);
            case 'UserOutlined':
                return (< icons.UserOutlined />);
            case 'UsergroupAddOutlined':
                return (< icons.UsergroupAddOutlined />);
            case 'ProfileOutlined':
                return (< icons.ProfileOutlined />);
            case 'PaperClipOutlined':
                return (< icons.PaperClipOutlined />);
            case 'IdcardOutlined':
                return (< icons.IdcardOutlined />);
            default:
                return (< icons.HomeOutlined />);

        }
    }

    return (
        getIcon(iconName)
    )
}

export default AntdIcon;