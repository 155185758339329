import React, { useEffect, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi'
import JSZip from 'jszip';
import { toPng } from 'html-to-image';
import system from '../../../config/systematic';
import { Button, Card, List, QRCode } from 'antd';

export default function MenuHeadCode(props:any) {
    /**后端数据vmService */
    const wmService = system.wmService;

    const [rows, setRows] = useState<Array<any>>([]);
    const menuHeadCode = system.wxQrCodeUrl.path;
    
    useEffect(() => {

        const fetchData = async () => {
            //查找品牌数据
            fetchApi.GET(wmService, {
                apiId: "menuHead",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    
                    let newData: Array<any> = [];
                    res.data.map((item: any) => {  
                        newData.push({
                            id:item.id,
                            url: `${menuHeadCode}menuHeadId=${item.id}&branchStoreId=${item.branchStoreId}`,
                            menuHeadName: item.menuHeadName,
                        })
                    })
                     
                    setRows(newData)
                }
            })


        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
   
    const downloadQRCode = (id: string) => {
        
        const canvas = document.getElementById(id)?.querySelector<HTMLCanvasElement>('canvas');
        
        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement('a');
            a.download = `${id}.png`;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };
    const insertAndCompressQrCodes = async () => {
        if (rows.length === 0) return false
        const zip = new JSZip();
        const folderName: string = `二维码`;
        let folder = zip.folder(folderName)!
        const imageFormat = 'png';
        for (let i = 0; i < rows.length; i++) {
            const qrCodeDataItem = rows[i];
            const qrCodeElement = document.getElementById(`${qrCodeDataItem.id}`);
            const imageDataUrl = await toPng(qrCodeElement!); // 替换为实际的图片格式转换方法
            folder.file(`${rows[i].menuHeadName}.${imageFormat}`, imageDataUrl.split(',')[1], { base64: true });

        }


        zip.generateAsync({ type: 'blob' }).then((content) => {
            // 下载压缩文件
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(content);
            downloadLink.download = '二维码.zip';
            downloadLink.click();
        });
    };
    return (
        <>

            <Button onClick={insertAndCompressQrCodes}>一键下载</Button>
            <List
                dataSource={rows}
                grid={{ gutter: 16, column: 4 }}
               
                renderItem={(item) => (
                    <List.Item>
                        <Card
                            actions={[
                                <div onClick={() => downloadQRCode(item.id)}>下载</div>
                            ]}
                        >
                            <div id={item.id}>
                                <QRCode size={200} style={{textAlign:"center",margin:"0 auto"}} value={item.url}></QRCode>
                            </div>
                            <div style={{textAlign:"center",margin:"0 auto"}}>菜谱:{item.menuHeadName}</div>

                        </Card>

                    </List.Item>
                )}
            />
        </>

    )
}
