import MemosTypeEdit from './MemosTypeEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';

//组件 
const WebComponents = (props: any) => {

    /** api服务id */
    const apiId = 'sys_memosType';

    //表头
    const columnsHead = [
        {
            title: '备注类型名称',
            dataIndex: 'memosTypeName',
            sorter: true,
        }
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '备注类型',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: MemosTypeEdit
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebComponents;
