import { Col, Form, Input, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';

//布局
const layout = {
    // labelCol: { span: 4 },
    // wrapperCol: { span: 4 },
};

const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    /** 后端数据wmService */
    let wmService: any = "";

    const [assetClassData, setAssetClassData] = useState<any>();

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;

            // ref.current.row.then(async (res: any) => {
            //     if (typeof res === 'undefined') { return }
            //     if (res.success === true) {
            //         // console.log(res.data);
            //     }
            // })
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: 'assetClass',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setAssetClassData(res.data);
                }
            });
        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'assetSubcategory',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '资产子类别'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                width={800}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row gutter={[8, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="资产类别"
                                name="assetClassId"
                                rules={[{ required: true, message: '请选择资产类别!' }]}>
                                <Select
                                    placeholder="资产类别"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {/* {getList.general(aliVoiceData, 'voiceName')} */}
                                    {getList.generalEx(assetClassData, { itemName: 'className' })}
                                </Select>
                            </Form.Item>
                        </Col>


                        <Col span={12}>
                            <Form.Item
                                label="子类别名"
                                name="subcategoryName"
                                rules={[{ required: true, message: '请输入资产子类别名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        
                        <Col span={6}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <Input type={"number"} />
                            </Form.Item>
                        </Col>

                        <Col span={6}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;