import fetchApi from '../utils/api/fetchApi';
import systematic from '../config/systematic';
import routes_menu from './routes_menu';
import routes_warehouse from './routes_warehouse';
import routes_sms from './routes_sms';
import routes_reserveRoom from './routes_reserveRoom';
import routes_moonManagement from './routes_moonManagement';
import routes_printManagement from './routes_printManagement';
import routes_asset from './routes_asset';

import routes_webWx from './routes_webWx';
import routes_cateringOrder from './routes_cateringOrder';

//development
import { RouteObject } from "react-router-dom"
import Login from '../components/basic/login/Login';
import LicenseGroup from '../components/basic/license/LicenseGroup';
import License from '../components/basic/license/License';
import DeviceManagement from '../components/basic/system/DeviceManagement';
import RoleGroup from '../components/basic/license/RoleGroup';
import Role from '../components/basic/license/Role';
import RoleLicenseExpand from '../components/basic/license/RoleLicenseExpand';
import SystemLogger from '../components/system/logger/SystemLogger';
import OpLogger from '../components/system/logger/OpLogger';
import SuPurchaseApplication from '../components/purchase/SuPurchaseApplication';
import DePurchaseApplication from '../components/purchase/DePurchaseApplication';
import DeptProdcutTime from '../components/deptmanagement/DeptProductTime';

//orderSignature
import PurchaseSignature from '../components/internal/orderManagement/orderSignature/PurchaseSignature';
import TransferSignature from '../components/internal/orderManagement/orderSignature/TransferSignature';
import HandleSignature from '../components/internal/orderManagement/orderSignature/HandleSignature';
import Brand from '../components/basic/general/Brand';
import Sb from '../components/basic/general/Sb';
import UserGroup from '../components/basic/user/UserGroup';
import User from '../components/basic/user/User';
import UserLogin from '../components/basic/user/UserLogin';
import UserLicenseExpand from '../components/basic/user/UserLicenseExpand';
import SocketOnline from '../components/basic/user/SocketOnline';
import Dept from '../components/basic/general/Dept';
import StoragePlace from '../components/basic/general/StoragePlace';
import BranchStore from '../components/basic/general/BranchStore';
import Area from '../components/basic/general/Area';
import BackupConfiguration from '../components/basic/general/BackupConfiguration';

import DevelopProjects from '../components/development/DevelopProjects';
import DevelopApi from '../components/development/DevelopApi';
import DevelopDatabase from '../components/development/DevelopDatabase';
import DevelopTable from '../components/development/DevelopTable';
import PersonnelInformation from '../components/basic/personnelManagement/Employees';
import FilesServer from '../components/filesSystem/FilesServer';
import FilesService from '../components/filesSystem/FilesService';
import FilesGroup from '../components/filesSystem/FilesGroup';
import Files from '../components/filesSystem/Files';
import CronTasks from '../components/system/cronTasks/CronTasks';
import Floor from '../components/basic/general/Floor';
import Configs from '../components/basic/configs/Configs';
import ConfigNodes from '../components/basic/configs/ConfigNodes';
import UserConfigs from '../components/basic/configs/UserConfigs';
import Ze8Eight from '../components/ze8/ze8Eight';
import Ze8Order from '../components/ze8/ze8Order';
import AppLayout from '../AppLayout';
import arrayHandle from '../utils/baseLib/arrayHandle';
import objectHandle from '../utils/baseLib/objectHandle';
import SocletIoTest from '../components/socletIo/SocletIoTest';
import ServiceCharge from '../components/basic/general/ServiceCharge';
import MemosType from '../components/basic/general/MemosType';
import Memos from '../components/basic/general/Memos';
import OtherSystemAccount from '../components/basic/general/OtherSystemAccount';

//值班管理
import BeOnDuty from '../components/basic/general/DutyManagement/BeOnDuty';
import BdPay from '../components/basic/general/DutyManagement/BdPay';

import DutyConfirmation from '../components/basic/general/DutyManagement/DutyConfirmation';
import PersonnelShift from '../components/basic/general/DutyManagement/PersonnelShift';
import PerBeOnDuty from '../components/basic/general/DutyManagement/PerBeOnDuty';
import AppVersionManage from '../components/basic/system/AppVersionManage.tsx';
import DeviceType from '../components/basic/general/DeviceType';
import Device from '../components/basic/general/Device';
import Dining from '../components/basic/general/Dining';
import DiningMethodCode from '../components/basic/general/DiningMethodCode';

/**
 * 获取路由地址
 * @param licenseList 
 * @param id 
 * @returns 
 */
export const getPageUrl = async (licenseList: any, id: string) => {

  /** 路由地址 */
  const pageUrl = (await arrayHandle.findValue(licenseList, 'id', id, 'pageUrl'))?.data;
  if (objectHandle.isEmpty(pageUrl) === true) {
    return '/' + id;
  } else {
    return '/' + pageUrl;
  }
};

/**
 * 路由
 * @returns 
 */
const Routers = async () => {
  /** 根据路由id列表获取路由地址列表 */
  let licenseList: any;
  const routerUrlList = await fetchApi.GET(systematic.wmService, {
    apiId: 'license',
    apiExtend: 'routeList'
  })

  if (routerUrlList.success === true) {
    licenseList = routerUrlList.data;
  }
  /** 子路由 */
  let routers: RouteObject[] = [
    //申购表单
    {
      path: await getPageUrl(licenseList, 'suPurchaseApplication'),
      element: <SuPurchaseApplication></SuPurchaseApplication>
    },
    {
      path: await getPageUrl(licenseList, 'dePurchaseApplication'),
      element: <DePurchaseApplication></DePurchaseApplication>
    },
    {
      path: "/deptProdcutTime",
      element: <DeptProdcutTime></DeptProdcutTime>
    },
    //权限管理 /basic/license
    {
      path: await getPageUrl(licenseList, 'deviceManagement'),
      element: <DeviceManagement />,
    },
    {
      // path: "/licenseGroup",
      path: await getPageUrl(licenseList, 'licenseGroup'),
      element: <LicenseGroup />,
    },
    {
      // path: "/license",
      path: await getPageUrl(licenseList, 'license'),
      element: <License />,
    },
    {
      // path: "/roleGroup",
      path: await getPageUrl(licenseList, 'roleGroup'),
      element: <RoleGroup />,
    },
    {
      // path: "/role",
      path: await getPageUrl(licenseList, 'role'),
      element: <Role />,
    },
    {
      // path: "/roleLicenseExpand",
      path: await getPageUrl(licenseList, 'roleLicenseExpand'),
      element: <RoleLicenseExpand />,
    },
    {
      // path: "/systemLogger",
      path: await getPageUrl(licenseList, 'systemLogger'),
      element: <SystemLogger />,
    },
    {
      path: await getPageUrl(licenseList, 'opLogger'),
      element: <OpLogger />,
    },
    //用户配置
    {
      // path: "/userConfigs",
      path: await getPageUrl(licenseList, 'userConfigs'),
      element: <UserConfigs />,
    },
    {
      // path: "/configs",
      path: await getPageUrl(licenseList, 'configs'),
      element: <Configs />,
    },
    {
      // path: "/configNodes",
      path: await getPageUrl(licenseList, 'configNodes'),
      element: <ConfigNodes />,
    },
    {
      // path: "/cronTasks",
      path: await getPageUrl(licenseList, 'cronTasks'),
      element: <CronTasks />,
    },

    //orderSignature
    {
      // path: "/purchaseSignature",
      path: await getPageUrl(licenseList, 'purchaseSignature'),
      element: <PurchaseSignature></PurchaseSignature>
    },
    {
      // path: "/transferSignature",
      path: await getPageUrl(licenseList, 'transferSignature'),
      element: <TransferSignature></TransferSignature>
    },
    {
      // path: "/handleSignature",
      path: await getPageUrl(licenseList, 'handleSignature'),
      element: <HandleSignature></HandleSignature>
    },
    /**基础资料 /basic/general */
    {
      // path: "/brand",
      path: await getPageUrl(licenseList, 'brand'),
      element: <Brand />
    },
    {
      // path: "/sb",
      path: await getPageUrl(licenseList, 'sb'),
      element: <Sb />
    },
    {
      // path: "/dept",
      path: await getPageUrl(licenseList, 'dept'),
      element: <Dept />
    },
    {
      path: await getPageUrl(licenseList, 'storagePlace'),
      element: <StoragePlace />
    },
    {
      // path: "/userGroup",
      path: await getPageUrl(licenseList, 'userGroup'),
      element: <UserGroup />
    },
    {
      // path: "/floor",
      path: await getPageUrl(licenseList, 'floor'),
      element: <Floor></Floor>
    },
    {
      // path: "/diningMethodCode",
      path: await getPageUrl(licenseList, 'diningMethodCode'),
      element: <DiningMethodCode></DiningMethodCode>
    },
    {
      path: await getPageUrl(licenseList, 'memosType'),
      element: <MemosType></MemosType>
    },
    {
      path: await getPageUrl(licenseList, 'memos'),
      element: <Memos></Memos>
    },
    {
      path: await getPageUrl(licenseList, 'otherSystemAccount'),
      element: <OtherSystemAccount></OtherSystemAccount>
    },

    //用户管理 /basic/user
    {
      // path: "/user",
      path: await getPageUrl(licenseList, 'user'),
      element: <User />
    }
    ,
    {
      // path: "/userLogin",
      path: await getPageUrl(licenseList, 'userLogin'),
      element: <UserLogin />
    },
    {
      // path: "/userLicenseExpand",
      path: await getPageUrl(licenseList, 'userLicenseExpand'),
      element: <UserLicenseExpand />
    },
    {
      path: await getPageUrl(licenseList, 'socketOnline'),
      element: <SocketOnline />
    },
    {
      // path: "/branchStore",
      path: await getPageUrl(licenseList, 'branchStore'),
      element: <BranchStore />
    },
    {
      // path: "/dining",
      path: await getPageUrl(licenseList, 'dining'),
      element: <Dining />
    },
    {
      // path: "/area",
      path: await getPageUrl(licenseList, 'area'),

      element: <Area></Area>
    },
    {
      path: await getPageUrl(licenseList, 'backupConfiguration'),

      element: <BackupConfiguration></BackupConfiguration>
    },

    // BackupConfiguration
    //development
    {
      // path: "/developProjects",
      path: await getPageUrl(licenseList, 'developProjects'),
      element: <DevelopProjects />,
    },
    {
      // path: "/developApi",
      path: await getPageUrl(licenseList, 'developApi'),
      element: <DevelopApi />,
    },
    {
      // path: "/developDatabase",
      path: await getPageUrl(licenseList, 'developDatabase'),
      element: <DevelopDatabase />,
    },
    {
      // path: "/developTable",
      path: await getPageUrl(licenseList, 'developTable'),
      element: <DevelopTable />,
    },
    {
      // path: "/filesServer",
      path: await getPageUrl(licenseList, 'filesServer'),
      element: <FilesServer />,
    },
    {
      // path: "/filesService",
      path: await getPageUrl(licenseList, 'filesService'),
      element: <FilesService />,
    },
    {
      // path: "/filesGroup",
      path: await getPageUrl(licenseList, 'filesGroup'),
      element: <FilesGroup />,
    },
    {
      // path: "/files",
      path: await getPageUrl(licenseList, 'files'),
      element: <Files />,
    },
    // personnelInformation
    {
      // path: "/personnelInformation",
      path: await getPageUrl(licenseList, 'personnelInformation'),
      element: <PersonnelInformation></PersonnelInformation>
    },
    // ze8Eight
    // 啫八神饮
    {
      path: "/ze8Eight",
      element: <Ze8Eight></Ze8Eight>
    },

    {
      path: "/ze8Order",
      element: <Ze8Order></Ze8Order>
    },
    {
      path: "/socletIoTest",
      element: <SocletIoTest></SocletIoTest>
    },


    {
      path: await getPageUrl(licenseList, 'serviceCharge'),
      element: <ServiceCharge></ServiceCharge>
    },


    //值班管理

    //值班信息
    {
      path: await getPageUrl(licenseList, 'bdPay'),
      element: <BdPay></BdPay>
    },
    {
      path: await getPageUrl(licenseList, 'beOnDuty'),
      element: <BeOnDuty></BeOnDuty>
    },
    //人员交班
    {
      path: await getPageUrl(licenseList, 'personnelShift'),
      element: <PersonnelShift></PersonnelShift>
    },
    //值班确认
    {
      path: await getPageUrl(licenseList, 'dutyConfirmation'),
      element: <DutyConfirmation></DutyConfirmation>
    },
    //值班确认
    {
      path: await getPageUrl(licenseList, 'dutyConfirmation'),
      element: <DutyConfirmation></DutyConfirmation>
    },

    //支付方式
    {
      path: await getPageUrl(licenseList, 'perBeOnDuty'),
      element: <PerBeOnDuty></PerBeOnDuty>
    },
    //app管理
    {
      path: await getPageUrl(licenseList, 'appVersionManage'),
      element: <AppVersionManage></AppVersionManage>
    },
    {
      path: '*',
      element: <div>找不到页面/页面不存在</div>
    },
    {
      // path: "/deviceType",
      path: await getPageUrl(licenseList, 'deviceType'),

      element: <DeviceType></DeviceType>
    },
    {
      // path: "/device",
      path: await getPageUrl(licenseList, 'device'),

      element: <Device></Device>
    },

  ];
  routers = routers.concat(await routes_menu(licenseList))
    .concat(await routes_warehouse(licenseList))
    .concat(await routes_sms(licenseList))
    .concat(await routes_reserveRoom(licenseList))
    .concat(await routes_moonManagement(licenseList))
    .concat(await routes_webWx(licenseList))
    .concat(await routes_cateringOrder(licenseList))
    .concat(await routes_printManagement(licenseList))
    .concat(await routes_asset(licenseList));

  /** 主路由 */
  const mainRoutes: RouteObject[] = [
    {
      path: "/",
      element: <AppLayout></AppLayout>,
      children: routers
    },
    {
      path: '*',
      element: <div>找不到页面/页面不存在</div>
    },
    {
      path: '/login',
      element: <Login />
    }
  ];

  return mainRoutes;
};

export default Routers;