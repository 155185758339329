import React, { useRef } from 'react'
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import DiningEdit from './DiningEdit';

export default function BranchStoreFloor(props: any) {
    const ref = useRef<any>();
  
    //表头
    const columnsHead = [
        {
            title: '就餐方式',
            dataIndex: 'diningName',
        },
    
        // orderPaymentModeName
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '就餐方式',
        apiId: 'dining',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: DiningEdit,
    };
    return (
        <div>

            <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
        </div>
    )
}
