import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table } from 'antd';
import dayjs from 'dayjs';

import React, { useEffect, useState } from 'react'
import systemConfig from '../../../../config/systematic';
import fetchApi from '../../../../utils/api/fetchApi';
import getList from '../../../universal/getList';
import { handleData } from './DailyReport';

const { RangePicker } = DatePicker;
export default function TransferReport() {
  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const [form] = Form.useForm()
  const [deptData, setDeptData] = useState<any>();

  // 
  const [rows, setRows] = useState<any>([])

  const columnsHead = [
    // {
    //   title: '货品来源',
    //   dataIndex: 'productSource',
    //   render: (text: any, record: any) => (record ? `${record.exportDept.deptName}[调出]--${record.importDept.deptName}[调入]` : "")

    // },
    {
      title: '货品来源',
      dataIndex: 'id',
      render: (text: any, record: any) => (text ? text.deptName:"")

    },
    {
      title: '调出',
      dataIndex: 'import',
      render: (text: any) => (text ? text.toFixed(2) : "0.00")

    },
    {
      title: '调入',
      dataIndex: 'export',
      render: (text: any) => (text ? text.toFixed(2) : "0.00")

    },
    
    //  {
    //    title: '调入',
    //    dataIndex: 'import',
    //    render:(text:any)=>(text?text.toFixed(2):"0.00")
    //  },
    // {
    //   title: '损溢',
    //   dataIndex: 'changed',
    // },

  ];
  useEffect(() => {
    const fetchData = async () => {
      let deptData = await fetchApi.GET(wmService, {
        apiId: "dept",
        apiExtend: 'showList',
        apiData:{
                    
        }
      })
      if (deptData.success) {
        setDeptData(deptData.data)
      }



      // 
    }
    fetchData()
  }, [])



  const onFinish = (values: any) => {

    fetchApi.GET(wmService, {
      apiId: "entrepotReport",
      apiExtend: 'transferReport',
      apiData: {
        deptIds: handleData(values.deptIds, "deptId"),
        time: values.chooseTime ? {
          startTime: dayjs(values.chooseTime[0]).format("YYYY-MM-DD"),
          endTime: dayjs(values.chooseTime[1]).format("YYYY-MM-DD"),
        } : null,
        selfs: values.selfStart && values.selfEnd ? {
          selfStart: values.selfStart,
          selfEnd: values.selfEnd
        } : null
      }
    }).then((res: any) => {
      setRows(res.data.rows)

    }).catch((err: any) => {
      console.log("err", err);

    })



  }
  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              label="部门"
              name="deptIds"
            >
              <Select
                // orderTypeData
                mode="multiple"
                showSearch
                placeholder="请选择部门!"
                optionFilterProp="children"
                onSearch={(e) => getList.search('dept', setDeptData, e)}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(deptData, 'deptName')}
              </Select>
            </Form.Item>
          </Col>
          {/* <Col span={24}>
          <Form.Item label="采购商"
            name="buyers"
          >
            <Select
              mode="multiple"
              showSearch
              placeholder="采购商"
              optionFilterProp="children"
              onSearch={(e) => getList.search('dept', setDeptData, e)}
              filterOption={(input, option: any) => {
                return true
              }}
            >
              {getList.general(buyerData, 'buyerName')}
            </Select>
          </Form.Item>
        </Col> */}

          <Col span={6}>
            <Form.Item
              label="品号"
              name="selfStart"
            >
              <Input
                // onChange={handleChange}
                // onBlur={handleBlur}
                placeholder="Input a number"
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              colon={false}
              label="-"
              name="selfEnd"
            >
              <Input
                // onChange={handleChange}
                // onBlur={handleBlur}
                placeholder="Input a number"
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="日期"
              name="chooseTime"
            >
              <RangePicker
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Form.Item >
              <Space>
                <Button type="primary" htmlType="submit">
                  统计
                </Button >
                {/* <Button onClick={() => DownloadExcel(prop, prop.data)} >
                                  下载EXCEL文件
                              </Button> */}
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>



      {/* <Button onClick={() => DownloadExcel(rows, "moonReport",queryCriteria)}>下载EXCEL文件</Button> */}
      <div style={{ marginTop: "20px" }}>
        <Table
          pagination={false}
          rowKey={(record: any) => record.id.deptId}
          columns={columnsHead}
          dataSource={rows}
        // summary={(pageData: any) => {

        //   let totalChanged = 0;
        //   let totalSolded = 0;
        //   let totalAmount = 0;
        //   let toTalOriginAmount = 0;
        //   if (pageData.length > 0) {
        //     pageData.map((item: any) => {
        //       if (item.changed) totalChanged += item.changed
        //       if (item.solded) totalSolded += item.solded;
        //       if (item.amount) totalAmount += item.amount;
        //       if (item.originAmount) toTalOriginAmount += item.originAmount;

        //     })
        //   }

        //   return (
        //     <Table.Summary fixed>
        //       <Table.Summary.Row>
        //         <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
        //         <Table.Summary.Cell index={1}></Table.Summary.Cell>
        //         <Table.Summary.Cell index={2}></Table.Summary.Cell>
        //         <Table.Summary.Cell index={3}>{totalSolded === 0 ? null : totalSolded}</Table.Summary.Cell>
        //         <Table.Summary.Cell index={4}>{totalChanged === 0 ? null : totalChanged}</Table.Summary.Cell>
        //         <Table.Summary.Cell index={5}>{toTalOriginAmount === 0 ? null : toTalOriginAmount.toFixed(2)}</Table.Summary.Cell>
        //         <Table.Summary.Cell index={6}>{totalAmount === 0 ? null : totalAmount.toFixed(2)}</Table.Summary.Cell>
        //       </Table.Summary.Row>
        //     </Table.Summary>
        //   )
        // }}
        >
        </Table>
      </div>
    </>
  )
}
