import EditComponent from './AppVersionManageEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Switch } from 'antd';

//组件 
const WebComponents = (props: any) => {

    /** api服务id */
    const apiId = 'appVersionManage';

    //表头
    const columnsHead = [
        {
            title: '名称',
            dataIndex: 'appName'
        },
        {
            title: '版本号',
            dataIndex: 'appVersion'
        },
        {
            title: '平台',
            dataIndex: 'appPlatformId'
        },
        {
            title: '架构',
            dataIndex: 'appArchId'
        },
        {
            title: '必需更新',
            dataIndex: 'isNeedUpdated',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: 'app版本管理',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebComponents;
