import { Col, DatePicker, Form, Input, Radio, RadioChangeEvent, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';
const { TextArea } = Input;
const CallerCustomerEdit = (props: any) => {
  const [form] = Form.useForm(); //form 
  const { data } = props;
  const [value, setValue] = useState<number>(1);
  const ref = useRef<any>();
  const [branchStoreData, setbranchStoreData] = useState<any>();

  const [appellationData, setAppellationData] = useState<any>();
  /** 后端数据wmService */
  let wmService: any = "";
  const handleOk = (e: any) => {
    ref.current.handleOk();
  };
  const handleCancel = (e: any) => {
    ref.current.handleCancel();
  };

  let extendProps: WEUExtendPropsType = {
    ...props,
    form,
    apiId: 'callerCustomer',
    dateColumnName: ["birthday"],
  };

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wmService = ref.current.wmService;
    }
    const fetchData = async () => {
      httpGet(wmService, {
        apiId: "branchStore",
        apiExtend: 'showList'
      }).then(res => {
        if (res.success) {

          setbranchStoreData(res.data)
        }
      })
      httpGet(wmService, {
        apiId: "generalData",
        apiExtend: 'showList',
        apiVariable: "appellation",
      }).then(res => {
        if (res.success) {

          setAppellationData(res.data)
        }
      })

    };
    fetchData();
  }, []);
  return (
    <><WebEditUniversal ref={ref} props={extendProps} />
      <AntDraggableModal
        title={(data.type === 'NEW' ? '新增' : '编辑') + '电话客户'}
        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        width={800}
      >
        <Form
          form={form}
          name="Edit"
          onFinish={handleOk}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}>

          <Row>
            <Col span={8} >
              <Form.Item
                label="电话"
                name="id"
                rules={[{ required: true, message: '请输入客户电话!' }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8} >
              <Form.Item
                label="客户名称"
                name="customerName"
                rules={[{ required: true, message: '请输入客户名称!' }]}>
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="称呼"
                name="appellationId"
                rules={[{ message: '请选择称呼!', required: true }]}>
                <Select
                  showSearch
                  placeholder="选择称呼"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(appellationData, 'name')}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8} >
              <Form.Item
                label="公司名称"
                name="companyName" >
                <Input />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="分店"
                name="branchStoreId">
                <Select
                  showSearch
                  placeholder="选择分店"
                  optionFilterProp="children"
                  disabled={true}
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(branchStoreData, 'branchStoreName')}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8} >
              <Form.Item
                label="性别"
                name="sex">
                <Radio.Group onChange={onChange} value={value}>
                  <Radio value={true}>男</Radio>
                  <Radio value={false}>女</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            <Col span={6} >
              <Form.Item
                label="生日"
                name="birthday">
                <DatePicker format={"YYYY-MM-DD"} />
              </Form.Item>
            </Col>

            <Col span={6} >
              <Form.Item
                label="邮编"
                name="postcode" >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} >
              <Form.Item
                label="地址"
                name="address"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24} >
              <Form.Item
                label="特征"
                name="features"
              >
                <TextArea
                  placeholder="特征"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
            </Col>

            <Col span={24} >
              <Form.Item
                label="喜好"
                name="preferences" >
                <TextArea
                  placeholder="喜好"
                  autoSize={{ minRows: 2, maxRows: 6 }}
                />
              </Form.Item>
            </Col>

            <Col span={20} >
              <Form.Item
                label="备注"
                name="memos">
                <TextArea
                  placeholder="备注"
                  autoSize={{ minRows: 1, maxRows: 6 }}
                />
              </Form.Item>
            </Col>

            <Col span={4} >
              <Form.Item
                label="状态"
                name="isUse"
                valuePropName="checked"
                initialValue={true}>
                < Switch checked={true}
                  checkedChildren="启用"
                  unCheckedChildren="关闭" />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </AntDraggableModal>
    </>
  )
}

export default CallerCustomerEdit;