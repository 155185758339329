import { Tooltip } from 'antd';
import moment from 'moment';
import React from 'react'
import WebUniversal, { WUExtendPropsType } from '../system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';
import Ze8OrderEdit from './ze8OrderEdit';

export default function Ze8Order(props: any) {
  /** api服务id */
  const apiId = 'ze8Order';

  //表头
  const columnsHead = [
    {
      title: '品牌',
      dataIndex: 'branchStoreId',
    },
    {
      title: '下单客户',
      dataIndex: 'unionId',
    },
    {
      title: '订单状态',
      dataIndex: 'orderStatusName',
    },
    {
      title: '出餐时间',
      dataIndex: 'sysDate',
      render: (text: any) => (text ? moment(text.createdAt).format('YYYY-MM-DD HH:mm:ss') : '')
    },
    {
      title: '支付单号',
      dataIndex: 'orderPayId',
    },
    {
      title: '价格',
      dataIndex: 'price',
      render: (test: any) => (test ? test + "元" : "0元")
    },
    {
      title: '备注',
      dataIndex: 'memo',
    }
  ];

  const columns = columnsheadId
    .concat(columnsHead as []);

  /** 子表头内容 */
  const z8ColumnsHead = [
    {
      title: '名称',
      dataIndex: 'productName',
      render: (text: any, record: any) => <Tooltip placement="topLeft" title={record.id}>{
        text
      }</Tooltip>
    },
    {
      title: '规格',
      dataIndex: 'productPriceSpec',
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 8 ? text.substring(0, 8) + '......' : text)
          : ''
      }</Tooltip>
    },
    {
      title: '价格',
      dataIndex: 'price',
    },
    {
      title: '成交价',
      dataIndex: 'purchasePrice',
    },
    {
      title: '数量',
      dataIndex: 'amount',
    },
    {
      title: '单位',
      dataIndex: 'productUnit',
    },
    {
      title: '金额',
      dataIndex: 'totalPurchasePrice',
    },
    {
      title: '产品状态',
      dataIndex: 'productStatus_Name',
    },
    {
      title: '',
      dataIndex: 'chargebackStatus_Name',
    },
    {
      title: '退换规则',
      dataIndex: 'productReturnRules_Name',
    },
  ];

  /** 子表头 */
  const z8Columns = z8ColumnsHead;


  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '啫八订单',
    apiId,
    columns,
    showStates: true,
    pagination: true,
    EditComponent: Ze8OrderEdit,
    //子表格
    isExpandable:true,
    z8Columns,
  };

  return (
    <WebUniversal props={extendProps} />
  )
}
