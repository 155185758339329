import React from 'react'
import TransferSignatureEdit from './TransferSignatureEdit';
import WebUniversal, { WUExtendPropsType } from '../../../system/WebUniversalNewEx'
import { columnsheadId } from '../../../universal/columnshead';
import moment from 'moment';
export default function TransferSignature(props: any) {
  /** api服务id */
  const apiId = 'transferSignature';

  //表头
  const columnsHead = [
    {
      title: "单号",
      dataIndex: "transferOrderId"
    },
    {
      title: '部门',
      dataIndex: 'dept',
      render: (text: any) => (text ? text.deptName : "")
    },
    {
      title: '签名人员',
      dataIndex: 'user',
      render: (text: any) => (text ? text.userName : "")

    },
    {
      title: "签单时间",
      dataIndex: "sysDate",
      render: (text: any) => {
        return <>{text ? moment(text.createdAt).format("YYYY-MM-DD HH:mm:ss") : ""}</>
      }
    },
  ];

  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '调拨签名',
    apiId,
    columns,
    showAdd: false,
    showStates: true,
    pagination: true,
    EditComponent: TransferSignatureEdit
  };

  return (
    <WebUniversal props={extendProps} />
  )
}
