import AntDraggableModal from "../../../utils/extend/AntDraggableModal";
import { Descriptions } from 'antd';
import stringHandle from "../../../utils/baseLib/stringHandle";
import { useEffect, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import system from "../../../config/systematic";

const CheckIngredient = (props: any) => {
    //console.log(props)
    const { data, closeModal } = props;

    /** 主配料数据 */
    const [ingredientData, setIngredientData] = useState<any>([]);

    /** 主配料表基础的数据*/
    const [ingredientBaseData, setIngredientBaseData] = useState<any>();

    /** 主配料详细的数据*/
    const [ingredientDetailData, setIngredientDetailData] = useState<any>([]);

    /** 后端数据Service */
    let wmService = system.wmService;

    /**数据 */
    useEffect(() => {
        // if (ref.current) {
        //     // eslint-disable-next-line react-hooks/exhaustive-deps
        //     wmService = ref.current.wmService;
        //     ref.current.row.then(async (res: any) => {
        //         if (typeof res === 'undefined') { return }
        //     })
        // }

        const fetchData = async () => {

            // const menuWarehouseIngredientId = data.id;

            fetchApi.GET(wmService, {
                // apiId: "menuWarehouseIngredientDetail",
                // apiExtend: "showLists",
                // apiData: {
                //     menuWarehouseIngredientId: menuWarehouseIngredientId,
                // }
                apiId: "menuWarehouseIngredient",
                apiExtend: "showSingle",
                apiVariable: data.id
            }).then(async res => {
                if (res.success) {
                    let new_arr = [];
                    let str_arr: any = [];

                    /** 点击编辑后，根据数据的内容来进行分析，筛选出之前有数据的口味 */
                    for (var i_data = 0, len_data = res.data.rowsDetail.length; i_data < len_data; i_data++) {
                        if (res.data.rowsDetail[i_data].length === 0) {
                            str_arr.push(i_data);
                        }
                        else {
                            str_arr.push(999);
                        }
                    }

                    await fetchApi.GET(wmService, {
                        apiId: 'generalData',
                        apiVariable: 'ingredient',
                        apiExtend: 'showList'
                        // eslint-disable-next-line no-loop-func
                    }).then(ress => {
                        new_arr = ress.data.map((item: any) => ({
                            id: item.id,                      //设置字段，在form.list下的form.item中指定的字段值
                            name: item.name,
                            visible: str_arr[item.id] === item.id ? false : item.visible
                        }));
                        //console.log(new_arr)
                        setIngredientData(new_arr);
                    });
                    //console.log(res.data)
                    setIngredientDetailData(res.data.rowsDetail);
                }
            })

            fetchApi.GET(wmService, {
                apiId: 'menuWarehouseIngredient',
                //apiExtend: 'showSingle',
                apiVariable: data.id
            }).then(res => {
                if (res.success) {
                    setIngredientBaseData(res.data);
                }
            });
        }
        fetchData()
    }, [])

    const CheckData = (rows: any) => {
        if (typeof rows !== "undefined") {
            rows = rows
            return (
                <Descriptions bordered>
                    <Descriptions.Item label="菜式名称" span={2}>{rows.menuWarehouseSpecId ? rows.menuWarehouse.foodName : rows.feastTeamMenuWarehouse.foodName}</Descriptions.Item>
                    <Descriptions.Item label="规格">{rows.menuWarehouseSpecId ? rows.menuWarehouseSpec.menuWarehouseSpecName : rows.productUnit.productUnitName}</Descriptions.Item>
                    <Descriptions.Item label="售价">{rows.sellPrice} 元</Descriptions.Item>
                    <Descriptions.Item label="成本价">{rows.costPrice} 元</Descriptions.Item>
                    <Descriptions.Item label="毛利率">{rows.grossProfitRatio} %</Descriptions.Item>
                    <Descriptions.Item span={3}> </Descriptions.Item>

                    <Descriptions.Item label="名称" contentStyle={{ backgroundColor: "#282828" }}>定量 + 单位</Descriptions.Item>
                    <Descriptions.Item label="起率" contentStyle={{ backgroundColor: "#282828" }}>总价</Descriptions.Item>
                    <Descriptions.Item label="金额" contentStyle={{ backgroundColor: "#282828", width: 250 }}>备注</Descriptions.Item>
                   
                    {
                        ingredientData.map((item: any, index: any) => {
                            if (item.visible === true) {
                                return (
                                    <>
                                        <Descriptions.Item label={item.name} span={3} labelStyle={{ border: '0px', backgroundColor: "#1f1f1f" }}> </Descriptions.Item>
                                        {
                                            ingredientDetailData.map((items: any, indexs: any) => {
                                                if (indexs === index) {
                                                    return (
                                                        <>
                                                            {
                                                                items.map((itemss: any) => {
                                                                    // console.log(itemss);                                                                
                                                                    return (
                                                                        <>
                                                                            <Descriptions.Item label={itemss.product.productName} contentStyle={{ backgroundColor: "#282828" }}>{itemss.amount} {itemss.productUnit.productUnitName}</Descriptions.Item>
                                                                            <Descriptions.Item label={itemss.yieldRatio + " %"} contentStyle={{ backgroundColor: "#282828" }}>{itemss.unitPrice} 元</Descriptions.Item>
                                                                            <Descriptions.Item label={itemss.total + " 元"} contentStyle={{ backgroundColor: "#282828" }}>{itemss.memo}</Descriptions.Item>
                                                                        </>
                                                                    )

                                                                })
                                                            }
                                                        </>
                                                    )
                                                }
                                            })
                                        }
                                    </>
                                )
                            }
                        })
                    }
                </Descriptions>
            )
        }
    };

    const handleOk = (e: any) => {
        closeModal(true);
    };

    return (
        <>
            <AntDraggableModal
                title={'配料表'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}
                cancelButtonProps={{style:{display:"none"}}}
                onOk={handleOk}
                //onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={1100}>
                {CheckData(ingredientBaseData)}
            </AntDraggableModal>
        </>
    )
}

export default CheckIngredient;