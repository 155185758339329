import { Button, Col, DatePicker, Form, Input, InputNumber, Row, Select, Space, Switch } from 'antd';
import moment from 'moment';
import { useEffect, useRef, useState, } from 'react'
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewExEx';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';
import WebProductFormList from '../../generalComponents/WebProductFormList';
import "./TransferOrder.css"
import dayjs from 'dayjs';
export default function TransferOrderEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;

    const ref = useRef<any>();
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const [deleteData, setDeleteData] = useState<any>();

    /**会计期禁用 */
    // const [accountantDisable, setAccountantDisable] = useState<any>(true)
    const [formList, setFormList] = useState<any>({ formName: "transferProducts", form, type: data.type, ref, setDeleteData })
    const [loading, setLoading] = useState<boolean>(false)
    /**调入部门数据 */
    const [importDeptData, setImportDeptData] = useState<any>();


    /**调出部门数据 */
    const [exportDeptData, setExportDeptData] = useState<any>();

    /**仓库数据 */
    const [entrepotData, setEntrepotData] = useState<any>();



    useEffect(() => {
        // let deptId = sessionStorage.getItem("deptId")

        if (ref.current) {
            ref.current.row.then((res: any) => {
                if (typeof res === 'undefined') return
                if (res.success) {
                    form.setFieldsValue({
                        userName: res.data.userLogin.userName,
                        orderTime: dayjs(res.data.sysDate.createdAt).format("YYYY-MM-DD")
                    })
                }
            })
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        // if (deptId && data.type === "NEW") {
        //     form.setFieldsValue({
        //         "exportDeptId": deptId
        //     })
        //     httpGet(wmService, {
        //         apiId: "dept",
        //         apiExtend: 'showList'
        //     }).then(res => {

        //         res.data.forEach((item: any) => {
        //             if (item.id === deptId) {
        //                 setExportDeptData([item])

        //             }
        //         })
        //     })


        // }
        const fetchData = async () => {

            httpGet(wmService, {
                apiId: "dept",
                apiExtend: 'showList',
                apiData:{
                    
                }
            }).then(res => {

                setImportDeptData(res.data)
                setExportDeptData(res.data)

            })
            httpGet(wmService, {
                apiId: "entrepot",
                apiExtend: 'showList'
            }).then(res => {
                setEntrepotData(res.data)

            })

        }
        fetchData()
    }, [])
    const handleOk = (e: any) => {

        setLoading(true)
        var timer: any = null;
        clearTimeout(timer);
        timer = setTimeout(() => {
            if (ref.current?.handleOk) ref.current.handleOk();

        }, 1500)


    };
    const handleCancel = (e: any) => {

        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'transferOrder',
        formListName: "transferProducts",
        changeOrderTime: {
            isTime: true,
            time: sessionStorage.getItem("date")
        }
    };
    const onChangeImportEntrepot = (e: any) => {


        if (data && data.level === 1) {


        } else {
            fetchApi.GET(wmService, {
                apiId: "entrepot",
                apiExtend: "getDeptToStore",
                apiVariable: e
            }).then((res) => {


                setEntrepotData(res.data)
                if (res.data.length === 1) {
                    form.setFieldValue("importEntrepotId", res.data[0].id)
                }
                if (res.data.length > 1) {
                    form.resetFields(["importEntrepotId"])
                }


            }).catch((err) => {
                console.log("err", err);

                form.resetFields(["importEntrepotId"])

            })
        }


    }

    return (
        <>
            <WebEditUniversal ref={ref} totalData={deleteData} props={extendProps} setLoading={setLoading}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : data.type === "EDIT" ? "编辑" : "查看") + '订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                okButtonProps={{ disabled: data.type === 'VIEW' ? true : false }}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                confirmLoading={loading}
                width={"70%"}
            >
                <Form
                    form={form}
                    name="Edit"
                    // onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.altKey) {
                            if (e.key === "s" && e.altKey) {
                                if (loading === false) {
                                    handleOk(e)
                                }
                            }
                        }

                    }}
                >
                    <Row >

                        <Col span={8}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="开单人"
                                name="userName">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="日期"
                                name="orderTime">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item

                                label={<span style={{ "color": "yellow" }}>调出部门</span>}
                                name="exportDeptId"
                                style={{ "color": "yellow" }}
                                rules={[{ required: true, message: '请输入调出部门!' }]}>
                                <Select
                                    className='exportDept'
                                    style={{ "color": "yellow" }}
                                    // disabled={data.type === "NEW" ? false : true}
                                    showSearch
                                    placeholder="请输入调出部门"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.searchEx('dept', setExportDeptData, e, undefined, {
                                        form,
                                        setObj: ["exportDeptId"],
                                        setObjValue: ["id"],
                                    })}
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}>
                                    {getList.general(exportDeptData, 'deptName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={7}>
                            <Form.Item
                                label="调入部门"
                                name="importDeptId"

                                rules={[{ required: true, message: '请输入调入部门!' }]}>
                                <Select
                                    // disabled={data.type === "NEW" ? false : true}
                                    showSearch
                                    onSearch={(e) => getList.searchEx('dept', setImportDeptData, e, undefined, {
                                        form,
                                        setObj: ["importDeptId"],
                                        setObjValue: ["id"],
                                        selectFun: onChangeImportEntrepot
                                    })}
                                    onSelect={(e) => { onChangeImportEntrepot(e) }}
                                    placeholder="请输入调入部门"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}>
                                    {getList.general(importDeptData, 'deptName')}
                                </Select>
                            </Form.Item>
                        </Col>



                        <Col span={7}>
                            <Form.Item
                                label="调入仓库"
                                name="importEntrepotId"

                                rules={[{ required: true, message: '请输入调入仓库!' }]}
                            >
                                <Select
                                    // disabled={data.level === 1 ? true : false}
                                    showSearch
                                    placeholder="请输入调入仓库"
                                    optionFilterProp="children"
                                    onSearch={(e) => getList.searchEx('entrepot', setEntrepotData, e, undefined, {
                                        form,
                                        setObj: ["importEntrepotId"],
                                        setObjValue: ["id"],
                                    })}
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}>

                                    {getList.general(entrepotData, 'entrepotName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={3}>
                                <Form.Item
                                    label="限制调出部门"
                                    name="isLimitDept"
                                    valuePropName="checked"
                                    initialValue={true}
                                >
                                    < Switch
                                        checked={true}
                                        checkedChildren="是"
                                        unCheckedChildren="否" />
                                </Form.Item>
                            </Col>
                        {/* {data.level === 2 ?
                            : null} */}

                        {data.level === 1 && data.type === "EDIT" ?
                            <>
                                <Col span={12}>
                                    <Form.Item
                                        label="验收签名"
                                        name="acDeptSignatureUrl">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="发出签名"
                                        name="exDeptSignatureUrl">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </>

                            : null}


                        <WebProductFormList props={formList}></WebProductFormList>


                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}


