


export function getQueryStringArgs() {
  var qs = (window.location.search.length > 0 ? window.location.search.substring(1) : "");
  let args: any = {};
  let items = qs.length ? qs.split("&") : [];
  let item: any;
  let name: any;
  let value: any;
  let i = 0;
  let len = items.length;

  for (i = 0; i < len; i++) {
    item = items[i].split("=");

    // decodeURIComponent解码
    name = decodeURIComponent(item[0]);
    value = decodeURIComponent(item[1]);
    try {
      value = JSON.parse(value)
    } catch (e) {
      value = value
    }
   

    if (name.length) {
      args[name] = value
    }
  }
 
  return args;
}

