import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import WarehouseCategoriesEdit from './WarehouseCategoriesEdit';

const WarehouseCategories = (props:any) => {


    //表头
    const columnsHead = [
        {
            title: '大类',
            dataIndex: 'warehouseCategoriesName',
            
        },
      
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '大类',
        apiId: 'warehouseCategories',
        showStates: true,
        columns,
        pagination:true,
        EditComponent: WarehouseCategoriesEdit,
    };

    return (
       <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}


export default WarehouseCategories
