import React, { useState } from 'react';
import RoomTypeEdit from './RoomTypeEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import RoomReserveEdit from './ReserveOrderEdit';
import { Button, Space } from 'antd';
import ChooseReserveRoom from './ChooseReserveRoom';

//组件 RoomReserve 
const RoomReserve = (props: any) => {

    /** api服务id */
    const apiId = 'roomReserve';
    const [modalChooseReserveRoomopen, setModalChooseReserveRoomopen] = useState<Boolean>(false)

     /**选择房间数据 */
     const [branchStoreRoomData, setbranchStoreRoomData] = useState<any>();
    //表头
    const columnsHead = [
        {
            title: 'customerReserveId',
            dataIndex: 'customerReserveId',

        },

    ];
    //传关参数
    let sendData = {
        type: 'EDIT',
        bookingDate: '',
        scheduledtimeTypeId: '',
        roomTypeId:"",
        id:"",
        customerReserveId:""
    }
    //打开部门产品
    const handleRoom = (e: any) => {
        sendData.id=e.id
        sendData.bookingDate=e.bookingDate;
        sendData.customerReserveId=e.customerReserveId
        sendData.scheduledtimeTypeId=e.scheduledtimeTypeId;
        sendData.roomTypeId=e.customerReserve.roomTypeId;

        setbranchStoreRoomData(sendData);
        setModalChooseReserveRoomopen(true);

    }
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '房间预订',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        showReserve: true,
        EditComponent: RoomReserveEdit
    };

    return (
        <>
         <WebUniversal props={extendProps} />
            {/* {modalChooseReserveRoomopen ? (<ChooseReserveRoom data={branchStoreRoomData} closeModal={setModalChooseReserveRoomopen}
            />)
                : null} */}
        </>
       
    )
}

export default RoomReserve;
