import { Button, DatePicker, Select, Table } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import systemConfig from '../../config/systematic';
import fetchApi from '../../utils/api/fetchApi';
import DownloadExcel from '../report/DownloadExcel';
import { columnsheadId } from '../universal/columnshead';
import getList from '../universal/getList';

const { RangePicker } = DatePicker;
export default function MoonReport() {
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    /** 数据刷新中 */
    let loading = false;
    const [rows, setRows] = useState<any>([])
    /**票据状态 */
    const [moonStatusData, setMoonStatusData] = useState<any>();

    //票据状态id
    const [moonStatus, setMoonStatus] = useState<any>(100);

    //票据类型id
    const [moonTypeId, setMoonTypeId] = useState<any>("-1");

    //时间字符串
    const [timeStr, setTimeStr] = useState<any>("所有时间")

    const [branchStoreId, setbranchStoreId] = useState("999");
    const [timeE, setTimeE] = useState<any>("all")

    //时间
    const [time, setTime] = useState<any>()

    /**分店 */
    const [branchStoreData, setbranchStoreData] = useState<any>()
    /**票据类型 */
    const [moonTypeData, setMoonTypeData] = useState<any>()
    const [queryCriteria, setQueryCriteria] = useState<any>()
    const columnsHead = [
        {
            title: '分店',
            dataIndex: 'branchStoreName',
        },
        {
            title: '票据类型',
            dataIndex: 'moonType',
        },
        {
            title: '折扣',
            dataIndex: 'disCountName',
        },
        {
            title: '已售出',
            dataIndex: 'solded',
        },
        {
            title: '已兑换',
            dataIndex: 'changed',
        },
        {
            title: '原总价(售出)',
            dataIndex: 'originAmount',
        },
        {
            title: '原总价(兑换)',
            dataIndex: 'originExAmount',
        },
        {
            title: '折后价',
            dataIndex: 'amount',
        },
    ];

    /*查看不同状态的票据*/
    const moonStatusChange = (e: any) => {
        let timeName;
        if (e === 1) {
            setTimeStr("售出时间")
            setTimeE("soldTime")
            timeName = "soldTime"
        }
        if (e === 2) {
            setTimeStr("兑换时间")
            setTimeE("exchangeTime")
            timeName = "exchangeTime"
        }
        if (e === 100) {
            setTimeStr("所有时间")
            setTimeE("all")
            timeName = "all"

        }

        setMoonStatus(e)
        getData(
            e,
            moonTypeId,
            time,
            branchStoreId,
            timeName,

        )

    }

    //查看不同类型的票据
    const moonTypeChange = async (e: any) => {

        setMoonTypeId(e)
        getData(
            moonStatus,
            e,
            time,
            branchStoreId,
            timeE,
        )
    }


    //根据时间查看
    const moonTimeChange = async (e: any) => {

        setTime(e)
        getData(
            moonStatus,
            moonTypeId,
            e,
            branchStoreId,
            timeE,
        )

    }
    /*查看不同分店的票据*/
    const branchStoreChange = (e: any) => {

        setbranchStoreId(e)
        getData(
            moonStatus,
            moonTypeId,
            time,
            e,
            timeE,
        )

    }
    useEffect(() => {
        getData(
            moonStatus,
            moonTypeId,
            time,
            branchStoreId,
            timeE,

        )
        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'moonStatus',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {

                        setMoonStatusData(res.data);
                    }
                })
            fetchApi.GET(
                wmService,
                {
                    apiId: "moonType",
                    apiExtend: 'showList'
                }
            ).then(res => {
                if (res.success) {
                    let newData = [{ id: "-1", moonType: "全部类型" }, ...res.data]

                    setMoonTypeData(newData);
                }
            })

            fetchApi.GET(
                wmService,
                {
                    apiId: "branchStore",
                    apiExtend: 'showList'
                }
            ).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", branchStoreName: "所有分店" }, ...res.data]

                    setbranchStoreData(newData);
                }
            })
        };
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //获取数据
    const getData = async (moonStatus: number, moonTypeId: string, time: any, branchStoreId: any, timeName?: any) => {
        let timeNameA = timeName ? timeName : timeE
        if (loading === true) { return }
        loading = true;
        fetchApi.GET(
            wmService,
            {
                apiId: "moon",
                apiExtend: 'moonReport',
                apiData: {
                    moonStatus,
                    moonTypeId,
                    time,
                    timeNameA,
                    branchStoreId

                }
            }).then(res => {

                if (res.success === true) {
                    setQueryCriteria(res.data.queryCriteria)
                    setRows(res.data.rows);


                }
                loading = false;
            })
    }
    
    return (
        <>
            <label>票据状态 :  </label>
            <Select
                showSearch
                defaultValue="所有状态"
                onChange={moonStatusChange}
                placeholder="票据状态"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(moonStatusData)}
            </Select>
            <span> - </span>
            <label>票据类型 :  </label>
            <Select
                showSearch
                defaultValue="所有类型"
                onChange={moonTypeChange}
                popupMatchSelectWidth={200}
                placeholder="票据类型"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(moonTypeData, "moonType")}
            </Select>
            <span> - </span>
            <label>分店 :  </label>
            <Select
                showSearch
                defaultValue="所有分店"
                onChange={branchStoreChange}
                popupMatchSelectWidth={200}
                placeholder="分店"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(branchStoreData, "branchStoreName")}
            </Select>
            <span> - </span>
            <label>{timeStr} :  </label>
            <RangePicker
                format="YYYY-MM-DD"
                onChange={moonTimeChange}
            />
            <span>   </span>
            <Button onClick={() => DownloadExcel(rows, "moonReport", queryCriteria)}>下载EXCEL文件</Button>
            <div style={{ marginTop: "20px" }}>
                <Table
                    pagination={false}
                    rowKey={(record: any) => record.id}
                    columns={columnsHead} dataSource={rows}
                    summary={(pageData: any) => {

                        let totalChanged = 0;
                        let totalSolded = 0;
                        let totalAmount = 0;
                        let toTalOriginAmount = 0;
                        let toTalOriginExAmount = 0;
                        if (pageData.length > 0) {
                            pageData.map((item: any) => {
                                if (item.changed) totalChanged += item.changed
                                if (item.solded) totalSolded += item.solded;
                                if (item.amount) totalAmount += item.amount;
                                if (item.originAmount) toTalOriginAmount += item.originAmount;
                                if (item.originExAmount) toTalOriginExAmount += item.originExAmount;


                            })
                        }

                        return (
                            <Table.Summary fixed>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell index={0}>合计</Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}>{totalSolded === 0 ? null : totalSolded}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{totalChanged === 0 ? null : totalChanged}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>{toTalOriginAmount === 0 ? null : toTalOriginAmount.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>{toTalOriginExAmount === 0 ? null : toTalOriginExAmount.toFixed(2)}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}>{totalAmount === 0 ? null : totalAmount.toFixed(2)}</Table.Summary.Cell>

                                </Table.Summary.Row>
                            </Table.Summary>
                        )
                    }}
                >
                </Table>
            </div>
        </>
    )
}
