import { Button, Form, Input, Radio, RadioChangeEvent, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import WebEditUniversal, { WEUExtendPropsType } from '../system/WebEditUniversalNewEx'
import systemConfig from '../../config/systematic';
import fetchApi, { httpGet } from '../../utils/api/fetchApi';
import AntDraggableModal from '../../utils/extend/AntDraggableModal';
import getList from '../universal/getList';
import antMessage from '../../utils/extend/AntdNotification';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
//布局
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};
const MoonUpdate = (props: any) => {
  const [form] = Form.useForm(); //form 
  const { data, closeModal } = props;


  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  /**产品数据 */
  /**分店数据 */
  const [branchStoreData, setbranchStoreData] = useState<any>()
  /**票据状态数据 */
  const [moonStatusData, setMoonStatusData] = useState<any>()

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [value, setValue] = useState(1);
  useEffect(() => {
    let branchStoreId = sessionStorage.getItem("branchStoreId")
    form.setFieldsValue({ exchangeBranchStore: branchStoreId })
    form.setFieldsValue({ moonStatus: 2 })
    const fetchData = async () => {
      httpGet(wmService, {
        apiId: "generalData",
        apiExtend: 'showList',
        apiVariable: "moonStatus",
      }).then(res => {
        if (res.success) {
          setMoonStatusData(res.data)
        }
      })

      httpGet(wmService, {
        apiId: "branchStore",
        apiExtend: 'showList',
      }).then(res => {

        if (res.success) {
          setbranchStoreData(res.data)
        }
      })

    }
    fetchData()
  }, [])
  const handleOk = (e: any) => {
    form.validateFields()
      .then(async (values: any) => {
        setIsLoading(true)
        fetchApi.PUT(wmService, {
          apiId: "moon",
          apiExtend: "upDate",
          apiData: values
        }).then(res => {
          if (res.success === true) {
            setIsLoading(false)
            antMessage('success', '新增成功', 'success');
            closeModal(true)
          }

        }

        ).catch(err => {
          setIsLoading(false)
          antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
        })

      })

  };
  const handleCancel = (e: any) => {
    closeModal(false)

  };


  const aa = (e: any) => {

  }

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };
  return (
    <>
      <AntDraggableModal
        title={"批量兑换"}
        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        confirmLoading={isLoading}
      >
        <Form

          form={form}
          name="Edit"
          onFinish={handleOk}
         onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}>

          <Form.Item
            label="兑换分店"
            name="exchangeBranchStore"
            rules={[{ required: true, message: '请输入兑换分店!' }]}>
            <Select
              showSearch
              disabled
              placeholder="兑换分店"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(branchStoreData, 'branchStoreName')}
            </Select>
          </Form.Item>

          {/* <Form.Item
            label="票据类型"
            name="moonTypeId"
            rules={[{ required: true, message: '请输入票据类型!' }]}>
            <Select
              showSearch
              placeholder="票据类型"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(moonTypeData, 'moonType')}
            </Select>
          </Form.Item> */}

          {/* <Form.Item
            label="票据类型"
            name="moonTypeId"
            rules={[{ required: true, message: '请输入票据类型!' }]}>
            <Select
              showSearch
              placeholder="票据类型"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(moonTypeData, 'moonType')}
            </Select>
          </Form.Item> */}

          <Form.Item
            label="票据状态"
            name="moonStatus"
            rules={[{ required: true, message: '请输入票据状态!' }]}>
            <Select
              disabled
              showSearch
              placeholder="票据状态"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(moonStatusData, 'name')}
            </Select>
          </Form.Item>

          <Form.Item
            label="兑换"
            name="isEx"
            initialValue={1}>
            <Radio.Group onChange={onChange} value={value}>
              <Radio value={1}>规则兑换</Radio>
              <Radio value={2}>不规则兑换</Radio>
            </Radio.Group>
          </Form.Item>


          {
            value === 2 ? <Form.List
              
              name="ticketNos"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(new Error('至少需要输入一个票号'));
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                      label={index === 0 ? '票号' : ''}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: "请输入票号!!",
                          },
                        ]}
                        noStyle
                      >
                        <Input placeholder="请输入包括票头的7位数，列如输入:7000001" style={{ width: '80%' }} />
                      </Form.Item>
                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: '40%' }}
                      icon={<PlusOutlined />}
                    >
                      增加票号
                    </Button>

                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List> : <>
              <Form.Item
                label="开始票号"
                name="statrTicketNo"
                rules={[{ required: true, message: '请输入票据开始票号!' }]}
              >
                <Input placeholder='请输入包括票头的7位数，列如输入:7000001' />
              </Form.Item>

              <Form.Item
                label="结束票号"
                name="endTicketNo"
                rules={[{ required: true, message: '请输入票据结束票号!' }]}
              >
                <Input placeholder='请输入包括票头的7位数，列如输入:7000001' />
              </Form.Item>

            </>
          }
        </Form>
      </AntDraggableModal>
    </>
  )
}

export default MoonUpdate;