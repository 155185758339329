import { Badge, Button, Card, Drawer, List, Popconfirm, Space, } from 'antd';
import systemConfig from '../../../config/systematic';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import antMessage from '../../../utils/extend/AntdNotification';
import {useEffect, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import dayjs from 'dayjs';
import DrawerDetailList from '../cashier/DrawerDetailList';

export default function PendingDetail(props: any) {
    const { data, closeModal } = props;
   
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [rows, setRows] = useState<Array<any>>([])
    const [menuData, setMenuData] = useState<any>();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);


    const handleOk = (e: any) => {
        closeModal(true)
    }
    const handleCancel = (e: any) => {
        closeModal(true)

    }
    useEffect(() => {
        initData(true, 1)

      
    }, [])

    //初始化数据
    const initData = (status?: boolean, open?: number) => {

        fetchApi.GET(wmService, {
            apiId: "cateringOrder",
            apiExtend: "pendingDetail",
            apiData: {
                roomId: data.roomId,
                sbId: data.sbId,


            }
        }).then((res) => {
            let data = res.data;
            
            setRows(data)



            if (status) {
                switch (open) {
                    //初始打开时候，判断里面长度是否为1，若是，则自动打开详情弹窗
                    case 1:
                        if (data.length === 1) {
                            handleCheckMenu(data[0])
                        }
                        break;
                    //关闭抽屉时是否判断本页面数据长度是否为0，若为零自动关闭
                    case 2:
                        if (data.length === 0) closeModal(true)
                        break;
                    //关闭抽屉时是否判断本页面数据长度是否为1，若为零自动关闭
                    case 3:
                        if (data.length === 1) closeModal(true)
                        break;
                }



            }



        })
    }

    //打开抽屉详情
    const handleCheckMenu = (item: any) => {
        setMenuData({
            roomNum: item.roomNum,
            caMenuStatus: [1],
            id: item.id,
            time: item.time,
            tempRoomId: data.tempRoomId,
            type:"pending"
        })
        setMenuOpen(true)
    }

    //关闭抽屉
    const onCloseCheckMenu = (status: boolean, open?: number) => {
        setMenuOpen(false);
        if (status) {
            initData(true, open)
            // if(open){
            //     closeModal(open)

            // }else{


            // }

        }
    }





    //关闭订单详情
    // const onCloseCheckMenu = (status: boolean) => {
    //     setMenuOpen(false);
    //     if (status) {
    //         initData()
    //     }
    // }


    return (
        <>
            <AntDraggableModal
                title={`待处理列表`}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                footer={null}

                maskClosable={true}
                width={"90%"}
            >

                <List
                    itemLayout="horizontal"
                    grid={{ gutter: 16, column: 3 }}

                    dataSource={rows}
                    // position
                    renderItem={(order: any) => (
                        <List.Item onClick={() => handleCheckMenu(order)}>
                            <Card
                                style={{ height: "200px" }}
                                title={<div style={{ fontSize: 30, display: "flex", justifyContent: "space-between", alignItems: "center", height: "60px" }}>
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", }}>
                                        <div >{"开单:"+Math.abs(dayjs(order.orderDate).diff(dayjs(), "minute")) + "分前"}</div>

                                    </div>
                                    <div>
                                        <div >{order.roomNum}</div>
                                    </div>
                                </div>}

                            >
                                <div>
                                    <div>订单:{order.id}</div>
                                    <div>整单备注:{order.memo}</div>

                                </div>
                                {/* <p>金额：{order.amount}</p> */}
                            </Card>
                        </List.Item>
                    )}
                />
            </AntDraggableModal>
            {menuOpen ? <DrawerDetailList data={menuData} closeModal={onCloseCheckMenu} ></DrawerDetailList> : null}



        </>
    )
}
