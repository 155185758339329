import { Form, Input, InputNumber, Select } from 'antd';
import React from 'react'
import { useEffect, useRef, useState } from 'react'
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';

import getList from '../../universal/getList';


export default function AreaProductEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const [areaData, setAreaData] = useState<any>()

    const ref = useRef<any>();
    /** 后端数据wmService */
    let wmService: any = "";
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };


    useEffect(() => {

        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            httpGet(wmService, {
                apiId: "area",
                apiExtend: 'showList',

            }).then(res => {
                if (res.success) {
                    setAreaData(res.data)
                }
            })


        }
        fetchData()
    }, [])
    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'areaProduct'
    };
    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '区域产品'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="区域"
                        name="areaId"
                        rules={[{ required: true, message: '请选择区域!' }]}>
                        <Select
                            showSearch
                            placeholder="请选择区域"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(areaData, 'areaName')}
                        </Select>
                    </Form.Item>



                    <Form.Item
                        label="品名"
                        name="productName"
                        rules={[{ required: true, message: '请输入品名!!' }]}>
                        <Input />

                    </Form.Item>




                </Form>
            </AntDraggableModal>
        </>
    )
}
