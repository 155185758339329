import { Suspense, createContext, useEffect, useState } from 'react';
import './App.css';
import { useRoutes, } from "react-router-dom";
import routers from './routers/index';
import wsServer, { modeType } from './utils/system/wsServer';
import systematic from './config/systematic';
import antMessage from './utils/extend/AntdNotification';

/**
 * 封装一个可以用于请求数据的工具函数
 * @returns 
 */
function fetchValue() {
  let status = "pending";
  let result: any = [];
  new Promise(async (resolve, reject) => {
    resolve(await routers())
  }).then((res) => {
    status = "resolved";
    result = res;
  })
  return {
    read() {
      if (status === "resolved") {
        return result;
      }
      if (status === "rejected") {
        throw Promise.reject(null);
      }
      if (status === "pending") {
        throw Promise.resolve(null);
      }
    },
  };
};

/**
 * ws监听
 * @param data 
 */
const wsListener = async (data: any) => {
};

/**
 * wsServer连接成功后的回调函数
 * @param socket 
 */
const wsConnectSuccess = (socket: any) => {
  const successMessage = 'WS服务器连接成功，sokcetId: ' + socket.id
  console.log(successMessage);
  antMessage('success', successMessage);
  //保存socketId
  localStorage.setItem('socketId', socket.id);
};

/**
 * ws 连接错误时的回调
 */
const wsConnectError = async () => {
  const errorMessage = 'WS服务器连接错误，请检查连接!';
  console.log(errorMessage);
  antMessage('error', errorMessage);
  //清空socketId
  localStorage.setItem('socketId', '');
};

/**
 * ws断开连接
 * @param reason 
 * @param details 
 */
const wsDisconnectListener = async (socketId: string, options: { reason: any, details: any }) => {
  const { reason, details } = options;

  const errorMessage = 'WS服务器断开连接, socketId: ' + socketId + ', reason: ' + reason + ', details: ' + details;
  console.log(errorMessage);
  antMessage('error', errorMessage);
  //清空socketId
  localStorage.setItem('socketId', '');
};

/** wsServer */
const ws = new wsServer();

/**
 * ws连接
 * @param token 
 */
export const wsConnect = (mode: modeType,
  options: {
    /** 登录id */
    logId?: string,
    /** 登录密码 */
    logPass?: string,
    /** token */
    token?: string,
  }) => {
  const { logId, logPass, token } = options;
  ws.connect({
    serverUrl: systematic.wsServer.url,
    nameSpace: systematic.wsServer.service.wm,
    path: systematic.wsServer.path,
    listenerId: 'system',
    // wsListener,
    licenseTypeId: 'licenseManage',
    // roomType: 'system',
    auth: {
      mode,
      logId,
      logPass,
      token,
      mountDataOptions: {
        userMount: {
          isAll: true
        }
      }
    }
  }, {
    connectSuccessListener: wsConnectSuccess,
    connectErrorListener: wsConnectError,
    disconnectListener: wsDisconnectListener
  }).then(async (_res: any) => {
    // console.log("res",_res);
    // console.log("ws",ws);
    // console.log("wsddd",ws.connected);
    
    
    
    // console.log('WS Server Connection succeeded! socketId: ' + res.socket.id);
  }).catch(err => {
    console.log('WS Server Connection Failed!');
    console.log(err);
  })
};

export const wsDisconnect = () => {
  if (ws) {
    ws.disconnect();
  }
};

/** 请求动态路由数据 */
const fv = fetchValue();

interface config {
  wsServer: wsServer,
  contextData?: any,
  setContextData?: any
}
/**
 * 路由组件
 * @returns 
 */
const RouterCom = () => {
  const routers = fv.read();
  return useRoutes(routers)
};
const SocketContext = createContext<config>({ wsServer: ws });

/** Application */
const App = () => {
  const [contextData, setContextData] = useState<any>()
  return (
    <SocketContext.Provider value={{ contextData, setContextData, wsServer: ws }}>
      <Suspense fallback={<>loading</>}>
        <RouterCom />
      </Suspense>
    </SocketContext.Provider>
  )
}

export default App;
export { SocketContext };
