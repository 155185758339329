import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import MenuWarehouseArticleCategoryEdit from './MenuWarehouseArticleCategoryEdit';

const MenuWarehouseArticleCategory = (props: any) => {

    //表头
    const columnsHead = [
        {
            title: '大类名称',
            dataIndex: 'menuWarehouseArticleCategoryName',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '仓库菜式文章大类',
        apiId: 'menuWarehouseArticleCategory',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: MenuWarehouseArticleCategoryEdit,
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}

export default MenuWarehouseArticleCategory