//reserveRoom
import Room from '../components/external/reserveRoom/Room';
import RoomType from '../components/external/reserveRoom/RoomType';
import CustomerReserve from '../components/external/reserveRoom/CustomerReserve';
import ScheduledTime from '../components/external/reserveRoom/ScheduledTime';
import ReserveOrder from '../components/external/reserveRoom/ReserveOrder';
import RoomReserve from '../components/external/reserveRoom/RoomReserve';
import BsRoomConfig from '../components/external/reserveRoom/BsRoomConfig';
import ReserveMessage from '../components/external/reserveRoom/ReserveMessage';
import TimeType from '../components/external/reserveRoom/TimeType';
import Banquet from '../components/external/reserveRoom/Banquet';
import { RouteObject } from 'react-router-dom';
import { getPageUrl } from './index';
import RoomQrCode from '../components/external/reserveRoom/RoomQrCode';
import Cast from '../components/external/cashier/Cast';
import RejectionList from '../components/external/cashier/RejectionList';


const routes_reserveRoom = async (licenseList: Array<any>) => {
    const res: RouteObject[] = [
        //房间订位管理
        {
            path: await getPageUrl(licenseList, 'room'),
            element: <Room></Room>
        },
        {
            path: await getPageUrl(licenseList, 'roomType'),
            element: <RoomType></RoomType>
        },
        {
            path: await getPageUrl(licenseList, 'customerReserve'),
            element: <CustomerReserve></CustomerReserve>
        },
        {
            path: await getPageUrl(licenseList, 'scheduledTime'),
            element: <ScheduledTime></ScheduledTime>
        },
        {
            path: await getPageUrl(licenseList, 'reserveOrder'),
            element: <ReserveOrder></ReserveOrder>
        },
        {
            path: await getPageUrl(licenseList, 'roomReserve'),
            element: <RoomReserve></RoomReserve>
        },
        {
            path: await getPageUrl(licenseList, 'timeType'),
            element: <TimeType></TimeType>
        },
        {
            path: await getPageUrl(licenseList, 'bsRoomConfig'),
            element: <BsRoomConfig></BsRoomConfig>
        },
        {
            path: await getPageUrl(licenseList, 'reserveMessage'),
            element: <ReserveMessage></ReserveMessage>
        },
        {
            path: await getPageUrl(licenseList, 'banquet'),
            element: <Banquet></Banquet>
        },
        {
            path: await getPageUrl(licenseList, 'roomQrCode'),
            element: <RoomQrCode></RoomQrCode>
        },
        {
            path: await getPageUrl(licenseList, 'cast'),
            element: <Cast></Cast>
        },
        {
            path: await getPageUrl(licenseList, 'rejectionList'),
            element: <RejectionList></RejectionList>
        },

    ]
    return res;
}




export default routes_reserveRoom