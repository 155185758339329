import { Form, Input, Switch, Select } from 'antd';
import { useRef, useState, useEffect } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const RoleEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();
    const [userId, setUserId] = useState('');
    const [brandData, setBrandData] = useState<any>();
    const [roleGroupData, setRoleGroupData] = useState<any>();
    /** 后端数据wmService */
    let wmService: any = '';
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(
                wmService,
                {
                    apiId: 'brand',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setBrandData(res.data)
                    }
                });
            fetchApi.GET(wmService,
                {
                    apiId: 'roleGroup',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setRoleGroupData(res.data)
                    }
                })
        };
        fetchData();
        setUserId(sessionStorage.getItem('userId')!);
    }, []);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'role',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '角色'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                   onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>

                    <Form.Item
                        label="ID"
                        name="id"
                    >
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="角色名称"
                        name="roleName"
                        rules={[{ required: true, message: '请输入角色名称!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="角色组"
                        name="roleGroupId" >
                        <Select
                            showSearch
                            placeholder="用户组"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(roleGroupData, 'roleGroupName')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="品牌"
                        name="brandId"
                        rules={[{ required: true, message: '请选择品牌!' }]}>
                        <Select
                            showSearch
                            placeholder="选择品牌"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        >
                            {getList.general(brandData, 'brandName')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="创建"
                        name="createdAtUserId"
                        initialValue={userId}>
                        <Input disabled={true}
                        />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        < Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                </Form>
            </AntDraggableModal>
        </>
    )
}

export default RoleEdit;