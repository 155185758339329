import systemConfig from '../../../config/systematic';
import { Button, Card, Col, DatePicker, Form, Input, Row, Select, Space, Table, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import dayjs from 'dayjs';
import antMessage from '../../../utils/extend/AntdNotification'
import DownloadExcel from '../../report/DownloadExcel';
import BranchStoreList from '../../generalComponents/BranchStoreList';
import { QuestionCircleFilled } from '@ant-design/icons';
import { SocketContext } from '../../../App';
const { RangePicker } = DatePicker;

/**结账方式统计 */
export default function PaymentMethodCount() {
  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const [form] = Form.useForm();

  const [sbData, setSbData] = useState<any>();

  const useContextData = useContext(SocketContext);
  const {  contextData } = useContextData;

  const [rows, setRows] = useState<any>([]);
  const [sbName, setSbName] = useState<string>("所有");

  const [userName, setUserName] = useState<string>("");

  const [actualOrderCounts, setActualOrderCounts] = useState<number>(0);
  const [orderCounts, setOrderCounts] = useState<number>(0);
  const [refundOrderCounts, setRefundOrderCounts] = useState<number>(0);



  useEffect(() => {
    let branchStoreId = contextData?.currentBranchStoreId;

    const fetchData = async () => {
      form.setFieldsValue({
        "chooseTime": [dayjs(), dayjs()]
      })
      if (branchStoreId) {
        let sb = await fetchApi.GET(wmService, {
          apiId: "sb",
          apiExtend: 'getBrandStoreIdToSb',
          apiData: {
            branchStoreId
          }
        })
        if (sb.success) {

          setSbData(sb.data)
        }

      }

    }
    fetchData()
  }, [])
  /** 表头内容 */
  let columnsHead: any = [
    // {
    //   title: "采购商",
    //   dataIndex: "buyerName",
    // },
    {
      title: "项目",
      dataIndex: "paymentMethodName",
    },
    {
      title: "数量",
      dataIndex: "amount",
    },
    {
      title: <div>金额  <Tooltip title="所有收入，包含退货"><QuestionCircleFilled /></Tooltip> </div>,
      dataIndex: "payAmount",

    },

    {
      title: "退款(原路)",
      dataIndex: "refundAmount",
    },
    {
      title: "退款(现金)",
      dataIndex: "cashRefundAmount",
    },
    {
      title: "实际收入",
      dataIndex: "realIncome",
    },


  ]
  const onFinish = () => {
    let branchStoreId = contextData?.currentBranchStoreId;
    if (!branchStoreId) {
      antMessage("error", '错误', "必须选择分店");
      return false
    }
    form.validateFields()
      .then(async (values: any) => {

        let data = await fetchApi.GET(wmService, {
          apiId: "financeReport",
          apiExtend: 'paymentMethodCount',
          apiData: {
            ...values,
            branchStoreId,
          }
        })
        if (data.success) {
          setUserName(data.data.userName)
          setActualOrderCounts(data.data.actualOrderCounts)
          setOrderCounts(data.data.orderCounts)
          setRefundOrderCounts(data.data.refundOrderCounts)
          setRows(data.data.rows)
        }
      })
      .catch((err) => {

        antMessage('warning', '无法通过数据校验', '请输入所需数据！');
      })





  }
  const changeSb = (e: any, option: any) => {

    if (option?.children) setSbName(option.children)

  }
  return (
    <>

      <BranchStoreList setSbData={setSbData} form={form}></BranchStoreList>
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Row>

          <Col span={6}>
            <Form.Item
              label="市别"
              name="sbId"
            >
              <Select
                onChange={changeSb}
                allowClear
                placeholder="市别"
                optionFilterProp="children"
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(sbData, 'sbName')}
              </Select>
            </Form.Item>
          </Col>


          <Col span={6}>
            <Form.Item
              label="日期"
              name="chooseTime"
            >
              <RangePicker
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>









          <Col span={4}>
            <Form.Item >
              <Space>
                <Button type="primary" htmlType="submit">
                  统计
                </Button >
                <Button onClick={() => DownloadExcel(rows, "paymentMethodCount", { date: form.getFieldValue("chooseTime"), sbName, userName,actualOrderCounts,orderCounts,refundOrderCounts})}>下载EXCEL文件</Button>

                {/* purContrast */}
              </Space>
            </Form.Item>
          </Col>
        </Row>

      </Form>

      <Row gutter={16}>
        <Col span={8}>
          <Card title={<div>订单笔数<Tooltip title="包含退款订单"><QuestionCircleFilled /></Tooltip> </div>} bordered={false}>
            {orderCounts}
          </Card>
        </Col>
        <Col span={8}>
          <Card title={<div>退款笔数<Tooltip title="涉及到退款的所有订单"><QuestionCircleFilled /></Tooltip> </div>} bordered={false}>
            {refundOrderCounts}
          </Card>
        </Col>

        <Col span={8}>
          <Card title={<div>成交笔数<Tooltip title="不涉及到退款的所有订单"><QuestionCircleFilled /></Tooltip> </div>} bordered={false}>
            {actualOrderCounts}
          </Card>
        </Col>

      </Row>

      <div style={{ marginTop: "20px" }}>
        <Table
          pagination={false}
          rowKey={(record: any, index: any) => {
            return index
          }}
          columns={columnsHead}
          dataSource={rows}

        >
        </Table>

      </div>
    </>
  )
}
