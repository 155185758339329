import SbEdit from './SbEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import dayjs from 'dayjs';

//组件 
const WebComponents = (props: any) => {

    /** api服务id */
    const apiId = 'sb';

    //表头
    const columnsHead = [
        {
            title: '名称',
            dataIndex: 'sbName',
            sorter: true,
        },
        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any, record: any) => (text ? text.branchStoreName : "")
        },
        {
            title: '开始时间',
            dataIndex: 'startTime',
            render: (val:any) =>
            <span>
                 {val.substring(0,5)}
            </span>,
          },
          {
            title: '结束时间',
            dataIndex: 'endTime',
            render: (val:any) =>
            <span>
                 {val.substring(0,5)}
            </span>,
          },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '市别',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: SbEdit,
        mountDataOptions:{
            userMount:{
               isDept:true,
            }
       },
    };

    return (
        <WebUniversal props={extendProps} />
    )
}

export default WebComponents;
