import { Table, Switch, Select, Tooltip, Input, Space, Button, Popconfirm, Checkbox } from 'antd';
import { useEffect, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi'
import antMessage from '../../../utils/extend/AntdNotification'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import { columnsheadId } from '../../universal/columnshead';
import getList from '../../universal/getList';
import systematic from '../../../config/systematic';
import RoleOrUserLicenseExtend from './RoleOrUserLicenseExtend';

const RoleOrUserLicense = (props: any) => {

    /** 使用类型 userLicense or roleLicense */
    const useType = props.useType;
    const { info, closeModal } = props;
    const { roleOrUserId, itemName } = info
    /** 后端数据wmService */
    const wmService = systematic.wmService;
    const [rows, setRows] = useState<any>();
    const [licenseTypeData, setLicenseTypeData] = useState<any>();
    const [licenseTypeIdData, setLicenseTypeIdData] = useState<any>();
    const [licenseGroupData, setLicenseGroupData] = useState<any>();
    const [licenseGroupIdData, setLicenseGroupIdData] = useState<any>();
    const [currentData, setCurrentData] = useState(1);
    const [pageSizeData, setPageSizeData] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [queryData, setQueryData] = useState('');
    const [modalExtend, setModalExtend] = useState(false);
    const [extendInfo, setExtendInfo] = useState<any>();
    const [isAllData, setIsAllData] = useState(false);

    let title: any;
    switch (useType) {
        case 'userLicense':
            title = '用户 [ ' + itemName + ' ] 权限';
            break;
        case 'roleLicense':
            title = '角色 [ ' + itemName + ' ] 权限';
            break;
    };

    /** 默认权根类型id */
    const defaultLicenseTypeId = 'licenseManage';

    /** 数据刷新中 */
    let loading = false;

    useEffect(() => {
        setLicenseTypeIdData(defaultLicenseTypeId);
        setLicenseGroupIdData(-1);
        getRoleLicense({
            licenseTypeId: defaultLicenseTypeId,
            licenseGroupId: '-1'
        })
        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'licenseType',
                    apiExtend: 'showList'
                }).then(res => {

                    if (res.success) {
                        setLicenseTypeData(res.data);
                    }
                });
            fetchApi.GET(wmService,
                {
                    apiId: 'licenseGroup',
                    apiExtend: 'showList'
                }).then(res => {
                    if (res.success) {
                        setLicenseGroupData(res.data.concat({
                            icon: null,
                            id: -1,
                            licenseGroupName: "所有权限组",
                            licenseGroupSort: null,
                        }));
                    }
                });
        };
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const handleOk = (e: any) => {
        closeModal();
    };

    /**
     * 获取数据
     * @param options 选项
     * @returns 
     */
    const getRoleLicense = async (options?: {
        /** 权限类型id */
        licenseTypeId?: string,
        /** 权限组id */
        licenseGroupId?: string,
        /** 当前页 */
        current?: number,
        /** 分页数量 */
        pageSize?: number,
        /** 查询内容 */
        queryString?: string
    }) => {
        if (typeof options === 'undefined') {
            options = {};
        }
        let { licenseTypeId, licenseGroupId, current, pageSize, queryString } = options;
        licenseTypeId = licenseTypeId ? licenseTypeId : licenseTypeIdData;
        licenseGroupId = licenseGroupId ? licenseGroupId : licenseGroupIdData;
        current = current ? current : currentData;
        pageSize = pageSize ? pageSize : pageSizeData;
        queryString = queryString ? queryString : queryData;
        if (loading === true) { return }
        loading = true;
        fetchApi.GET(
            wmService,
            {
                apiId: useType,
                apiExtend: 'query',
                id: roleOrUserId,
                licenseTypeId,
                licenseGroupId,
                current,
                pageSize,
                queryString
            }).then(res => {
                if (res.success === true) {
                    setRows(res.data.rows);
                    setLicenseTypeIdData(licenseTypeId);
                    setLicenseGroupIdData(licenseGroupId);
                    setCurrentData(res.data.current);
                    setPageSizeData(res.data.pageSize);
                    setTotalData(res.data.total);
                    setQueryData(res.data.queryString)
                }
                loading = false;
            })
    };

    //改变数据
    const handleChange = async (id: number, columnName: string, isValue: boolean) => {
        const bodys = {
            apiId: useType,
            apiVariable: id,
            apiData: {
                columnName,
                isValue
            }
        }
        fetchApi.PUT(wmService, bodys).then(res => {
            antMessage('success', '编辑成功', 'success');
            getRoleLicense();
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };

    // 表格分页属性
    const paginationProps = {
        showSizeChanger: true,
        showQuickJumper: false,
        showTotal: () => `共${totalData}条`,
        pageSize: pageSizeData,
        current: currentData,
        total: totalData,
        onShowSizeChange: (current: number, pageSize: number) =>
            getRoleLicense({ current, pageSize }),
        onChange: (current: number) =>
            getRoleLicense({ current }),
    };

    /** 关闭extend */
    const onCloseModalExtend = () => {
        setModalExtend(false);
    };
    /** 打开extend */
    const openExtend = (record: any) => {
        setExtendInfo({
            id: record.id,
            licenseId: record.license.id,
            licenseName: record.license.licenseName,
        })
        setModalExtend(true);
    };

    /** 更新数据 */
    const handleUpdate = () => {
        fetchApi.PUT(wmService,
            {
                apiId: useType,
                apiExtend: 'updateData',
                apiVariable: roleOrUserId
            }).then(res => {
                if (res.success) {
                    getRoleLicense();
                    antMessage('success', '更新数据成功', 'success');
                }
            });
    };

    /** 清除所有空数据 */
    const handleClearEmptyData = () => {
        fetchApi.DELETE(wmService,
            {
                apiId: useType,
                apiExtend: 'clearEmptyData',
                apiData: {
                    isAllData,
                    id: roleOrUserId
                }
            }).then(res => {
                if (res.success) {
                    getRoleLicense();
                    antMessage('success', '清除空数据成功', 'success');
                }
            });
    };

    /** 删除数据 */
    const handleDelete = (id: string) => {
        fetchApi.DELETE(wmService,
            {
                apiId: useType,
                apiVariable: id,
            }).then(res => {
                if (res.success) {
                    getRoleLicense();
                    antMessage('success', '删除数据成功', 'success');
                }
            });
    };

    /** 表头内容 */
    const columnsHead = [
        {
            title: '权限',
            dataIndex: "license",
            editable: true,
            render: (text: any) => <Tooltip placement="topLeft" title={text.id}>{
                text.licenseName
            }</Tooltip>,
        },
        {
            title: '查看',
            dataIndex: 'isShow',
            editable: true,
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isShow', e)}
                />
            )
        },
        {
            title: '增加',
            dataIndex: 'isAdd',
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isAdd', e)}
                />
            )
        },
        {
            title: '更新',
            dataIndex: 'isUpdate',
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isUpdate', e)}
                />
            )
        },
        {
            title: '删除',
            dataIndex: 'isRemove',
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isRemove', e)}
                />
            )
        },
        {
            title: '下载',
            dataIndex: 'isDownload',
            render: (text: any, record: any) => (
                <Switch checkedChildren="启用" unCheckedChildren="关闭"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isDownload', e)}
                />
            )
        },
        {
            title: '扩展',
            dataIndex: 'apiExtend',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} onClick={() => openExtend(record)}>扩展</Button>
                </Space>
        },
        {
            title: '操作',
            dataIndex: 'id',
            render: (text: any) =>
                <Space size="small">
                    <Popconfirm description={'将不可恢复'}
                        title="是否删除？"
                        trigger="click"
                        onConfirm={() => {
                            handleDelete(text);
                        }} >
                        <Button size={"small"} type="text" danger >删除</Button>
                    </Popconfirm>
                </Space>
        },
    ];

    /** 表头 */
    const columns = columnsheadId
        .concat(columnsHead as []);

    return (
        <>
            <AntDraggableModal
                title={title}
                open={true}
                okText='确定'
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={handleOk}
                closable={false}
                maskClosable={false}
                width={1080} >
                <Space>
                    <label>权限类型:</label>
                    <Select
                        showSearch
                        defaultValue={defaultLicenseTypeId}
                        onChange={(e) => {
                            getRoleLicense({
                                licenseTypeId: e
                            })
                        }}
                        placeholder="权限类型"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {getList.general(licenseTypeData)}
                    </Select>
                    <label>权限组:</label>
                    <Select
                        showSearch
                        defaultValue="所有权限组"
                        onChange={(e) => {
                            getRoleLicense({
                                licenseGroupId: e
                            })
                        }}
                        placeholder="权限组"
                        optionFilterProp="children"
                        filterOption={(input, option: any) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {getList.general(licenseGroupData, 'licenseGroupName')}
                    </Select>
                    <Input style={{ width: '120px' }}
                        onChange={(e: any) => {
                            setQueryData(e.target.value);
                        }}
                    />
                    <Button onClick={() => {
                        getRoleLicense();
                    }}>查询</Button>
                </Space>

                <Tooltip placement="topLeft" title={'选择后会对所有数据的空数据清除'}>
                    <Checkbox onChange={(e) => {
                        setIsAllData(e.target.checked);
                    }} style={{ float: "right" }}>清空所有数据</Checkbox>
                </Tooltip>
                <span style={{ float: "right" }}>&nbsp;</span>
                <Popconfirm
                    title="是否清除空数据？"
                    description={`将会清除${isAllData === true ? '所有权限' : title}空数据！`}
                    trigger="click"
                    onConfirm={handleClearEmptyData}>
                    <Button style={{ float: "right", color: 'red' }}>清除空数据</Button>
                </Popconfirm >
                <span style={{ float: "right" }}>&nbsp;</span>
                <Button onClick={handleUpdate} style={{ float: "right" }}>更新数据</Button>


                <Table
                    rowKey={(row: any) => row.id}
                    columns={columns}
                    dataSource={rows}
                    size="small"
                    pagination={paginationProps}
                />
                {modalExtend ? (<RoleOrUserLicenseExtend
                    closeModal={onCloseModalExtend}
                    info={extendInfo}
                    useType={useType} />)
                    : null}
            </AntDraggableModal>
        </>
    )
}

export default RoleOrUserLicense;
