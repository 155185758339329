import React, { useRef } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import BranchStoreFloorEdit from './BranchStoreFloorEdit';

export default function BranchStoreFloor(props: any) {
  const { branchStoreId, closeModal } = props;
  const ref = useRef<any>();
  const handleOk = (e: any) => {
    closeModal(true);
  };
  //表头
  const columnsHead = [

    {
      title: '分店',
      dataIndex: 'branchStore',
      render: (text: any) => (text ? text.branchStoreName : "")
    },
    {
      title: '楼层',
      dataIndex: 'floor',
      render: (text: any) => (text ? text.floor : "")
    },
    {
      title: '排序',
      dataIndex: 'sortBy',
    },




  ];
  const columns = columnsheadId
    .concat(columnsHead as []);
  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '分店楼层',
    apiId: 'branchStoreFloor',
    showStates: true,
    sendPageData: { branchStoreId },
    columns,
    pagination: true,
    EditComponent: BranchStoreFloorEdit,
  };
  return (
    <div>
      <AntDraggableModal
        open={true}
        okText='确定'
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleOk}
        closable={false}
        maskClosable={false}
        width={800}>
        <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
      </AntDraggableModal>
    </div>
  )
}
