import { Button, Form, Input, InputNumber, Row, Select, Space } from 'antd'
import { useEffect, useState } from 'react';
import getList from '../universal/getList';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

//  主配料formList通用组件
export default function WebIngredientFormList(props: any) {

    const { formName, dataName, isBase, ingredient, ingredientLength, tempDeleteData } = props;
    const { form, type, ref, setIngredientDetailData, setTempDeleteData, setPersent } = props.props;

    // const formListData = Form.useWatch(formName, form) as any;

    /**产品数据 */
    const [productData, setProductData] = useState<any>();

    /**产品单位数据 */
    const [productUnitData, setProductUnitData] = useState<any>();


    const mergeIngredientDetail = (ingredientLength: any, delData?: any) => {

        let ingredientDetail: any;
        let costPrice: any = 0;

        for (var i_data = 0; i_data < ingredientLength; i_data++) {
            let str_data = "ingredientDetail" + i_data;

            if (ingredientDetail) {
                if (form.getFieldsValue()[str_data]) {
                    ingredientDetail = ingredientDetail.concat(form.getFieldsValue()[str_data]);
                }
            }
            else {
                ingredientDetail = form.getFieldsValue()[str_data];
            }
        }

        // 计算总成本价格
        for (var i_costPrice = 0; i_costPrice < ingredientDetail.length; i_costPrice++) {
            costPrice = Number(costPrice) + Number(ingredientDetail[i_costPrice].total !== undefined ? ingredientDetail[i_costPrice].total : 0);
        }

        // console.log("------------------合并时，收到需要删除的数据------------------");
        // console.log(delData);

        if (delData) {
            ingredientDetail = ingredientDetail.concat(delData);
        }

        // console.log("------------------最后的总数据------------------");
        // console.log(ingredientDetail);

        setIngredientDetailData(ingredientDetail);
        form.setFieldsValue({ costPrice: costPrice.toFixed(2) });
        setPersent(form, form.getFieldValue("sellPrice"));
    };


    //remove 删除
    const removeList = (remove: Function, num: number, key: number, originData: any) => {

        //即将删除的数据
        let removeData = form.getFieldsValue()[formName][num];
        let concatData: any;

        if (removeData && removeData.id) {

            let addDeleteData: any;
            addDeleteData = { ...removeData, state: "delete" };

            if (originData) {
                originData.push(addDeleteData);
                concatData = originData;
            }
            else {
                let tmpData: any = [];
                tmpData.push(addDeleteData);
                concatData = tmpData;
            }

            setTempDeleteData(concatData);
        }
        else {
            if (originData) {
                concatData = originData;
            }

            setTempDeleteData(concatData);
        }

        remove(num);
        mergeIngredientDetail(ingredientLength, concatData);
        delete productData[key];
    }

    const customizeAdd = (add: Function, ingredient: any, originData: any) => {
        add();
        form.setFieldValue([formName, form.getFieldsValue()[formName].length - 1], { ingredientId: ingredient, state: "insert" });
        mergeIngredientDetail(ingredientLength, originData);
    }

    /**将修改的内容的状态改为update 并和 需要删除的数据进行合并*/
    const handleChange = (form: any, e: any, name?: any, FormListName?: string, type?: string, originData?: any) => {
        let amount = form.getFieldValue([FormListName, name, "amount"])
        let yieldRatio = form.getFieldValue([FormListName, name, "yieldRatio"])
        let unitPrice = form.getFieldValue([FormListName, name, "unitPrice"])
        
        let newTotalPrice = Number(amount) * Number(unitPrice) / Number(yieldRatio) * 100
        //设置总价格
        form.setFieldValue([FormListName, name, "total"], newTotalPrice.toFixed(2))
        
        if (type === "EDIT") {
            form.setFieldValue([FormListName, name], { ...form.getFieldsValue()[FormListName!][name], state: form.getFieldsValue()[FormListName!][name].id ? "update" : "insert" })
        }
        mergeIngredientDetail(ingredientLength, originData);
    }

    useEffect(() => {
        if (ref.current) {
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {

                    let ingredientObj: any = {};
                    let ingredientObj1: any = {};
                    //自定义formList数据初始状态
                    let newData: Array<any> = [];

                    // console.log("---------------------返回的数据------------------------");
                    // console.log(res.data);

                    if (isBase) {

                        for (var i = 0; i < res.data.rowsDetail[ingredient].length; i++) {

                            ingredientObj[i] = [res.data.rowsDetail[ingredient][i].product];
                            ingredientObj1[i] = [res.data.rowsDetail[ingredient][i].productUnit];

                            if (type === "EDIT") {
                                newData.push({
                                    id: res.data.rowsDetail[ingredient][i].id,
                                    ingredientId: res.data.rowsDetail[ingredient][i].ingredientId,
                                    productId: res.data.rowsDetail[ingredient][i].productId,
                                    amount: res.data.rowsDetail[ingredient][i].amount,
                                    productUnitId: res.data.rowsDetail[ingredient][i].productUnitId,
                                    yieldRatio: res.data.rowsDetail[ingredient][i].yieldRatio,
                                    unitPrice: res.data.rowsDetail[ingredient][i].unitPrice,
                                    total: res.data.rowsDetail[ingredient][i].total,
                                    memo: res.data.rowsDetail[ingredient][i].memo,
                                    state: "origin"
                                })
                            }
                        }

                        // console.log(ingredientObj);
                        // console.log("ingredientObj");
                        // console.log(ingredientObj1[0] ? ingredientObj1 : "1");
                        // console.log("newData", newData);

                        form.setFieldsValue({ [formName]: newData });

                        setProductData(ingredientObj);
                        setProductUnitData(ingredientObj1);
                    }
                }
            })
        }
    }, [])

    return (
        <>
            <Form.List name={formName}>
                {(fields, { add, remove }) => {
                    return (
                        <>
                            {fields.map(({ key, name, ...restField }) => (
                                <Space
                                    key={key}
                                    style={{ display: 'flex', marginBottom: 8 }}
                                    align="baseline"
                                >
                                    <Form.Item
                                        {...restField}
                                        label="原材料"
                                        name={[name, 'productId']}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入原材料',
                                            },
                                        ]}
                                    >
                                        <Select
                                            //optionLabelProp="label"
                                            style={{ width: 230 }}
                                            showSearch
                                            allowClear
                                            onSearch={(e) => getList.searchEx('product', setProductData, e, productData, {
                                                keyId: key,
                                                isFormList: true,
                                                form,
                                                FormListName: formName,
                                                name
                                            })}
                                            onChange={(e) => handleChange(form, e, name, formName, type, tempDeleteData)}
                                            placeholder="原材料"
                                            optionFilterProp="children"
                                            // filterOption={(input, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                                            filterOption={(input, option: any) => {
                                                // console.log("option",option);
                                                // console.log("input",input);
                                                return true
                                            }}
                                        >
                                            {getList.general(productData ? productData[key] ? productData[key] : [] : [], "productName")}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        {...restField}
                                        label="单位"
                                        name={[name, 'productUnitId']}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入单位',
                                            },
                                        ]}
                                    >
                                        <Select
                                            //optionLabelProp="label"
                                            style={{ width: 90 }}
                                            showSearch
                                            allowClear
                                            onSearch={(e) => getList.searchEx('productUnit', setProductUnitData, e, productUnitData, {
                                                keyId: key,
                                                isFormList: true,
                                                form,
                                                FormListName: formName,
                                                name
                                            })}
                                            onChange={(e) => handleChange(form, e, name, formName, type, tempDeleteData)}
                                            placeholder="单位"
                                            optionFilterProp="children"
                                            // filterOption={(input, option: any) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}

                                            filterOption={(input, option: any) => {
                                                // console.log("option",option);
                                                // console.log("input",input);
                                                return true
                                            }}
                                        >
                                            {getList.general(productUnitData ? productUnitData[key] ? productUnitData[key] : [] : [], "productUnitName")}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        label="定量"
                                        {...restField}
                                        name={[name, 'amount']}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入定量',
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            style={{ width: 80 }}
                                            onChange={(e) => handleChange(form, e, name, formName, type, tempDeleteData)}
                                            placeholder="定量"
                                            min={0.01}
                                        />
                                    </Form.Item>

                                    <Form.Item
                                        label="起率"
                                        {...restField}
                                        initialValue={100}
                                        name={[name, 'yieldRatio']}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入起率',
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            style={{ width: 100 }}
                                            onChange={(e) => handleChange(form, e, name, formName, type, tempDeleteData)}
                                            placeholder="起率"
                                            min={0.01}
                                            max={100}
                                            addonAfter="%" />
                                    </Form.Item>

                                    <Form.Item
                                        label="单价"
                                        {...restField}
                                        name={[name, 'unitPrice']}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入单价',
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            style={{ width: 80 }}
                                            onChange={(e) => handleChange(form, e, name, formName, type, tempDeleteData)}
                                            placeholder="单价"
                                            min={0} />
                                    </Form.Item>

                                    <Form.Item
                                        label="金额"
                                        {...restField}
                                        name={[name, 'total']}
                                        rules={[
                                            {
                                                required: true,
                                                message: '请输入金额',
                                            },
                                        ]}
                                    >
                                        <InputNumber
                                            style={{ width: 80 }}
                                            disabled
                                            onChange={(e) => handleChange(form, e, name, formName, type, tempDeleteData)}
                                            placeholder="金额"
                                            min={0} />
                                    </Form.Item>

                                    <Form.Item
                                        label="备注"
                                        {...restField}
                                        name={[name, 'memo']}
                                    >
                                        <Input
                                            style={{ width: 220 }}
                                            onChange={(e) => handleChange(form, e, name, formName, type, tempDeleteData)}
                                            placeholder="备注"
                                        />
                                    </Form.Item>

                                    <MinusCircleOutlined onClick={() => removeList(remove, name, key, tempDeleteData)} />
                                </Space>
                            ))}
                            <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => customizeAdd(add, ingredient, tempDeleteData)}
                                    block
                                    icon={<PlusOutlined />}
                                >
                                    添加
                                </Button>
                            </Form.Item>
                        </>
                    )
                }
                }
            </Form.List>
        </>
    )
}