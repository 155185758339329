import React, { useEffect, useState } from 'react'
import io from 'socket.io-client';
import systematic from '../../config/systematic';
import { Button, Input } from 'antd';
import wsServer from '../../utils/system/wsServer';
import { rtnType } from '../../types/general';
import fetchApi from '../../utils/api/fetchApi';
let ws = new wsServer()

export default function SocletIoTest() {
    let wmService = systematic.wmService;

    const [roomId, setRoomId] = useState<string>("1")
    const [socketId, setSocketId] = useState<string>("1");

    const [token,setToken]=useState<string>('1')

    useEffect(() => {
        // ws.connect({
        //     serverUrl: systematic.wsServer.url,
        //     nameSpace: "/wmService",
        //     path: systematic.wsServer.path,
        //     listenerId: "system",
        //     licenseTypeId: "licenseSystem",
        //     roomId: "1",
        //     auth: {
        //         token: String(localStorage.getItem("token")),
        //         // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlvbklkIjoib2RqS1NzeDg5eTBfaFpXdFhKckZJenV2c1h3ZyIsIm5pY2tOYW1lIjoi6YKT5a626L6JIiwiaWF0IjoxNjk3MjU1MTUyLCJleHAiOjE2OTc1MTQzNTJ9.fTXPaluKSVPQZzPaiF6s5X1wZYhcx0O0daSHdWw6pAc"
        //         // token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlvbklkIjoib2RqS1NzOGE3NWhXdkN3Y3lNQ1pVbW8zcTBMZyIsIm5pY2tOYW1lIjoi5ri45a6iIiwiaWF0IjoxNjk3MjU4OTE3LCJleHAiOjE2OTc1MTgxMTd9.vK3zbG7liihU70C3JIkkJHiGyoSPeaf6uqTN3ToCyGY"


        //     },
        // }, {
        //     evs: ["system"],
        //     evsListeners: { ["system"]: wsListener },

        // })

    }, []);
    /**
     * ws监听
     * @param payload 接收到的数据
     * @param callback 回复服务器
     */
    const wsListener = async (payload: rtnType, callback: Function) => {
          
            
        if (payload.success === true) {
            const { data } = payload;
            const { apiId } = data;
            switch (apiId) {
                case 'login':
                    const { token } = data;
                    //保存token
                    break;
                case 'printOrder':
                    const { apiExtend } = data;
                    /** apiExtend转换为windowId */
                    let windowId = apiExtend;

                    break;
                case 'caller':
                    break;
                default:
                    break;
            }
        } else {
        }
    };
    const aa = () => {
        ws.connect({
            serverUrl: systematic.wsServer.url,
            nameSpace: "/wmService",
            path: systematic.wsServer.path,
            listenerId: "wx",
            licenseTypeId: "wxWisca",
            roomId,
            auth: {
                mode:"tokenLogin",
                // token: String(localStorage.getItem("token")),
                token,
            },
        }, {
            evs: ["system"],
            evsListeners: { ["system"]: wsListener },

        })
        // console.log(ws.);
    }
    const bb = (e: any) => {

          console.log("?????");
          
        // ws.emit({
        //     method: "GET",
        //     apiId: "socletIoTest",
        //     apiExtend: "bb",
        //     apiData: {
        //         values: e.target.value
        //     }


        // },{callback:(res:any)=>{
        //     console.log(res);

        // }})
        // ws.emit({
        //     // licenseTypeId:"wxWisca",
        //     method: "GET",
        //     apiId: "menuHead",
        //     apiExtend: "aa",
        //     apiData: {
        //         socketId,
        //         shopTypeId: "f4d9be804e1311eeaeb6f9c46295ca5e",
        //         menuHeadId: "fe2496e04e1311eeaeb6f9c46295ca5e",
        //     }
        // })

        ws.emit({
            // licenseTypeId:"wxWisca",
            method: "POST",
            apiId: "wxWiscaMenuShoppingCart",
            apiExtend: "addShoppingCart",
            apiData: {
                roomId,
                amount: 1,
                menuSpecId: "0450a3104e1411eeaeb6f9c46295ca5e",
            }
        })
    }

    const cc = (e: any) => {
        setSocketId(e.target.value)
    }
    const gg = (e: any) => {
        setRoomId(e.target.value)

    }
    const dd = (e: any) => {
        console.log("e",e.target.value);
        
        setToken(e.target.value)

    }
    return (
        <>
            <div>SocletIoTest</div>

            <Input addonBefore={"连接"} addonAfter={<Button onClick={aa}>连接</Button>} size="large" onChange={gg}></Input>

            <Input size="small" addonBefore={<>通信</>} onChange={cc} onPressEnter={bb}></Input>

            <Input addonBefore={<div>token</div>} size="small" onChange={dd}></Input>

        </>
    )
}
