import { useEffect, useState } from "react";
import { columnsheadId } from "../../universal/columnshead";
import AssetStatementUploadFileEdit from "./AssetStatementUploadFileEdit";
import systemExpand from "../../../config/systemExpand";
import UploadFileListUniversal, { UFLUExtendPropsType } from "../../system/UploadFileListUniversalNewEx";

const UploadFileComponents = (props: any) => {

    //extendProps
    const [extendProps, setExtendProps] = useState<UFLUExtendPropsType>();

    //表头
    const columnsHead = [
        {
            title: '资产',
            dataIndex: 'assetStatement',
            render: (text: any) => {
                console.log(text);
                
                return (
                    <div>{text.aliasName}</div>
                )
            }
        },
    ];

    const filesColumns = columnsheadId
        .concat(columnsHead as []);

    useEffect(() => {
        systemExpand.getConfig({
            id: 'config',
            nodeId: 'files'
        }).then(res => {
            const { assetStatementFilesGroupId } = res as any;
            setExtendProps({
                ...props,
                titleName: '资产表图片',
                apiId: 'assetStatement',
                filesGroupId: assetStatementFilesGroupId,
                fileType: "files",
                //+ File
                //上传 + UploadFile
                //删除 + DeleteFile
                filesColumns,
                showImage: true,
                showDownload: true,
                showStates: true,
                showDefault: true,
                extendIdName: "assetStatementId",
                EditComponent: AssetStatementUploadFileEdit
            })
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (extendProps) {
        return (
            <UploadFileListUniversal props={extendProps} />
        )
    }
}

export default UploadFileComponents;