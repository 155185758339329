import ClientUser from "../components/basic/user/ClientUser"
import { RouteObject } from 'react-router-dom';
import { getPageUrl } from './index';

const routes_webWx = async (licenseList: Array<any>) => {
    const res: RouteObject[] = [
        //房间订位管理
        {
            path: await getPageUrl(licenseList, 'clientUser'),
            element: <ClientUser></ClientUser>
        },

    ]
    return res;
}
export default routes_webWx