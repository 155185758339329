import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import FeastTeamMenuHeadEdit from './FeastTeamMenuHeadEdit';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Button, Space } from 'antd';
import CheckWeekMenu from './CheckWeekMenu';
import FeastTeamMenu from './FeastTeamMenu';

const FeastTeamMenuHead = (props: any) => {

    const ref = useRef<any>();
    const apiId = "feastTeamMenuHead"

    //查看本周福食菜单窗口
    const [checkWeekMenuVisible, setCheckWeekMenuVisible] = useState(false);

    //查看本周数据窗口
    const [feastTeamMenuVisible, setFeastTeamMenuVisible] = useState(false);

    //数据
    const [weekMenuData, setWeekMenuData] = useState<any>();

    const [feastTeamMenuData, setFeastTeamMenuData] = useState<any>();


    useEffect(() => {
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据


    //  打开查看本周福食菜单
    const HandleCheckWeekMenu = (record: any) => {
        // console.log(record);
        // let startDate: any;
        // let endDate: any;
        // let start: any;
        // let end: any;

        // if (Number(dayjs(record.selectDay).day()) > 0) {
        //     start = Number(dayjs(record.selectDay).day()) - Number(1);
        //     end = Number(7) - Number(dayjs(record.selectDay).day());
        // }
        // else {
        //     start = 6;
        //     end = 0;
        // }

        // startDate = dayjs(record.selectDay).subtract(start, 'days');
        // endDate = dayjs(record.selectDay).add(end, 'days');

        // startDate = dayjs(record.selectDay).format('d') === '1' ? record.selectDay : dayjs(startDate.$d).format('YYYY-MM-DD');
        // endDate = dayjs(record.selectDay).format('d') === '0' ? record.selectDay : dayjs(endDate.$d).format('YYYY-MM-DD');

        sendData.id = record.id;
        sendData.startDate = dayjs(record.feastTeamMenu_startDate).format("YYYY-MM-DD");
        sendData.endDate = dayjs(dayjs(record.feastTeamMenu_startDate).add(6, 'day')).format("YYYY-MM-DD");
        sendData.areaId = record.area.id;
        sendData.areaName = record.area.areaName;

        setWeekMenuData(sendData);
        setCheckWeekMenuVisible(true);
    };

    //  关闭查看本周福食菜单
    const onCloseModalCheckWeekMenuVisible = (state: boolean) => {
        setCheckWeekMenuVisible(false);
    };

    //  打开查看本周数据
    const HandleFeastTeamMenu = (record: any) => {

        sendData.id = record.id;
        sendData.startDate = dayjs(record.feastTeamMenu_startDate).format("YYYY-MM-DD");
        sendData.endDate = dayjs(dayjs(record.feastTeamMenu_startDate).add(6, 'day')).format("YYYY-MM-DD");
        sendData.areaId = record.area.id;
        sendData.areaName = record.area.areaName;

        setFeastTeamMenuData(sendData);
        setFeastTeamMenuVisible(true);
    };

    //  关闭查看本周数据
    const onCloseModalFeastTeamMenuVisible = (state: boolean) => {
        setFeastTeamMenuVisible(false);
    };

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
        startDate: '',
        endDate: '',
        areaId: '',
        areaName: '',
    }

    //表头
    const columnsHead = [
        {
            title: '年份',
            dataIndex: 'feastTeamMenu_years',
        },
        {
            title: '第几周',
            dataIndex: 'feastTeamMenu_weeks',
        },
        {
            title: '日期',
            dataIndex: 'feastTeamMenu_days',
            key: 'feastTeamMenu_days',
            render: (text: any, record: any) => {
                return (
                    <div>{dayjs(record.feastTeamMenu_startDate).format("YYYY-MM-DD") + " 至 " + dayjs(dayjs(record.feastTeamMenu_startDate).add(6, 'day')).format("YYYY-MM-DD")}</div>
                )
            }
        },
        {
            title: '所属区域',
            dataIndex: 'areaName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.area.areaName}</div>
                )
            }
        },
        {
            title: '编辑福食菜单',
            dataIndex: 'feastTeamMenu',
            key: 'feastTeamMenu',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => HandleFeastTeamMenu(record)}>编辑福食菜单</Button>
                </Space>
        },
        {
            title: '查看本周福食菜单',
            dataIndex: 'checkWeekMenu',
            key: 'checkWeekMenu',
            render: (text: any, record: any) =>
                <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => HandleCheckWeekMenu(record)}>查看本周福食菜单</Button>
                </Space>
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '福食小组菜单',
        apiId,
        showImage: false,
        showEdit: false,
        showUploadFile: false,
        showStates: false,
        disabledAdd: false,
        showOperation: true,
        showAdd: true,
        pagination: true,
        columns,
        EditComponent: FeastTeamMenuHeadEdit,
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>

            {checkWeekMenuVisible
                ? (<CheckWeekMenu
                    closeModal={onCloseModalCheckWeekMenuVisible} data={weekMenuData} />)
                : null}

            {feastTeamMenuVisible
                ? (<FeastTeamMenu
                    closeModal={onCloseModalFeastTeamMenuVisible} data={feastTeamMenuData} />)
                : null}
        </>
    )
}

export default FeastTeamMenuHead